import { motion } from 'framer-motion'
import { isBrowser } from 'lib/utilities'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import { accordionVariants } from 'styles/motion-variants'

import { ChevronDownIcon } from '@heroicons/react/solid'

type Props = {
  text: string
  href: string
  children: ReactNode
  isSubNavExpanded: (state: boolean) => void
}

const ExpandingNavLink = ({ text, children, isSubNavExpanded, href }: Props) => {
  const [openSubNav, setOpenSubNav] = useState<boolean>(false)
  const router = useRouter()

  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: '-100%' },
  }

  const expandNav = (state: boolean) => {
    setOpenSubNav(state)
    isSubNavExpanded(state)
  }

  const closeNavAndGoTo = () => {
    expandNav(false)
    router.push(href)
  }

  return (
    <>
      <div
        onMouseEnter={() => window.matchMedia('(min-width: 1024px)').matches && expandNav(true)}
        onMouseLeave={() => window.matchMedia('(min-width: 1024px)').matches && expandNav(false)}
        onClick={() => {
          setOpenSubNav(!openSubNav)
          if (isBrowser() && window.innerWidth > 1023) closeNavAndGoTo()
        }}
        className={`z-10 my-auto flex cursor-pointer flex-row items-center p-3 lg:w-fit ${
          openSubNav ? 'text-tella-green-300' : ''
        }`}
      >
        <p>{text}</p>
        <ChevronDownIcon
          height={20}
          width={20}
          className={`transition-transform duration-300 ${openSubNav ? 'rotate-180' : 'rotate-0'}`}
        />
      </div>
      <motion.div
        animate={openSubNav ? 'open' : 'close'}
        transition={{ ease: 'easeInOut' }}
        variants={typeof window !== 'undefined' ? accordionVariants : {}}
        className="z-0 h-0 w-full overflow-hidden bg-tella-blue-300 lg:fixed lg:left-0 lg:top-0"
        onMouseEnter={() => {
          window.matchMedia('(min-width: 1024px)').matches && expandNav(true)
        }}
        onMouseLeave={() => {
          window.matchMedia('(min-width: 1024px)').matches && expandNav(false)
        }}
      >
        <motion.div
          className="m-auto max-w-screen-xl lg:mb-8 lg:pt-16"
          animate={openSubNav ? 'open' : 'closed'}
          variants={typeof window !== 'undefined' && window.matchMedia('(max-width: 1024px)').matches ? variants : {}}
          transition={{ type: 'tween' }}
        >
          {children}
        </motion.div>
      </motion.div>
    </>
  )
}

export default ExpandingNavLink
