import { SVGProps } from 'react'

const ImgNoImage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512" {...props}>
    <g id="no-image" transform="translate(-2062 8510)">
      <circle
        id="Ellipse_34"
        data-name="Ellipse 34"
        cx={256}
        cy={256}
        r={256}
        transform="translate(2062 -8510)"
        fill="#40c6a3"
      />
      <text
        id="NO_IMAGE"
        data-name="NO IMAGE"
        transform="translate(2247 -8242)"
        fill="#fff"
        fontSize={30}
        fontFamily="SegoeUI, Segoe UI"
      >
        <tspan x={0} y={0}>
          {'NO IMAGE'}
        </tspan>
      </text>
    </g>
  </svg>
)

export default ImgNoImage
