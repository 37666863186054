import { SVGProps } from 'react'

const ImgHouseWithTwoPlants = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a686eda2-ee1c-4ee4-8176-9bea73261286{isolation:isolate;}.f36245f5-7eb1-445e-87c5-d9addb164da7{opacity:0.15;mix-blend-mode:multiply;fill:url(#f77d7b93-5cfa-4233-b704-b12fd6b048b4);}.a04dcb63-aeb5-457b-b366-c6e898d13aa6{fill:none;}.aa27ef3e-108c-4dea-a8f3-6d8095112d01{fill:url(#be325e70-186f-4e46-8352-f0d35f2c9166);}.a76264eb-5a74-4659-b779-f26aa6160e52{fill:url(#b26591a6-4f31-447d-ba6c-7dc1fc184a28);}.ec975608-9b1f-499f-8fb8-6b1f7c96284f{fill:url(#fc709683-d6d0-424e-abcf-54b278f32559);}.f73be2e2-c0d1-412a-8afa-01af4dbac95c{fill:url(#e112b870-5619-41f7-ad16-2c0b8568bfa9);}.bdba710c-c4a3-4d8a-b6a9-f7773d9b4b0c{fill:url(#ebd42f4f-29b5-4e64-a37d-f1244ff1d7bc);}.a281e90e-dc31-4c6f-8a6a-7fab492ccb0b{fill:url(#ab6a7768-e90a-40ff-ac42-4f0cdac0b6b0);}.fff3fc31-47c2-4673-8a26-e9dff50b3baa{fill:url(#b99a3588-2f14-473c-9854-793d88e11d26);}.a7fddcd2-3f5a-45a1-954c-bb00b96d5f23{fill:#fff;}.ede1d1a2-9b55-4ccf-890b-d91c3175382c{fill:url(#b3eb4281-c51e-4eed-ba2f-743a5ad6a2eb);}.b2907035-3c98-415e-804e-299b2145170f{fill:url(#f5f19c26-7339-4bda-8899-4a15f01b89c6);}.ad89af4e-5e58-48f7-8f20-1e60b1888f8c{fill:url(#bf471aea-bfee-448c-9c57-8df9246b4f50);}.b773fdfb-3d20-41df-a785-46ec31e8d846{fill:url(#a5f58a8e-f633-411e-8c88-9f782818af52);}'
        }
      </style>
      <linearGradient
        id="f77d7b93-5cfa-4233-b704-b12fd6b048b4"
        x1={-109.15}
        y1={265.89}
        x2={1.46}
        y2={265.89}
        gradientTransform="matrix(1.26, -1.26, 1.78, 1.78, -206.44, -205.96)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" stopOpacity={0} />
        <stop offset={0.15} stopColor="#6d67c4" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#6660c1" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#5953bc" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#4840b5" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#3129ac" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#160ca1" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="be325e70-186f-4e46-8352-f0d35f2c9166"
        x1={102}
        y1={-4139.48}
        x2={410}
        y2={-4139.48}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="b26591a6-4f31-447d-ba6c-7dc1fc184a28"
        x1={107.84}
        y1={-4099.05}
        x2={348.02}
        y2={-3858.87}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="fc709683-d6d0-424e-abcf-54b278f32559"
        x1={292.09}
        y1={-4100.4}
        x2={425.84}
        y2={-3966.65}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="e112b870-5619-41f7-ad16-2c0b8568bfa9"
        x1={289.22}
        y1={-4136.86}
        x2={381.1}
        y2={-4044.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="ebd42f4f-29b5-4e64-a37d-f1244ff1d7bc"
        x1={167.49}
        y1={-4015.26}
        x2={208.98}
        y2={-3919.09}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ab6a7768-e90a-40ff-ac42-4f0cdac0b6b0"
        x1={158.84}
        y1={-4120.05}
        x2={220.2}
        y2={-4058.69}
        xlinkHref="#ebd42f4f-29b5-4e64-a37d-f1244ff1d7bc"
      />
      <linearGradient
        id="b99a3588-2f14-473c-9854-793d88e11d26"
        x1={109.23}
        y1={-4131.18}
        x2={149.11}
        y2={-4091.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="b3eb4281-c51e-4eed-ba2f-743a5ad6a2eb"
        x1={238.07}
        y1={-4130.34}
        x2={272.04}
        y2={-4096.37}
        xlinkHref="#b99a3588-2f14-473c-9854-793d88e11d26"
      />
      <linearGradient
        id="f5f19c26-7339-4bda-8899-4a15f01b89c6"
        x1={271.22}
        y1={-4006.19}
        x2={403.69}
        y2={-4041.82}
        xlinkHref="#fc709683-d6d0-424e-abcf-54b278f32559"
      />
      <linearGradient
        id="bf471aea-bfee-448c-9c57-8df9246b4f50"
        x1={90.84}
        y1={-3967.24}
        x2={275.47}
        y2={-3782.61}
        xlinkHref="#ebd42f4f-29b5-4e64-a37d-f1244ff1d7bc"
      />
      <linearGradient
        id="a5f58a8e-f633-411e-8c88-9f782818af52"
        x1={283.54}
        y1={-4058.72}
        x2={357.26}
        y2={-4016.35}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
    </defs>
    <g className="a686eda2-ee1c-4ee4-8176-9bea73261286">
      <g id="b9b079d1-a254-4205-ba3d-4783c5e8c253" data-name="Layer 1">
        <polygon
          className="f36245f5-7eb1-445e-87c5-d9addb164da7"
          points="397.2 394 258 533.2 0 275.2 115.2 160 397.2 394"
        />
        <g id="bca27fa3-3c56-4cea-8a4a-1f5b0f925bd1" data-name="backdrop">
          <circle className="a04dcb63-aeb5-457b-b366-c6e898d13aa6" cx={256} cy={256} r={256} />
        </g>
        <path className="aa27ef3e-108c-4dea-a8f3-6d8095112d01" d="M408,397H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
        <polygon
          className="a76264eb-5a74-4659-b779-f26aa6160e52"
          points="115.2 160 115.2 393.6 256 393.6 256 89.6 115.2 160"
        />
        <polygon
          className="ec975608-9b1f-499f-8fb8-6b1f7c96284f"
          points="256 393.6 396.8 393.6 396.8 288 339.2 249.6 256 249.6 256 393.6"
        />
        <rect className="f73be2e2-c0d1-412a-8afa-01af4dbac95c" x={275.2} y={316.8} width={102.4} height={76.8} />
        <rect className="bdba710c-c4a3-4d8a-b6a9-f7773d9b4b0c" x={160} y={182.4} width={51.2} height={92.8} />
        <rect className="a281e90e-dc31-4c6f-8a6a-7fab492ccb0b" x={160} y={304} width={51.2} height={89.6} />
        <path
          className="fff3fc31-47c2-4673-8a26-e9dff50b3baa"
          d="M137.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S137.6,370.2,137.6,380.8Z"
        />
        <path
          className="a7fddcd2-3f5a-45a1-954c-bb00b96d5f23"
          d="M121.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,121.6,396.8Z"
        />
        <path
          className="a7fddcd2-3f5a-45a1-954c-bb00b96d5f23"
          d="M168,358.4a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,1,1,3.2,0v16A1.6,1.6,0,0,1,168,358.4Z"
        />
        <path
          className="ede1d1a2-9b55-4ccf-890b-d91c3175382c"
          d="M265.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S265.6,370.2,265.6,380.8Z"
        />
        <path
          className="a7fddcd2-3f5a-45a1-954c-bb00b96d5f23"
          d="M249.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,249.6,396.8Z"
        />
        <polygon
          className="b2907035-3c98-415e-804e-299b2145170f"
          points="396.8 288 256 288 256 249.6 339.2 249.6 396.8 288"
        />
        <polygon
          className="ad89af4e-5e58-48f7-8f20-1e60b1888f8c"
          points="256 96 115.2 166.4 115.2 160 256 89.6 256 96"
        />
        <rect className="b773fdfb-3d20-41df-a785-46ec31e8d846" x={256} y={288} width={140.8} height={3.2} />
        <path
          className="a7fddcd2-3f5a-45a1-954c-bb00b96d5f23"
          d="M160,182.4v92.8h51.2V182.4ZM208,272H163.2V230.4H208Zm0-44.8H163.2V185.6H208Z"
        />
        <path
          className="a7fddcd2-3f5a-45a1-954c-bb00b96d5f23"
          d="M350.4,310.4h-48a1.6,1.6,0,0,1,0-3.2h48a1.6,1.6,0,0,1,0,3.2Z"
        />
        <polygon
          className="a7fddcd2-3f5a-45a1-954c-bb00b96d5f23"
          points="275.2 316.8 275.2 393.6 278.4 393.6 278.4 320 374.4 320 374.4 393.6 377.6 393.6 377.6 316.8 275.2 316.8"
        />
      </g>
    </g>
  </svg>
)

export default ImgHouseWithTwoPlants
