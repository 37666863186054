import { SVGProps } from 'react'

const ImgVideoGlitter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
      {...props}
    >
      <defs>
        <style>
          {
            '.a17ed6fa-ddcd-46d0-a72f-e4aa2bd1d1ef{fill:none;}.fe8b55be-154a-49cd-9515-f70be8b3e885{opacity:0.15;fill:url(#e78c1117-44e4-4a19-b7a3-93cd58a6369a);}.aebb02dd-ede8-4f40-bfb3-4a751e7f5799{fill:url(#ea92d733-92fc-417e-a160-059c9eb52660);}.bf7fcbd2-6d41-4ad3-8a75-627750b900d0{fill:url(#a8745724-8d1d-438c-adb4-764f4b96c697);}.f49fa701-0bc8-400f-a467-c1f6d4910db2{fill:#37c49f;}.aa2742f7-349d-4bda-b955-2279cdc64478{fill:#fff;}.b67bffc9-4cc4-491b-8263-73833db9a0c9{fill:url(#a492e217-5267-48ba-bad4-f93dadf2af2e);}'
          }
        </style>
        <linearGradient
          id="e78c1117-44e4-4a19-b7a3-93cd58a6369a"
          x1={75.22}
          y1={436.79}
          x2={274.61}
          y2={237.39}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="ea92d733-92fc-417e-a160-059c9eb52660"
          x1={387.59}
          y1={4541.19}
          x2={122.1}
          y2={4275.7}
          gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="a8745724-8d1d-438c-adb4-764f4b96c697"
          x1={376.28}
          y1={4509.8}
          x2={133.61}
          y2={4267.14}
          gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5851bc" />
          <stop offset={1} stopColor="#281fa9" />
        </linearGradient>
        <linearGradient
          id="a492e217-5267-48ba-bad4-f93dadf2af2e"
          x1={220.25}
          y1={288.07}
          x2={264.25}
          y2={244.07}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fdf0f3" />
          <stop offset={1} stopColor="#fefafb" />
        </linearGradient>
      </defs>
      <g id="f0f9d07a-83ec-4760-8ac0-93531ef4d994" data-name="Layer 1">
        <path
          className="a17ed6fa-ddcd-46d0-a72f-e4aa2bd1d1ef"
          d="M511.6,255.9c0,141.3-114.6,256-256,256-6.6,0-13.2-.3-19.6-.8h-1.7A255.7,255.7,0,0,1,86.6,448.2c-3.4-3-6.6-6-9.9-9.2l-.5-.5-3.3-3.3-.5-.5c-3.1-3.2-6.2-6.5-9.1-9.9A254.7,254.7,0,0,1,.5,278.2a24.5,24.5,0,0,0-.2-2.7c-.5-6.5-.7-13-.7-19.6,0-141.4,114.6-256,256-256S511.6,114.5,511.6,255.9Z"
        />
        <path
          className="fe8b55be-154a-49cd-9515-f70be8b3e885"
          d="M391.2,354,234.3,511A255.7,255.7,0,0,1,86.6,448.2L63.3,424.8A254.7,254.7,0,0,1,.5,278.2L120.8,157.9Z"
        />
        <rect
          className="aebb02dd-ede8-4f40-bfb3-4a751e7f5799"
          x={118.5}
          y={155.7}
          width={275}
          height={200.68}
          rx={7.4}
        />
        <rect
          className="bf7fcbd2-6d41-4ad3-8a75-627750b900d0"
          x={118.5}
          y={195.8}
          width={275}
          height={160.53}
          rx={7.4}
        />
        <path
          className="f49fa701-0bc8-400f-a467-c1f6d4910db2"
          d="M340.6,142.9a7.9,7.9,0,1,1,7.9-7.9A7.8,7.8,0,0,1,340.6,142.9Zm0-12.7a4.9,4.9,0,1,0,4.9,4.8A4.9,4.9,0,0,0,340.6,130.2Z"
        />
        <path
          className="f49fa701-0bc8-400f-a467-c1f6d4910db2"
          d="M405.9,337.6a1.4,1.4,0,0,1-.9-.3l-11.9-8.9-11.8,8.9a1.5,1.5,0,0,1-1.8,0l-12.8-9.6a1.5,1.5,0,0,1-.3-2.1,1.7,1.7,0,0,1,2.1-.3l11.9,8.9,11.8-8.9a1.5,1.5,0,0,1,1.8,0l11.9,8.9,11.8-8.9a1.7,1.7,0,0,1,2.1.3,1.4,1.4,0,0,1-.3,2.1l-12.7,9.6A1.6,1.6,0,0,1,405.9,337.6Z"
        />
        <path
          className="f49fa701-0bc8-400f-a467-c1f6d4910db2"
          d="M104.4,308a1.4,1.4,0,0,1-1.5-1.5V290.6a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5v15.9A1.5,1.5,0,0,1,104.4,308Z"
        />
        <path
          className="f49fa701-0bc8-400f-a467-c1f6d4910db2"
          d="M112.3,300.1H96.4a1.5,1.5,0,1,1,0-3h15.9a1.5,1.5,0,1,1,0,3Z"
        />
        <path
          className="aa2742f7-349d-4bda-b955-2279cdc64478"
          d="M170.5,183.5a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,1,1,12.8,0A6.4,6.4,0,0,1,170.5,183.5Z"
        />
        <path
          className="aa2742f7-349d-4bda-b955-2279cdc64478"
          d="M196.1,183.5a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,196.1,183.5Z"
        />
        <path
          className="aa2742f7-349d-4bda-b955-2279cdc64478"
          d="M144.9,183.5a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,144.9,183.5Z"
        />
        <polygon
          className="b67bffc9-4cc4-491b-8263-73833db9a0c9"
          points="286.3 266.1 225.8 293.6 225.8 238.6 286.3 266.1"
        />
      </g>
    </svg>
  )
}

export default ImgVideoGlitter
