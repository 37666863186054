import { SVGProps } from 'react'

const ImgHandHoldingIdCard = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
      {...props}
    >
      <defs>
        <style>
          {
            '.eb79cedf-3d08-4b29-8b90-6cc531125bfe{fill:none;}.adbd16d3-8217-4ab1-af6f-31dab36a97b6{opacity:0.15;fill:url(#f88acaea-740a-4cd5-8c06-046e7f773f34);}.e044476e-97bb-4852-9cc1-bf971a319e42{fill:url(#b922e9b9-b4f5-4b0b-a514-57446778a873);}.f698ffaa-ac87-4d59-8ba2-3e52308a5837{fill:url(#a6b0d160-3f29-4d46-bd2f-15559edb09da);}.a88cddde-bf35-4da9-aa82-cbae84f351e1{fill:url(#ec286ea4-2cc7-4249-b738-6b69cd84aea6);}.e33312c6-8e3c-47e7-ab4f-0503e709db38{fill:url(#ba2588f9-3433-4022-97d2-175821eda1d7);}.e44d06ff-a24d-4357-9189-732f5051691a{fill:url(#b651fbcc-a2e6-462c-b1c6-fbb9198e13e1);}.f20c99d0-b178-4cdf-9c4f-72eb32b30627{fill:url(#b25e1fba-db34-440e-80b4-e13173f15571);}'
          }
        </style>
        <linearGradient
          id="f88acaea-740a-4cd5-8c06-046e7f773f34"
          x1={3130.37}
          y1={-1585.19}
          x2={3200.08}
          y2={-1585.19}
          gradientTransform="matrix(2.47, -2.47, 3.21, 3.21, -2541.89, 13165.03)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
          <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="b922e9b9-b4f5-4b0b-a514-57446778a873"
          x1={231.91}
          y1={-9272.44}
          x2={418.49}
          y2={-9545.24}
          gradientTransform="matrix(1, 0, 0, -1, 0, -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="a6b0d160-3f29-4d46-bd2f-15559edb09da"
          x1={291.76}
          y1={-9374.1}
          x2={376.69}
          y2={-9459.03}
          gradientTransform="matrix(1, 0, 0, -1, 0, -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="ec286ea4-2cc7-4249-b738-6b69cd84aea6"
          x1={333.18}
          y1={-9320.32}
          x2={132.22}
          y2={-9108.29}
          gradientTransform="matrix(1, 0, 0, -1, 0, -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="ba2588f9-3433-4022-97d2-175821eda1d7"
          x1={195.7}
          y1={-9241.37}
          x2={250.8}
          y2={-9296.47}
          gradientTransform="matrix(1, 0, 0, -1, 0, -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5fd0b2" />
          <stop offset={1} stopColor="#37c49f" />
        </linearGradient>
        <linearGradient
          id="b651fbcc-a2e6-462c-b1c6-fbb9198e13e1"
          x1={190.16}
          y1={-9196.11}
          x2={236.58}
          y2={-9242.52}
          gradientTransform="matrix(1, 0, 0, -1, 0, -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fdf0f3" />
          <stop offset={1} stopColor="#fefafb" />
        </linearGradient>
        <linearGradient
          id="b25e1fba-db34-440e-80b4-e13173f15571"
          x1={299.22}
          y1={-9329.02}
          x2={350.89}
          y2={-9380.69}
          gradientTransform="matrix(1, 0, 0, -1, 0, -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#706ac5" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
      </defs>
      <g id="ea99cb41-6a7b-4138-84ac-a305588bdf5b" data-name="Layer 1">
        <g id="adec882e-334e-4e3d-affc-ccefbe5d47ae" data-name="backdrop">
          <circle className="eb79cedf-3d08-4b29-8b90-6cc531125bfe" cx={256} cy={256} r={256} />
        </g>
        <polygon
          className="adbd16d3-8217-4ab1-af6f-31dab36a97b6"
          points="390.4 281.6 218.1 453.9 0.5 236.3 121.6 115.2 390.4 281.6"
        />
        <path
          className="e044476e-97bb-4852-9cc1-bf971a319e42"
          d="M336,220.8a20.9,20.9,0,0,0-16,7V160a19.2,19.2,0,0,0-19.2-19.2h-3.2a6.4,6.4,0,0,0-6.4,6.4v-16a16,16,0,0,0-32,0v19.2a6.4,6.4,0,0,0-6.4-6.4h-3.2a19.2,19.2,0,0,0-19.2,19.2v32a6.4,6.4,0,0,0-6.4-6.4h-3.2A19.2,19.2,0,0,0,201.6,208V313.5a36.9,36.9,0,0,0,2.2,12.7l14.8,51.6a36.9,36.9,0,0,1,2.2,12.7v31.9h86.4V379.6a38.2,38.2,0,0,1,6.4-21.2h0L336,320.1a38.3,38.3,0,0,0,6.4-21.3V227.2A6.4,6.4,0,0,0,336,220.8Z"
        />
        <path
          className="f698ffaa-ac87-4d59-8ba2-3e52308a5837"
          d="M336.2,220.8c-14.2-.1-22.6,11.4-22.6,25.6v35.2h-112v31.9a36.9,36.9,0,0,0,2.2,12.7l14.8,51.6a36.9,36.9,0,0,1,2.2,12.7v31.9h86.4V379.6a38.2,38.2,0,0,1,6.4-21.2h0L336,320.1a38.3,38.3,0,0,0,6.4-21.3V227.2A6.3,6.3,0,0,0,336.2,220.8Z"
        />
        <rect className="a88cddde-bf35-4da9-aa82-cbae84f351e1" x={121.6} y={115.2} width={268.8} height={166.4} />
        <path
          className="e33312c6-8e3c-47e7-ab4f-0503e709db38"
          d="M227.2,198.4l32,16v12.8a9.6,9.6,0,0,1-9.6,9.6h-64a9.6,9.6,0,0,1-9.6-9.6V214.4l32-16Z"
        />
        <path
          className="e44d06ff-a24d-4357-9189-732f5051691a"
          d="M214.4,160h6.4a16,16,0,0,1,16,16v12.8A19.2,19.2,0,0,1,217.6,208h0a19.2,19.2,0,0,1-19.2-19.2V176A16,16,0,0,1,214.4,160Z"
        />
        <path
          className="f20c99d0-b178-4cdf-9c4f-72eb32b30627"
          d="M336.2,220.8c-14.2-.1-22.6,11.4-22.6,25.6v35.2c-34.5,5.7-48,32-48.6,52.4-.4,13.8.6,33.6,13.2,39.2l29,6.4a38.2,38.2,0,0,1,6.4-21.2h0L336,320.1a38.3,38.3,0,0,0,6.4-21.3V227.2A6.3,6.3,0,0,0,336.2,220.8Z"
        />
      </g>
    </svg>
  )
}

export default ImgHandHoldingIdCard
