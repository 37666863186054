import ContainerHeader from 'components/elements/containers/container-header'
import TopNavigation from 'components/layouts/navigation/top-navigation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const Header = () => {
  const [isPageScrolled, setIsPageScrolled] = useState<boolean>(false)
  const router = useRouter()

  const changeIsPageScrolled = () => {
    if (window.scrollY >= 1) {
      setIsPageScrolled(true)
    } else setIsPageScrolled(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', changeIsPageScrolled)
  })

  return (
    <div className="print:hidden">
      <div
        className={`fixed left-0 top-0 z-40 w-full overflow-hidden bg-black transition-height ${
          router.pathname === '/' ? '' : 'hidden'
        }`}
      >
        <div className="mx-auto flex h-12 max-w-screen-xl items-center px-4 text-white md:px-6">
          <p className="text-xs sm:text-sm">
            Important disclosure, privacy and complaints information:{' '}
            <Link href="/important-info" passHref>
              <u className="cursor-pointer">view here</u>
            </Link>
          </p>
        </div>
      </div>
      <div
        className={`fixed z-30 w-full ${isPageScrolled ? 'bg-tella-blue-300' : ''} ${
          router.pathname === '/' ? 'top-12' : 'top-0'
        }`}
      >
        <ContainerHeader className={`${isPageScrolled ? ' text-white' : ' text-white md:text-tella-blue-300'}`}>
          <TopNavigation
            isSubMenuOpen={isPageScrolled}
            isPageScrolled={isPageScrolled}
            isMobileNavOpen={(state) => setIsPageScrolled(state)}
          />
        </ContainerHeader>
      </div>
    </div>
  )
}

export default Header
