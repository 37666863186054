import { SVGProps } from 'react'

const ImgHouseWithTwoPlantsGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b0f2c705-eeef-4e98-b2dc-2a3ecac5246d{isolation:isolate;}.b202b6b5-d84a-47bb-ade8-5bdb2a29f586{opacity:0.15;mix-blend-mode:multiply;fill:url(#b3e0438b-51e9-44ec-8d74-812275cab9e0);}.ac054f38-b5d1-4e15-9d82-98cbbe316717{fill:none;}.af3835e2-94f5-4167-9592-6cc74e4c4ea6{fill:url(#ad437ab0-7303-4178-ac40-5b85a6f524f2);}.a3260db7-b1eb-4bf0-b866-8a8fc3479314{fill:url(#a11f3b30-9b8c-489a-a576-84687cc12fdf);}.b208cae9-0a29-496b-bc4e-5d97c54f8e42{fill:url(#ac30e1c1-9fd9-493f-b08b-8eaeb628940e);}.f71f16c0-64c7-4604-a7eb-f37bdcca0f73{fill:url(#e807b78a-a2cf-4187-bed6-feb4b73a4ff3);}.ec5e40a3-1683-4cf9-9102-d818018baf99{fill:url(#f155924a-2f76-47f8-8bc5-355720dd6dd4);}.b555f1b7-38bb-4890-937b-ccc46cf2e9e2{fill:url(#f12408fb-f4ed-4ab7-aff5-7252e8cabeb4);}.ffe0348e-92c9-45c2-914a-a2e36d30b86c{fill:url(#ab12f247-bc03-4e84-8e73-aadde5168560);}.bc059c6c-2979-44c4-8203-e399e56b8f45{fill:#fff;}.fb18fc3c-6359-470a-87e8-689483a7163b{fill:url(#a56c7ce3-2c13-462f-add4-50704d816ea3);}.f370f202-d096-486c-84b9-5747035a9cc9{fill:url(#a86803a1-85cf-4c38-84ef-5ede407274c7);}.a9ce6351-96c7-4080-b981-6224219eccdd{fill:url(#e4b500ab-58a3-4577-8e87-cfa5d7c2ba87);}.a97a5b8c-6f77-4da8-888d-416ae1982fc4{fill:url(#fb8c7cd8-f1d6-49af-90e0-0a6c6b80477f);}.afc0f632-ab74-4384-b5d2-4824d2bb1c35{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="b3e0438b-51e9-44ec-8d74-812275cab9e0"
        x1={-109.15}
        y1={265.89}
        x2={1.46}
        y2={265.89}
        gradientTransform="matrix(1.26, -1.26, 1.78, 1.78, -206.44, -205.96)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" stopOpacity={0} />
        <stop offset={0.15} stopColor="#6d67c4" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#6660c1" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#5953bc" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#4840b5" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#3129ac" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#160ca1" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ad437ab0-7303-4178-ac40-5b85a6f524f2"
        x1={102}
        y1={-4139.48}
        x2={410}
        y2={-4139.48}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="a11f3b30-9b8c-489a-a576-84687cc12fdf"
        x1={107.84}
        y1={-4099.05}
        x2={348.02}
        y2={-3858.87}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="ac30e1c1-9fd9-493f-b08b-8eaeb628940e"
        x1={292.09}
        y1={-4100.4}
        x2={425.84}
        y2={-3966.65}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="e807b78a-a2cf-4187-bed6-feb4b73a4ff3"
        x1={289.22}
        y1={-4136.86}
        x2={381.1}
        y2={-4044.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="f155924a-2f76-47f8-8bc5-355720dd6dd4"
        x1={167.49}
        y1={-4015.26}
        x2={208.98}
        y2={-3919.09}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f12408fb-f4ed-4ab7-aff5-7252e8cabeb4"
        x1={158.84}
        y1={-4120.05}
        x2={220.2}
        y2={-4058.69}
        xlinkHref="#f155924a-2f76-47f8-8bc5-355720dd6dd4"
      />
      <linearGradient
        id="ab12f247-bc03-4e84-8e73-aadde5168560"
        x1={109.23}
        y1={-4131.18}
        x2={149.11}
        y2={-4091.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="a56c7ce3-2c13-462f-add4-50704d816ea3"
        x1={238.07}
        y1={-4130.34}
        x2={272.04}
        y2={-4096.37}
        xlinkHref="#ab12f247-bc03-4e84-8e73-aadde5168560"
      />
      <linearGradient
        id="a86803a1-85cf-4c38-84ef-5ede407274c7"
        x1={271.22}
        y1={-4006.19}
        x2={403.69}
        y2={-4041.82}
        xlinkHref="#ac30e1c1-9fd9-493f-b08b-8eaeb628940e"
      />
      <linearGradient
        id="e4b500ab-58a3-4577-8e87-cfa5d7c2ba87"
        x1={90.84}
        y1={-3967.24}
        x2={275.47}
        y2={-3782.61}
        xlinkHref="#f155924a-2f76-47f8-8bc5-355720dd6dd4"
      />
      <linearGradient
        id="fb8c7cd8-f1d6-49af-90e0-0a6c6b80477f"
        x1={283.54}
        y1={-4058.72}
        x2={357.26}
        y2={-4016.35}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
    </defs>
    <g className="b0f2c705-eeef-4e98-b2dc-2a3ecac5246d">
      <g id="ab7134ae-2070-49a9-9204-7abef11a7042" data-name="Layer 1">
        <polygon
          className="b202b6b5-d84a-47bb-ade8-5bdb2a29f586"
          points="397.2 394 258 533.2 0 275.2 115.2 160 397.2 394"
        />
        <g id="fb5ad2b3-a9b3-4693-933a-4b1f1b590efd" data-name="backdrop">
          <circle className="ac054f38-b5d1-4e15-9d82-98cbbe316717" cx={256} cy={256} r={256} />
        </g>
        <path className="af3835e2-94f5-4167-9592-6cc74e4c4ea6" d="M408,397H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
        <polygon
          className="a3260db7-b1eb-4bf0-b866-8a8fc3479314"
          points="115.2 160 115.2 393.6 256 393.6 256 89.6 115.2 160"
        />
        <polygon
          className="b208cae9-0a29-496b-bc4e-5d97c54f8e42"
          points="256 393.6 396.8 393.6 396.8 288 339.2 249.6 256 249.6 256 393.6"
        />
        <rect className="f71f16c0-64c7-4604-a7eb-f37bdcca0f73" x={275.2} y={316.8} width={102.4} height={76.8} />
        <rect className="ec5e40a3-1683-4cf9-9102-d818018baf99" x={160} y={182.4} width={51.2} height={92.8} />
        <rect className="b555f1b7-38bb-4890-937b-ccc46cf2e9e2" x={160} y={304} width={51.2} height={89.6} />
        <path
          className="ffe0348e-92c9-45c2-914a-a2e36d30b86c"
          d="M137.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S137.6,370.2,137.6,380.8Z"
        />
        <path
          className="bc059c6c-2979-44c4-8203-e399e56b8f45"
          d="M121.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,121.6,396.8Z"
        />
        <path
          className="bc059c6c-2979-44c4-8203-e399e56b8f45"
          d="M168,358.4a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,1,1,3.2,0v16A1.6,1.6,0,0,1,168,358.4Z"
        />
        <path
          className="fb18fc3c-6359-470a-87e8-689483a7163b"
          d="M265.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S265.6,370.2,265.6,380.8Z"
        />
        <path
          className="bc059c6c-2979-44c4-8203-e399e56b8f45"
          d="M249.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,249.6,396.8Z"
        />
        <polygon
          className="f370f202-d096-486c-84b9-5747035a9cc9"
          points="396.8 288 256 288 256 249.6 339.2 249.6 396.8 288"
        />
        <polygon
          className="a9ce6351-96c7-4080-b981-6224219eccdd"
          points="256 96 115.2 166.4 115.2 160 256 89.6 256 96"
        />
        <rect className="a97a5b8c-6f77-4da8-888d-416ae1982fc4" x={256} y={288} width={140.8} height={3.2} />
        <path
          className="bc059c6c-2979-44c4-8203-e399e56b8f45"
          d="M160,182.4v92.8h51.2V182.4ZM208,272H163.2V230.4H208Zm0-44.8H163.2V185.6H208Z"
        />
        <path
          className="bc059c6c-2979-44c4-8203-e399e56b8f45"
          d="M350.4,310.4h-48a1.6,1.6,0,0,1,0-3.2h48a1.6,1.6,0,0,1,0,3.2Z"
        />
        <polygon
          className="bc059c6c-2979-44c4-8203-e399e56b8f45"
          points="275.2 316.8 275.2 393.6 278.4 393.6 278.4 320 374.4 320 374.4 393.6 377.6 393.6 377.6 316.8 275.2 316.8"
        />
        <path
          className="afc0f632-ab74-4384-b5d2-4824d2bb1c35"
          d="M233.6,310.4a1.6,1.6,0,0,1-1.6-1.6V292.9a1.6,1.6,0,0,1,3.2,0v15.9A1.6,1.6,0,0,1,233.6,310.4Z"
        />
        <path
          className="afc0f632-ab74-4384-b5d2-4824d2bb1c35"
          d="M241.6,302.4h-16a1.6,1.6,0,0,1-1.6-1.6,1.7,1.7,0,0,1,1.6-1.6h16a1.7,1.7,0,0,1,1.6,1.6A1.6,1.6,0,0,1,241.6,302.4Z"
        />
        <path
          className="afc0f632-ab74-4384-b5d2-4824d2bb1c35"
          d="M278.6,168a8,8,0,0,1-8-8,8,8,0,0,1,16,0A8,8,0,0,1,278.6,168Zm0-12.8a4.9,4.9,0,0,0-4.8,4.8,4.8,4.8,0,0,0,9.6,0A4.9,4.9,0,0,0,278.6,155.2Z"
        />
        <path
          className="afc0f632-ab74-4384-b5d2-4824d2bb1c35"
          d="M116.8,212.8a1.7,1.7,0,0,1-1-.3L104,203.6l-11.8,8.9a1.7,1.7,0,0,1-1.9,0l-12.8-9.6a1.6,1.6,0,0,1-.3-2.2,1.7,1.7,0,0,1,2.3-.4l11.7,8.9,11.8-8.9a1.8,1.8,0,0,1,2,0l11.8,8.9,11.7-8.9a1.7,1.7,0,0,1,2.3.4,1.6,1.6,0,0,1-.3,2.2l-12.8,9.6Z"
        />
      </g>
    </g>
  </svg>
)

export default ImgHouseWithTwoPlantsGlitter
