import { SVGProps } from 'react'

const ImgSpeechBubbles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b93f756e-f7a8-48cf-adaa-18a5340e6681{fill:none;}.bef25544-9631-4917-a9b0-02e80833642c{opacity:0.15;fill:url(#b3c537b2-489c-40df-a817-8ec2eb8334fc);}.bba84529-2aaf-403d-bf28-2c2787b98e24{fill:url(#a97808b4-bf03-4d30-bffd-6d5bd3d85228);}.e18410b5-b237-495c-9fd1-366782b4526f{fill:url(#e2d9a3a2-ed8e-4ee2-850d-84a502284c5c);}.b2a02904-af0a-4f1f-8b8d-16a7648ec098{fill:#f7ced7;}'
        }
      </style>
      <linearGradient
        id="b3c537b2-489c-40df-a817-8ec2eb8334fc"
        x1={76.56}
        y1={438.58}
        x2={248.32}
        y2={266.82}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a97808b4-bf03-4d30-bffd-6d5bd3d85228"
        x1={392.68}
        y1={8144.67}
        x2={217.56}
        y2={7969.55}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e2d9a3a2-ed8e-4ee2-850d-84a502284c5c"
        x1={115}
        y1={8014.19}
        x2={314.41}
        y2={8213.61}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
    </defs>
    <g id="ae8602ee-04e1-49ed-a5c7-203e64b1a3a3" data-name="Layer 1">
      <g id="bb7f63da-299f-4392-a07d-ebd57158cc2f" data-name="backdrop">
        <path
          className="b93f756e-f7a8-48cf-adaa-18a5340e6681"
          d="M512,256c0,138.2-109.5,250.8-246.4,255.8l-9.6.2A255.5,255.5,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A254.9,254.9,0,0,1,0,256v-3.2C1.7,112.9,115.7,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="bef25544-9631-4917-a9b0-02e80833642c"
        d="M379.5,398,265.6,511.8l-9.6.2A255.5,255.5,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A254.9,254.9,0,0,1,0,256v-3.2L102.4,150.4Z"
      />
      <polygon
        className="bba84529-2aaf-403d-bf28-2c2787b98e24"
        points="192 355.2 332.8 355.2 377.6 400 377.6 355.2 409.6 355.2 409.6 201.6 192 201.6 192 355.2"
      />
      <polygon
        className="e18410b5-b237-495c-9fd1-366782b4526f"
        points="320 304 179.2 304 134.4 348.8 134.4 304 102.4 304 102.4 150.4 320 150.4 320 304"
      />
      <path
        className="b2a02904-af0a-4f1f-8b8d-16a7648ec098"
        d="M211.2,233.6a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,211.2,233.6Z"
      />
      <path
        className="b2a02904-af0a-4f1f-8b8d-16a7648ec098"
        d="M236.8,233.6a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,236.8,233.6Z"
      />
      <path
        className="b2a02904-af0a-4f1f-8b8d-16a7648ec098"
        d="M185.6,233.6a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,185.6,233.6Z"
      />
    </g>
  </svg>
)

export default ImgSpeechBubbles
