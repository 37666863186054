import { SVGProps } from 'react'

const ImgPaperAndPenGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bb16a30f-1c24-430a-940b-23ec3f152c9d{fill:none;}.a7557ce7-f545-40cf-810e-e400533faf06{opacity:0.15;fill:url(#e1061b50-9c75-4eaa-92b3-b9dce83265f8);}.b9f6aa22-a779-44cb-9ba5-0ab0397a7181{fill:url(#be858d78-57fe-465d-9b7f-903f58fb3072);}.eb7b8e64-f2da-4a6b-a023-8e802d157bc0{fill:url(#fed18035-c0cf-41d9-8263-f1c774bff3d6);}.a8d5cbb3-2e8e-4a1e-aebc-c9e4e7304ca1{fill:#fff;}.b2f2d219-1fe9-4e17-b33f-8f267982e2b8{fill:url(#baba22bd-65e9-4527-a7b1-4fbc2e7f6b69);}.a277ef88-c262-4d99-a8ba-b6819221ae1c{fill:url(#f683a51d-96a1-4563-9fd2-250d558f31f9);}.a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="e1061b50-9c75-4eaa-92b3-b9dce83265f8"
        x1={86.26}
        y1={448.14}
        x2={299.2}
        y2={235.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="be858d78-57fe-465d-9b7f-903f58fb3072"
        x1={125.47}
        y1={15525.6}
        x2={146.44}
        y2={15525.6}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="fed18035-c0cf-41d9-8263-f1c774bff3d6"
        x1={131.7}
        y1={15522.4}
        x2={147.61}
        y2={15522.4}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="baba22bd-65e9-4527-a7b1-4fbc2e7f6b69"
        x1={411.57}
        y1={15591.74}
        x2={150.84}
        y2={15462.23}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="f683a51d-96a1-4563-9fd2-250d558f31f9"
        x1={374.15}
        y1={15662.95}
        x2={353.7}
        y2={15642.49}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
    </defs>
    <g id="ab1ac970-a9c8-4908-a3ff-31eab7b77992" data-name="Layer 1">
      <g id="ae2c6d83-7ca0-49fc-bbe1-dbae88218399" data-name="backdrop">
        <path
          className="bb16a30f-1c24-430a-940b-23ec3f152c9d"
          d="M512,256c0,129.8-96.5,237-221.7,253.7A258.9,258.9,0,0,1,256,512,255.1,255.1,0,0,1,80.4,442.3c-3.7-3.5-7.2-7-10.7-10.7A255.2,255.2,0,0,1,.3,268.5C.1,264.4,0,260.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a7557ce7-f545-40cf-810e-e400533faf06"
        d="M390.4,409.6,290.3,509.7A258.9,258.9,0,0,1,256,512,255.1,255.1,0,0,1,80.4,442.3L69.7,431.6A255.2,255.2,0,0,1,.3,268.5L166.4,102.4Z"
      />
      <path
        className="b9f6aa22-a779-44cb-9ba5-0ab0397a7181"
        d="M147.2,384l-9.6,25.6L128,384V112a9.6,9.6,0,0,1,9.6-9.6h0a9.6,9.6,0,0,1,9.6,9.6Z"
      />
      <path
        className="eb7b8e64-f2da-4a6b-a023-8e802d157bc0"
        d="M147.2,153.6h0V364.8h0A12.8,12.8,0,0,1,134.4,352V166.4A12.8,12.8,0,0,1,147.2,153.6Z"
      />
      <path
        className="a8d5cbb3-2e8e-4a1e-aebc-c9e4e7304ca1"
        d="M122.8,242a2,2,0,0,1-2-2V140.8a11.6,11.6,0,0,1,11.6-11.6h18a2,2,0,1,1,0,4h-18a7.6,7.6,0,0,0-7.6,7.6V240A2,2,0,0,1,122.8,242Z"
      />
      <path className="b2f2d219-1fe9-4e17-b33f-8f267982e2b8" d="M352,102.4H166.4V409.6h224V140.8Z" />
      <polygon className="a277ef88-c262-4d99-a8ba-b6819221ae1c" points="352 140.8 390.4 140.8 352 102.4 352 140.8" />
      <path
        className="a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5"
        d="M278.4,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,278.4,262.4Z"
      />
      <path
        className="a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5"
        d="M304,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,304,262.4Z"
      />
      <circle className="a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5" cx={252.8} cy={256} r={6.4} />
      <path
        className="a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5"
        d="M416,321.9a8.3,8.3,0,0,1-8.3-8.3,8.4,8.4,0,1,1,8.3,8.3Zm0-12.7a4.4,4.4,0,0,0-4.3,4.4,4.3,4.3,0,0,0,4.3,4.3,4.4,4.4,0,0,0,4.4-4.3A4.4,4.4,0,0,0,416,309.2Z"
      />
      <path
        className="a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5"
        d="M225.1,383.7a1.9,1.9,0,0,1-1.2-.4l-11.5-8.7-11.6,8.7a2.1,2.1,0,0,1-2.4,0l-12.7-9.6a1.9,1.9,0,0,1-.4-2.8,2.2,2.2,0,0,1,2.8-.4l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2.2,2.2,0,0,1,2.8.4,1.9,1.9,0,0,1-.4,2.8l-12.8,9.6A1.6,1.6,0,0,1,225.1,383.7Z"
      />
      <path
        className="a5a0ba40-b4c7-4699-ad52-3d3c3acdfdd5"
        d="M102.8,238h-6v-6a2,2,0,0,0-4,0v6H86.9a2,2,0,1,0,0,4h5.9v6a2,2,0,0,0,4,0v-6h6a2,2,0,1,0,0-4Z"
      />
    </g>
  </svg>
)

export default ImgPaperAndPenGlitter
