import { SVGProps } from 'react'

const ImgSmileyInBetweenTwoHandsGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.e7e1ae4b-1e82-4593-81d9-f363e0fa7352{isolation:isolate;}.bf405524-664f-4df8-bf98-269cfbc2f01f{fill:none;}.b48bef62-9292-49c7-ad2f-5dc91f3f434e{opacity:0.15;mix-blend-mode:multiply;fill:url(#bf2d1297-aa5f-4fbb-98ab-9676a2c8218f);}.fdadbec8-0626-4aac-a49f-647d4dbfb0b1{fill:url(#be503552-db7d-44aa-b2f1-cb32b772778f);}.f68d408c-8fe1-4d11-8342-ffae438ab537{fill:url(#ba11e3c3-a2bb-41e0-ae08-14a3ae00f7f4);}.a31b441c-2204-4d04-a9ee-d18f05b8e6b1{fill:url(#b662b811-f687-4dee-b8fc-1c8909e3298e);}.ad53e103-a633-4f5e-88cd-f857af65ae58{fill:#ef426f;}.ac8a85c4-60d1-4e1c-b7e1-5b8a68fba70b{fill:url(#ece4c779-a4b2-46be-9c9e-99823f33fbb0);}.fa3eb126-4908-40fd-81af-8afede12a1be{fill:url(#bf08c961-2cf1-4118-85eb-e7da74f8db96);}.a5f5fb4a-da72-4e07-9a2b-ba61783a1ecb{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="bf2d1297-aa5f-4fbb-98ab-9676a2c8218f"
        x1={3.18}
        y1={248.79}
        x2={121.79}
        y2={248.79}
        gradientTransform="matrix(1.32, -1.32, 1.72, 1.72, -315.97, 53.26)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.12} stopColor="#10069f" stopOpacity={0.06} />
        <stop offset={0.32} stopColor="#10069f" stopOpacity={0.21} />
        <stop offset={0.56} stopColor="#10069f" stopOpacity={0.46} />
        <stop offset={0.85} stopColor="#10069f" stopOpacity={0.8} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="be503552-db7d-44aa-b2f1-cb32b772778f"
        x1={-5243.74}
        y1={-1348.41}
        x2={-5205.67}
        y2={-1289.14}
        gradientTransform="matrix(-0.21, -0.98, 0.98, -0.21, 470.02, -5024.32)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ba11e3c3-a2bb-41e0-ae08-14a3ae00f7f4"
        x1={-5359.16}
        y1={-8043.04}
        x2={-5397.23}
        y2={-8102.31}
        gradientTransform="matrix(0.21, 0.98, -0.98, 0.21, -6468.02, 7067.84)"
        xlinkHref="#be503552-db7d-44aa-b2f1-cb32b772778f"
      />
      <radialGradient
        id="b662b811-f687-4dee-b8fc-1c8909e3298e"
        cx={170.82}
        cy={-3919.31}
        r={229.48}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </radialGradient>
      <linearGradient
        id="ece4c779-a4b2-46be-9c9e-99823f33fbb0"
        x1={270.58}
        y1={161.98}
        x2={354.82}
        y2={77.73}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="bf08c961-2cf1-4118-85eb-e7da74f8db96"
        x1={185.59}
        y1={405.86}
        x2={269.83}
        y2={321.61}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
    </defs>
    <g className="e7e1ae4b-1e82-4593-81d9-f363e0fa7352">
      <g id="bbd89055-6d13-4482-b383-ed7456bb05f7" data-name="Layer 1">
        <g id="bb0b5bc5-8103-4ad7-977c-89cea97f032b" data-name="backdrop">
          <circle className="bf405524-664f-4df8-bf98-269cfbc2f01f" cx={256} cy={256} r={256} />
        </g>
        <polygon
          className="b48bef62-9292-49c7-ad2f-5dc91f3f434e"
          points="345.2 392.2 188.3 549.1 42.5 403.3 104.2 341.6 216.5 378.1 345.2 392.2"
        />
        <path
          className="fdadbec8-0626-4aac-a49f-647d4dbfb0b1"
          d="M204.8,409.6,332.2,383a2.7,2.7,0,0,0,2-3.3c0-.2-.1-.3-.2-.5l-.7-1.8a16.9,16.9,0,0,0-19.7-9.4l-97.1,10.1Z"
        />
        <path
          className="f68d408c-8fe1-4d11-8342-ffae438ab537"
          d="M307.2,102.4,179.8,129a2.7,2.7,0,0,0-2,3.3c0,.2.1.3.2.5l.7,1.8a16.9,16.9,0,0,0,19.7,9.4l97.1-10.1Z"
        />
        <path
          className="a31b441c-2204-4d04-a9ee-d18f05b8e6b1"
          d="M256,153.6A102.4,102.4,0,1,0,358.4,256,102.3,102.3,0,0,0,256,153.6Z"
        />
        <path
          className="ad53e103-a633-4f5e-88cd-f857af65ae58"
          d="M288,224a12.8,12.8,0,0,1,12.8,12.8,3.2,3.2,0,1,1-6.4,0,6.4,6.4,0,1,0-12.8,0,3.2,3.2,0,0,1-6.4,0A12.8,12.8,0,0,1,288,224Z"
        />
        <path
          className="ad53e103-a633-4f5e-88cd-f857af65ae58"
          d="M224,224a12.8,12.8,0,0,1,12.8,12.8,3.2,3.2,0,0,1-6.4,0,6.4,6.4,0,0,0-12.8,0,3.2,3.2,0,1,1-6.4,0A12.8,12.8,0,0,1,224,224Z"
        />
        <path
          className="ad53e103-a633-4f5e-88cd-f857af65ae58"
          d="M306.7,283.2a60.8,60.8,0,0,1-101.4,0,3.3,3.3,0,0,1,.9-4.4,3.2,3.2,0,0,1,4.5.9,54.4,54.4,0,0,0,75.4,15.2,55,55,0,0,0,15.2-15.2,3.2,3.2,0,0,1,4.5-.9,3.3,3.3,0,0,1,.9,4.4Z"
        />
        <path
          className="ac8a85c4-60d1-4e1c-b7e1-5b8a68fba70b"
          d="M403.2,119h-.6l-28.2,2.6a66,66,0,0,1-19.2-3.2L320,105.6a70.5,70.5,0,0,0-12.8-3.2c-3.2,0-136.3,15.4-136.3,15.4-1.8.4-4.5.6-4.5,3.8s7.8,13.8,16,12.8l113.1-.5,19.9,14.8-14.1.3c-13.8-3.6-28.4-.9-29.2,7.3l-.3,2.6a3.1,3.1,0,0,0,2.7,3.5s74.3,10.4,87.1,10.4h41.6a6.4,6.4,0,0,0,6.4-6.4v-41A6.4,6.4,0,0,0,403.2,119Z"
        />
        <path
          className="fa3eb126-4908-40fd-81af-8afede12a1be"
          d="M329.6,377.6l-113.1.5-19.9-14.8,14.1-.3c13.8,3.6,28.4.9,29.2-7.3l.3-2.6a3.1,3.1,0,0,0-2.7-3.5s-74.3-10.4-87.1-10.4H108.8a6.4,6.4,0,0,0-6.4,6.4v41a6.4,6.4,0,0,0,6.4,6.4h.6l28.2-2.6a66,66,0,0,1,19.2,3.2L192,406.4a70.5,70.5,0,0,0,12.8,3.2c3.2,0,136.3-15.4,136.3-15.4,1.8-.4,4.5-.6,4.5-3.8S337.8,376.6,329.6,377.6Z"
        />
        <path
          className="a5f5fb4a-da72-4e07-9a2b-ba61783a1ecb"
          d="M133.9,225.5a7.9,7.9,0,1,1,7.9-7.9A7.8,7.8,0,0,1,133.9,225.5Zm0-12.7a4.8,4.8,0,0,0-4.8,4.8,4.9,4.9,0,1,0,9.7,0A4.8,4.8,0,0,0,133.9,212.8Z"
        />
        <path
          className="a5f5fb4a-da72-4e07-9a2b-ba61783a1ecb"
          d="M363,327.2a1.2,1.2,0,0,1-.9-.3L350.2,318l-11.8,8.9a1.5,1.5,0,0,1-1.8,0l-12.8-9.6a1.5,1.5,0,0,1-.3-2.1,1.6,1.6,0,0,1,2.1-.3l11.9,8.9,11.8-8.9a1.5,1.5,0,0,1,1.8,0l11.9,8.9,11.8-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.7,9.6A1.6,1.6,0,0,1,363,327.2Z"
        />
        <path
          className="a5f5fb4a-da72-4e07-9a2b-ba61783a1ecb"
          d="M350.2,147.4h-6.5v-6.5a1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5v6.5h-6.4a1.5,1.5,0,0,0,0,3h6.4v6.5a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6.5h6.5a1.5,1.5,0,0,0,0-3Z"
        />
      </g>
    </g>
  </svg>
)

export default ImgSmileyInBetweenTwoHandsGlitter
