import { SVGProps } from 'react'

const ImgHouseLeakyGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ac259b70-cd64-4a08-bed4-c2d46210f1f3{isolation:isolate;}.b89434bd-1821-4def-92bb-e8d9a6059928{fill:none;}.e5c3dd2f-8180-40d6-8b66-b8221d29df9b{opacity:0.15;mix-blend-mode:multiply;fill:url(#ec29ef31-f48d-4b7f-98ff-41898b23ee14);}.ba2f0ff8-f920-4645-966e-cc03ffcf8ff6{fill:#fff;}.eccffc56-09cb-4e7c-b12c-1b2770262e9d{fill:url(#ffe82cef-dd30-4bbd-a026-ba0a68aeee3d);}.b1ec3bd4-d763-406a-b5e5-8909caa88643{fill:url(#ac1effa4-057c-4b3c-9487-1678a8976138);}.af9548fd-27ce-41e4-9d46-80b241544433{fill:url(#b4469da3-804a-41a4-8ec1-e5e031d97523);}.a984c651-db66-4863-a9d9-670dc5589165{fill:url(#fb2ec0a4-75c6-4d55-8ca5-1296e6f8ab3b);}.ef0ec351-7cdf-4f6d-9ac7-b6d06f219c75{fill:url(#a1375334-6cf4-4dd2-80c7-9f807d1f8bc0);}.e1ade98f-4f8f-4349-9f6b-2774656172a9{fill:url(#b662f1e0-7051-4e89-8f7d-b43375e7a8f4);}.bde7cd62-04b5-4153-b813-f5d6eee610ab{fill:url(#e4cb5d3d-c254-403d-9a32-938ed7214344);}.a78571a3-1e3b-422d-b4ee-d07382c0acc7{fill:url(#b4c61ded-456f-48be-af87-f5de202bed08);}.b3dbb4bb-e83c-4d84-9d45-370eba80550e{fill:#37c49f;}.e17facb5-ee00-494d-b531-812faedc18f6{fill:url(#b22426a3-e85d-42ba-b50a-6da2e9e095a2);}.ef54d4fb-56ed-4009-a2d0-be5b9ecda6f2{fill:url(#e31e6bf3-4f04-413c-966c-59422726ee61);}'
        }
      </style>
      <linearGradient
        id="ec29ef31-f48d-4b7f-98ff-41898b23ee14"
        x1={89.65}
        y1={450.84}
        x2={276.95}
        y2={263.54}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" stopOpacity={0} />
        <stop offset={0.16} stopColor="#6e67c4" stopOpacity={0.03} />
        <stop offset={0.32} stopColor="#6660c1" stopOpacity={0.1} />
        <stop offset={0.48} stopColor="#5a53bc" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#4941b5" stopOpacity={0.41} />
        <stop offset={0.8} stopColor="#322aad" stopOpacity={0.64} />
        <stop offset={0.96} stopColor="#180ea2" stopOpacity={0.92} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ffe82cef-dd30-4bbd-a026-ba0a68aeee3d"
        x1={107.84}
        y1={6759.03}
        x2={348.02}
        y2={6999.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="ac1effa4-057c-4b3c-9487-1678a8976138"
        x1={292.09}
        y1={6757.68}
        x2={425.84}
        y2={6891.43}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="b4469da3-804a-41a4-8ec1-e5e031d97523"
        x1={289.22}
        y1={6721.22}
        x2={381.1}
        y2={6813.09}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="fb2ec0a4-75c6-4d55-8ca5-1296e6f8ab3b"
        x1={158.84}
        y1={6738.03}
        x2={220.2}
        y2={6799.39}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a1375334-6cf4-4dd2-80c7-9f807d1f8bc0"
        x1={109.23}
        y1={6726.9}
        x2={149.11}
        y2={6766.78}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="b662f1e0-7051-4e89-8f7d-b43375e7a8f4"
        x1={238.07}
        y1={6727.74}
        x2={272.04}
        y2={6761.71}
        xlinkHref="#a1375334-6cf4-4dd2-80c7-9f807d1f8bc0"
      />
      <linearGradient
        id="e4cb5d3d-c254-403d-9a32-938ed7214344"
        x1={290.21}
        y1={6808.6}
        x2={356.38}
        y2={6874.78}
        xlinkHref="#ac1effa4-057c-4b3c-9487-1678a8976138"
      />
      <linearGradient
        id="b4c61ded-456f-48be-af87-f5de202bed08"
        x1={152.16}
        y1={94.55}
        x2={217.32}
        y2={159.72}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#fb2ec0a4-75c6-4d55-8ca5-1296e6f8ab3b"
      />
      <linearGradient
        id="b22426a3-e85d-42ba-b50a-6da2e9e095a2"
        x1={167.37}
        y1={372.3}
        x2={151.76}
        y2={387.91}
        gradientTransform="translate(367.8 630.5) rotate(180)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b7b4e2" />
        <stop offset={0.99} stopColor="#cfcdec" />
      </linearGradient>
      <linearGradient
        id="e31e6bf3-4f04-413c-966c-59422726ee61"
        x1={169.92}
        y1={197.63}
        x2={257.98}
        y2={109.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b7b4e2" />
        <stop offset={1} stopColor="#e7e6f5" />
      </linearGradient>
    </defs>
    <g className="ac259b70-cd64-4a08-bed4-c2d46210f1f3">
      <g id="b32b2fda-1d05-4441-83fe-6fd7ce730fd8" data-name="Layer 1">
        <g id="a4df9e4f-90e4-44fc-9b11-67f5a1721a94" data-name="backdrop">
          <path
            className="b89434bd-1821-4def-92bb-e8d9a6059928"
            d="M512,256c0,127.4-93.1,233.1-214.9,252.7A256.3,256.3,0,0,1,87.8,449a266,266,0,0,1-24.9-24.9A254.8,254.8,0,0,1,.7,274.6C.2,268.4,0,262.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
          />
        </g>
        <path
          className="e5c3dd2f-8180-40d6-8b66-b8221d29df9b"
          d="M409.6,396.2,297.1,508.7A256.3,256.3,0,0,1,87.8,449L62.9,424.1A254.8,254.8,0,0,1,.7,274.6L115.2,160,278.4,393.6Z"
        />
        <path className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6" d="M408,397H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
        <polygon
          className="eccffc56-09cb-4e7c-b12c-1b2770262e9d"
          points="115.2 160 115.2 393.6 256 393.6 256 89.6 115.2 160"
        />
        <polygon
          className="b1ec3bd4-d763-406a-b5e5-8909caa88643"
          points="256 393.6 396.8 393.6 396.8 288 339.2 249.6 256 249.6 256 393.6"
        />
        <rect className="af9548fd-27ce-41e4-9d46-80b241544433" x={275.2} y={316.8} width={102.4} height={76.8} />
        <rect className="a984c651-db66-4863-a9d9-670dc5589165" x={160} y={304} width={51.2} height={89.6} />
        <path
          className="ef0ec351-7cdf-4f6d-9ac7-b6d06f219c75"
          d="M137.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S137.6,370.2,137.6,380.8Z"
        />
        <path
          className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6"
          d="M121.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,121.6,396.8Z"
        />
        <path
          className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6"
          d="M168,358.4a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,1,1,3.2,0v16A1.6,1.6,0,0,1,168,358.4Z"
        />
        <path
          className="e1ade98f-4f8f-4349-9f6b-2774656172a9"
          d="M265.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S265.6,370.2,265.6,380.8Z"
        />
        <path
          className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6"
          d="M249.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,249.6,396.8Z"
        />
        <polygon
          className="bde7cd62-04b5-4153-b813-f5d6eee610ab"
          points="396.8 288 256 288 256 249.6 339.2 249.6 396.8 288"
        />
        <polygon
          className="a78571a3-1e3b-422d-b4ee-d07382c0acc7"
          points="256 89.6 256 96 160 144 115.2 166.4 115.2 160 160 137.6 256 89.6"
        />
        <rect className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6" x={256} y={288} width={140.8} height={3.2} />
        <path
          className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6"
          d="M350.4,310.4h-48a1.6,1.6,0,0,1,0-3.2h48a1.6,1.6,0,0,1,0,3.2Z"
        />
        <polygon
          className="ba2f0ff8-f920-4645-966e-cc03ffcf8ff6"
          points="275.2 316.8 275.2 393.6 278.4 393.6 278.4 320 374.4 320 374.4 393.6 377.6 393.6 377.6 316.8 275.2 316.8"
        />
        <path
          className="b3dbb4bb-e83c-4d84-9d45-370eba80550e"
          d="M241.6,278.1h-6.4v-6.4a1.6,1.6,0,0,0-3.2,0v6.4h-6.4a1.6,1.6,0,1,0,0,3.2H232v6.4a1.6,1.6,0,0,0,3.2,0v-6.4h6.4a1.6,1.6,0,1,0,0-3.2Z"
        />
        <path
          className="b3dbb4bb-e83c-4d84-9d45-370eba80550e"
          d="M278.6,121.5a7.9,7.9,0,0,1-8-7.9,8,8,0,0,1,16,0A7.9,7.9,0,0,1,278.6,121.5Zm0-12.7a4.8,4.8,0,1,0,4.8,4.8A4.8,4.8,0,0,0,278.6,108.8Z"
        />
        <path
          className="b3dbb4bb-e83c-4d84-9d45-370eba80550e"
          d="M123.3,212.8a1.7,1.7,0,0,1-1-.3l-11.8-8.9-11.8,8.9a1.7,1.7,0,0,1-1.9,0l-12.7-9.6a1.5,1.5,0,0,1-.4-2.2,1.7,1.7,0,0,1,2.3-.4l11.8,8.9,11.8-8.9a1.7,1.7,0,0,1,1.9,0l11.8,8.9,11.8-8.9a1.6,1.6,0,0,1,2.2.4,1.6,1.6,0,0,1-.3,2.2l-12.8,9.6Z"
        />
        <path
          className="e17facb5-ee00-494d-b531-812faedc18f6"
          d="M209.8,261.6a9.8,9.8,0,0,0,9.8-9.8c0-9.5-9.8-19.6-9.8-19.6s-9.8,10.1-9.8,19.6A9.8,9.8,0,0,0,209.8,261.6Z"
        />
        <path
          className="ef54d4fb-56ed-4009-a2d0-be5b9ecda6f2"
          d="M244,95.6v65.7a10,10,0,0,1-20,0v-4.9a1.3,1.3,0,0,1-.1.6v-.7a2,2,0,0,0-2-2,2,2,0,0,0-1.9,1.4v57.7a10,10,0,0,1-20,0V160.8a10,10,0,0,0-20,0v22.8a10,10,0,1,1-20,0v-46Z"
        />
      </g>
    </g>
  </svg>
)

export default ImgHouseLeakyGlitter
