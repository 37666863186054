import { SVGProps } from 'react'

const ImgPlantWithCoinsGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.aa7caf5a-7223-467d-8831-c9fdb25b83cd{isolation:isolate;}.f1b0c095-8c35-4c6e-8487-a4ff509dbf21{opacity:0.15;mix-blend-mode:multiply;fill:url(#b99d0f49-aec9-491f-92b6-13fc91e9033d);}.e2712582-5d71-4ee0-bb84-c158443d7d2d{fill:none;}.b7996ae5-aee9-466a-ab9a-b6735a6dc48a{fill:#fff;}.a45470f3-2420-4257-b537-27ddf302f1f2{fill:url(#a3a280cc-c308-4263-96b2-b82721b82aae);}.f0a434b7-c68d-4190-8901-1651206055bd{fill:url(#b25cf505-d3c1-419c-93ae-ceb10f625f4a);}.ef8df103-4dbf-4371-bb17-244dc88a3088{fill:url(#ae071877-0f1d-4af2-a3df-d6c33032fbe6);}.a0ec11dd-7728-4d47-83f2-76261d5ee18a{fill:url(#ec5c0a89-1859-4be1-a278-b85bb8975ef4);}.e3fe4d39-028f-4b28-bc8c-50036a6994c0{fill:url(#b035cc2a-d369-442f-b0d0-b644af21f2a2);}.fe7e7a9b-563e-4109-8586-51a935c4c398{fill:url(#e4d32875-2848-4111-9e21-cffeb42af485);}.a7d6ea0f-ffc3-4160-87b0-cf5aaef0aaa6{fill:url(#e929f4af-fd09-4212-b3d2-f08934035fc6);}.a12295a7-1488-4576-83ce-defeee13592e{fill:url(#a4d1d86e-2e79-4cf7-9457-f79b4dcca4a0);}.ab329463-61b0-4259-bb93-144e749f024b{fill:url(#a6a68450-bf42-4e05-a91d-625e233812c6);}.b01fd0e1-b450-411b-9eef-8e8e3754fe84{fill:url(#e1472fb9-f14a-48f9-aa59-427c2bb5fca3);}.a432709b-d490-4abd-b3bb-464020e7ce40{fill:url(#b3c80a3a-210a-41df-9eb1-cc2024250f9c);}.aaab0e95-9ec8-475a-ad79-dac5563c3aa7{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="b99d0f49-aec9-491f-92b6-13fc91e9033d"
        x1={-181.74}
        y1={372.68}
        x2={14.92}
        y2={372.68}
        gradientTransform="matrix(0.97, -0.97, 1.04, 1.04, -103.11, -94.08)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a3a280cc-c308-4263-96b2-b82721b82aae"
        x1={156.45}
        y1={-4044.18}
        x2={360.42}
        y2={-4034.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="b25cf505-d3c1-419c-93ae-ceb10f625f4a"
        x1={255.6}
        y1={-3962.83}
        x2={265.49}
        y2={-3926.79}
        gradientTransform="matrix(1, 0, 0, -1, 4.5, -3744.08)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="ae071877-0f1d-4af2-a3df-d6c33032fbe6"
        x1={256}
        y1={153.6}
        x2={313.6}
        y2={153.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="ec5c0a89-1859-4be1-a278-b85bb8975ef4"
        x1={204.8}
        y1={118.4}
        x2={256}
        y2={118.4}
        xlinkHref="#ae071877-0f1d-4af2-a3df-d6c33032fbe6"
      />
      <linearGradient
        id="b035cc2a-d369-442f-b0d0-b644af21f2a2"
        x1={147.79}
        y1={-4118.29}
        x2={214.04}
        y2={-4052.05}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        xlinkHref="#b25cf505-d3c1-419c-93ae-ceb10f625f4a"
      />
      <linearGradient
        id="e4d32875-2848-4111-9e21-cffeb42af485"
        x1={156.57}
        y1={365.03}
        x2={201.83}
        y2={319.77}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e929f4af-fd09-4212-b3d2-f08934035fc6"
        x1={253.55}
        y1={-4120.71}
        x2={259.44}
        y2={-4100.94}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        xlinkHref="#b25cf505-d3c1-419c-93ae-ceb10f625f4a"
      />
      <linearGradient
        id="a4d1d86e-2e79-4cf7-9457-f79b4dcca4a0"
        x1={253.55}
        y1={-4107.91}
        x2={259.11}
        y2={-4089.24}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        xlinkHref="#b25cf505-d3c1-419c-93ae-ceb10f625f4a"
      />
      <linearGradient
        id="a6a68450-bf42-4e05-a91d-625e233812c6"
        x1={243.67}
        y1={-4099.21}
        x2={272.5}
        y2={-4070.38}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        xlinkHref="#b25cf505-d3c1-419c-93ae-ceb10f625f4a"
      />
      <linearGradient
        id="e1472fb9-f14a-48f9-aa59-427c2bb5fca3"
        x1={253.55}
        y1={-4133.51}
        x2={259.29}
        y2={-4114.26}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        xlinkHref="#b25cf505-d3c1-419c-93ae-ceb10f625f4a"
      />
      <linearGradient
        id="b3c80a3a-210a-41df-9eb1-cc2024250f9c"
        x1={200.27}
        y1={267.13}
        x2={311.73}
        y2={155.67}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#a3a280cc-c308-4263-96b2-b82721b82aae"
      />
    </defs>
    <g className="aa7caf5a-7223-467d-8831-c9fdb25b83cd">
      <g id="aa2b7e80-529c-4816-962b-9ee2340ab6ca" data-name="Layer 1">
        <polygon
          className="f1b0c095-8c35-4c6e-8487-a4ff509dbf21"
          points="316.6 387.8 409.4 389.5 218.5 580.5 -2.9 359.1 148.9 207.3 316.6 387.8"
        />
        <circle className="e2712582-5d71-4ee0-bb84-c158443d7d2d" cx={256} cy={256} r={256} />
        <path
          className="b7996ae5-aee9-466a-ab9a-b6735a6dc48a"
          d="M256,315.2a1.6,1.6,0,0,1-1.6-1.6V84.8a1.6,1.6,0,0,1,3.2,0V313.6A1.6,1.6,0,0,1,256,315.2Z"
        />
        <path
          className="a45470f3-2420-4257-b537-27ddf302f1f2"
          d="M315.6,387.2H196.4a12.8,12.8,0,0,1-12.6-10.7L154.7,212.2a6.4,6.4,0,0,1,5.4-7.3H350.9a6.4,6.4,0,0,1,6.4,6.4v1L328.2,376.5A12.8,12.8,0,0,1,315.6,387.2Z"
        />
        <path
          className="f0a434b7-c68d-4190-8901-1651206055bd"
          d="M166.4,217.5s40.1-32,89.6-31.9,89.6,32.1,89.6,32.1Z"
        />
        <path
          className="ef8df103-4dbf-4371-bb17-244dc88a3088"
          d="M294.4,134.4h19.2a38.4,38.4,0,0,1-38.4,38.4H256A38.4,38.4,0,0,1,294.4,134.4Z"
        />
        <path
          className="a0ec11dd-7728-4d47-83f2-76261d5ee18a"
          d="M256,134.4H236.8a32,32,0,0,1-32-32H224a32,32,0,0,1,32,32Z"
        />
        <circle className="e3fe4d39-028f-4b28-bc8c-50036a6994c0" cx={179.2} cy={342.4} r={44.8} />
        <circle className="fe7e7a9b-563e-4109-8586-51a935c4c398" cx={179.2} cy={342.4} r={32} />
        <path
          className="b7996ae5-aee9-466a-ab9a-b6735a6dc48a"
          d="M408,390.4H104a1.6,1.6,0,0,1,0-3.2H408a1.6,1.6,0,0,1,0,3.2Z"
        />
        <rect className="a7d6ea0f-ffc3-4160-87b0-cf5aaef0aaa6" x={224} y={361.6} width={64} height={12.8} rx={3.2} />
        <rect className="a12295a7-1488-4576-83ce-defeee13592e" x={224} y={348.8} width={64} height={12.8} rx={3.2} />
        <rect className="ab329463-61b0-4259-bb93-144e749f024b" x={224} y={336} width={64} height={12.8} rx={3.2} />
        <rect className="b01fd0e1-b450-411b-9eef-8e8e3754fe84" x={224} y={374.4} width={64} height={12.8} rx={3.2} />
        <path
          className="a432709b-d490-4abd-b3bb-464020e7ce40"
          d="M153.6,205H358.4a6.4,6.4,0,0,1,6.4,6.4h0a6.4,6.4,0,0,1-6.4,6.4H153.6a6.4,6.4,0,0,1-6.4-6.4h0A6.4,6.4,0,0,1,153.6,205Z"
        />
        <path
          className="aaab0e95-9ec8-475a-ad79-dac5563c3aa7"
          d="M154.7,174.3a7.9,7.9,0,1,1,7.8-7.9A7.9,7.9,0,0,1,154.7,174.3Zm0-12.8a4.9,4.9,0,1,0,0,9.8,4.9,4.9,0,0,0,0-9.8Z"
        />
        <path
          className="aaab0e95-9ec8-475a-ad79-dac5563c3aa7"
          d="M351.8,299.1a1.6,1.6,0,0,1-.9-.3l-11.8-8.9-11.9,8.9a1.5,1.5,0,0,1-1.8,0l-12.7-9.6a1.5,1.5,0,0,1-.3-2.1,1.6,1.6,0,0,1,2.1-.3l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,0l11.8,8.9,11.9-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.7,9.6A1.7,1.7,0,0,1,351.8,299.1Z"
        />
        <path
          className="aaab0e95-9ec8-475a-ad79-dac5563c3aa7"
          d="M321.6,79.8h-6.5V73.4a1.5,1.5,0,1,0-3,0v6.4h-6.5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5h6.5v6.5a1.5,1.5,0,1,0,3,0V82.8h6.5a1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,321.6,79.8Z"
        />
      </g>
    </g>
  </svg>
)

export default ImgPlantWithCoinsGlitter
