import { SVGProps } from 'react'

const ImgMoneyWithArrows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b2756de1-7c87-40d1-9930-c0ec54dbc2c8{opacity:0.15;fill:url(#f37fbeda-0edc-4a41-a131-ecd901d1083f);}.abf2ce70-a2cd-46cb-8658-3b72c9b29b27{fill:none;}.bcb1d7ad-312e-43ef-8074-457157f5c92e{fill:url(#b9b5c20d-dcae-4aab-938c-4a603128eff4);}.fd704489-e006-40d5-9032-6a26fa9f389c{fill:url(#e004d789-c387-440c-9715-25806023f828);}.ac78c3f9-dc7a-438d-895f-b010adb719fa{fill:url(#b0dbc169-0771-49c8-ba7a-57e5facb3a4c);}.e21da365-7564-4a89-b3ea-bbea23e646d1{fill:url(#b63cd9e5-d8f8-415d-9b3c-073f1c4427f0);}.a9add8e4-2370-47e6-a165-55c1c1afd422{fill:url(#a92768a0-91b2-4a51-bedf-04414767309e);}.b21dd800-506c-45d1-a106-526feb9cede0{fill:url(#e107bf39-0b2e-4cb5-8ecc-ac9896fb7fce);}.aa32c2eb-4f36-43c3-9c76-6e58b30f8afc{fill:#f7ced7;}.a90cf762-8baa-466d-92ea-1ddf85c01e82,.bed54730-7327-43fd-965a-e3eaf048339d{fill:#fff;}.a90cf762-8baa-466d-92ea-1ddf85c01e82{isolation:isolate;}'
        }
      </style>
      <linearGradient
        id="f37fbeda-0edc-4a41-a131-ecd901d1083f"
        x1={74.99}
        y1={437.01}
        x2={294.4}
        y2={217.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b9b5c20d-dcae-4aab-938c-4a603128eff4"
        x1={357.64}
        y1={9407.23}
        x2={150.73}
        y2={9200.33}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9561.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="e004d789-c387-440c-9715-25806023f828"
        x1={181.08}
        y1={251.48}
        x2={190.12}
        y2={260.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="b0dbc169-0771-49c8-ba7a-57e5facb3a4c"
        x1={155.48}
        y1={251.48}
        x2={164.52}
        y2={260.52}
        xlinkHref="#e004d789-c387-440c-9715-25806023f828"
      />
      <linearGradient
        id="b63cd9e5-d8f8-415d-9b3c-073f1c4427f0"
        x1={347.48}
        y1={251.48}
        x2={356.52}
        y2={260.52}
        xlinkHref="#e004d789-c387-440c-9715-25806023f828"
      />
      <linearGradient
        id="a92768a0-91b2-4a51-bedf-04414767309e"
        x1={321.88}
        y1={251.48}
        x2={330.92}
        y2={260.52}
        xlinkHref="#e004d789-c387-440c-9715-25806023f828"
      />
      <linearGradient
        id="e107bf39-0b2e-4cb5-8ecc-ac9896fb7fce"
        x1={212}
        y1={256}
        x2={300}
        y2={256}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
    </defs>
    <g id="bc2678ee-941f-4720-bf1a-fb7dbaadd61e" data-name="Layer 1">
      <path
        className="b2756de1-7c87-40d1-9930-c0ec54dbc2c8"
        d="M406.4,329.6l-181,180.9A254.3,254.3,0,0,1,77.6,439.6l-4.4-4.4A254.8,254.8,0,0,1,2.1,285.9L105.6,182.4Z"
      />
      <g id="ff59838f-05cc-43b6-b10d-55156307656f" data-name="backdrop">
        <path
          className="abf2ce70-a2cd-46cb-8658-3b72c9b29b27"
          d="M512.4,256.4c0,141.4-114.6,256-256,256a244.6,244.6,0,0,1-31-1.9A254.3,254.3,0,0,1,77.6,439.6l-4.4-4.4A254.8,254.8,0,0,1,2.1,285.9,261.1,261.1,0,0,1,.4,256.4C.4,115,115,.4,256.4.4S512.4,115,512.4,256.4Z"
        />
      </g>
      <path className="bcb1d7ad-312e-43ef-8074-457157f5c92e" d="M105.6,182.4V329.6H406.4V182.4Z" />
      <path
        className="fd704489-e006-40d5-9032-6a26fa9f389c"
        d="M185.6,262.4A6.4,6.4,0,1,1,192,256,6.4,6.4,0,0,1,185.6,262.4Z"
      />
      <path
        className="ac78c3f9-dc7a-438d-895f-b010adb719fa"
        d="M160,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,160,262.4Z"
      />
      <path
        className="e21da365-7564-4a89-b3ea-bbea23e646d1"
        d="M352,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,352,262.4Z"
      />
      <path
        className="a9add8e4-2370-47e6-a165-55c1c1afd422"
        d="M326.4,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,326.4,262.4Z"
      />
      <path className="b21dd800-506c-45d1-a106-526feb9cede0" d="M256,300a44,44,0,1,1,44-44A44,44,0,0,1,256,300Z" />
      <circle className="aa32c2eb-4f36-43c3-9c76-6e58b30f8afc" cx={256} cy={256} r={40} />
      <path
        className="a90cf762-8baa-466d-92ea-1ddf85c01e82"
        d="M270.2,128.2l-12.8-12.8h0l-.3-.3h-2.2l-.3.3h0l-12.8,12.8a2,2,0,1,0,2.8,2.8l9.4-9.4V392a2,2,0,0,0,4,0V121.6l9.4,9.4a1.9,1.9,0,0,0,2.8,0A1.9,1.9,0,0,0,270.2,128.2Z"
      />
      <path
        className="bed54730-7327-43fd-965a-e3eaf048339d"
        d="M292.6,150.6l-12.8-12.8h0a1.9,1.9,0,0,0-2.8,0h0l-12.8,12.8a2,2,0,0,0,2.8,2.8l9.4-9.4V408a2,2,0,0,0,4,0V144l9.4,9.4a1.9,1.9,0,0,0,2.8,0A1.9,1.9,0,0,0,292.6,150.6Z"
      />
      <path
        className="bed54730-7327-43fd-965a-e3eaf048339d"
        d="M247.8,150.6,235,137.8h0a1.9,1.9,0,0,0-2.8,0h0l-12.8,12.8a1.9,1.9,0,0,0,0,2.8,1.9,1.9,0,0,0,2.8,0l9.4-9.4V408a2,2,0,0,0,4,0V144l9.4,9.4a2,2,0,0,0,2.8-2.8Z"
      />
    </g>
  </svg>
)

export default ImgMoneyWithArrows
