type Props = {
  href: string
  text: string
  onClick?: () => void
  openInNewTab?: boolean
}

const FooterLink = ({ href, text, onClick, openInNewTab }: Props) => {
  return (
    <a
      href={href}
      className="hover:underline"
      onClick={onClick}
      target={openInNewTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {text}
    </a>
  )
}

export default FooterLink
