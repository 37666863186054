import { SVGProps } from 'react'

const ImgSheetOfPaper = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b6735502-96f7-468c-ae2e-9fa25f10cbfb{fill:none;}.b072c34b-c914-4e72-9285-273eb6420d30{opacity:0.15;fill:url(#e4186bf3-1095-4dc3-a413-f9db9880e17a);}.ea335767-5507-4b52-be02-e4d48f8038c7{fill:url(#a5c76147-d0a7-41ba-b1ff-5787ff934e57);}.b1dae14b-33c7-485a-a21f-2474bc3cfb60{fill:url(#adc844a8-8ef8-4dbd-a173-6516d03ac40a);}.b55a06a1-f433-41b0-9865-7c9db3d75784{fill:url(#f582a73e-ace1-43b6-9736-fec05da5f970);}.e9fa852f-8fcf-45c1-b78b-e9ed1b3291b9{fill:#37c49f;}.b7d615da-5ccd-4133-b127-5b7a1803ddd9{fill:#fff;opacity:0.9;isolation:isolate;}'
        }
      </style>
      <linearGradient
        id="e4186bf3-1095-4dc3-a413-f9db9880e17a"
        x1={77.47}
        y1={437.71}
        x2={265.21}
        y2={249.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a5c76147-d0a7-41ba-b1ff-5787ff934e57"
        x1={262.83}
        y1={11740.45}
        x2={404.06}
        y2={11881.68}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="adc844a8-8ef8-4dbd-a173-6516d03ac40a"
        x1={279.57}
        y1={11773.97}
        x2={378.6}
        y2={11872.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="f582a73e-ace1-43b6-9736-fec05da5f970"
        x1={346.18}
        y1={11860.55}
        x2={124.39}
        y2={11638.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
    </defs>
    <g id="f4ae5491-187d-47df-9d57-2104a4d1c10e" data-name="Layer 1">
      <g id="bfefc599-0633-44f0-8472-2b34a42fa0b2" data-name="backdrop">
        <path
          className="b6735502-96f7-468c-ae2e-9fa25f10cbfb"
          d="M512,256c0,141.4-114.6,256-256,256-7.8,0-15.5-.4-23.1-1A256,256,0,0,1,1.6,284.9,275.3,275.3,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b072c34b-c914-4e72-9285-273eb6420d30"
        d="M379.5,364.3,232.9,511a255.6,255.6,0,0,1-139-56.8L57.8,418.1A255.7,255.7,0,0,1,1.6,284.9L132.7,153.8Z"
      />
      <path
        className="ea335767-5507-4b52-be02-e4d48f8038c7"
        d="M204.8,115.2V352a19.2,19.2,0,0,1-19.2,19.2H364.8A19.2,19.2,0,0,0,384,352h0V115.2l-12.8-12.8-12.8,12.8-12.8-12.8-12.8,12.8L320,102.4l-12.8,12.8-12.8-12.8-12.8,12.8-12.8-12.8L256,115.2l-12.8-12.8-12.8,12.8-12.8-12.8Z"
      />
      <path
        className="b1dae14b-33c7-485a-a21f-2474bc3cfb60"
        d="M345.6,352V166.4a19.2,19.2,0,0,0-19.2-19.2H147.2a19.2,19.2,0,0,1,19.2,19.2V352a19.2,19.2,0,0,0,19.2,19.2H364.8A19.2,19.2,0,0,1,345.6,352Z"
      />
      <path
        className="b55a06a1-f433-41b0-9865-7c9db3d75784"
        d="M307.2,166.4a19.2,19.2,0,0,1,19.2-19.2H147.2A19.2,19.2,0,0,0,128,166.4V396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8L192,409.6l12.8-12.8,12.8,12.8,12.8-12.8,12.8,12.8L256,396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8Z"
      />
      <rect className="e9fa852f-8fcf-45c1-b78b-e9ed1b3291b9" x={233.6} y={355.2} width={44.8} height={12.8} />
      <path
        className="b7d615da-5ccd-4133-b127-5b7a1803ddd9"
        d="M278.4,213.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="b7d615da-5ccd-4133-b127-5b7a1803ddd9"
        d="M278.4,245.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="b7d615da-5ccd-4133-b127-5b7a1803ddd9"
        d="M278.4,277.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="b7d615da-5ccd-4133-b127-5b7a1803ddd9"
        d="M278.4,309.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="b7d615da-5ccd-4133-b127-5b7a1803ddd9"
        d="M278.4,341.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
    </g>
  </svg>
)

export default ImgSheetOfPaper
