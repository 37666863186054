import { SVGProps } from 'react'

const ImgFolderWithArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ede7db04-c3e0-41a2-8455-608aa40b1455{fill:none;}.a9af7230-a815-4e0e-8d6c-c7511b74bb55{opacity:0.15;fill:url(#e97a1cdb-cab8-4de0-8af3-3fc9850d148b);}.b1c6781c-9c6a-484f-a769-26b2974af138{fill:url(#bdb5eafe-db76-45d3-a10e-4801dd8c4cbc);}.a36bfe51-3663-4f6d-b947-33807ca0340d{fill:#fff;}.e7a52b1d-4c32-4767-b8b6-5d116bbc18e2{fill:url(#a6558cd0-d4f1-4a86-a2ff-407dd370a1eb);}.e25c6a25-77c4-4e94-8ee0-a5c432a15ce9{fill:#f7ced7;}'
        }
      </style>
      <linearGradient
        id="e97a1cdb-cab8-4de0-8af3-3fc9850d148b"
        x1={89.55}
        y1={451.02}
        x2={279.95}
        y2={260.62}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bdb5eafe-db76-45d3-a10e-4801dd8c4cbc"
        x1={73.75}
        y1={15683.84}
        x2={435.73}
        y2={15321.87}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="a6558cd0-d4f1-4a86-a2ff-407dd370a1eb"
        x1={365.28}
        y1={15366.65}
        x2={94.9}
        y2={15637.03}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
    </defs>
    <g id="e350def3-82f3-41c5-b976-964b5dcbf05f" data-name="Layer 1">
      <g id="a8bcb7e9-5891-4f87-90a0-8a3a98ae3f5c" data-name="backdrop">
        <path
          className="ede7db04-c3e0-41a2-8455-608aa40b1455"
          d="M512,256c0,131.2-98.7,239.3-225.9,254.2A249.5,249.5,0,0,1,256,512,256.1,256.1,0,0,1,1.4,283.3,272.6,272.6,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a9af7230-a815-4e0e-8d6c-c7511b74bb55"
        d="M407.8,388.5,286.1,510.2A249.5,249.5,0,0,1,256,512,254.8,254.8,0,0,1,85.3,446.8L65.1,426.6A255.5,255.5,0,0,1,1.4,283.3L121.6,163.2Z"
      />
      <path
        className="b1c6781c-9c6a-484f-a769-26b2974af138"
        d="M230.4,188.8l-25.6-25.6H121.6V384a6.4,6.4,0,0,0,6.4,6.4H403.2a6.4,6.4,0,0,0,6.4-6.4V195.2a6.4,6.4,0,0,0-6.4-6.4Z"
      />
      <path
        className="a36bfe51-3663-4f6d-b947-33807ca0340d"
        d="M308.5,123.5,270.1,85.1l-.4-.4h0l-.7-.5h-.2l-.7-.4H268l-.8-.3H267l-.8-.2h-1.9l-.8.2h-.1l-1.5.8h-.2l-.6.6h0l-38.4,38.4a6.4,6.4,0,0,0,9,9l27.5-27.4V326.4a6.4,6.4,0,1,0,12.8,0V105.1l27.5,27.4a6.3,6.3,0,0,0,9,0A6.3,6.3,0,0,0,308.5,123.5Z"
      />
      <path
        className="e7a52b1d-4c32-4767-b8b6-5d116bbc18e2"
        d="M211.2,220.8l-25.6-25.6H102.4L121,384.7a6.4,6.4,0,0,0,6.4,5.7H402.7a6.4,6.4,0,0,0,6.4-6.4v-.5L390.9,226.7a6.5,6.5,0,0,0-6.4-5.9Z"
      />
      <path className="e25c6a25-77c4-4e94-8ee0-a5c432a15ce9" d="M358.4,258H323.2a2,2,0,0,1,0-4h35.2a2,2,0,0,1,0,4Z" />
    </g>
  </svg>
)

export default ImgFolderWithArrowUp
