import { SVGProps } from 'react'

const ImgPaperAndPen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bd852421-def1-4bc9-a2e9-26ad2601fa5c{fill:none;}.f03a8cac-c636-4383-8d35-c697041ec92d{opacity:0.15;fill:url(#eca13da6-dc87-4118-b887-6faa67d5243a);}.a84eb0f5-7ee0-4b05-bb3c-fbeff763e9b7{fill:url(#a9c5faf1-c639-4680-9fcb-35e5552145d3);}.a1eaa1d2-c36b-4f25-89a2-3cdcb0e1fcbb{fill:url(#e89fbb5f-669a-4371-ae2a-afe825c1d163);}.fa503af9-440d-46f6-99dc-0ebdcc8cc6ee{fill:#fff;}.fce018d5-392b-4a3f-97f9-67f1303360b1{fill:url(#afb6a06f-24ad-487a-9f02-9c3f2b7c6099);}.a85fb44d-b6a1-4867-b69a-7fc23de9c9da{fill:url(#b0310b6b-2440-44af-aa27-9a031424e43e);}.b917725c-894a-4da0-a617-3fd1777de08b{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="eca13da6-dc87-4118-b887-6faa67d5243a"
        x1={86.26}
        y1={448.14}
        x2={299.2}
        y2={235.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a9c5faf1-c639-4680-9fcb-35e5552145d3"
        x1={125.47}
        y1={15525.6}
        x2={146.44}
        y2={15525.6}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="e89fbb5f-669a-4371-ae2a-afe825c1d163"
        x1={131.7}
        y1={15522.4}
        x2={147.61}
        y2={15522.4}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="afb6a06f-24ad-487a-9f02-9c3f2b7c6099"
        x1={411.57}
        y1={15591.74}
        x2={150.84}
        y2={15462.23}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="b0310b6b-2440-44af-aa27-9a031424e43e"
        x1={374.15}
        y1={15662.95}
        x2={353.7}
        y2={15642.49}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
    </defs>
    <g id="adccce21-2b22-4d1b-8e02-963ba81aae29" data-name="Layer 1">
      <g id="ba6ea83c-56fa-4bbc-8c8b-6658bf40bbdd" data-name="backdrop">
        <path
          className="bd852421-def1-4bc9-a2e9-26ad2601fa5c"
          d="M512,256c0,129.8-96.5,237-221.7,253.7A258.9,258.9,0,0,1,256,512,255.1,255.1,0,0,1,80.4,442.3c-3.7-3.5-7.2-7-10.7-10.7A255.2,255.2,0,0,1,.3,268.5C.1,264.4,0,260.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="f03a8cac-c636-4383-8d35-c697041ec92d"
        d="M390.4,409.6,290.3,509.7A258.9,258.9,0,0,1,256,512,255.1,255.1,0,0,1,80.4,442.3L69.7,431.6A255.2,255.2,0,0,1,.3,268.5L166.4,102.4Z"
      />
      <path
        className="a84eb0f5-7ee0-4b05-bb3c-fbeff763e9b7"
        d="M147.2,384l-9.6,25.6L128,384V112a9.6,9.6,0,0,1,9.6-9.6h0a9.6,9.6,0,0,1,9.6,9.6Z"
      />
      <path
        className="a1eaa1d2-c36b-4f25-89a2-3cdcb0e1fcbb"
        d="M147.2,153.6h0V364.8h0A12.8,12.8,0,0,1,134.4,352V166.4A12.8,12.8,0,0,1,147.2,153.6Z"
      />
      <path
        className="fa503af9-440d-46f6-99dc-0ebdcc8cc6ee"
        d="M122.8,242a2,2,0,0,1-2-2V140.8a11.6,11.6,0,0,1,11.6-11.6h18a2,2,0,1,1,0,4h-18a7.6,7.6,0,0,0-7.6,7.6V240A2,2,0,0,1,122.8,242Z"
      />
      <path className="fce018d5-392b-4a3f-97f9-67f1303360b1" d="M352,102.4H166.4V409.6h224V140.8Z" />
      <polygon className="a85fb44d-b6a1-4867-b69a-7fc23de9c9da" points="352 140.8 390.4 140.8 352 102.4 352 140.8" />
      <path
        className="b917725c-894a-4da0-a617-3fd1777de08b"
        d="M278.4,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,278.4,262.4Z"
      />
      <path
        className="b917725c-894a-4da0-a617-3fd1777de08b"
        d="M304,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,304,262.4Z"
      />
      <circle className="b917725c-894a-4da0-a617-3fd1777de08b" cx={252.8} cy={256} r={6.4} />
    </g>
  </svg>
)

export default ImgPaperAndPen
