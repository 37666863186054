import TagManager from 'react-gtm-module'
import scrollIntoView from 'scroll-into-view-if-needed'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'
import { BlogPost } from 'types/strapi/blog-posts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapEnumToOptions = (_enum: any): IOption<number, string>[] => {
  return Object.keys(_enum)
    .filter((key) => typeof _enum[key] === 'number')
    .map((key) => ({ key: _enum[key], value: key }))
}

export const classNames = (...classes: (string | undefined)[]): string => {
  return classes.filter(Boolean).join(' ')
}

export interface IOption<TKey, TValue> {
  key: TKey
  value: TValue
}

export interface IApplicantsSummary {
  options: IOption<string, string>[]
  isSoloApplicant: boolean | undefined
  soloApplicantId: string
  isDualApplicant: boolean | undefined
  dualApplicantToggleOptions: IOption<number, string>[]
  dualApplicantToggleAnswerValues: string[][]
  translationSuffixKey: string
  names: string
}

export enum Frequency {
  Year = 1,
  Month = 12,
  Week = 52,
}

export enum FrequencyInMonth {
  Month = 1,
  Fortnight = 2,
  Week = 4,
}

export const frequencyOptions: IOption<Frequency, string>[] = [
  { key: Frequency.Year, value: 'per Year' },
  { key: Frequency.Month, value: 'per Month' },
  { key: Frequency.Week, value: 'per Week' },
]

export const frequencyOptionsMonth: IOption<FrequencyInMonth, string>[] = [
  { key: FrequencyInMonth.Month, value: 'per Month' },
  { key: FrequencyInMonth.Fortnight, value: 'per Fortnight' },
  { key: FrequencyInMonth.Week, value: 'per Week' },
]

export enum YesNo {
  Yes = 1,
  No = 0,
}

export const yesNoOptions = (yes = 'Yes', no = 'No'): IOption<YesNo, string>[] => {
  return [
    { key: YesNo.Yes, value: yes },
    { key: YesNo.No, value: no },
  ]
}

export const booleanToYesNoOption = (input: boolean | undefined): YesNo | undefined => {
  return input === true ? YesNo.Yes : input === false ? YesNo.No : undefined
}

export const yesNoOptionToBoolean = (input: YesNo | number | string): boolean => {
  return input === YesNo.Yes || input === YesNo.Yes.toString() ? true : false
}

export const calculateAsYearly = (frequency: Frequency, value: number): number => {
  switch (frequency) {
    case Frequency.Month:
      return 12 * value || 0
    case Frequency.Week:
      return 52 * value || 0
    default:
      return value || 0
  }
}
export const calculateAsMonthly = (frequency: FrequencyInMonth, value: number): number => {
  switch (frequency) {
    case FrequencyInMonth.Fortnight:
      return 2 * value || 0
    case FrequencyInMonth.Week:
      return 4 * value || 0
    default:
      return value || 0
  }
}

/** Create applicants summary object, which is a helper object throughout the application */
// export const getApplicantsSummary = (
//   mortgageApplicationStepBorrowers: MortgageApplicationStepBorrowersDto
// ): IApplicantsSummary => {
//   const applicantsSummary: IApplicantsSummary = {
//     options: [],
//     isSoloApplicant: undefined,
//     soloApplicantId: '',
//     isDualApplicant: undefined,
//     dualApplicantToggleOptions: [],
//     dualApplicantToggleAnswerValues: [[]],
//     translationSuffixKey: '',
//     names: '',
//   }
//   if (mortgageApplicationStepBorrowers) {
//     if (mortgageApplicationStepBorrowers.primaryApplicant == null) return applicantsSummary
//     /** The full options array is more for 3+ borrowers */
//     applicantsSummary.options.push({
//       key: mortgageApplicationStepBorrowers.primaryApplicant.id ?? '',
//       value: `${mortgageApplicationStepBorrowers.primaryApplicant.firstName} ${mortgageApplicationStepBorrowers.primaryApplicant.lastName}`,
//     })
//     mortgageApplicationStepBorrowers.otherApplicants.forEach((otherApplicant) => {
//       applicantsSummary.options.push({
//         key: otherApplicant.id ?? '',
//         value: `${otherApplicant.firstName} ${otherApplicant.lastName}`,
//       })
//     })
//     if (
//       mortgageApplicationStepBorrowers.otherApplicants.length === 0 &&
//       mortgageApplicationStepBorrowers.primaryApplicant
//     ) {
//       /** In single borrower scenario we won't be showing any question so don't need options */
//       applicantsSummary.isSoloApplicant = true
//       applicantsSummary.isDualApplicant = false
//       applicantsSummary.soloApplicantId = mortgageApplicationStepBorrowers.primaryApplicant.id ?? ''
//       applicantsSummary.translationSuffixKey = '1'
//       applicantsSummary.names = applicantsSummary.options[0].value.split(' ')[0]
//     } else if (
//       mortgageApplicationStepBorrowers.otherApplicants.length === 1 &&
//       mortgageApplicationStepBorrowers.primaryApplicant
//     ) {
//       /** This gives us the required data to just use a toggle button approach for dual applicants */
//       applicantsSummary.isSoloApplicant = false
//       applicantsSummary.isDualApplicant = true
//       applicantsSummary.dualApplicantToggleOptions = [
//         { key: 0, value: `${applicantsSummary.options[0].value.split(' ')[0]}` },
//         { key: 1, value: `${applicantsSummary.options[1].value.split(' ')[0]}` },
//         {
//           key: 2,
//           value: `Both ${applicantsSummary.options[0].value.split(' ')[0]} and ${
//             applicantsSummary.options[1].value.split(' ')[0]
//           }`,
//         },
//       ]
//       applicantsSummary.dualApplicantToggleAnswerValues = [
//         [applicantsSummary.options[0].key],
//         [applicantsSummary.options[1].key],
//         [applicantsSummary.options[0].key, applicantsSummary.options[1].key],
//       ]
//       applicantsSummary.translationSuffixKey = '2'
//       applicantsSummary.names =
//         applicantsSummary.options[0].value.split(' ')[0] + ' & ' + applicantsSummary.options[1].value.split(' ')[0]
//     } else {
//       applicantsSummary.isSoloApplicant = false
//       applicantsSummary.isDualApplicant = false
//       applicantsSummary.translationSuffixKey = '3+'
//       applicantsSummary.names = 'everyone'
//     }
//   }
//   return applicantsSummary
// }

export const addRemoveDaysFromDate = (date: string, days: number) => {
  const result = new window.Date(date)
  result.setDate(result.getDate() + days)
  return window.Date.parse(result.toString())
}

export const formatFirstLetterCaps = (str?: string) =>
  str?.replace(/(\B)[^ ]*/g, (match) => match.toLowerCase()).replace(/^[^ ]/g, (match) => match.toUpperCase()) ?? ''

export const formatCamelCaseOrNumberToLowerWithHyphen = (str?: string) =>
  str
    ?.replace(/([A-Z])/g, '-$1')
    .replace(/(\d)/i, '-$1')
    .toLowerCase()

export const dateToday = new Date()

export const isBrowser = (): boolean => typeof window !== 'undefined'

/** import multiple files from a folder
 * https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
 * USAGE EXAMPLE: const images = importAll(require.context('/public/logos', false, /\.(png|jpe?g|svg)$/))
 */
export const importAll = (r: __WebpackModuleApi.RequireContext) => {
  return r.keys().map(r) as string[]
}

export const scrollToElement = (id: string) => {
  /**Use native smooth scrolling when it's available, otherwise fallback to smooth scrolling ponyfill
   * basically smooth scrolling ponyfill works for unsupported browsers like Safari, Safari iOS and IE*/
  const scrollIntoViewSmoothly =
    'scrollBehavior' in document.documentElement.style ? scrollIntoView : smoothScrollIntoView

  const element = isBrowser() && document.getElementById(id)
  return element && scrollIntoViewSmoothly(element, { behavior: 'smooth', block: 'start' })
}

const fireFbPixelEvent = (eventName: string, eventParams?: string) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      ReactPixel.init(process.env.NEXT_PUBLIC_GTM_ID as string)
      ReactPixel.trackCustom(eventName, { eventParams })
    })
}

export const fireCalculatorInteractionEvent = (event: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
    },
  })
  fireFbPixelEvent(event)
}

export const fireGAEvent = ({ event, element }: { event: string; element?: string }) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      element,
    },
  })
}

export const getBlogThumbnailUrl = (post?: BlogPost) => {
  return post?.attributes.thumbnail.data.attributes.url
}

export const getBlogSquareThumbnailUrl = (post?: BlogPost) => {
  return post?.attributes.squareThumbnail?.data
    ? post?.attributes.squareThumbnail.data.attributes.url
    : getBlogThumbnailUrl(post)
}

export const getEstimateReadingTime = (text: string) => {
  const words = text.match(/\w+/g)

  const wordCount = words ? words.length : 0

  const minute = Math.ceil(wordCount / 30)

  return `${minute} minute read`
}
