import { SVGProps } from 'react'

const ImgChecklist = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.fbd27cf1-123c-4725-bc14-100e66eb3a12{opacity:0.15;fill:url(#a0b7ef30-2c78-490a-a259-ae38215aa085);}.a70a66a6-69e3-4268-951b-b90dc2e50e15{fill:none;}.e807413b-8839-4425-ad5f-056e11075a1b{fill:url(#b05bad92-cab2-40b8-9942-538037c9339f);}.a5f802ab-a784-4a21-ba3e-27c30791288f{fill:url(#e3c379fd-481e-47e5-9066-e6008f12caae);}.b30570ab-8a71-4062-abe6-393c501f6fd5{fill:url(#a5017b64-a2a2-4cf0-ad8c-97e4bcfa723a);}.fe7678b3-b1da-475a-a568-053715b9eacc{fill:url(#b53e3b87-e70f-4cf0-8059-3b0fe8300f18);}.aad0be21-99ea-40d2-8687-e710068c4925{fill:url(#e6cbdad1-2d98-4915-89bb-16b358c4691f);}.f350043c-d562-4157-b438-2a779e85d500{fill:url(#bb676d04-9a21-4a81-a1c0-2da521e3730a);}.bd5b947a-0713-459f-9d1f-4f4dcdd17906{fill:#fff;}.f7671c2a-bf08-4c5f-a27a-dbd7618bb848{fill:url(#b68d01f9-0028-4c17-966f-907838c40371);}.e53bee72-c79b-4ca6-8c43-a6733911d399{fill:url(#ecb3f9e3-3ac9-4b4c-bf1f-90a7c250bcc8);}.ad2f0839-736a-4fc1-bb19-ad737beb0137{fill:url(#f06a3222-35ab-4ec1-b499-46940f374425);}.b3824527-a382-4971-a8a9-43dca74657d1{fill:url(#ef18b2ff-e423-4cca-993b-061fe31004e9);}.a4fcee3d-5173-402b-aacd-3c67c4316b3c{fill:url(#f3d8acc9-73e0-4464-8b5f-6a90d9d93b16);}'
        }
      </style>
      <linearGradient
        id="a0b7ef30-2c78-490a-a259-ae38215aa085"
        x1={67.03}
        y1={428.97}
        x2={259.2}
        y2={236.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b05bad92-cab2-40b8-9942-538037c9339f"
        x1={372.8}
        y1={388.8}
        x2={107.2}
        y2={123.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="e3c379fd-481e-47e5-9066-e6008f12caae"
        x1={135.64}
        y1={15424.44}
        x2={357.89}
        y2={15646.68}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a5017b64-a2a2-4cf0-ad8c-97e4bcfa723a"
        x1={208.24}
        y1={15701.08}
        x2={278.33}
        y2={15630.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="b53e3b87-e70f-4cf0-8059-3b0fe8300f18"
        x1={144.43}
        y1={15762.16}
        x2={443.37}
        y2={15463.22}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={0.99} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e6cbdad1-2d98-4915-89bb-16b358c4691f"
        x1={267.36}
        y1={15418.75}
        x2={775.29}
        y2={15926.68}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="bb676d04-9a21-4a81-a1c0-2da521e3730a"
        x1={370.37}
        y1={15660.96}
        x2={387.89}
        y2={15678.48}
        xlinkHref="#e3c379fd-481e-47e5-9066-e6008f12caae"
      />
      <radialGradient
        id="b68d01f9-0028-4c17-966f-907838c40371"
        cx={179.2}
        cy={204.8}
        r={6.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </radialGradient>
      <radialGradient
        id="ecb3f9e3-3ac9-4b4c-bf1f-90a7c250bcc8"
        cx={179.2}
        cy={230.4}
        r={6.4}
        xlinkHref="#b68d01f9-0028-4c17-966f-907838c40371"
      />
      <radialGradient
        id="f06a3222-35ab-4ec1-b499-46940f374425"
        cx={179.2}
        cy={256}
        r={6.4}
        xlinkHref="#b68d01f9-0028-4c17-966f-907838c40371"
      />
      <radialGradient
        id="ef18b2ff-e423-4cca-993b-061fe31004e9"
        cx={179.2}
        cy={281.6}
        r={6.4}
        xlinkHref="#b68d01f9-0028-4c17-966f-907838c40371"
      />
      <radialGradient
        id="f3d8acc9-73e0-4464-8b5f-6a90d9d93b16"
        cx={179.2}
        cy={307.2}
        r={6.4}
        xlinkHref="#b68d01f9-0028-4c17-966f-907838c40371"
      />
    </defs>
    <g id="e895035f-d2c9-4f04-b509-9bde1cf83c9b" data-name="Layer 1">
      <path
        className="fbd27cf1-123c-4725-bc14-100e66eb3a12"
        d="M353.6,408,249.7,511.9A254.8,254.8,0,0,1,79.4,441.3l-8.7-8.7A255,255,0,0,1,0,256a268.2,268.2,0,0,1,1.4-27l125-125Z"
      />
      <g id="f887363c-e8e6-4444-945d-29a48e127e76" data-name="backdrop">
        <path
          className="a70a66a6-69e3-4268-951b-b90dc2e50e15"
          d="M512,256c0,141.4-114.6,256-256,256h-6.3A254.8,254.8,0,0,1,79.4,441.3q-4.5-4.2-8.7-8.7A255,255,0,0,1,0,256a268.2,268.2,0,0,1,1.4-27C14.9,100.3,123.7,0,256,0,397.4,0,512,114.6,512,256Z"
        />
      </g>
      <polygon
        className="e807413b-8839-4425-ad5f-056e11075a1b"
        points="353.6 408 353.6 104 171.2 104 164.8 104 126.4 104 126.4 408 353.6 408"
      />
      <rect className="a5f802ab-a784-4a21-ba3e-27c30791288f" x={140.8} y={118.4} width={198.4} height={268.8} />
      <path
        className="b30570ab-8a71-4062-abe6-393c501f6fd5"
        d="M300.8,102.4H179.2a6.4,6.4,0,0,0-6.4,6.4l12.8,22.4H294.4l12.8-22.4A6.4,6.4,0,0,0,300.8,102.4Zm-35.2,16H214.4a3.2,3.2,0,0,1,0-6.4h51.2a3.2,3.2,0,0,1,0,6.4Z"
      />
      <path
        className="fe7678b3-b1da-475a-a568-053715b9eacc"
        d="M387.2,390.4l-9.6,19.2V107.2a4.7,4.7,0,0,1,4.8-4.8h0a4.7,4.7,0,0,1,4.8,4.8Z"
      />
      <path
        className="aad0be21-99ea-40d2-8687-e710068c4925"
        d="M377.6,409.6,368,390.4V107.2a4.7,4.7,0,0,1,4.8-4.8h0a4.7,4.7,0,0,1,4.8,4.8Z"
      />
      <path
        className="f350043c-d562-4157-b438-2a779e85d500"
        d="M387.2,107.2v14.4H368V107.2a4.7,4.7,0,0,1,4.8-4.8h9.6A4.7,4.7,0,0,1,387.2,107.2Z"
      />
      <path className="bd5b947a-0713-459f-9d1f-4f4dcdd17906" d="M304,206.8H201.6a2,2,0,1,1,0-4H304a2,2,0,0,1,0,4Z" />
      <path className="bd5b947a-0713-459f-9d1f-4f4dcdd17906" d="M304,232.4H201.6a2,2,0,0,1,0-4H304a2,2,0,0,1,0,4Z" />
      <path className="bd5b947a-0713-459f-9d1f-4f4dcdd17906" d="M304,283.6H201.6a2,2,0,0,1,0-4H304a2,2,0,0,1,0,4Z" />
      <path className="bd5b947a-0713-459f-9d1f-4f4dcdd17906" d="M304,309.2H201.6a2,2,0,1,1,0-4H304a2,2,0,0,1,0,4Z" />
      <circle className="f7671c2a-bf08-4c5f-a27a-dbd7618bb848" cx={179.2} cy={204.8} r={6.4} />
      <circle className="e53bee72-c79b-4ca6-8c43-a6733911d399" cx={179.2} cy={230.4} r={6.4} />
      <circle className="ad2f0839-736a-4fc1-bb19-ad737beb0137" cx={179.2} cy={256} r={6.4} />
      <circle className="b3824527-a382-4971-a8a9-43dca74657d1" cx={179.2} cy={281.6} r={6.4} />
      <circle className="a4fcee3d-5173-402b-aacd-3c67c4316b3c" cx={179.2} cy={307.2} r={6.4} />
      <path className="bd5b947a-0713-459f-9d1f-4f4dcdd17906" d="M304,258H201.6a2,2,0,0,1,0-4H304a2,2,0,0,1,0,4Z" />
    </g>
  </svg>
)

export default ImgChecklist
