/**
 * formate a URL to comply with Next.JS linking requirements
 * takes a url and returns a url with a leading slash
 * only does this if the url doesn't have a leading slash
 * or begin with http
 * @param url string the URL to format
 * @returns the formatted URL
 */
export const formatUrl = (url: string) => {
  if (!url.startsWith('/') && !url.startsWith('http')) {
    return '/' + url
  }
  return url
}
