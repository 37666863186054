import { SVGProps } from 'react'

const ImgBarGraphGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bee6f2fb-daae-427b-b77a-54bc1b5b28dc{fill:none;}.a5965835-2155-4a5a-8c37-d7b74ee0cb64{opacity:0.15;fill:url(#b5d0c355-0d77-456b-9884-7a09b2cf5442);}.b1506a37-9043-4eff-8369-7772574f714b{fill:url(#bdb81f4a-dc12-432f-9a9c-ca19af9b5faa);}.bf7f6ad3-bae6-44ac-a337-fb6993a44930{fill:url(#a56a21b2-748c-4cfa-9ddd-929cf4e0c350);}.a4e661b1-de28-4655-baed-b164093bc332{fill:url(#adf02cef-8eb6-4c77-a629-795ba650fcc0);}.a4602dba-b2e2-4601-a890-2a4528a2ebae{fill:url(#ff1a1fd2-8d99-494f-b024-bdc5733bfc68);}.e9dee73c-220a-4397-b443-a9daa0aeabe3{fill:#fff;}.aa81f14f-43af-4f5c-bc66-e1ced586be79{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="b5d0c355-0d77-456b-9884-7a09b2cf5442"
        x1={98.76}
        y1={460.78}
        x2={291.32}
        y2={268.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bdb81f4a-dc12-432f-9a9c-ca19af9b5faa"
        x1={217.48}
        y1={9282.93}
        x2={146.44}
        y2={9211.9}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="a56a21b2-748c-4cfa-9ddd-929cf4e0c350"
        x1={173.35}
        y1={9289.21}
        x2={83.14}
        y2={9198.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="adf02cef-8eb6-4c77-a629-795ba650fcc0"
        x1={327.62}
        y1={9353.84}
        x2={451.24}
        y2={9230.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="ff1a1fd2-8d99-494f-b024-bdc5733bfc68"
        x1={246.97}
        y1={9344.09}
        x2={367.74}
        y2={9223.32}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
    </defs>
    <g id="a8aef31b-d4f7-42fd-8081-5a8545024cf6" data-name="Layer 1">
      <g id="a5f05ea1-09ef-4eb3-9222-535207f50c05" data-name="backdrop">
        <path
          className="bee6f2fb-daae-427b-b77a-54bc1b5b28dc"
          d="M512,256c0,129.5-96.1,236.5-220.9,253.6A256.2,256.2,0,0,1,77.2,439.2l-4.4-4.4A255.3,255.3,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a5965835-2155-4a5a-8c37-d7b74ee0cb64"
        d="M411.9,388.8,291.1,509.6A256.2,256.2,0,0,1,77.2,439.2l-4.4-4.4A255.2,255.2,0,0,1,6.2,312.2l109-109V388.8Z"
      />
      <rect className="b1506a37-9043-4eff-8369-7772574f714b" x={185.6} y={163.2} width={64} height={217.6} />
      <rect className="bf7f6ad3-bae6-44ac-a337-fb6993a44930" x={115.2} y={203.2} width={64} height={177.6} />
      <rect className="a4e661b1-de28-4655-baed-b164093bc332" x={326.4} y={83.2} width={64} height={297.6} />
      <rect className="a4602dba-b2e2-4601-a890-2a4528a2ebae" x={256} y={123.2} width={64} height={257.6} />
      <path className="e9dee73c-220a-4397-b443-a9daa0aeabe3" d="M408,390.8H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="e9dee73c-220a-4397-b443-a9daa0aeabe3"
        d="M410,113.2v-.6h0l-.3-.3h0l-.3-.3H385.6a2,2,0,0,0,0,4h17.6l-100,100H213.6l-111,111a1.9,1.9,0,0,0,0,2.8,1.9,1.9,0,0,0,2.8,0L215.2,219.6h89.6L406,118.4V136a2,2,0,0,0,4,0V113.6h0Z"
      />
      <path
        className="aa81f14f-43af-4f5c-bc66-e1ced586be79"
        d="M266,71.2a2,2,0,0,1-2-2V53.3a2,2,0,0,1,4,0V69.2A2,2,0,0,1,266,71.2Z"
      />
      <path className="aa81f14f-43af-4f5c-bc66-e1ced586be79" d="M273.9,63.3H258a2,2,0,0,1,0-4h15.9a2,2,0,0,1,0,4Z" />
      <path
        className="aa81f14f-43af-4f5c-bc66-e1ced586be79"
        d="M422.5,260.4a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,422.5,260.4Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,422.5,247.6Z"
      />
      <path
        className="aa81f14f-43af-4f5c-bc66-e1ced586be79"
        d="M132.4,181.6a1.9,1.9,0,0,1-1.2-.4l-11.5-8.7-11.5,8.7a2.3,2.3,0,0,1-2.5,0L93,171.6a1.9,1.9,0,0,1-.4-2.8,2.2,2.2,0,0,1,2.8-.4l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2.2,2.2,0,0,1,2.8.4,1.9,1.9,0,0,1-.4,2.8l-12.7,9.6A2,2,0,0,1,132.4,181.6Z"
      />
    </g>
  </svg>
)

export default ImgBarGraphGlitter
