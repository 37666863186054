import Container from 'components/elements/containers/container'
import FooterLink from 'components/links/footer-link'
import SocialLinks from 'components/links/social-links'
import TellaIc from 'images/others/tella-icon.png'
import { EV_BOOK_A_CHAT, EV_HL_APPLICATION_PATH_CLICK } from 'lib/constants/analytics-events'
import * as href from 'lib/constants/hrefs'
import { fireGAEvent } from 'lib/utilities'
import Image from 'next/image'

const Footer = () => {
  return (
    <Container bgColor="bg-tella-blue-300" containerClass="print:hidden">
      <section className="mx-auto flex flex-col justify-between text-white sm:grid sm:grid-cols-12 sm:gap-4">
        <div className="grid h-max gap-4 px-2 pb-6 sm:col-span-3 sm:pb-0">
          <Image src={TellaIc} alt="tella-icon" layout="fixed" />
          <p>Making the home loan journey easy for you anytime, anywhere.</p>
          <SocialLinks color="text-white" containerClass="sm:w-40 sm:gap-2 sm:-ml-2 lg:w-52 lg:gap-4" />
          <div className={`m-2 flex w-max rounded-full bg-[#3a3acc] lg:m-0 lg:mr-6 lg:mt-0`}>
            <a
              href="tel:+640800483552"
              className={`relative left-[5px] top-[5px] mr-2 h-[28px] w-[28px] rounded-full bg-white text-center`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="blue"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`ml-1 mt-1 h-5 w-5 text-tella-blue-300`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </a>
            <a href="tel:+640800483552" className="mt-[1px] py-[6px] pl-2 pr-6">
              0800 483 552
            </a>
          </div>
        </div>
        <div className="grid h-max gap-2 px-2 py-6 sm:col-span-3 sm:ml-6 sm:py-0">
          <p className="cursor-default opacity-60">Resources</p>
          <FooterLink href={href.RATES} text="Interest rates" />
          <FooterLink href={href.CALC_AFFORDABILITY} text="Affordability Calculator" />
          <FooterLink href={href.CALC_REPAYMENT} text="Repayment Calculator" />
          <FooterLink href={href.CALC_FIRST_HOME_DEPOSIT} text="First Home Calculator" />
          <FooterLink
            href="/home-loans-mortgages/first-home-buyer/kiwisaver-first-home-grant"
            text="KiwiSaver Withdrawal"
          />
          <FooterLink href={href.KIWISAVER_PROVIDERS} text="KiwiSaver Provider Info" />
          <FooterLink
            href="/home-loans-mortgages/first-home-buyer/kiwisaver-first-home-grant"
            text="Government Assistance"
          />
        </div>

        <div className="grid h-max gap-2 px-2 py-6 sm:col-span-2 sm:py-0">
          <p className="cursor-default opacity-60">Support</p>
          <FooterLink href={`${href.MY_TELLA}/auth`} text="Log in" />
          <FooterLink
            openInNewTab
            href={href.HL_APPLICATION}
            text="Apply online"
            onClick={() => fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Apply online (Footer)' })}
          />
          <FooterLink
            href={href.BOOK_A_CHAT}
            text="Book a chat"
            onClick={() => fireGAEvent({ event: EV_BOOK_A_CHAT })}
          />
          <FooterLink
            href={href.HL_APPLICATION_BUY_A_HOME}
            text="Buy a home"
            onClick={() => fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Buy a home (Footer)' })}
          />
          <FooterLink
            href={href.HL_APPLICATION_REFINANCE}
            text="Refinance"
            onClick={() => fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Refinance (Footer)' })}
          />
          <FooterLink href={href.FAQS} text="FAQs" />
          {/* 
          #440-Remove Glossary from the Learn menu and the footer
          <FooterLink href="/glossary" text="Glossary" /> */}
        </div>
        <div className="grid h-max gap-2 px-2 py-6 sm:col-span-2 sm:py-0">
          <p className="cursor-default opacity-60">Company</p>
          <FooterLink href={href.ABOUT_US} text="About Us" />
          <FooterLink href={href.CONTACT_US} text="Contact Us" />
          <FooterLink href={href.CONTACT_US} text="Careers" />
          <FooterLink href={href.PARTNER_WITH_US} text="Partner With Tella" />
          <FooterLink href={href.REALESTATE_PARTNER} text="Real Estate Partner Program" />
        </div>
        <div className="col-span-2 grid h-max gap-2 px-2 pt-6 sm:py-0">
          <p className="cursor-default opacity-60">Important Info</p>
          <FooterLink href={href.IMPORTANT_INFO} text="Public Disclosure" />
          <FooterLink href={href.IMPORTANT_INFO} text="Privacy Policy" />
          <FooterLink href={href.IMPORTANT_INFO} text="Website Terms and Conditions" />
          <FooterLink href={href.IMPORTANT_INFO} text="Service Agreement" />
        </div>
      </section>
      {/* #440-Remove Glossary from the Learn menu and the footer
      <div className="mx-auto my-10 hidden h-[1px] bg-tella-light-pink-100 opacity-50 xl:flex"></div>
      <div className="hidden justify-center xl:flex">
        <GlossaryAlphabets isDarkTheme={true} />
      </div> */}
      <div className="mx-auto my-10 flex h-[1px] bg-tella-light-pink-100 opacity-50"></div>
      <p className="text-center text-xs text-tella-light-pink-100 opacity-50">
        © Copyright 2024 Tella (New Zealand) Limited. All Rights Reserved. Powered by Tella.
      </p>
    </Container>
  )
}

export default Footer
