import { SVGProps } from 'react'

const ImgComputerWithLampGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ed5362bb-d434-46d0-a904-385961022087{opacity:0.15;fill:url(#e44c75a0-7890-41f1-95fc-aad688094424);}.ba6cbfce-d854-408a-abce-a0a8c8797ee8{fill:none;}.f09e4434-0037-477c-9a01-1ebe67806548{fill:url(#a77288f4-effd-4776-8d12-d81898a730f2);}.acc64608-c08a-4765-8532-246ad212d704{fill:url(#faaa5f3d-b4c7-4e06-81aa-72bd084f4fa8);}.abf1da6e-9239-45ac-921b-095e08c0d55d{fill:url(#afc64010-6dac-4137-ac5a-6966edd92a24);}.b8f4b186-6c41-406e-9aee-ee9ed151cdc2{fill:url(#b908f153-27f7-4d73-b316-95b2c4f8de3a);}.f11a8772-77ae-4ec3-9085-34762aea526b{fill:url(#b9c8889a-c863-4f5d-b923-bd020e851c50);}.bb1b664a-d93e-4eb4-b086-be3a6d84215c{fill:#fff;}.fcff3908-8056-4ced-b146-3c022b348ea8{fill:url(#a8dda3f8-6ad6-4732-a80e-d201ce571bf9);}.ab09bab6-2147-48d8-aa88-d7e2990ecb74{fill:url(#bb42fd6c-94a9-4909-8bec-33c67734ac94);}.b56fae55-1ef7-44da-b2bf-e69b9ee007cc{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="e44c75a0-7890-41f1-95fc-aad688094424"
        x1={93.44}
        y1={455.48}
        x2={287.36}
        y2={261.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a77288f4-effd-4776-8d12-d81898a730f2"
        x1={171.49}
        y1={7990.69}
        x2={400.1}
        y2={8219.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="faaa5f3d-b4c7-4e06-81aa-72bd084f4fa8"
        x1={195.94}
        y1={7943.41}
        x2={337}
        y2={8084.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="afc64010-6dac-4137-ac5a-6966edd92a24"
        x1={280.08}
        y1={8014.82}
        x2={272.3}
        y2={7901.74}
        xlinkHref="#faaa5f3d-b4c7-4e06-81aa-72bd084f4fa8"
      />
      <linearGradient
        id="b908f153-27f7-4d73-b316-95b2c4f8de3a"
        x1={253.51}
        y1={7963.91}
        x2={361.2}
        y2={8071.59}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b9c8889a-c863-4f5d-b923-bd020e851c50"
        x1={208.98}
        y1={7877.77}
        x2={335.18}
        y2={8003.98}
        xlinkHref="#faaa5f3d-b4c7-4e06-81aa-72bd084f4fa8"
      />
      <linearGradient
        id="a8dda3f8-6ad6-4732-a80e-d201ce571bf9"
        x1={204.03}
        y1={8121.49}
        x2={253.58}
        y2={8171.04}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="bb42fd6c-94a9-4909-8bec-33c67734ac94"
        x1={186.5}
        y1={7959.86}
        x2={190.12}
        y2={7980.11}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="e3c35b84-2057-4da1-9e9a-057a5960807a" data-name="Layer 1">
      <path
        className="ed5362bb-d434-46d0-a904-385961022087"
        d="M403.2,377.4,268.9,511.7c-4.2.2-8.6.3-12.9.3C133.6,512,31.3,426.1,6,311.3l162-162L311.4,356.9,323.2,374Z"
      />
      <g id="a6804248-8d20-447e-a93b-2f28e5ca4a80" data-name="backdrop">
        <path
          className="ba6cbfce-d854-408a-abce-a0a8c8797ee8"
          d="M512,256c0,137-107.7,248.9-243.1,255.7-4.2.2-8.6.3-12.9.3C133.6,512,31.3,426.1,6,311.3A258.1,258.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <rect
        className="f09e4434-0037-477c-9a01-1ebe67806548"
        x={166.4}
        y={147.2}
        width={236.8}
        height={172.8}
        rx={6.4}
      />
      <path
        className="acc64608-c08a-4765-8532-246ad212d704"
        d="M166.4,294.4H403.2v19.2a6.4,6.4,0,0,1-6.4,6.4h-224a6.4,6.4,0,0,1-6.4-6.4V294.4Z"
      />
      <path
        className="abf1da6e-9239-45ac-921b-095e08c0d55d"
        d="M313.6,320v48a6.4,6.4,0,0,0,6.4,6.4H243.2a6.4,6.4,0,0,1-6.4-6.4V320Z"
      />
      <path
        className="b8f4b186-6c41-406e-9aee-ee9ed151cdc2"
        d="M320,320v44.8a3.2,3.2,0,0,0,3.2,3.2H246.4a3.2,3.2,0,0,1-3.2-3.2V320Z"
      />
      <rect className="f11a8772-77ae-4ec3-9085-34762aea526b" x={256} y={368} width={83.2} height={6.4} />
      <path
        className="bb1b664a-d93e-4eb4-b086-be3a6d84215c"
        d="M97.6,272.4a2,2,0,0,1-1.4-.6,1.9,1.9,0,0,1,0-2.8l88-88a2,2,0,0,1,2.8,2.8l-88,88A2,2,0,0,1,97.6,272.4Z"
      />
      <path
        className="fcff3908-8056-4ced-b146-3c022b348ea8"
        d="M284.8,192H182.4v-9.6a3.2,3.2,0,0,1,3.2-3.2H272A12.8,12.8,0,0,1,284.8,192Z"
      />
      <path
        className="bb1b664a-d93e-4eb4-b086-be3a6d84215c"
        d="M187.2,362a2,2,0,0,1-1.4-.6L96.2,271.8A2,2,0,1,1,99,269l89.6,89.6a1.9,1.9,0,0,1,0,2.8A2,2,0,0,1,187.2,362Z"
      />
      <path className="bb1b664a-d93e-4eb4-b086-be3a6d84215c" d="M401.6,378H97.6a2,2,0,0,1,0-4h304a2,2,0,0,1,0,4Z" />
      <path
        className="ab09bab6-2147-48d8-aa88-d7e2990ecb74"
        d="M211.2,361.6h-48a12.8,12.8,0,0,0-12.8,12.8H224A12.8,12.8,0,0,0,211.2,361.6Z"
      />
      <path
        className="b56fae55-1ef7-44da-b2bf-e69b9ee007cc"
        d="M136.3,201.7a7.9,7.9,0,1,1,0-15.7,7.9,7.9,0,1,1,0,15.7Zm0-12.7a4.9,4.9,0,0,0-4.9,4.9,4.9,4.9,0,0,0,9.8,0A4.9,4.9,0,0,0,136.3,189Z"
      />
      <path
        className="b56fae55-1ef7-44da-b2bf-e69b9ee007cc"
        d="M423,277.9a1.6,1.6,0,0,1-.9-.3l-11.8-8.8-11.8,8.8a1.7,1.7,0,0,1-1.9,0l-12.7-9.5a1.5,1.5,0,0,1,1.8-2.4l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,0l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,2.4L424,277.6A1.7,1.7,0,0,1,423,277.9Z"
      />
      <path
        className="b56fae55-1ef7-44da-b2bf-e69b9ee007cc"
        d="M335.9,123.6h-6.4v-6.4a1.5,1.5,0,0,0-1.5-1.5,1.4,1.4,0,0,0-1.5,1.5v6.4H320a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5h6.5v6.5a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6.5h6.4a1.4,1.4,0,0,0,1.5-1.5A1.5,1.5,0,0,0,335.9,123.6Z"
      />
    </g>
  </svg>
)

export default ImgComputerWithLampGlitter
