const ImgPlantWithCoins = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="f4b0a339-5718-4ae0-9570-197d69f91eb3"
          x1={-478.64}
          y1={667.08}
          x2={-281.98}
          y2={667.08}
          gradientTransform="matrix(.97 -.97 1.04 1.04 -120.78 -688.41)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="a12a537f-67c0-42b4-8c80-c2cd2b0dbd28"
          x1={167.92}
          y1={-4112.2}
          x2={341.79}
          y2={-3938.33}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="e6414713-e0b1-4e2d-9f33-f820c54a9d97"
          x1={244.97}
          y1={-3971.74}
          x2={301.62}
          y2={-3914.97}
          gradientTransform="matrix(1 0 0 -1 4.5 -3743.38)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fdf0f3" />
          <stop offset={1} stopColor="#fefafb" />
        </linearGradient>
        <linearGradient
          id="a06856bb-3de5-4849-be81-352b9933351f"
          x1={260.8}
          y1={177.6}
          x2={308.8}
          y2={129.6}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5fd0b2" />
          <stop offset={1} stopColor="#37c49f" />
        </linearGradient>
        <linearGradient
          id="ba1ff014-0611-4d70-b59a-babf6ea0f08d"
          x1={218.97}
          y1={129.83}
          x2={241.83}
          y2={106.97}
          xlinkHref="#a06856bb-3de5-4849-be81-352b9933351f"
        />
        <linearGradient
          id="b3d3b8e9-6de4-45e0-ba75-49f3ce11f14d"
          x1={147.79}
          y1={-4118.29}
          x2={214.04}
          y2={-4052.05}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          xlinkHref="#e6414713-e0b1-4e2d-9f33-f820c54a9d97"
        />
        <linearGradient
          id="aff26939-c41b-4afa-8293-69acf81ecf26"
          x1={156.57}
          y1={365.03}
          x2={201.83}
          y2={319.77}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="b97b431a-f442-4e1f-b97e-2b9832538e98"
          x1={253.55}
          y1={-4120.71}
          x2={259.44}
          y2={-4100.94}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          xlinkHref="#e6414713-e0b1-4e2d-9f33-f820c54a9d97"
        />
        <linearGradient
          id="be2fad02-904e-47cc-a3c1-038723c16f33"
          x1={253.55}
          y1={-4107.91}
          x2={259.11}
          y2={-4089.24}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          xlinkHref="#e6414713-e0b1-4e2d-9f33-f820c54a9d97"
        />
        <linearGradient
          id="b65dfe32-7caa-48f6-81b2-eb7b1dab20bb"
          x1={243.67}
          y1={-4099.21}
          x2={272.5}
          y2={-4070.38}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          xlinkHref="#e6414713-e0b1-4e2d-9f33-f820c54a9d97"
        />
        <linearGradient
          id="be5ef917-1a58-4305-bb46-7d3f96be242b"
          x1={253.55}
          y1={-4133.51}
          x2={259.29}
          y2={-4114.26}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          xlinkHref="#e6414713-e0b1-4e2d-9f33-f820c54a9d97"
        />
        <linearGradient
          id="e2dfb872-00f7-4000-9950-6d9aef3c7f4f"
          x1={200.27}
          y1={267.13}
          x2={311.73}
          y2={155.67}
          xlinkHref="#a12a537f-67c0-42b4-8c80-c2cd2b0dbd28"
        />
        <style>{'.b9441606-cae8-4c3e-a363-9f9930826dfe{fill:#fff}'}</style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="a07bd2ad-8fbe-40e0-a449-707e6c2d0bbb" data-name="Layer 1">
          <path
            style={{
              opacity: 0.15,
              mixBlendMode: 'multiply',
              fill: 'url(#f4b0a339-5718-4ae0-9570-197d69f91eb3)',
            }}
            d="m316.6 387.8 92.8 1.7-190.9 191L-2.9 359.1l151.8-151.8 167.7 180.5z"
          />
          <circle
            cx={257.6}
            cy={256}
            r={256}
            style={{
              fill: 'none',
            }}
          />
          <path
            className="b9441606-cae8-4c3e-a363-9f9930826dfe"
            d="M256 315.2a1.6 1.6 0 0 1-1.6-1.6V84.8a1.6 1.6 0 0 1 3.2 0v228.8a1.6 1.6 0 0 1-1.6 1.6Z"
          />
          <path
            d="M315.6 387.2H196.4a12.8 12.8 0 0 1-12.6-10.7l-29.1-164.3a6.4 6.4 0 0 1 5.4-7.3h190.8a6.4 6.4 0 0 1 6.4 6.4v1l-29.1 164.2a12.8 12.8 0 0 1-12.6 10.7Z"
            style={{
              fill: 'url(#a12a537f-67c0-42b4-8c80-c2cd2b0dbd28)',
            }}
          />
          <path
            d="M166.4 217.5s40.1-32 89.6-31.9 89.6 32.1 89.6 32.1Z"
            style={{
              fill: 'url(#e6414713-e0b1-4e2d-9f33-f820c54a9d97)',
            }}
          />
          <path
            d="M294.4 134.4h19.2a38.4 38.4 0 0 1-38.4 38.4H256a38.4 38.4 0 0 1 38.4-38.4Z"
            style={{
              fill: 'url(#a06856bb-3de5-4849-be81-352b9933351f)',
            }}
          />
          <path
            d="M256 134.4h-19.2a32 32 0 0 1-32-32H224a32 32 0 0 1 32 32Z"
            style={{
              fill: 'url(#ba1ff014-0611-4d70-b59a-babf6ea0f08d)',
            }}
          />
          <circle
            cx={179.2}
            cy={342.4}
            r={44.8}
            style={{
              fill: 'url(#b3d3b8e9-6de4-45e0-ba75-49f3ce11f14d)',
            }}
          />
          <circle
            cx={179.2}
            cy={342.4}
            r={32}
            style={{
              fill: 'url(#aff26939-c41b-4afa-8293-69acf81ecf26)',
            }}
          />
          <path
            className="b9441606-cae8-4c3e-a363-9f9930826dfe"
            d="M408 390.4H104a1.6 1.6 0 0 1 0-3.2h304a1.6 1.6 0 0 1 0 3.2Z"
          />
          <rect
            x={224}
            y={361.6}
            width={64}
            height={12.8}
            rx={3.2}
            style={{
              fill: 'url(#b97b431a-f442-4e1f-b97e-2b9832538e98)',
            }}
          />
          <rect
            x={224}
            y={348.8}
            width={64}
            height={12.8}
            rx={3.2}
            style={{
              fill: 'url(#be2fad02-904e-47cc-a3c1-038723c16f33)',
            }}
          />
          <rect
            x={224}
            y={336}
            width={64}
            height={12.8}
            rx={3.2}
            style={{
              fill: 'url(#b65dfe32-7caa-48f6-81b2-eb7b1dab20bb)',
            }}
          />
          <rect
            x={224}
            y={374.4}
            width={64}
            height={12.8}
            rx={3.2}
            style={{
              fill: 'url(#be5ef917-1a58-4305-bb46-7d3f96be242b)',
            }}
          />
          <path
            d="M153.6 205h204.8a6.4 6.4 0 0 1 6.4 6.4 6.4 6.4 0 0 1-6.4 6.4H153.6a6.4 6.4 0 0 1-6.4-6.4 6.4 6.4 0 0 1 6.4-6.4Z"
            style={{
              fill: 'url(#e2dfb872-00f7-4000-9950-6d9aef3c7f4f)',
            }}
          />
        </g>
      </g>
    </svg>
  )
}

export default ImgPlantWithCoins
