const ImgThreeShapesWithArrowNoMask = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <style>
          {
            '.a534e55d-447c-4ab2-940e-4e88042dec76{fill:none;}.a0256ea9-a387-4f38-a592-d4a7870f674f,.bbeaf1ae-8c9e-4fe9-b8fa-158e3628865d,.e0d3d1a8-0afa-415b-b5c5-bc3a13d90754{opacity:0.15;}.a0256ea9-a387-4f38-a592-d4a7870f674f{fill:url(#ba00885b-5f3d-459d-aade-d0f89d46daca);}.bbeaf1ae-8c9e-4fe9-b8fa-158e3628865d{fill:url(#a6f5d3ad-2060-4fab-8f0b-1cddf882bc18);}.e0d3d1a8-0afa-415b-b5c5-bc3a13d90754{fill:url(#fb0c296b-baad-4130-beb1-6a67c7fc62f6);}.acf00f60-f713-409b-9fb5-7e3bfb7bb905{fill:url(#b679e768-d033-4b9c-88d8-c51b831e7d23);}.f4ae8c96-a299-4f75-a9ef-b07aea0a406b{fill:#fff;}.bb6de029-8799-4ee2-9646-cb50af685137{fill:url(#ae0ff6cb-b90e-4a22-9643-e0d611cbcf5d);}.b183d2b6-a5b7-42f0-a548-9ee07754c418{fill:url(#eb3de80a-a0cb-4d12-88ca-3ed6125a1e0e);}'
          }
        </style>
        <linearGradient
          id="ba00885b-5f3d-459d-aade-d0f89d46daca"
          x1={16.86}
          y1={319.97}
          x2={122.29}
          y2={319.97}
          gradientTransform="translate(147.5 -149) scale(1.42 1.16)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="a6f5d3ad-2060-4fab-8f0b-1cddf882bc18"
          x1={-60.32}
          y1={440.23}
          x2={8.91}
          y2={440.23}
          gradientTransform="translate(319.4 -252.9) scale(2.12 1.48)"
          xlinkHref="#ba00885b-5f3d-459d-aade-d0f89d46daca"
        />
        <linearGradient
          id="fb0c296b-baad-4130-beb1-6a67c7fc62f6"
          x1={-87.16}
          y1={325.9}
          x2={-31.28}
          y2={325.9}
          gradientTransform="matrix(1.29, -1.29, 0.9, 0.9, -124.55, -50.34)"
          xlinkHref="#ba00885b-5f3d-459d-aade-d0f89d46daca"
        />
        <linearGradient
          id="b679e768-d033-4b9c-88d8-c51b831e7d23"
          x1={112.41}
          y1={-4043.2}
          x2={200.72}
          y2={-3954.9}
          gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#37c49f" />
          <stop offset={1} stopColor="#4bcaa9" />
        </linearGradient>
        <linearGradient
          id="ae0ff6cb-b90e-4a22-9643-e0d611cbcf5d"
          x1={249.15}
          y1={218.05}
          x2={339.65}
          y2={127.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ef426f" />
          <stop offset={1} stopColor="#f1557d" />
        </linearGradient>
        <linearGradient
          id="eb3de80a-a0cb-4d12-88ca-3ed6125a1e0e"
          x1={268.39}
          y1={-4138.5}
          x2={369.56}
          y2={-4037.33}
          gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" />
          <stop offset={1} stopColor="#281fa9" />
        </linearGradient>
      </defs>
      <g id="e303aa86-dd68-4049-9239-2277088a5a6c" data-name="Layer 1">
        <rect
          className="a0256ea9-a387-4f38-a592-d4a7870f674f"
          x={171.5}
          y={157.1}
          width={149.8}
          height={127.56}
          transform="translate(-84 238.9) rotate(-45)"
        />
        <rect
          className="bbeaf1ae-8c9e-4fe9-b8fa-158e3628865d"
          x={191.5}
          y={316}
          width={146.7}
          height={163.02}
          transform="translate(-203.5 303.7) rotate(-45)"
        />
        <polygon
          className="e0d3d1a8-0afa-415b-b5c5-bc3a13d90754"
          points="159.1 351.1 105.2 405.1 6 305.9 78.3 233.5 159.1 351.1"
        />
        <polygon
          className="acf00f60-f713-409b-9fb5-7e3bfb7bb905"
          points="155.2 355.1 78.4 233.5 232 233.5 155.2 355.1"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M331.2,245.2a2,2,0,0,1-2-2,23.6,23.6,0,0,0-23.6-23.6H158.4A27.7,27.7,0,0,1,130.8,192a2,2,0,0,1,4,0,23.6,23.6,0,0,0,23.6,23.6H305.6a27.7,27.7,0,0,1,27.6,27.6A2,2,0,0,1,331.2,245.2Z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M164.8,296.4a2,2,0,0,1-2-2,27.7,27.7,0,0,1,27.6-27.6H305.6a23.6,23.6,0,0,0,23.6-23.6,2,2,0,0,1,4,0,27.7,27.7,0,0,1-27.6,27.6H190.4a23.6,23.6,0,0,0-23.6,23.6A2,2,0,0,1,164.8,296.4Z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M376,373.2H97.6a2,2,0,1,1,0-4H376a23.6,23.6,0,0,0,23.6-23.6,2,2,0,0,1,4,0A27.7,27.7,0,0,1,376,373.2Z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M385.6,184.4a2,2,0,0,1-1.4-.6,1.9,1.9,0,0,1,0-2.8l14.6-14.6-14.6-14.6A2,2,0,0,1,387,149l16,16a1.9,1.9,0,0,1,0,2.8l-16,16A2,2,0,0,1,385.6,184.4Z"
        />
        <circle className="bb6de029-8799-4ee2-9646-cb50af685137" cx={294.4} cy={172.8} r={64} />
        <rect className="b183d2b6-a5b7-42f0-a548-9ee07754c418" x={259.2} y={288} width={115.2} height={115.2} />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M401.6,347.6a2,2,0,0,1-2-2A23.6,23.6,0,0,0,376,322H190.4a27.7,27.7,0,0,1-27.6-27.6,2,2,0,0,1,4,0A23.6,23.6,0,0,0,190.4,318H376a27.7,27.7,0,0,1,27.6,27.6A2,2,0,0,1,401.6,347.6Z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M132.8,194a2,2,0,0,1-2-2,27.7,27.7,0,0,1,27.6-27.6H401.6a2,2,0,0,1,0,4H158.4A23.6,23.6,0,0,0,134.8,192,2,2,0,0,1,132.8,194Z"
        />
      </g>
    </svg>
  )
}

export default ImgThreeShapesWithArrowNoMask
