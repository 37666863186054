import { SVGProps } from 'react'

const ImgThumbsUpGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.af2c644a-68a5-4b3c-b08d-cd95d8216277{opacity:0.15;fill:url(#e66aca1f-c7e8-43d2-a9f9-8906c5a2f1d7);}.aff75edc-0131-490c-b289-1028f1afaf21{fill:none;}.fee349d7-893c-4aee-84af-b1a876b75075{fill:#f7ced7;}.fc8a09dd-e32b-4388-bad9-89d3836747ae{fill:url(#bc952ea6-7b00-4ba0-8ae4-28a43b517ede);}.abb1873a-c1a8-4794-a18d-c1cb1c96c395{fill:url(#a3f41bfa-de95-4e9a-96ed-6c7293c03232);}.e3eae438-0c1e-47e0-9b99-4f7e97a88554{fill:#37c49f;}.f64504ab-1f2e-4a18-8b01-7ca99bca7904{fill:url(#f05bd21d-7331-402a-bdbd-2ea3af7fac17);}.af2b9a9f-5d96-4ae2-ade6-8a2f7d4f0c6a{fill:url(#f7fc724a-8402-4a3b-beb2-e02e6b211722);}.a0c94dc1-6cf4-400a-8387-004f5d8f7c78{fill:url(#f042fb8a-838c-4e69-b22c-6abd454e2482);}.ac0d2423-3980-4a18-8007-9d7714aa61b6{fill:url(#e74017f6-9fc7-4615-8b0c-4ec360c616d1);}.fcab9916-9c95-4f57-9f09-79ca51e4e012{fill:url(#aa29d601-d009-4b83-abed-fa20c54329e1);}.a38ac269-eb9f-48be-8405-57731f150a4b{fill:url(#a3babc9c-34d2-4734-8dcd-b2025aa132ab);}.e96b6acf-4023-4e58-ae56-365cd27260f9{fill:#fff;}.ab10e1f0-4610-44c7-91ab-cca6517109cc{fill:url(#bdaedf7e-b444-4aca-9305-c987da1d5b0f);}.f14ceb5b-eaf8-4cb9-aade-39b2023d8215{fill:url(#b751028c-6fa5-4a6c-8e62-d94022da480a);}.fc56c2d3-8731-483f-a9f7-e0b6a108a47e{fill:url(#bfc352fb-f50e-4867-a502-4e71f4102923);}.e65f6f14-3d57-41d0-bd43-a24fcea57cec{fill:url(#a216e82b-1e76-4834-aa65-7010748af589);}'
        }
      </style>
      <linearGradient
        id="e66aca1f-c7e8-43d2-a9f9-8906c5a2f1d7"
        x1={299.15}
        y1={-1006.43}
        x2={399.09}
        y2={-1006.43}
        gradientTransform="translate(1389 2761.1) rotate(-45) scale(2.46 2.52)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bc952ea6-7b00-4ba0-8ae4-28a43b517ede"
        x1={107.67}
        y1={-6768.59}
        x2={271.2}
        y2={-6605.06}
        gradientTransform="matrix(1, 0, 0, -1, 0, -6392.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a3f41bfa-de95-4e9a-96ed-6c7293c03232"
        x1={78.71}
        y1={-6768.18}
        x2={223.68}
        y2={-6623.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, -6392.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="f05bd21d-7331-402a-bdbd-2ea3af7fac17"
        x1={279.66}
        y1={-6718.4}
        x2={396.84}
        y2={-6601.23}
        gradientTransform="matrix(1, 0, 0, -1, 0, -6392.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#706ac5" />
      </linearGradient>
      <linearGradient
        id="f7fc724a-8402-4a3b-beb2-e02e6b211722"
        x1={210.96}
        y1={-6645.16}
        x2={322.79}
        y2={-6533.34}
        gradientTransform="matrix(1, 0, 0, -1, 0, -6392.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#706ac5" />
      </linearGradient>
      <linearGradient
        id="f042fb8a-838c-4e69-b22c-6abd454e2482"
        x1={382.72}
        y1={231.68}
        x2={330.85}
        y2={283.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="e74017f6-9fc7-4615-8b0c-4ec360c616d1"
        x1={383.94}
        y1={345.66}
        x2={327.23}
        y2={402.37}
        xlinkHref="#f042fb8a-838c-4e69-b22c-6abd454e2482"
      />
      <linearGradient
        id="aa29d601-d009-4b83-abed-fa20c54329e1"
        x1={388.63}
        y1={305.77}
        x2={331.57}
        y2={362.83}
        xlinkHref="#f042fb8a-838c-4e69-b22c-6abd454e2482"
      />
      <linearGradient
        id="a3babc9c-34d2-4734-8dcd-b2025aa132ab"
        x1={391.91}
        y1={267.29}
        x2={333.66}
        y2={325.54}
        xlinkHref="#f042fb8a-838c-4e69-b22c-6abd454e2482"
      />
      <linearGradient
        id="bdaedf7e-b444-4aca-9305-c987da1d5b0f"
        x1={327.61}
        y1={-6656.49}
        x2={350.99}
        y2={-6633.1}
        gradientTransform="matrix(1, 0, 0, -1, 0, -6392.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#8782cf" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="b751028c-6fa5-4a6c-8e62-d94022da480a"
        x1={325.4}
        y1={-6773.9}
        x2={353.41}
        y2={-6745.88}
        xlinkHref="#bdaedf7e-b444-4aca-9305-c987da1d5b0f"
      />
      <linearGradient
        id="bfc352fb-f50e-4867-a502-4e71f4102923"
        x1={325.49}
        y1={-6735.4}
        x2={352.45}
        y2={-6708.45}
        xlinkHref="#bdaedf7e-b444-4aca-9305-c987da1d5b0f"
      />
      <linearGradient
        id="a216e82b-1e76-4834-aa65-7010748af589"
        x1={325.59}
        y1={-6696.91}
        x2={357.8}
        y2={-6664.69}
        xlinkHref="#bdaedf7e-b444-4aca-9305-c987da1d5b0f"
      />
    </defs>
    <g id="b23e8496-df15-4409-92c5-276f0131d0e2" data-name="Layer 1">
      <polygon
        className="af2c644a-68a5-4b3c-b08d-cd95d8216277"
        points="398.4 384 224.8 557.6 -0.1 332.7 108.8 224 398.4 384"
      />
      <g id="a77c6b3b-dd0a-43a9-9413-50e3dfb1611c" data-name="backdrop">
        <circle className="aff75edc-0131-490c-b289-1028f1afaf21" cx={256} cy={256} r={256} />
      </g>
      <circle className="fee349d7-893c-4aee-84af-b1a876b75075" cx={284.8} cy={192} r={102.4} />
      <path
        className="fc8a09dd-e32b-4388-bad9-89d3836747ae"
        d="M108.8,236.8V384H208c44.8,0,83.2-44.8,83.2-96,0-38.4-12.8-51.2-12.8-51.2Z"
      />
      <path
        className="abb1873a-c1a8-4794-a18d-c1cb1c96c395"
        d="M153.6,224H108.8V396.8h70.4V249.6A25.6,25.6,0,0,0,153.6,224Z"
      />
      <path
        className="e3eae438-0c1e-47e0-9b99-4f7e97a88554"
        d="M334.4,198.4H328V192a1.6,1.6,0,0,0-3.2,0v6.4h-6.4a1.6,1.6,0,1,0,0,3.2h6.4V208a1.6,1.6,0,0,0,3.2,0v-6.4h6.4a1.6,1.6,0,1,0,0-3.2Z"
      />
      <path
        className="e3eae438-0c1e-47e0-9b99-4f7e97a88554"
        d="M392,124.8a8,8,0,1,1,8-8A8,8,0,0,1,392,124.8Zm0-12.8a4.8,4.8,0,1,0,4.8,4.8A4.9,4.9,0,0,0,392,112Z"
      />
      <path
        className="e3eae438-0c1e-47e0-9b99-4f7e97a88554"
        d="M196.8,176a1.9,1.9,0,0,1-1-.3L184,166.8l-11.8,8.9a1.8,1.8,0,0,1-2,0l-12.8-9.6a1.6,1.6,0,1,1,2-2.6l11.8,8.9,11.8-8.9a1.8,1.8,0,0,1,2,0l11.8,8.9,11.8-8.9a1.6,1.6,0,1,1,2,2.6l-12.8,9.6A1.9,1.9,0,0,1,196.8,176Z"
      />
      <path
        className="f64504ab-1f2e-4a18-8b01-7ca99bca7904"
        d="M416,294.4a19.2,19.2,0,0,0-19.2-19.2H384a19.2,19.2,0,0,0,0-38.4H192V352c0,21.2,21.5,38.4,48,38.4H384a19.2,19.2,0,1,0,0-38.4h6.4a19.2,19.2,0,1,0,0-38.4h6.4A19.2,19.2,0,0,0,416,294.4Z"
      />
      <path
        className="af2b9a9f-5d96-4ae2-ade6-8a2f7d4f0c6a"
        d="M313.6,140.8c0-9.6-3.2-25.6-16.7-25.6l-31.3,51.2-41.6,32-32,38.4h86.4l-4.3-6.4a6.5,6.5,0,0,1-.3-6.6L297.6,176S313.6,166.4,313.6,140.8Z"
      />
      <rect className="a0c94dc1-6cf4-400a-8387-004f5d8f7c78" x={313.6} y={236.8} width={89.6} height={38.4} rx={19.2} />
      <path
        className="ac0d2423-3980-4a18-8007-9d7714aa61b6"
        d="M384,352H332.8a19.2,19.2,0,0,0-19.2,19.2h0a19.2,19.2,0,0,0,19.2,19.2H384a19.2,19.2,0,0,0,19.2-19.2h0A19.2,19.2,0,0,0,384,352Z"
      />
      <path
        className="fcab9916-9c95-4f57-9f09-79ca51e4e012"
        d="M390.4,313.6H332.8a19.2,19.2,0,0,0,0,38.4h57.6a19.2,19.2,0,1,0,0-38.4Z"
      />
      <rect
        className="a38ac269-eb9f-48be-8405-57731f150a4b"
        x={313.6}
        y={275.2}
        width={102.4}
        height={38.4}
        rx={19.2}
      />
      <path
        className="e96b6acf-4023-4e58-ae56-365cd27260f9"
        d="M160,379.1a7.9,7.9,0,1,1,7.9-7.9A7.9,7.9,0,0,1,160,379.1Zm0-12.8a4.9,4.9,0,1,0,4.9,4.9A4.9,4.9,0,0,0,160,366.3Z"
      />
      <path
        className="ab10e1f0-4610-44c7-91ab-cca6517109cc"
        d="M332.8,243.2h9.6a3.2,3.2,0,0,1,3.2,3.2v19.2a3.2,3.2,0,0,1-3.2,3.2h-9.6A12.8,12.8,0,0,1,320,256h0A12.8,12.8,0,0,1,332.8,243.2Z"
      />
      <path
        className="f14ceb5b-eaf8-4cb9-aade-39b2023d8215"
        d="M332.8,358.4h9.6a3.2,3.2,0,0,1,3.2,3.2v19.2a3.2,3.2,0,0,1-3.2,3.2h-9.6A12.8,12.8,0,0,1,320,371.2h0A12.8,12.8,0,0,1,332.8,358.4Z"
      />
      <path
        className="fc56c2d3-8731-483f-a9f7-e0b6a108a47e"
        d="M332.8,320h9.6a3.2,3.2,0,0,1,3.2,3.2v19.2a3.2,3.2,0,0,1-3.2,3.2h-9.6A12.8,12.8,0,0,1,320,332.8h0A12.8,12.8,0,0,1,332.8,320Z"
      />
      <path
        className="e65f6f14-3d57-41d0-bd43-a24fcea57cec"
        d="M332.8,281.6h9.6a3.2,3.2,0,0,1,3.2,3.2V304a3.2,3.2,0,0,1-3.2,3.2h-9.6A12.8,12.8,0,0,1,320,294.4h0A12.8,12.8,0,0,1,332.8,281.6Z"
      />
    </g>
  </svg>
)

export default ImgThumbsUpGlitter
