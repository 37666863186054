import { SVGProps } from 'react'

const ImgHandWithKey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ef1b3afe-f823-4f12-ae1e-75769203aa08{fill:none;}.fe24e21b-3f05-4495-b44b-ca5a6228191f{fill:url(#ac2508b3-bc1a-4cfa-96b2-58188200b56e);}.bb65283f-9937-498c-880d-894049d8c2e1{fill:url(#bac8a3ae-9f61-4dd1-91cb-cc5e226aa14d);}.a735b743-d595-47d0-a999-57b4bab2c45c{opacity:0.15;fill:url(#b9b0a3f0-fbdd-4cb5-b291-9ba553ec28f3);}.fdbcc339-5a9f-4652-bae8-005878ab5f3e{fill:url(#e079d674-25cb-4604-96a7-6dc082169696);}.f831a309-70ad-4735-a185-ebf4a78d2c3e{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="ac2508b3-bc1a-4cfa-96b2-58188200b56e"
        x1={347.97}
        y1={14179.96}
        x2={389.47}
        y2={14221.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bac8a3ae-9f61-4dd1-91cb-cc5e226aa14d"
        x1={188.96}
        y1={244.41}
        x2={314.11}
        y2={369.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="b9b0a3f0-fbdd-4cb5-b291-9ba553ec28f3"
        x1={90.71}
        y1={452.62}
        x2={343.66}
        y2={199.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e079d674-25cb-4604-96a7-6dc082169696"
        x1={302.23}
        y1={176.68}
        x2={226.12}
        y2={252.79}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="eb54ff24-7021-4ebe-bade-c4ea1414a98f" data-name="Layer 1">
      <g id="aec5a970-1869-4d3a-817f-9aeebf1d54b4" data-name="backdrop">
        <path
          className="ef1b3afe-f823-4f12-ae1e-75769203aa08"
          d="M512,256c0,141.4-114.6,256-256,256A254.5,254.5,0,0,1,79.9,441.8c-3.3-3.1-6.6-6.4-9.7-9.7A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="fe24e21b-3f05-4495-b44b-ca5a6228191f"
        d="M300.8,300.8s50.8-47.6,57.6-54.4a29.3,29.3,0,0,1,22.4-9.6c3.8,0,5.7,2.7,3.2,6.4l-57.6,80Z"
      />
      <path
        className="bb65283f-9937-498c-880d-894049d8c2e1"
        d="M400,243.2c-9.6,0-12.8,3.2-22.4,9.6l-76.8,48H231.2a32.8,32.8,0,0,1,17.7-5h29.5a22.4,22.4,0,0,0,22.4-22.4h0a3.2,3.2,0,0,0-3.2-3.2H224.1c-19.8,0-25.7,6.4-64.1,25.6L108.8,315a22,22,0,0,0-6.4,15.5v40.7A12.8,12.8,0,0,0,115.2,384L177,354.7a25.5,25.5,0,0,1,11.4-2.7h62.9a38.8,38.8,0,0,0,15.1-3.1l60-25.7,76.8-64c3.2-3.2,6.4-6.4,6.4-9.6S409.6,243.2,400,243.2Z"
      />
      <path
        className="a735b743-d595-47d0-a999-57b4bab2c45c"
        d="M403.3,259.3,166.6,496a256.6,256.6,0,0,1-86.7-54.2l-9.7-9.7a256.4,256.4,0,0,1-33.8-44.4l72.5-72.6,191.9-14.3Z"
      />
      <path
        className="fdbcc339-5a9f-4652-bae8-005878ab5f3e"
        d="M233.7,246.8a32.8,32.8,0,1,1,30-46.1h20.7l8.9,7.4,8.9-7.4,9,7.4,8.9-7.4L341,215.4l-14.9,12.1H263.7a33,33,0,0,1-30,19.3Zm-1.5-32.7a8.9,8.9,0,1,0-8.9,8.9A8.9,8.9,0,0,0,232.2,214.1Z"
      />
      <path
        className="f831a309-70ad-4735-a185-ebf4a78d2c3e"
        d="M324.9,220.8H267.8a1.9,1.9,0,0,1-2-2,2,2,0,0,1,2-2h57.1a2,2,0,0,1,2,2A1.9,1.9,0,0,1,324.9,220.8Z"
      />
    </g>
  </svg>
)

export default ImgHandWithKey
