import { SVGProps } from 'react'

const ImgChecklistGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bcc2c923-002d-4499-b57d-cd5f64374e55{opacity:0.15;fill:url(#b09ab19d-18c8-4c6a-a341-5c48bb8653f9);}.eee35573-8f80-4a18-a94a-3143093096ee{fill:none;}.ee5e9a3a-92eb-4aee-89c2-b413a45e864a{fill:url(#e7a1f54f-b57c-48cd-ba72-880036ce4376);}.a7641249-2af8-451d-81b4-9fa1983854e1{fill:url(#a6eeafe0-db1a-4e72-b70a-9442bc38a10e);}.a35d3aa4-da10-4d92-9ac2-56f8026828e7{fill:url(#aba871fc-3c4c-48a7-b583-ea3a2f7be612);}.e7551807-9580-49f5-8fd5-bbd13257f7de{fill:#37c49f;}.a4e64619-d99c-4970-80da-a45748bb9233{fill:url(#a2728ed8-9451-4c23-9531-1cd2fa8ed1a7);}.a0e9be5e-c90a-432b-a461-b10b34c12ae9{fill:url(#b0680bbf-af55-44db-9946-a21e5179df5e);}.ea0152e2-4873-4d7a-87b4-19f10e2b39ea{fill:url(#bf55cc9b-c879-4b95-bbbd-0bec5ef631dc);}.be1748e1-fe63-4663-be6a-0a2af79dbc92{fill:#fff;}.b5d54775-84c8-4f4d-b8c4-fee172e3a929{fill:url(#b4d51911-0552-4fe3-a4b7-301c8b57cbfc);}.a1c4e9a3-d247-4d15-97fd-3f94e3fecb4b{fill:url(#bea75c03-f7bf-4a49-b155-ca6d97410538);}.b409180c-3d3b-4bf4-96e5-3d48354b9fe2{fill:url(#e4c14333-319b-4bdf-b073-3ee3d21c1a04);}.b410637a-a440-44a1-9d3f-a21ffd8712cf{fill:url(#f30e94cc-5bfc-497d-8751-7b46e7ef358d);}.b1f062b9-06d1-4f08-b6c3-77afb8916f2f{fill:url(#e5d0adf4-245a-494a-b5e2-4ffd6504290d);}'
        }
      </style>
      <linearGradient
        id="b09ab19d-18c8-4c6a-a341-5c48bb8653f9"
        x1={67.03}
        y1={428.97}
        x2={259.2}
        y2={236.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e7a1f54f-b57c-48cd-ba72-880036ce4376"
        x1={372.8}
        y1={388.8}
        x2={107.2}
        y2={123.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="a6eeafe0-db1a-4e72-b70a-9442bc38a10e"
        x1={135.64}
        y1={15424.44}
        x2={357.89}
        y2={15646.68}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="aba871fc-3c4c-48a7-b583-ea3a2f7be612"
        x1={208.24}
        y1={15701.08}
        x2={278.33}
        y2={15630.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="a2728ed8-9451-4c23-9531-1cd2fa8ed1a7"
        x1={144.43}
        y1={15762.16}
        x2={443.37}
        y2={15463.22}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={0.99} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b0680bbf-af55-44db-9946-a21e5179df5e"
        x1={267.36}
        y1={15418.75}
        x2={775.29}
        y2={15926.68}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="bf55cc9b-c879-4b95-bbbd-0bec5ef631dc"
        x1={370.37}
        y1={15660.96}
        x2={387.89}
        y2={15678.48}
        xlinkHref="#a6eeafe0-db1a-4e72-b70a-9442bc38a10e"
      />
      <radialGradient
        id="b4d51911-0552-4fe3-a4b7-301c8b57cbfc"
        cx={179.2}
        cy={204.8}
        r={6.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </radialGradient>
      <radialGradient
        id="bea75c03-f7bf-4a49-b155-ca6d97410538"
        cx={179.2}
        cy={230.4}
        r={6.4}
        xlinkHref="#b4d51911-0552-4fe3-a4b7-301c8b57cbfc"
      />
      <radialGradient
        id="e4c14333-319b-4bdf-b073-3ee3d21c1a04"
        cx={179.2}
        cy={256}
        r={6.4}
        xlinkHref="#b4d51911-0552-4fe3-a4b7-301c8b57cbfc"
      />
      <radialGradient
        id="f30e94cc-5bfc-497d-8751-7b46e7ef358d"
        cx={179.2}
        cy={281.6}
        r={6.4}
        xlinkHref="#b4d51911-0552-4fe3-a4b7-301c8b57cbfc"
      />
      <radialGradient
        id="e5d0adf4-245a-494a-b5e2-4ffd6504290d"
        cx={179.2}
        cy={307.2}
        r={6.4}
        xlinkHref="#b4d51911-0552-4fe3-a4b7-301c8b57cbfc"
      />
    </defs>
    <g id="a36ed4e1-e53d-4fed-ab02-862b06597c67" data-name="Layer 1">
      <path
        className="bcc2c923-002d-4499-b57d-cd5f64374e55"
        d="M353.6,408,249.7,511.9A254.8,254.8,0,0,1,79.4,441.3l-8.7-8.7A255,255,0,0,1,0,256a268.2,268.2,0,0,1,1.4-27l125-125Z"
      />
      <g id="b29654c5-b18e-452b-9ecd-0df6ed80c1ac" data-name="backdrop">
        <path
          className="eee35573-8f80-4a18-a94a-3143093096ee"
          d="M512,256c0,141.4-114.6,256-256,256h-6.3A254.8,254.8,0,0,1,79.4,441.3q-4.5-4.2-8.7-8.7A255,255,0,0,1,0,256a268.2,268.2,0,0,1,1.4-27C14.9,100.3,123.7,0,256,0,397.4,0,512,114.6,512,256Z"
        />
      </g>
      <polygon
        className="ee5e9a3a-92eb-4aee-89c2-b413a45e864a"
        points="353.6 408 353.6 104 171.2 104 164.8 104 126.4 104 126.4 408 353.6 408"
      />
      <rect className="a7641249-2af8-451d-81b4-9fa1983854e1" x={140.8} y={118.4} width={198.4} height={268.8} />
      <path
        className="a35d3aa4-da10-4d92-9ac2-56f8026828e7"
        d="M300.8,102.4H179.2a6.4,6.4,0,0,0-6.4,6.4l12.8,22.4H294.4l12.8-22.4A6.4,6.4,0,0,0,300.8,102.4Zm-35.2,16H214.4a3.2,3.2,0,0,1,0-6.4h51.2a3.2,3.2,0,0,1,0,6.4Z"
      />
      <path
        className="e7551807-9580-49f5-8fd5-bbd13257f7de"
        d="M411,212.7a7.9,7.9,0,1,1,7.9-7.9A7.9,7.9,0,0,1,411,212.7Zm0-12.8a4.9,4.9,0,1,0,4.9,4.9A4.9,4.9,0,0,0,411,199.9Z"
      />
      <path
        className="e7551807-9580-49f5-8fd5-bbd13257f7de"
        d="M142,166.4a1.6,1.6,0,0,1-.9-.3l-11.9-8.9-11.8,8.9a1.5,1.5,0,0,1-1.8,0l-12.8-9.6a1.5,1.5,0,1,1,1.8-2.4l11.9,8.9,11.8-8.9a1.5,1.5,0,0,1,1.8,0L142,163l11.8-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.7,9.6A1.6,1.6,0,0,1,142,166.4Z"
      />
      <path
        className="e7551807-9580-49f5-8fd5-bbd13257f7de"
        d="M219.2,350.2h-6.5v-6.5a1.5,1.5,0,0,0-3,0v6.5h-6.5a1.5,1.5,0,0,0,0,3h6.5v6.5a1.5,1.5,0,0,0,3,0v-6.5h6.5a1.5,1.5,0,0,0,0-3Z"
      />
      <path
        className="a4e64619-d99c-4970-80da-a45748bb9233"
        d="M387.2,390.4l-9.6,19.2V107.2a4.7,4.7,0,0,1,4.8-4.8h0a4.7,4.7,0,0,1,4.8,4.8Z"
      />
      <path
        className="a0e9be5e-c90a-432b-a461-b10b34c12ae9"
        d="M377.6,409.6,368,390.4V107.2a4.7,4.7,0,0,1,4.8-4.8h0a4.7,4.7,0,0,1,4.8,4.8Z"
      />
      <path
        className="ea0152e2-4873-4d7a-87b4-19f10e2b39ea"
        d="M387.2,107.2v14.4H368V107.2a4.7,4.7,0,0,1,4.8-4.8h9.6A4.7,4.7,0,0,1,387.2,107.2Z"
      />
      <path className="be1748e1-fe63-4663-be6a-0a2af79dbc92" d="M304,206.8H201.6a2,2,0,1,1,0-4H304a2,2,0,0,1,0,4Z" />
      <path className="be1748e1-fe63-4663-be6a-0a2af79dbc92" d="M304,232.4H201.6a2,2,0,0,1,0-4H304a2,2,0,0,1,0,4Z" />
      <path className="be1748e1-fe63-4663-be6a-0a2af79dbc92" d="M304,283.6H201.6a2,2,0,0,1,0-4H304a2,2,0,0,1,0,4Z" />
      <path className="be1748e1-fe63-4663-be6a-0a2af79dbc92" d="M304,309.2H201.6a2,2,0,1,1,0-4H304a2,2,0,0,1,0,4Z" />
      <circle className="b5d54775-84c8-4f4d-b8c4-fee172e3a929" cx={179.2} cy={204.8} r={6.4} />
      <circle className="a1c4e9a3-d247-4d15-97fd-3f94e3fecb4b" cx={179.2} cy={230.4} r={6.4} />
      <circle className="b409180c-3d3b-4bf4-96e5-3d48354b9fe2" cx={179.2} cy={256} r={6.4} />
      <circle className="b410637a-a440-44a1-9d3f-a21ffd8712cf" cx={179.2} cy={281.6} r={6.4} />
      <circle className="b1f062b9-06d1-4f08-b6c3-77afb8916f2f" cx={179.2} cy={307.2} r={6.4} />
      <path className="be1748e1-fe63-4663-be6a-0a2af79dbc92" d="M304,258H201.6a2,2,0,0,1,0-4H304a2,2,0,0,1,0,4Z" />
    </g>
  </svg>
)

export default ImgChecklistGlitter
