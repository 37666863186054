const ImgThreeShapesWithArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="ba00885b-5f3d-459d-aade-d0f89d46daca"
          x1={16.86}
          y1={319.97}
          x2={122.29}
          y2={319.97}
          gradientTransform="matrix(1.42 0 0 1.16 147.5 -149)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="a6f5d3ad-2060-4fab-8f0b-1cddf882bc18"
          x1={-60.32}
          y1={440.23}
          x2={8.91}
          y2={440.23}
          gradientTransform="matrix(2.12 0 0 1.48 319.4 -252.9)"
          xlinkHref="#ba00885b-5f3d-459d-aade-d0f89d46daca"
        />
        <linearGradient
          id="fb0c296b-baad-4130-beb1-6a67c7fc62f6"
          x1={-87.16}
          y1={325.9}
          x2={-31.28}
          y2={325.9}
          gradientTransform="matrix(1.29 -1.29 .9 .9 -124.55 -50.34)"
          xlinkHref="#ba00885b-5f3d-459d-aade-d0f89d46daca"
        />
        <linearGradient
          id="b679e768-d033-4b9c-88d8-c51b831e7d23"
          x1={112.41}
          y1={-4043.2}
          x2={200.72}
          y2={-3954.9}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#37c49f" />
          <stop offset={1} stopColor="#4bcaa9" />
        </linearGradient>
        <linearGradient
          id="ae0ff6cb-b90e-4a22-9643-e0d611cbcf5d"
          x1={249.15}
          y1={218.05}
          x2={339.65}
          y2={127.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ef426f" />
          <stop offset={1} stopColor="#f1557d" />
        </linearGradient>
        <linearGradient
          id="eb3de80a-a0cb-4d12-88ca-3ed6125a1e0e"
          x1={268.39}
          y1={-4138.5}
          x2={369.56}
          y2={-4037.33}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" />
          <stop offset={1} stopColor="#281fa9" />
        </linearGradient>
        <style>{'.f4ae8c96-a299-4f75-a9ef-b07aea0a406b{fill:#fff}'}</style>
      </defs>
      <g id="e303aa86-dd68-4049-9239-2277088a5a6c" data-name="Layer 1">
        <circle
          cx={256}
          cy={256}
          r={256}
          style={{
            fill: 'none',
          }}
          id="b9a6ad0f-d007-4a1a-ab9b-e9a91cb2865e"
          data-name="backdrop"
        />
        <path
          transform="rotate(-45 246.378 220.847)"
          style={{
            fill: 'url(#ba00885b-5f3d-459d-aade-d0f89d46daca)',
            opacity: 0.15,
          }}
          d="M171.5 157.1h149.8v127.56H171.5z"
        />
        <path
          transform="rotate(-45 264.848 397.496)"
          style={{
            fill: 'url(#a6f5d3ad-2060-4fab-8f0b-1cddf882bc18)',
            opacity: 0.15,
          }}
          d="M191.5 316h146.7v163.02H191.5z"
        />
        <path
          style={{
            fill: 'url(#fb0c296b-baad-4130-beb1-6a67c7fc62f6)',
            opacity: 0.15,
          }}
          d="m159.1 351.1-53.9 54L6 305.9l72.3-72.4 80.8 117.6z"
        />
        <path
          style={{
            fill: 'url(#b679e768-d033-4b9c-88d8-c51b831e7d23)',
          }}
          d="M155.2 355.1 78.4 233.5H232l-76.8 121.6z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M331.2 245.2a2 2 0 0 1-2-2 23.6 23.6 0 0 0-23.6-23.6H158.4a27.7 27.7 0 0 1-27.6-27.6 2 2 0 0 1 4 0 23.6 23.6 0 0 0 23.6 23.6h147.2a27.7 27.7 0 0 1 27.6 27.6 2 2 0 0 1-2 2Z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M164.8 296.4a2 2 0 0 1-2-2 27.7 27.7 0 0 1 27.6-27.6h115.2a23.6 23.6 0 0 0 23.6-23.6 2 2 0 0 1 4 0 27.7 27.7 0 0 1-27.6 27.6H190.4a23.6 23.6 0 0 0-23.6 23.6 2 2 0 0 1-2 2ZM376 373.2H97.6a2 2 0 1 1 0-4H376a23.6 23.6 0 0 0 23.6-23.6 2 2 0 0 1 4 0 27.7 27.7 0 0 1-27.6 27.6ZM385.6 184.4a2 2 0 0 1-1.4-.6 1.9 1.9 0 0 1 0-2.8l14.6-14.6-14.6-14.6a2 2 0 0 1 2.8-2.8l16 16a1.9 1.9 0 0 1 0 2.8l-16 16a2 2 0 0 1-1.4.6Z"
        />
        <circle
          cx={294.4}
          cy={172.8}
          r={64}
          style={{
            fill: 'url(#ae0ff6cb-b90e-4a22-9643-e0d611cbcf5d)',
          }}
        />
        <path
          style={{
            fill: 'url(#eb3de80a-a0cb-4d12-88ca-3ed6125a1e0e)',
          }}
          d="M259.2 288h115.2v115.2H259.2z"
        />
        <path
          className="f4ae8c96-a299-4f75-a9ef-b07aea0a406b"
          d="M401.6 347.6a2 2 0 0 1-2-2A23.6 23.6 0 0 0 376 322H190.4a27.7 27.7 0 0 1-27.6-27.6 2 2 0 0 1 4 0 23.6 23.6 0 0 0 23.6 23.6H376a27.7 27.7 0 0 1 27.6 27.6 2 2 0 0 1-2 2ZM132.8 194a2 2 0 0 1-2-2 27.7 27.7 0 0 1 27.6-27.6h243.2a2 2 0 0 1 0 4H158.4a23.6 23.6 0 0 0-23.6 23.6 2 2 0 0 1-2 2Z"
        />
      </g>
    </svg>
  )
}

export default ImgThreeShapesWithArrow
