import { SVGProps } from 'react'

const ImgMoneyWithArrowsGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.fcd0c0df-374d-4625-8526-66786adc27c3{opacity:0.15;fill:url(#bc39b83a-8fa6-467a-b839-4485e6dd839b);}.a1537225-7ee0-4009-ab79-b6ab148b5928{fill:none;}.be34ee75-9ce2-4657-b1d2-da10f2bf11ee{fill:url(#e85133f0-9fba-4039-b16b-c0fb1f04f54c);}.e88987a1-5da1-416b-b3f3-90c1b0f28f32{fill:url(#ba0bd8a6-2b1b-4124-a451-6be626f6cd91);}.faa271e7-3809-4ce3-b312-324b2b0e7fa1{fill:url(#fef037f5-fc96-460d-9b8d-de3d6b01b243);}.be2c2454-ad99-4217-be43-7d46892fe627{fill:url(#ae6aac5c-6cec-437e-be2c-7301aba758da);}.e5c89567-d5a3-4f13-9758-16a6b683e6a4{fill:url(#a7dd5cdb-42ab-4ea4-be3f-559078cec039);}.a7d2d504-2b42-48ed-9a06-311e28d79e1b{fill:url(#af2aacf4-a60d-46b8-a97c-99d2fca43cd6);}.a5dc0f09-ca57-4b90-8cab-7382bef575ab{fill:#f7ced7;}.a5c6a046-9f5d-4923-90bb-bf6455cc4798,.f7862e21-343f-4ae8-af40-716dfefa04c8{fill:#fff;}.f7862e21-343f-4ae8-af40-716dfefa04c8{isolation:isolate;}.f1a6fa70-290a-4539-ba43-9f6a1d8f3cff{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="bc39b83a-8fa6-467a-b839-4485e6dd839b"
        x1={74.99}
        y1={437.01}
        x2={294.4}
        y2={217.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e85133f0-9fba-4039-b16b-c0fb1f04f54c"
        x1={357.64}
        y1={9407.23}
        x2={150.73}
        y2={9200.33}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9561.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="ba0bd8a6-2b1b-4124-a451-6be626f6cd91"
        x1={181.08}
        y1={251.48}
        x2={190.12}
        y2={260.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="fef037f5-fc96-460d-9b8d-de3d6b01b243"
        x1={155.48}
        y1={251.48}
        x2={164.52}
        y2={260.52}
        xlinkHref="#ba0bd8a6-2b1b-4124-a451-6be626f6cd91"
      />
      <linearGradient
        id="ae6aac5c-6cec-437e-be2c-7301aba758da"
        x1={347.48}
        y1={251.48}
        x2={356.52}
        y2={260.52}
        xlinkHref="#ba0bd8a6-2b1b-4124-a451-6be626f6cd91"
      />
      <linearGradient
        id="a7dd5cdb-42ab-4ea4-be3f-559078cec039"
        x1={321.88}
        y1={251.48}
        x2={330.92}
        y2={260.52}
        xlinkHref="#ba0bd8a6-2b1b-4124-a451-6be626f6cd91"
      />
      <linearGradient
        id="af2aacf4-a60d-46b8-a97c-99d2fca43cd6"
        x1={212}
        y1={256}
        x2={300}
        y2={256}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
    </defs>
    <g id="ede14363-deb0-4608-865f-091ef7647e68" data-name="Layer 1">
      <path
        className="fcd0c0df-374d-4625-8526-66786adc27c3"
        d="M406.4,329.6l-181,180.9A254.3,254.3,0,0,1,77.6,439.6l-4.4-4.4A254.8,254.8,0,0,1,2.1,285.9L105.6,182.4Z"
      />
      <g id="a9874c32-1509-41e4-a630-573ac1a1e430" data-name="backdrop">
        <path
          className="a1537225-7ee0-4009-ab79-b6ab148b5928"
          d="M512.4,256.4c0,141.4-114.6,256-256,256a244.6,244.6,0,0,1-31-1.9A254.3,254.3,0,0,1,77.6,439.6l-4.4-4.4A254.8,254.8,0,0,1,2.1,285.9,261.1,261.1,0,0,1,.4,256.4C.4,115,115,.4,256.4.4S512.4,115,512.4,256.4Z"
        />
      </g>
      <path className="be34ee75-9ce2-4657-b1d2-da10f2bf11ee" d="M105.6,182.4V329.6H406.4V182.4Z" />
      <path
        className="e88987a1-5da1-416b-b3f3-90c1b0f28f32"
        d="M185.6,262.4A6.4,6.4,0,1,1,192,256,6.4,6.4,0,0,1,185.6,262.4Z"
      />
      <path
        className="faa271e7-3809-4ce3-b312-324b2b0e7fa1"
        d="M160,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,160,262.4Z"
      />
      <path
        className="be2c2454-ad99-4217-be43-7d46892fe627"
        d="M352,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,352,262.4Z"
      />
      <path
        className="e5c89567-d5a3-4f13-9758-16a6b683e6a4"
        d="M326.4,262.4a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,326.4,262.4Z"
      />
      <path className="a7d2d504-2b42-48ed-9a06-311e28d79e1b" d="M256,300a44,44,0,1,1,44-44A44,44,0,0,1,256,300Z" />
      <circle className="a5dc0f09-ca57-4b90-8cab-7382bef575ab" cx={256} cy={256} r={40} />
      <path
        className="f7862e21-343f-4ae8-af40-716dfefa04c8"
        d="M270.2,128.2l-12.8-12.8h0l-.3-.3h-2.2l-.3.3h0l-12.8,12.8a2,2,0,1,0,2.8,2.8l9.4-9.4V392a2,2,0,0,0,4,0V121.6l9.4,9.4a1.9,1.9,0,0,0,2.8,0A1.9,1.9,0,0,0,270.2,128.2Z"
      />
      <path
        className="a5c6a046-9f5d-4923-90bb-bf6455cc4798"
        d="M292.6,150.6l-12.8-12.8h0a1.9,1.9,0,0,0-2.8,0h0l-12.8,12.8a2,2,0,0,0,2.8,2.8l9.4-9.4V408a2,2,0,0,0,4,0V144l9.4,9.4a1.9,1.9,0,0,0,2.8,0A1.9,1.9,0,0,0,292.6,150.6Z"
      />
      <path
        className="a5c6a046-9f5d-4923-90bb-bf6455cc4798"
        d="M247.8,150.6,235,137.8h0a1.9,1.9,0,0,0-2.8,0h0l-12.8,12.8a1.9,1.9,0,0,0,0,2.8,1.9,1.9,0,0,0,2.8,0l9.4-9.4V408a2,2,0,0,0,4,0V144l9.4,9.4a2,2,0,0,0,2.8-2.8Z"
      />
      <path
        className="f1a6fa70-290a-4539-ba43-9f6a1d8f3cff"
        d="M369.5,360h-6.3v-6.4a1.6,1.6,0,0,0-1.6-1.6,1.6,1.6,0,0,0-1.6,1.6V360h-6.4a1.6,1.6,0,0,0,0,3.2H360v6.4a1.6,1.6,0,0,0,1.6,1.6,1.7,1.7,0,0,0,1.6-1.6v-6.4h6.3a1.6,1.6,0,1,0,0-3.2Z"
      />
      <path
        className="f1a6fa70-290a-4539-ba43-9f6a1d8f3cff"
        d="M174.4,160a8,8,0,1,1,0-16,8,8,0,0,1,0,16Zm0-12.8a4.8,4.8,0,1,0,4.7,4.8A4.8,4.8,0,0,0,174.4,147.2Z"
      />
      <path
        className="f1a6fa70-290a-4539-ba43-9f6a1d8f3cff"
        d="M106.3,308.8a1.5,1.5,0,0,1-.9-.4l-11.8-8.8-11.8,8.8a1.4,1.4,0,0,1-1.9,0l-12.8-9.5a1.7,1.7,0,0,1-.3-2.3,1.6,1.6,0,0,1,2.2-.3l11.8,8.9,11.8-8.9a1.7,1.7,0,0,1,1.9,0l11.8,8.9,11.8-8.9a1.6,1.6,0,0,1,2.2.3,1.6,1.6,0,0,1-.3,2.3l-12.7,9.5A2.1,2.1,0,0,1,106.3,308.8Z"
      />
    </g>
  </svg>
)

export default ImgMoneyWithArrowsGlitter
