import { SVGProps } from 'react'

const ImgAwardBadgeGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.afb106da-bf26-4817-9ed2-d500899fed7d{opacity:0.15;fill:url(#fad9e279-9f5b-40a8-ad9c-5f0fbafd4568);}.aa6d9b4f-9337-4ca4-9d9b-748a7615b6a8{fill:none;}.ade1c520-5a6b-431a-97a6-da02481a5ce2{fill:url(#abee33ac-3cbb-44fb-b253-ab3c4d797c6e);}.ae4b870e-340c-434d-a767-60266cc14a61{fill:url(#e99c6ffe-a162-4949-a4cb-77bec8d2917c);}.b789345a-3bbd-463e-9a49-d238a6155c5b{fill:url(#a4c89a52-2a1f-4d99-82f1-9184a89bc142);}.f55bf348-9ac6-4c14-9c92-1714e7da626a{fill:#fff;opacity:0.9;isolation:isolate;}.ac5f7f4f-be42-4c1a-9d3e-74ed3607398f{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="fad9e279-9f5b-40a8-ad9c-5f0fbafd4568"
        x1={75.86}
        y1={437.8}
        x2={258.91}
        y2={254.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="abee33ac-3cbb-44fb-b253-ab3c4d797c6e"
        x1={186.04}
        y1={10684.37}
        x2={313.92}
        y2={10454.11}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="e99c6ffe-a162-4949-a4cb-77bec8d2917c"
        x1={235.6}
        y1={10441.2}
        x2={302.73}
        y2={10374.06}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="a4c89a52-2a1f-4d99-82f1-9184a89bc142"
        x1={333.79}
        y1={10636.19}
        x2={87.94}
        y2={10390.34}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
    </defs>
    <g id="b9e5190c-9d2e-4844-b9db-32c517c79adc" data-name="Layer 1">
      <path
        className="afb106da-bf26-4817-9ed2-d500899fed7d"
        d="M291.2,416l-90.1,90.1A255,255,0,0,1,79.4,441.3l-8.7-8.7a255.8,255.8,0,0,1-64.2-119L162.1,158Z"
      />
      <g id="aa0aa8c3-3e84-48e1-8f80-0afb236d1255" data-name="backdrop">
        <path
          className="aa6d9b4f-9337-4ca4-9d9b-748a7615b6a8"
          d="M291.2,416l-90.1,90.1A255,255,0,0,1,79.4,441.3q-4.5-4.2-8.7-8.7a255.8,255.8,0,0,1-64.2-119L162.1,158Z"
        />
      </g>
      <g id="b7f03d59-38ed-47a5-8fde-dd228b45be8f" data-name="backdrop">
        <circle className="aa6d9b4f-9337-4ca4-9d9b-748a7615b6a8" cx={256} cy={256} r={256} />
      </g>
      <path
        className="ade1c520-5a6b-431a-97a6-da02481a5ce2"
        d="M362,235.2a42.9,42.9,0,0,1-.4-8,42.9,42.9,0,0,1,.4-8l2.3-15.9a53.6,53.6,0,0,0-33.5-58l-14.9-5.9a48.8,48.8,0,0,1-13.8-8l-12.6-10A54.6,54.6,0,0,0,256,108.8a54.6,54.6,0,0,0-33.5,12.6l-12.6,10a48.8,48.8,0,0,1-13.8,8l-14.9,5.9a53.6,53.6,0,0,0-33.5,58l2.3,15.9a42.9,42.9,0,0,1,.4,8,42.9,42.9,0,0,1-.4,8l-2.3,15.9a53.6,53.6,0,0,0,33.5,58l14.9,5.9a48.8,48.8,0,0,1,13.8,8l12.6,10A54.6,54.6,0,0,0,256,345.6,54.6,54.6,0,0,0,289.5,333l12.6-10a48.8,48.8,0,0,1,13.8-8l14.9-5.9a53.6,53.6,0,0,0,33.5-58Z"
      />
      <polygon
        className="ae4b870e-340c-434d-a767-60266cc14a61"
        points="291.2 416 256 380.8 220.8 416 220.8 313.6 291.2 313.6 291.2 416"
      />
      <path
        className="b789345a-3bbd-463e-9a49-d238a6155c5b"
        d="M361.8,193.7l-10-12.6a48.8,48.8,0,0,1-8-13.8l-5.9-14.9a53.6,53.6,0,0,0-58-33.5L264,121.2a42.9,42.9,0,0,1-8,.4,42.9,42.9,0,0,1-8-.4l-15.9-2.3a53.6,53.6,0,0,0-58,33.5l-5.9,14.9a48.8,48.8,0,0,1-8,13.8l-10,12.6a54.6,54.6,0,0,0-12.6,33.5,54.6,54.6,0,0,0,12.6,33.5l10,12.6a48.8,48.8,0,0,1,8,13.8l5.9,14.9a53.6,53.6,0,0,0,58,33.5l15.9-2.3a42.9,42.9,0,0,1,8-.4,42.9,42.9,0,0,1,8,.4l15.9,2.3a53.6,53.6,0,0,0,58-33.5l5.9-14.9a48.8,48.8,0,0,1,8-13.8l10-12.6a54.6,54.6,0,0,0,12.6-33.5A54.6,54.6,0,0,0,361.8,193.7Z"
      />
      <path
        className="f55bf348-9ac6-4c14-9c92-1714e7da626a"
        d="M256,304.4a77.2,77.2,0,1,1,77.2-77.2A77.2,77.2,0,0,1,256,304.4ZM256,154a73.2,73.2,0,1,0,73.2,73.2A73.2,73.2,0,0,0,256,154Z"
      />
      <polygon
        className="ac5f7f4f-be42-4c1a-9d3e-74ed3607398f"
        points="274.3 252.3 256 239.1 237.7 252.3 244.7 230.9 226.5 217.6 249 217.6 256 196.1 263 217.6 285.5 217.6 267.3 230.9 274.3 252.3"
      />
      <path
        className="ac5f7f4f-be42-4c1a-9d3e-74ed3607398f"
        d="M324.8,362.6a7.9,7.9,0,1,1,7.9-7.9A7.8,7.8,0,0,1,324.8,362.6Zm0-12.7a4.9,4.9,0,0,0,0,9.7,4.9,4.9,0,1,0,0-9.7Z"
      />
      <path
        className="ac5f7f4f-be42-4c1a-9d3e-74ed3607398f"
        d="M150.3,270.5a1.6,1.6,0,0,1-.9-.3l-11.8-8.9-11.8,8.9a1.7,1.7,0,0,1-1.9,0l-12.7-9.6a1.5,1.5,0,0,1-.3-2.1,1.6,1.6,0,0,1,2.1-.3l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,0l11.8,8.9,11.9-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.7,9.6A1.7,1.7,0,0,1,150.3,270.5Z"
      />
      <path
        className="ac5f7f4f-be42-4c1a-9d3e-74ed3607398f"
        d="M362.1,143.7a1.5,1.5,0,0,1-1.5-1.5v-16a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5v16A1.5,1.5,0,0,1,362.1,143.7Z"
      />
      <path
        className="ac5f7f4f-be42-4c1a-9d3e-74ed3607398f"
        d="M370.1,135.7H354.2a1.5,1.5,0,0,1,0-3h15.9a1.5,1.5,0,0,1,0,3Z"
      />
    </g>
  </svg>
)

export default ImgAwardBadgeGlitter
