import { SVGProps } from 'react'

const ImgNotebookWithPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.efb19486-5b0e-40f1-840d-c6b87f7480a7{fill:none;}.abc48b74-7fd1-43f6-8a36-7b2710289035{opacity:0.15;fill:url(#aa4508d0-6be6-4256-adaa-0327ef54924c);}.e7fa202f-3dab-4587-b52a-95eed80cc10c{fill:url(#ae12039c-c5e1-4952-9bb3-ad3746fe7c48);}.a3a280e1-ed38-4a0b-ad7a-12ab0928b663{fill:url(#b457ffe7-48f5-4137-ae8b-f9d262e30a67);}.a18dab51-71ff-4841-befc-27fb88e9a9bf{fill:#fff;}.b998054d-22f9-43db-b2c0-0e895e0ff75d{fill:url(#b84ef82f-ce40-4a65-b763-d8c1d87e407e);}.ac62635c-6c90-4555-a1a1-256cb9d06d50{fill:url(#ba380901-dda0-46cc-83f3-601648d972da);}.ba817c0d-9e84-49e7-b4bf-09feec626a80{fill:url(#a5466770-158e-4692-abb0-4ba7fdfbfdd0);}.a3545fbc-58ae-4ff8-bbfb-fac7db134d6c{fill:url(#f310b5b9-c13d-4b6e-a97b-825259786f0c);}.ef536267-f43d-43df-b6b5-7c403f7e9b6a{fill:url(#e68c9b5b-d100-41c5-9a06-2a1a5195cf83);}.a1bf7450-52c0-49c1-997d-af836f559416{fill:#f7ced7;}'
        }
      </style>
      <linearGradient
        id="aa4508d0-6be6-4256-adaa-0327ef54924c"
        x1={75.73}
        y1={437.68}
        x2={272.03}
        y2={241.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ae12039c-c5e1-4952-9bb3-ad3746fe7c48"
        x1={236.14}
        y1={178.54}
        x2={74.81}
        y2={339.86}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="b457ffe7-48f5-4137-ae8b-f9d262e30a67"
        x1={392.61}
        y1={15649.96}
        x2={127.95}
        y2={15385.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="b84ef82f-ce40-4a65-b763-d8c1d87e407e"
        x1={131.86}
        y1={7055.36}
        x2={485.51}
        y2={6701.7}
        gradientTransform="matrix(0, -1, -1, 0, 7023.31, 677.31)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="ba380901-dda0-46cc-83f3-601648d972da"
        x1={158.47}
        y1={15624.53}
        x2={421.82}
        y2={15361.18}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        xlinkHref="#b84ef82f-ce40-4a65-b763-d8c1d87e407e"
      />
      <linearGradient
        id="a5466770-158e-4692-abb0-4ba7fdfbfdd0"
        x1={121.39}
        y1={15533.58}
        x2={102.02}
        y2={15514.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="f310b5b9-c13d-4b6e-a97b-825259786f0c"
        x1={357.49}
        y1={15671.89}
        x2={296.41}
        y2={15610.81}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="e68c9b5b-d100-41c5-9a06-2a1a5195cf83"
        x1={312.84}
        y1={15679.76}
        x2={300.36}
        y2={15667.29}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        xlinkHref="#ae12039c-c5e1-4952-9bb3-ad3746fe7c48"
      />
    </defs>
    <g id="a99bf61f-902f-4cc7-a75f-1b819b31b3dc" data-name="Layer 1">
      <g id="e0ad37f3-940e-4cca-bb0d-fc3fb261f8d3" data-name="backdrop">
        <path
          className="efb19486-5b0e-40f1-840d-c6b87f7480a7"
          d="M512,256c0,139.6-111.7,253-250.6,255.9H256A255.1,255.1,0,0,1,79.1,441q-4.2-3.9-8.1-8.1A255.1,255.1,0,0,1,0,256v-2.6C1.4,113.2,115.5,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="abc48b74-7fd1-43f6-8a36-7b2710289035"
        d="M367.3,406,261.4,511.9H256A255.1,255.1,0,0,1,79.1,441L71,432.9A255.1,255.1,0,0,1,0,256v-2.6l142.1-142Z"
      />
      <path
        className="e7fa202f-3dab-4587-b52a-95eed80cc10c"
        d="M147.2,108.8h19.2V409.6H147.2a6.4,6.4,0,0,1-6.4-6.4v-288A6.4,6.4,0,0,1,147.2,108.8Z"
      />
      <path
        className="a3a280e1-ed38-4a0b-ad7a-12ab0928b663"
        d="M358.4,108.8h-192V409.6h192a12.8,12.8,0,0,0,12.8-12.8h0V121.6a12.8,12.8,0,0,0-12.8-12.8Z"
      />
      <path className="a18dab51-71ff-4841-befc-27fb88e9a9bf" d="M160,382.8H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path className="a18dab51-71ff-4841-befc-27fb88e9a9bf" d="M160,363.6H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path className="a18dab51-71ff-4841-befc-27fb88e9a9bf" d="M160,158.8H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path className="a18dab51-71ff-4841-befc-27fb88e9a9bf" d="M160,139.6H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path
        className="b998054d-22f9-43db-b2c0-0e895e0ff75d"
        d="M393.1,264.2l16.5-8.2H107.2a4.7,4.7,0,0,0-4.8,4.8h0a4.7,4.7,0,0,0,4.8,4.8H387.4A12.6,12.6,0,0,0,393.1,264.2Z"
      />
      <path
        className="ac62635c-6c90-4555-a1a1-256cb9d06d50"
        d="M409.6,256l-16.5-8.2a12.6,12.6,0,0,0-5.7-1.4H107.2a4.7,4.7,0,0,0-4.8,4.8h0a4.7,4.7,0,0,0,4.8,4.8Z"
      />
      <path
        className="ba817c0d-9e84-49e7-b4bf-09feec626a80"
        d="M107.2,246.4h14.4v19.2H107.2a4.7,4.7,0,0,1-4.8-4.8v-9.6A4.7,4.7,0,0,1,107.2,246.4Z"
      />
      <path
        className="a3545fbc-58ae-4ff8-bbfb-fac7db134d6c"
        d="M339.2,102.4h-32a6.4,6.4,0,0,1,6.4,6.4v70.4l16-16,16,16V108.8A6.4,6.4,0,0,0,339.2,102.4Z"
      />
      <path
        className="ef536267-f43d-43df-b6b5-7c403f7e9b6a"
        d="M307.2,102.4a6.4,6.4,0,0,0-6.4,6.4h12.8A6.4,6.4,0,0,0,307.2,102.4Z"
      />
      <path
        className="a1bf7450-52c0-49c1-997d-af836f559416"
        d="M243.2,227.2a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,243.2,227.2Z"
      />
      <path
        className="a1bf7450-52c0-49c1-997d-af836f559416"
        d="M268.8,227.2a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,268.8,227.2Z"
      />
      <path
        className="a1bf7450-52c0-49c1-997d-af836f559416"
        d="M294.4,227.2a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,294.4,227.2Z"
      />
    </g>
  </svg>
)

export default ImgNotebookWithPencil
