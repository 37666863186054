import { SVGProps } from 'react'

const ImgWill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.e786b6b5-f4db-4896-ba10-6c27cbda7e81{fill:none;}.a6929577-fcbb-4c65-88f9-3bfeb2eba7f8{opacity:0.15;fill:url(#e6e87733-af61-4f4e-96a2-6c6fe308f7ef);}.b00aafa4-3a9e-43f7-85c7-483c6532e2f7{fill:url(#a103622b-0ce4-46b5-a847-57eb9ef2e1b1);}.b02fea4e-fdda-4fc1-a94b-7538e12325ac{fill:url(#ad5d4faf-4e11-4012-9479-667c9b958d32);}.a7397e1b-5101-4871-9686-0968a773ea59{fill:#fff;}.aedefb03-8eb3-45b9-9fce-cead8e6818ba{fill:url(#b72c812a-4fed-4bfe-83eb-b4e1c30fabb4);}.f6251dcb-cbd7-48f7-94f7-118859d37a60{fill:url(#af84523f-5e74-4137-91e1-02a697b61807);}.ee6ede19-f7a5-4dbd-bed7-8c04c06bec37{fill:url(#e3d43747-8093-4d96-a352-a0dcef911135);}'
        }
      </style>
      <linearGradient
        id="e6e87733-af61-4f4e-96a2-6c6fe308f7ef"
        x1={97.99}
        y1={459.44}
        x2={329.98}
        y2={227.45}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a103622b-0ce4-46b5-a847-57eb9ef2e1b1"
        x1={203.59}
        y1={15427.62}
        x2={423.84}
        y2={15647.88}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="ad5d4faf-4e11-4012-9479-667c9b958d32"
        x1={356.3}
        y1={15465.41}
        x2={191.56}
        y2={15300.67}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b72c812a-4fed-4bfe-83eb-b4e1c30fabb4"
        x1={287.95}
        y1={15618.14}
        x2={309.81}
        y2={15640}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="af84523f-5e74-4137-91e1-02a697b61807"
        x1={72.1}
        y1={15488.91}
        x2={193.03}
        y2={15609.84}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="e3d43747-8093-4d96-a352-a0dcef911135"
        x1={106.73}
        y1={15520.32}
        x2={252.29}
        y2={15665.89}
        xlinkHref="#af84523f-5e74-4137-91e1-02a697b61807"
      />
    </defs>
    <g id="e0a907f9-22ca-4ef4-b094-fd7b8a012bf6" data-name="Layer 1">
      <g id="e04c0281-6b32-4cf5-bb0f-eff9148963b8" data-name="backdrop">
        <path
          className="e786b6b5-f4db-4896-ba10-6c27cbda7e81"
          d="M512,256c0,126.7-92,231.9-212.8,252.4A256.8,256.8,0,0,1,85.6,447,256.2,256.2,0,0,1,4.3,303,259.2,259.2,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a6929577-fcbb-4c65-88f9-3bfeb2eba7f8"
        d="M403.4,404.2,299.2,508.4A256.8,256.8,0,0,1,85.6,447L64.9,426.3A254.3,254.3,0,0,1,4.3,303L204.9,102.4Z"
      />
      <path
        className="b00aafa4-3a9e-43f7-85c7-483c6532e2f7"
        d="M204.9,102.4V390.3a19.2,19.2,0,0,1-19.2,19.3H390.2a19.2,19.2,0,0,0,19.3-19.3V102.4Z"
      />
      <path
        className="b02fea4e-fdda-4fc1-a94b-7538e12325ac"
        d="M370.9,390.3V378.8H166.4v11.5a19.2,19.2,0,0,0,19.3,19.3H390.2A19.3,19.3,0,0,1,370.9,390.3Z"
      />
      <path className="a7397e1b-5101-4871-9686-0968a773ea59" d="M381.1,283H233.3a2,2,0,1,1,0-4H381.1a2,2,0,0,1,0,4Z" />
      <path className="a7397e1b-5101-4871-9686-0968a773ea59" d="M381.1,315H233.3a2,2,0,1,1,0-4H381.1a2,2,0,0,1,0,4Z" />
      <path className="a7397e1b-5101-4871-9686-0968a773ea59" d="M381.1,347H233.3a2,2,0,1,1,0-4H381.1a2,2,0,0,1,0,4Z" />
      <path className="a7397e1b-5101-4871-9686-0968a773ea59" d="M381.1,251H233.3a2,2,0,0,1,0-4H381.1a2,2,0,0,1,0,4Z" />
      <path className="a7397e1b-5101-4871-9686-0968a773ea59" d="M381.1,219H233.3a2,2,0,0,1,0-4H381.1a2,2,0,0,1,0,4Z" />
      <path className="a7397e1b-5101-4871-9686-0968a773ea59" d="M381.1,187H233.3a2,2,0,0,1,0-4H381.1a2,2,0,0,1,0,4Z" />
      <polygon
        className="aedefb03-8eb3-45b9-9fce-cead8e6818ba"
        points="307.2 125 312 132.5 320.8 130.6 318.9 139.4 326.4 144.2 318.9 149 320.8 157.8 312 155.9 307.2 163.4 302.4 155.9 293.6 157.8 295.5 149 288 144.2 295.5 139.4 293.6 130.6 302.4 132.5 307.2 125"
      />
      <path
        className="f6251dcb-cbd7-48f7-94f7-118859d37a60"
        d="M131.2,371.2s-28.8-38.4-28.8-105.6l16,9.6c-16-32-16-83.2-16-83.2,0-73.6,16-89.6,28.8-89.6Z"
      />
      <path
        className="ee6ede19-f7a5-4dbd-bed7-8c04c06bec37"
        d="M131.2,371.2s35.2-64,35.2-144l-16,9.6c9.6-41.6,22.4-134.4-19.2-134.4Z"
      />
      <path
        className="a7397e1b-5101-4871-9686-0968a773ea59"
        d="M131.2,410a2,2,0,0,1-2-2V128a2,2,0,0,1,4,0V408A2,2,0,0,1,131.2,410Z"
      />
    </g>
  </svg>
)

export default ImgWill
