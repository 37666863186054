import { SVGProps } from 'react'

const ImgComputerWithLamp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a2e1c0d2-3ee2-4286-ab7f-864754ff9e93{fill:none;}.f5082201-e18a-49f5-b3b7-ed6800917cb2{opacity:0.15;fill:url(#a59e0abc-32bd-4393-b52e-808c58c2d343);}.a61ee341-1897-4e45-8877-8aad316bec51{fill:url(#b39aa2ec-f7e1-4f3c-9f60-17f8026d2eb1);}.fdcb065c-f92e-4920-95cd-af1b71c9b964{fill:url(#bb97032e-8a57-4d3b-8a21-f082b7d7fa85);}.f30196c3-a90e-404b-9b7f-c9b72baa53c0{fill:url(#eedd336a-8199-42bf-9e6e-5736bf4e379d);}.b3063610-09ac-4895-9f69-013ab10cefcf{fill:url(#e26d6f2a-9d91-49be-804a-60d5a427dee3);}.e79a2874-d5cf-4396-98ad-86a85557fb53{fill:url(#ef86bf1b-5f31-4d47-9101-880f952673b6);}.f30aa987-ebf8-4f89-a070-ca15fe2ce2c8{fill:#fff;}.b15a1bb1-62b4-4b92-a323-314a60d46224{fill:url(#e3d11aa2-09ce-41b8-9ce3-985f63081d4f);}.f8c173a7-ba9b-478b-b966-04fdf71e4faa{fill:url(#a9674ff1-2478-4648-b6d3-574798029ea7);}'
        }
      </style>
      <linearGradient
        id="a59e0abc-32bd-4393-b52e-808c58c2d343"
        x1={93.44}
        y1={455.48}
        x2={287.36}
        y2={261.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b39aa2ec-f7e1-4f3c-9f60-17f8026d2eb1"
        x1={171.49}
        y1={7990.69}
        x2={400.1}
        y2={8219.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="bb97032e-8a57-4d3b-8a21-f082b7d7fa85"
        x1={195.94}
        y1={7943.41}
        x2={337}
        y2={8084.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="eedd336a-8199-42bf-9e6e-5736bf4e379d"
        x1={280.08}
        y1={8014.82}
        x2={272.3}
        y2={7901.74}
        xlinkHref="#bb97032e-8a57-4d3b-8a21-f082b7d7fa85"
      />
      <linearGradient
        id="e26d6f2a-9d91-49be-804a-60d5a427dee3"
        x1={253.51}
        y1={7963.91}
        x2={361.2}
        y2={8071.59}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ef86bf1b-5f31-4d47-9101-880f952673b6"
        x1={208.98}
        y1={7877.77}
        x2={335.18}
        y2={8003.98}
        xlinkHref="#bb97032e-8a57-4d3b-8a21-f082b7d7fa85"
      />
      <linearGradient
        id="e3d11aa2-09ce-41b8-9ce3-985f63081d4f"
        x1={204.03}
        y1={8121.49}
        x2={253.58}
        y2={8171.04}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="a9674ff1-2478-4648-b6d3-574798029ea7"
        x1={186.5}
        y1={7959.86}
        x2={190.12}
        y2={7980.11}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="afac2bc8-667c-453e-b843-fd6c4e367443" data-name="Layer 1">
      <g id="b324ce5d-bc48-47a7-a0c3-662535abc681" data-name="backdrop">
        <circle className="a2e1c0d2-3ee2-4286-ab7f-864754ff9e93" cx={256} cy={256} r={256} />
      </g>
      <path
        className="f5082201-e18a-49f5-b3b7-ed6800917cb2"
        d="M403.2,377.4,268.9,511.7c-4.2.2-8.6.3-12.9.3C133.6,512,31.3,426.1,6,311.3l162-162L311.4,356.9,323.2,374Z"
      />
      <rect
        className="a61ee341-1897-4e45-8877-8aad316bec51"
        x={166.4}
        y={147.2}
        width={236.8}
        height={172.8}
        rx={6.4}
      />
      <path
        className="fdcb065c-f92e-4920-95cd-af1b71c9b964"
        d="M166.4,294.4H403.2v19.2a6.4,6.4,0,0,1-6.4,6.4h-224a6.4,6.4,0,0,1-6.4-6.4V294.4Z"
      />
      <path
        className="f30196c3-a90e-404b-9b7f-c9b72baa53c0"
        d="M313.6,320v48a6.4,6.4,0,0,0,6.4,6.4H243.2a6.4,6.4,0,0,1-6.4-6.4V320Z"
      />
      <path
        className="b3063610-09ac-4895-9f69-013ab10cefcf"
        d="M320,320v44.8a3.2,3.2,0,0,0,3.2,3.2H246.4a3.2,3.2,0,0,1-3.2-3.2V320Z"
      />
      <rect className="e79a2874-d5cf-4396-98ad-86a85557fb53" x={256} y={368} width={83.2} height={6.4} />
      <path
        className="f30aa987-ebf8-4f89-a070-ca15fe2ce2c8"
        d="M97.6,272.4a2,2,0,0,1-1.4-.6,1.9,1.9,0,0,1,0-2.8l88-88a2,2,0,0,1,2.8,2.8l-88,88A2,2,0,0,1,97.6,272.4Z"
      />
      <path
        className="b15a1bb1-62b4-4b92-a323-314a60d46224"
        d="M284.8,192H182.4v-9.6a3.2,3.2,0,0,1,3.2-3.2H272A12.8,12.8,0,0,1,284.8,192Z"
      />
      <path
        className="f30aa987-ebf8-4f89-a070-ca15fe2ce2c8"
        d="M187.2,362a2,2,0,0,1-1.4-.6L96.2,271.8A2,2,0,1,1,99,269l89.6,89.6a1.9,1.9,0,0,1,0,2.8A2,2,0,0,1,187.2,362Z"
      />
      <path className="f30aa987-ebf8-4f89-a070-ca15fe2ce2c8" d="M401.6,378H97.6a2,2,0,0,1,0-4h304a2,2,0,0,1,0,4Z" />
      <path
        className="f8c173a7-ba9b-478b-b966-04fdf71e4faa"
        d="M211.2,361.6h-48a12.8,12.8,0,0,0-12.8,12.8H224A12.8,12.8,0,0,0,211.2,361.6Z"
      />
    </g>
  </svg>
)

export default ImgComputerWithLamp
