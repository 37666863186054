export const guidesVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
    },
  },
}
export const squareVariants = {
  hidden: {
    opacity: 0,
    y: '-10%',
    x: '20%',
  },
  visible: {
    opacity: 1,
    y: 0,
    x: '20%',
    transition: {
      duration: 0.7,
    },
  },
}
export const triangleVariants = {
  hidden: {
    opacity: 0,
    y: '20%',
    x: '50%',
  },
  visible: {
    opacity: 1,
    y: '30%',
    x: '50%',
    transition: {
      duration: 0.7,
    },
  },
}
export const circleVariants = {
  hidden: {
    opacity: 0,
    y: '40%',
  },
  visible: {
    opacity: 1,
    y: '50%',
    transition: {
      duration: 0.7,
    },
    whileHover: { scale: 1.2 },
  },
}

export const accordionVariants = {
  open: { height: 'auto' },
  close: { height: 0 },
}
