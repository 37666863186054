import { SVGProps } from 'react'

const ImgHandWithKeyGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ef57cf08-0e93-44df-b292-9261304b1152{fill:none;}.e92141bd-3859-4ba5-b161-25ff5d0b35b1{fill:url(#f0e706bf-4cc9-4016-9531-a5e04845b3ce);}.f34b0587-a4c4-4ea1-8b87-61d64e33bda3{fill:url(#a61773db-b73b-40c2-9b2f-93b671bb9263);}.bf7d9a67-5be0-40c6-b4a2-f9dd350f7921{opacity:0.15;fill:url(#bffa96eb-fbc9-4c69-82a9-1d7f25fa8992);}.a2c60830-93c4-4bd6-91bb-8cfbe2dfbc6b{fill:#37c49f;}.b9ecba27-9161-4a28-90f0-145777f28c2c{fill:url(#a7da13fe-ca37-450d-a1f5-218c18cf47c1);}.a271e206-2031-4192-b0b0-fb5d36b60abb{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="f0e706bf-4cc9-4016-9531-a5e04845b3ce"
        x1={347.97}
        y1={14179.96}
        x2={389.47}
        y2={14221.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a61773db-b73b-40c2-9b2f-93b671bb9263"
        x1={188.96}
        y1={244.41}
        x2={314.11}
        y2={369.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="bffa96eb-fbc9-4c69-82a9-1d7f25fa8992"
        x1={90.71}
        y1={452.62}
        x2={343.66}
        y2={199.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a7da13fe-ca37-450d-a1f5-218c18cf47c1"
        x1={302.23}
        y1={176.68}
        x2={226.12}
        y2={252.79}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="bf82cb55-568d-4289-8ac7-4bb611311c3f" data-name="Layer 1">
      <g id="a29a9ea1-0c79-4e8c-8044-5aa9a8cc526f" data-name="backdrop">
        <path
          className="ef57cf08-0e93-44df-b292-9261304b1152"
          d="M512,256c0,141.4-114.6,256-256,256A254.5,254.5,0,0,1,79.9,441.8c-3.3-3.1-6.6-6.4-9.7-9.7A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="e92141bd-3859-4ba5-b161-25ff5d0b35b1"
        d="M300.8,300.8s50.8-47.6,57.6-54.4a29.3,29.3,0,0,1,22.4-9.6c3.8,0,5.7,2.7,3.2,6.4l-57.6,80Z"
      />
      <path
        className="f34b0587-a4c4-4ea1-8b87-61d64e33bda3"
        d="M400,243.2c-9.6,0-12.8,3.2-22.4,9.6l-76.8,48H231.2a32.8,32.8,0,0,1,17.7-5h29.5a22.4,22.4,0,0,0,22.4-22.4h0a3.2,3.2,0,0,0-3.2-3.2H224.1c-19.8,0-25.7,6.4-64.1,25.6L108.8,315a22,22,0,0,0-6.4,15.5v40.7A12.8,12.8,0,0,0,115.2,384L177,354.7a25.5,25.5,0,0,1,11.4-2.7h62.9a38.8,38.8,0,0,0,15.1-3.1l60-25.7,76.8-64c3.2-3.2,6.4-6.4,6.4-9.6S409.6,243.2,400,243.2Z"
      />
      <path
        className="bf7d9a67-5be0-40c6-b4a2-f9dd350f7921"
        d="M403.3,259.3,166.6,496a256.6,256.6,0,0,1-86.7-54.2l-9.7-9.7a256.4,256.4,0,0,1-33.8-44.4l72.5-72.6,191.9-14.3Z"
      />
      <path
        className="a2c60830-93c4-4bd6-91bb-8cfbe2dfbc6b"
        d="M238.6,146a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,238.6,146Zm0-12.8a4.4,4.4,0,0,0,0,8.8,4.4,4.4,0,0,0,0-8.8Z"
      />
      <path
        className="b9ecba27-9161-4a28-90f0-145777f28c2c"
        d="M233.7,246.8a32.8,32.8,0,1,1,30-46.1h20.7l8.9,7.4,8.9-7.4,9,7.4,8.9-7.4L341,215.4l-14.9,12.1H263.7a33,33,0,0,1-30,19.3Zm-1.5-32.7a8.9,8.9,0,1,0-8.9,8.9A8.9,8.9,0,0,0,232.2,214.1Z"
      />
      <path
        className="a271e206-2031-4192-b0b0-fb5d36b60abb"
        d="M324.9,220.8H267.8a1.9,1.9,0,0,1-2-2,2,2,0,0,1,2-2h57.1a2,2,0,0,1,2,2A1.9,1.9,0,0,1,324.9,220.8Z"
      />
      <path
        className="a2c60830-93c4-4bd6-91bb-8cfbe2dfbc6b"
        d="M383.2,217.7a2,2,0,0,1-2-2v-16a1.9,1.9,0,0,1,2-2,1.9,1.9,0,0,1,2,2v16A1.9,1.9,0,0,1,383.2,217.7Z"
      />
      <path
        className="a2c60830-93c4-4bd6-91bb-8cfbe2dfbc6b"
        d="M391.2,209.7h-16a2,2,0,0,1-2-2,1.9,1.9,0,0,1,2-2h16a1.9,1.9,0,0,1,2,2A1.9,1.9,0,0,1,391.2,209.7Z"
      />
      <path
        className="a2c60830-93c4-4bd6-91bb-8cfbe2dfbc6b"
        d="M181.4,317.3a2.8,2.8,0,0,1-1.2-.4l-12.8-9.6a2,2,0,0,1-.3-2.8,1.8,1.8,0,0,1,2.7-.4l11.6,8.7,11.7-8.7a1.8,1.8,0,0,1,2.4,0l11.6,8.7,11.5-8.7a1.9,1.9,0,0,1,2.8.4,2,2,0,0,1-.3,2.8l-12.8,9.6a2.3,2.3,0,0,1-2.5,0l-11.5-8.7-11.7,8.7A2.1,2.1,0,0,1,181.4,317.3Z"
      />
    </g>
  </svg>
)

export default ImgHandWithKeyGlitter
