import { SVGProps } from 'react'

const ImgCreditCardGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.be7fdd65-ee02-4df8-bd30-9ae54f983382{fill:none;}.bc15211f-7267-4669-9ca7-5a49ff6d4772{opacity:0.15;fill:url(#e0a4bd60-3490-4381-9b36-026075eca20d);}.bbd82953-cfbe-4e54-98e2-791af20ccd34{fill:#37c49f;}.ab3e0a18-b617-4fbc-ba7f-86da47df2f80{fill:url(#f04a9456-08e4-4407-9b0b-757de38bd938);}.a984e907-37cb-4a4b-b8be-18dd95a4ed60{fill:url(#b04e501f-0116-4e14-95a3-b63bbf245854);}.ac063940-e69d-4efd-bf80-78fec6ebf539{fill:url(#a268a233-8cde-4f10-92cd-f3fdca2c65c8);}.b0a4b450-6c4c-4887-b09b-bfcfe1efe4ac{fill:#fff;}.b4526482-fd43-48f7-ae9d-bb13e8b92553{fill:url(#be10b5a3-6474-443c-bedd-0f17f4072a6f);}'
        }
      </style>
      <linearGradient
        id="e0a4bd60-3490-4381-9b36-026075eca20d"
        x1={78.19}
        y1={440.57}
        x2={268.73}
        y2={250.03}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f04a9456-08e4-4407-9b0b-757de38bd938"
        x1={170.09}
        y1={9260.79}
        x2={336.25}
        y2={9426.96}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="b04e501f-0116-4e14-95a3-b63bbf245854"
        x1={155.85}
        y1={9137.75}
        x2={380.35}
        y2={9362.25}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a268a233-8cde-4f10-92cd-f3fdca2c65c8"
        x1={166.78}
        y1={9305.48}
        x2={312.37}
        y2={9451.08}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="be10b5a3-6474-443c-bedd-0f17f4072a6f"
        x1={173.2}
        y1={310.35}
        x2={210.8}
        y2={272.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="a8979361-79eb-4508-8ca3-1a1c9c20db6b" data-name="Layer 1">
      <g id="a68cc0b8-8abf-4abc-8cb7-ed542e94fd0f" data-name="backdrop">
        <path
          className="be7fdd65-ee02-4df8-bd30-9ae54f983382"
          d="M512,256.3c0,131.3-98.8,239.5-226,254.3a249.1,249.1,0,0,1-30,1.7A255.1,255.1,0,0,1,76.1,438.4l-2.2-2.1A255.3,255.3,0,0,1,0,256.3c0-5.3.2-10.6.5-15.9C8.7,106.5,120,.3,256,.3,397.4.3,512,115,512,256.3Z"
        />
      </g>
      <path
        className="bc15211f-7267-4669-9ca7-5a49ff6d4772"
        d="M407.6,388.9,286,510.6a249.1,249.1,0,0,1-30,1.7A255.1,255.1,0,0,1,76.1,438.4l-2.2-2.1A255.3,255.3,0,0,1,0,256.3c0-5.3.2-10.6.5-15.9L104.1,136.8Z"
      />
      <path
        className="bbd82953-cfbe-4e54-98e2-791af20ccd34"
        d="M407.6,161.8a7.9,7.9,0,1,1,7.9-7.8A7.8,7.8,0,0,1,407.6,161.8Zm0-12.7a4.9,4.9,0,1,0,4.9,4.9A4.9,4.9,0,0,0,407.6,149.1Z"
      />
      <path
        className="bbd82953-cfbe-4e54-98e2-791af20ccd34"
        d="M76.2,311.3H69.7v-6.5a1.5,1.5,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5v6.5H60.3a1.4,1.4,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5h6.4v6.4a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6.4h6.5a1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,76.2,311.3Z"
      />
      <rect
        className="ab3e0a18-b617-4fbc-ba7f-86da47df2f80"
        x={102.4}
        y={134.8}
        width={275.2}
        height={179.2}
        rx={6.4}
      />
      <path
        className="a984e907-37cb-4a4b-b8be-18dd95a4ed60"
        d="M403.2,211.6H140.8a6.4,6.4,0,0,0-6.4,6.4V384.4a6.4,6.4,0,0,0,6.4,6.4H403.2a6.4,6.4,0,0,0,6.4-6.4V218A6.4,6.4,0,0,0,403.2,211.6Z"
      />
      <circle className="bbd82953-cfbe-4e54-98e2-791af20ccd34" cx={172.8} cy={352.4} r={6.4} />
      <path
        className="bbd82953-cfbe-4e54-98e2-791af20ccd34"
        d="M198.4,358.8a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,198.4,358.8Z"
      />
      <circle className="bbd82953-cfbe-4e54-98e2-791af20ccd34" cx={236.8} cy={352.4} r={6.4} />
      <path
        className="bbd82953-cfbe-4e54-98e2-791af20ccd34"
        d="M262.4,358.8a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,262.4,358.8Z"
      />
      <circle className="bbd82953-cfbe-4e54-98e2-791af20ccd34" cx={288} cy={352.4} r={6.4} />
      <path
        className="bbd82953-cfbe-4e54-98e2-791af20ccd34"
        d="M326.4,358.8a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,326.4,358.8Z"
      />
      <circle className="bbd82953-cfbe-4e54-98e2-791af20ccd34" cx={352} cy={352.4} r={6.4} />
      <rect className="ac063940-e69d-4efd-bf80-78fec6ebf539" x={102.4} y={154} width={275.2} height={44.8} />
      <path
        className="b0a4b450-6c4c-4887-b09b-bfcfe1efe4ac"
        d="M198.4,312.8H167.6V288.4a18,18,0,0,1,18-18h30.8v24.4A18.1,18.1,0,0,1,198.4,312.8Z"
      />
      <path
        className="b4526482-fd43-48f7-ae9d-bb13e8b92553"
        d="M171.6,308.8h26.8a14,14,0,0,0,14-14V274.4H185.6a14,14,0,0,0-14,14Z"
      />
      <path
        className="bbd82953-cfbe-4e54-98e2-791af20ccd34"
        d="M428.5,320.3a1.4,1.4,0,0,1-.9-.3l-11.9-8.9L403.9,320a1.5,1.5,0,0,1-1.8,0l-12.8-9.5a1.6,1.6,0,0,1-.3-2.1,1.5,1.5,0,0,1,2.1-.3l11.9,8.8,11.8-8.8a1.5,1.5,0,0,1,1.8,0l11.9,8.8,11.8-8.8a1.5,1.5,0,1,1,1.8,2.4L429.4,320A1.6,1.6,0,0,1,428.5,320.3Z"
      />
    </g>
  </svg>
)

export default ImgCreditCardGlitter
