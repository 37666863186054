import { SVGProps } from 'react'

const ImgPenRulerMouseGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a1e76cf1-12bf-4057-84c0-bf3d5d754d80{fill:none;}.ebafff17-dda6-45da-b06f-3e6208ff3d3c{opacity:0.15;fill:url(#bcdb456e-f6cc-4918-a09e-af257c1af4c0);}.ffb40e24-bebf-4c73-838b-4028d86b7e39{fill:url(#b3b58924-0162-4fa1-978c-f7545e54a319);}.b115db9f-da4c-4c71-bb78-8245b7fc52ab{fill:url(#e79fee0c-6527-4988-8a62-b7b8afe73cb7);}.b24b9c78-3058-4110-958c-e554994537aa{fill:#fff;}.feea78e5-72a7-4151-aee7-ce3ddd40de76{fill:url(#a64376cd-b030-4333-80d5-c90f5ff25cd0);}.a6039eed-5ceb-44c7-a329-ee329bddc543{fill:url(#bddecb44-b958-4831-a75a-10481eecd1f3);}.b72f0972-6047-44b6-8daa-8b5c2cbe5865{fill:#37c49f;}.e3e7b37f-70eb-4e28-8127-ef1063bce898{fill:url(#fb809f25-158d-425c-a4e6-1fbb4038211d);}.ed4228d1-a3a0-4cf8-be29-2d384153f873{fill:url(#a9a1aa87-1f67-42c6-b99a-d33c5d780c75);}.f9ce2499-e17d-4ae4-8272-b332abebe3f1{fill:url(#e76bb87b-21d8-40df-8f51-b16f5a9c85c6);}.eeda4eeb-8699-4502-8f3a-fbc012a18530{fill:url(#bcbe75fa-9e35-4e8c-9633-b5bfe6aee39c);}.b7218265-0f6a-4ebd-8d8b-0bd347928330{fill:url(#b4fb2d77-bb88-4992-b472-d51ce2f7bc56);}.ad54a17e-f92f-4f5b-8ce4-8da9e0c85b70{fill:url(#b97dbf1b-66bf-426a-9c29-bda76b3d669d);}.a2901202-dace-459c-beac-e0a2395da2ab{fill:url(#a1b3c00a-b37c-4977-a120-4cabfba36e0d);}'
        }
      </style>
      <linearGradient
        id="bcdb456e-f6cc-4918-a09e-af257c1af4c0"
        x1={86.73}
        y1={448.67}
        x2={318.9}
        y2={216.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b3b58924-0162-4fa1-978c-f7545e54a319"
        x1={267.3}
        y1={9308.16}
        x2={412.18}
        y2={9422}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="e79fee0c-6527-4988-8a62-b7b8afe73cb7"
        x1={141.11}
        y1={335.54}
        x2={326.09}
        y2={176.46}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="a64376cd-b030-4333-80d5-c90f5ff25cd0"
        x1={246.45}
        y1={9166.86}
        x2={389.79}
        y2={9290.13}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="bddecb44-b958-4831-a75a-10481eecd1f3"
        x1={287.73}
        y1={9217.2}
        x2={404.2}
        y2={9253.36}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#f58ea9" />
      </linearGradient>
      <linearGradient
        id="fb809f25-158d-425c-a4e6-1fbb4038211d"
        x1={-2.01}
        y1={9171.7}
        x2={198.59}
        y2={9372.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="a9a1aa87-1f67-42c6-b99a-d33c5d780c75"
        x1={212.61}
        y1={9224.04}
        x2={57.08}
        y2={9379.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="e76bb87b-21d8-40df-8f51-b16f5a9c85c6"
        x1={157.02}
        y1={9313.46}
        x2={187.77}
        y2={9313.46}
        xlinkHref="#a64376cd-b030-4333-80d5-c90f5ff25cd0"
      />
      <linearGradient
        id="bcbe75fa-9e35-4e8c-9633-b5bfe6aee39c"
        x1={190.29}
        y1={9313.46}
        x2={171.4}
        y2={9313.46}
        xlinkHref="#a64376cd-b030-4333-80d5-c90f5ff25cd0"
      />
      <linearGradient
        id="b4fb2d77-bb88-4992-b472-d51ce2f7bc56"
        x1={162.62}
        y1={9429.53}
        x2={181}
        y2={9445.08}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="b97dbf1b-66bf-426a-9c29-bda76b3d669d"
        x1={164.74}
        y1={9161.4}
        x2={204.05}
        y2={9200.71}
        xlinkHref="#a64376cd-b030-4333-80d5-c90f5ff25cd0"
      />
      <linearGradient
        id="a1b3c00a-b37c-4977-a120-4cabfba36e0d"
        x1={187.75}
        y1={9163.31}
        x2={171.73}
        y2={9162.84}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
    </defs>
    <g id="ac34dc83-debe-4b22-becc-c7e759e97737" data-name="Layer 1">
      <g id="bccc266b-8a75-4054-9c07-38a7355badee" data-name="backdrop">
        <path
          className="a1e76cf1-12bf-4057-84c0-bf3d5d754d80"
          d="M512,256c0,141.4-114.6,256-256,256h-4.4A255.2,255.2,0,0,1,79.4,441.3q-4.5-4.2-8.7-8.7A254.7,254.7,0,0,1,4.3,302.9,258.9,258.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="ebafff17-dda6-45da-b06f-3e6208ff3d3c"
        d="M369.4,394.2,251.6,512A255.2,255.2,0,0,1,79.4,441.3l-8.7-8.7A254.7,254.7,0,0,1,4.3,302.9L204.8,102.4l77,153.6Z"
      />
      <circle className="ffb40e24-bebf-4c73-838b-4028d86b7e39" cx={316.8} cy={208} r={105.6} />
      <path
        className="b115db9f-da4c-4c71-bb78-8245b7fc52ab"
        d="M204.8,102.4V409.6h57.6V102.4Zm28.8,28.8a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,233.6,131.2Z"
      />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,382.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,350.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,318.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,286.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,254.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,222.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,190.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="b24b9c78-3058-4110-958c-e554994537aa" d="M240,158.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path
        className="feea78e5-72a7-4151-aee7-ce3ddd40de76"
        d="M310.4,230.4h44.8A28.8,28.8,0,0,1,384,259.2v99.2a51.2,51.2,0,0,1-51.2,51.2h0a51.2,51.2,0,0,1-51.2-51.2V259.2A28.8,28.8,0,0,1,310.4,230.4Z"
      />
      <path
        className="a6039eed-5ceb-44c7-a329-ee329bddc543"
        d="M332.8,409.6h0c-28.3,0-51.2-87.7-25.6-144,13.2-29,38.4-35.2,48-35.2h0A28.8,28.8,0,0,1,384,259.2v99.2A51.2,51.2,0,0,1,332.8,409.6Z"
      />
      <path
        className="b24b9c78-3058-4110-958c-e554994537aa"
        d="M332.8,280.4a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,332.8,280.4Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,332.8,267.6Z"
      />
      <path
        className="b72f0972-6047-44b6-8daa-8b5c2cbe5865"
        d="M87.7,270.7a1.9,1.9,0,0,1-2-2V252.8a1.9,1.9,0,0,1,2-2,2,2,0,0,1,2,2v15.9A2,2,0,0,1,87.7,270.7Z"
      />
      <path className="b72f0972-6047-44b6-8daa-8b5c2cbe5865" d="M95.6,262.8H79.7a2,2,0,1,1,0-4H95.6a2,2,0,0,1,0,4Z" />
      <path
        className="b72f0972-6047-44b6-8daa-8b5c2cbe5865"
        d="M405.5,334.8a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,405.5,334.8Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,405.5,322Z"
      />
      <path
        className="b72f0972-6047-44b6-8daa-8b5c2cbe5865"
        d="M319.8,168.3a2.8,2.8,0,0,1-1.2-.4l-11.5-8.7-11.5,8.7a2.3,2.3,0,0,1-2.5,0l-12.7-9.6a2,2,0,1,1,2.4-3.2l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2,2,0,0,1,2.4,3.2l-12.7,9.6A2.8,2.8,0,0,1,319.8,168.3Z"
      />
      <path
        className="e3e7b37f-70eb-4e28-8127-ef1063bce898"
        d="M140.8,384,128,409.6,115.2,384V115.2A12.8,12.8,0,0,1,128,102.4h0a12.8,12.8,0,0,1,12.8,12.8Z"
      />
      <rect className="ed4228d1-a3a0-4cf8-be29-2d384153f873" x={121.6} y={134.4} width={19.2} height={230.4} />
      <path
        className="f9ce2499-e17d-4ae4-8272-b332abebe3f1"
        d="M160,108.8V374.4l12.8,6.4v-272a6.4,6.4,0,0,0-6.4-6.4h0A6.4,6.4,0,0,0,160,108.8Z"
      />
      <path
        className="eeda4eeb-8699-4502-8f3a-fbc012a18530"
        d="M179.2,102.4h0a6.4,6.4,0,0,0-6.4,6.4v272l12.8-6.4V108.8A6.4,6.4,0,0,0,179.2,102.4Z"
      />
      <path
        className="b7218265-0f6a-4ebd-8d8b-0bd347928330"
        d="M166.4,102.4h12.8a6.4,6.4,0,0,1,6.4,6.4V128H160V108.8A6.4,6.4,0,0,1,166.4,102.4Z"
      />
      <polygon
        className="ad54a17e-f92f-4f5b-8ce4-8da9e0c85b70"
        points="160 374.4 160 380.8 172.8 409.6 172.8 380.8 160 374.4"
      />
      <polygon
        className="a2901202-dace-459c-beac-e0a2395da2ab"
        points="185.6 374.4 172.8 380.8 172.8 409.6 185.6 380.8 185.6 374.4"
      />
    </g>
  </svg>
)

export default ImgPenRulerMouseGlitter
