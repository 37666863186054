import { SVGProps } from 'react'

const ImgKiwiGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a0a46000-caed-4d18-b313-688efbd7a7e0{opacity:0.2;fill:url(#a9b62fcc-c5df-4d90-bf4b-9c84e4e404eb);}.ffa3a985-822d-4a48-ae54-c83db6c955a3{fill:none;}.b2910ba8-054c-4d87-b324-1056b62500da{fill:url(#bc0f5180-b6fb-4b4c-b8cd-700297ce3cbc);}.a22f8d9e-0e2b-414d-82c5-7ac906e6eddf{fill:url(#b290c95e-090a-47dc-b811-e7fed9a4f1a6);}.fb48084d-2cbd-471d-9d51-714fbb52690f{fill:url(#afba9992-08b0-4c7d-a983-4f7f6139d1a5);}.f0297e96-f897-4a68-8eb7-c22cbcce66e9{fill:#fff;}.ec0af305-568f-4adc-9d92-ccd670910d44{fill:url(#b8d309bc-18ca-4155-b653-9eaa3507497b);}.e096cc0e-2609-4196-b6fc-b830cfc14b4b{fill:url(#abba31b2-2069-487b-8272-8d7a25fb0f1c);}.aaa7d75f-a1bf-4366-8e7c-6df86813ce3d{fill:url(#e1af3342-0c7a-44c1-8366-657151b49060);}.ac49960b-dde3-4c88-90e2-9dec3e35b4e3{fill:url(#f008af96-274e-4d2d-b70b-6e860faa86bf);}.a0befef7-b662-4fe4-ab77-499163a4c4b4{fill:url(#ae1125b7-1837-422b-864b-028fbfe32c27);}.b558a514-c58e-40b8-b7a1-6295951087ca{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a9b62fcc-c5df-4d90-bf4b-9c84e4e404eb"
        x1={109.37}
        y1={470.89}
        x2={305.82}
        y2={274.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bc0f5180-b6fb-4b4c-b8cd-700297ce3cbc"
        x1={291.13}
        y1={13076.65}
        x2={330.01}
        y2={13115.52}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="b290c95e-090a-47dc-b811-e7fed9a4f1a6"
        x1={301.02}
        y1={360.88}
        x2={331.34}
        y2={360.88}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="afba9992-08b0-4c7d-a983-4f7f6139d1a5"
        x1={259.3}
        y1={360.88}
        x2={289.62}
        y2={360.88}
        xlinkHref="#b290c95e-090a-47dc-b811-e7fed9a4f1a6"
      />
      <linearGradient
        id="b8d309bc-18ca-4155-b653-9eaa3507497b"
        x1={277.94}
        y1={322.61}
        x2={299.44}
        y2={344.12}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="abba31b2-2069-487b-8272-8d7a25fb0f1c"
        x1={319.03}
        y1={307.71}
        x2={348.35}
        y2={337.03}
        xlinkHref="#b8d309bc-18ca-4155-b653-9eaa3507497b"
      />
      <linearGradient
        id="e1af3342-0c7a-44c1-8366-657151b49060"
        x1={292.18}
        y1={223.83}
        x2={373.87}
        y2={305.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f008af96-274e-4d2d-b70b-6e860faa86bf"
        x1={66.41}
        y1={334.29}
        x2={151.83}
        y2={248.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="ae1125b7-1837-422b-864b-028fbfe32c27"
        x1={184.92}
        y1={230.23}
        x2={226.32}
        y2={271.63}
        xlinkHref="#e1af3342-0c7a-44c1-8366-657151b49060"
      />
    </defs>
    <g id="b082db1e-78a6-4e1a-a76a-85c2e6d67254" data-name="Layer 1">
      <path
        className="a0a46000-caed-4d18-b313-688efbd7a7e0"
        d="M415.8,384.4,290.5,509.7A261.9,261.9,0,0,1,256,512,254.8,254.8,0,0,1,84.8,446.3L65.6,427.1a256,256,0,0,1-49.9-82.5L156.5,203.8,257.2,319.3,317,341.4l7.6,38.9Z"
      />
      <g id="b6ee387b-f71f-43e1-b45b-c20d479a3569" data-name="backdrop">
        <path
          className="ffa3a985-822d-4a48-ae54-c83db6c955a3"
          d="M512,256c0,129.7-96.4,236.8-221.5,253.7A261.9,261.9,0,0,1,256,512,254.8,254.8,0,0,1,84.8,446.3a248.4,248.4,0,0,1-19.2-19.2A254.3,254.3,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b2910ba8-054c-4d87-b324-1056b62500da"
        d="M310.6,165a27.5,27.5,0,1,1,27.5-27.5A27.5,27.5,0,0,1,310.6,165Z"
      />
      <path
        className="a22f8d9e-0e2b-414d-82c5-7ac906e6eddf"
        d="M317,341.4V366h-8.8a7.2,7.2,0,1,0,0,14.3h16a7.1,7.1,0,0,0,7.1-7.1V341.4Z"
      />
      <path
        className="fb48084d-2cbd-471d-9d51-714fbb52690f"
        d="M275.3,341.4V366h-8.8a7.2,7.2,0,1,0,0,14.3h16a7.1,7.1,0,0,0,7.1-7.1V341.4Z"
      />
      <path className="f0297e96-f897-4a68-8eb7-c22cbcce66e9" d="M414.4,384.4h-304a2,2,0,1,1,0-4h304a2,2,0,0,1,0,4Z" />
      <circle className="ec0af305-568f-4adc-9d92-ccd670910d44" cx={281.8} cy={326.5} r={25.6} />
      <rect
        className="e096cc0e-2609-4196-b6fc-b830cfc14b4b"
        x={298.7}
        y={274.2}
        width={51.2}
        height={77.69}
        rx={25.6}
      />
      <ellipse className="aaa7d75f-a1bf-4366-8e7c-6df86813ce3d" cx={306.9} cy={238.6} rx={101.9} ry={92.5} />
      <path
        className="ac49960b-dde3-4c88-90e2-9dec3e35b4e3"
        d="M144,241.1c-34.2,39-74.3,97-70.5,100.3s33.8-50.1,77.9-79.3Z"
      />
      <circle className="a0befef7-b662-4fe4-ab77-499163a4c4b4" cx={192.4} cy={237.7} r={49.3} />
      <circle className="f0297e96-f897-4a68-8eb7-c22cbcce66e9" cx={171.8} cy={228.9} r={6.2} />
      <path
        className="b558a514-c58e-40b8-b7a1-6295951087ca"
        d="M257.7,124.6h-6.4v-6.4a1.6,1.6,0,0,0-1.6-1.6,1.6,1.6,0,0,0-1.6,1.6v6.4h-6.3a1.6,1.6,0,0,0-1.6,1.6,1.6,1.6,0,0,0,1.6,1.6h6.3v6.3a1.6,1.6,0,0,0,1.6,1.6,1.6,1.6,0,0,0,1.6-1.6v-6.3h6.4a1.6,1.6,0,0,0,1.6-1.6A1.6,1.6,0,0,0,257.7,124.6Z"
      />
      <path
        className="b558a514-c58e-40b8-b7a1-6295951087ca"
        d="M208,328.9a7.9,7.9,0,0,1-7.9-7.9,7.9,7.9,0,0,1,7.9-8,8,8,0,0,1,8,8A7.9,7.9,0,0,1,208,328.9Zm0-12.7a4.8,4.8,0,1,0,0,9.5,4.8,4.8,0,1,0,0-9.5Z"
      />
      <path
        className="b558a514-c58e-40b8-b7a1-6295951087ca"
        d="M409.9,204.1l-.9-.3L397.2,195l-11.8,8.8a1.7,1.7,0,0,1-1.9,0l-12.8-9.5a1.7,1.7,0,0,1-.3-2.3,1.6,1.6,0,0,1,2.2-.3l11.8,8.8,11.8-8.8a1.7,1.7,0,0,1,1.9,0l11.8,8.8,11.8-8.8a1.7,1.7,0,0,1,2.3.3,1.7,1.7,0,0,1-.4,2.3l-12.7,9.5A1.7,1.7,0,0,1,409.9,204.1Z"
      />
    </g>
  </svg>
)

export default ImgKiwiGlitter
