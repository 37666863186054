const ImgPiggybankGlitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="a6988c69-d582-4955-9116-84f74d8afee3"
          x1={264.89}
          y1={-6561.74}
          x2={299.77}
          y2={-6526.85}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f9d8df" />
          <stop offset={1} stopColor="#f7ced7" />
        </linearGradient>
        <linearGradient
          id="b180ee39-0856-4914-a3c0-5bcd0be0d8a7"
          x1={256}
          y1={-6545.02}
          x2={307.2}
          y2={-6545.02}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fefafb" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="bbb07589-6fd8-4630-bccc-b300a9185f1d"
          x1={-196.34}
          y1={392.31}
          x2={-89.87}
          y2={392.31}
          gradientTransform="matrix(1.56 -1.56 2.2 2.2 -432.07 -745.37)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.48} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="b7f5a031-6a9d-4ddc-94ec-ebaf86abb3e7"
          x1={129.61}
          y1={-6663.36}
          x2={151.47}
          y2={-6641.5}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#281fa9" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="e37f60b6-1653-4521-8ec2-91d78d612c4a"
          x1={164.87}
          y1={-6585.94}
          x2={192.35}
          y2={-6558.46}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="f2912e95-4908-4ffd-b4bc-7baeeae0cf5c"
          x1={176.63}
          y1={-6719.01}
          x2={339.54}
          y2={-6556.09}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="a78c3f99-8377-405c-a376-ee3aba53af7a"
          x1={187.41}
          y1={-6765.35}
          x2={236.44}
          y2={-6716.32}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5851bc" />
          <stop offset={1} stopColor="#281fa9" />
        </linearGradient>
        <linearGradient
          id="e8c82ba2-6cdb-42b9-9d2b-c7012c6fc4b4"
          x1={309.73}
          y1={-6761.4}
          x2={351.44}
          y2={-6719.69}
          xlinkHref="#a78c3f99-8377-405c-a376-ee3aba53af7a"
        />
        <style>
          {'.f978cd2a-19e3-47b0-a39f-7eabffbaed9d{fill:#fff}.e0bfd16a-92dc-4781-af7f-8137b4798179{fill:#37c49f}'}
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="b3c4429e-aec1-4c0d-9a00-a42873c9651f" data-name="Layer 1">
          <circle
            cx={256}
            cy={256}
            r={256}
            style={{
              fill: 'none',
            }}
            id="ee18a979-69bc-4835-aa9e-b0dadcb4c705"
            data-name="backdrop"
          />
          <path
            className="f978cd2a-19e3-47b0-a39f-7eabffbaed9d"
            d="M396.8 257.6h-9.6a1.6 1.6 0 1 1 0-3.2h9.6c7.9 0 14.4-5 14.4-11.2a1.6 1.6 0 1 1 3.2 0c0 7.9-7.9 14.4-17.6 14.4Z"
          />
          <circle
            cx={281.6}
            cy={152.5}
            r={22.4}
            style={{
              fill: 'url(#a6988c69-d582-4955-9116-84f74d8afee3)',
            }}
          />
          <path
            d="M281.6 178.1a25.6 25.6 0 1 1 25.6-25.6 25.6 25.6 0 0 1-25.6 25.6Zm0-48a22.4 22.4 0 1 0 22.4 22.4 22.4 22.4 0 0 0-22.4-22.4Z"
            style={{
              fill: 'url(#b180ee39-0856-4914-a3c0-5bcd0be0d8a7)',
            }}
          />
          <path
            style={{
              opacity: 0.15,
              mixBlendMode: 'multiply',
              fill: 'url(#bbb07589-6fd8-4630-bccc-b300a9185f1d)',
            }}
            d="m343.6 380.8 72.5 2.7-166.3 166.3L.5 300.5l157.9-157.9 185.2 238.2z"
          />
          <path
            d="M147.2 291.2h-20.9a12.8 12.8 0 0 1-12.7-11l-3.8-26.5a6.4 6.4 0 0 1 5.5-7.2h31.9a6.4 6.4 0 0 1 6.4 6.4v32a6.4 6.4 0 0 1-6.4 6.3Z"
            style={{
              fill: 'url(#b7f5a031-6a9d-4ddc-94ec-ebaf86abb3e7)',
            }}
          />
          <path
            className="f978cd2a-19e3-47b0-a39f-7eabffbaed9d"
            d="M414.4 384.4h-304a2 2 0 1 1 0-4h304a2 2 0 0 1 0 4Z"
          />
          <path
            d="m217.6 198.1-54 12.8a6.7 6.7 0 0 1-6.8-6.7v-58.7a3.4 3.4 0 0 1 3.4-3.4 3.7 3.7 0 0 1 2.4 1Z"
            style={{
              fill: 'url(#e37f60b6-1653-4521-8ec2-91d78d612c4a)',
            }}
          />
          <path
            d="M262.4 163.2a173.8 173.8 0 0 0-44.8 5.9l-32.3-32.3a3.6 3.6 0 0 0-5.1 0 3.6 3.6 0 0 0-1 2.5v46.4c-29.3 18.2-48 45.8-48 76.7 0 54.8 58.7 99.2 131.2 99.2s131.2-44.4 131.2-99.2-58.7-99.2-131.2-99.2Z"
            style={{
              fill: 'url(#f2912e95-4908-4ffd-b4bc-7baeeae0cf5c)',
            }}
          />
          <path
            d="M221.1 377.9a3.3 3.3 0 0 1-3.2 2.9h-33a6.4 6.4 0 0 1-6.4-6.4 6.4 6.4 0 0 1 .3-2l12.4-40.5a17.5 17.5 0 0 1 16.5-11.9 17.4 17.4 0 0 1 17.5 17.4c0 .7-.1 1.3-.1 1.9Z"
            style={{
              fill: 'url(#a78c3f99-8377-405c-a376-ee3aba53af7a)',
            }}
          />
          <path
            d="M307.4 377.9a3.2 3.2 0 0 0 3.1 2.9h33.1a6.2 6.2 0 0 0 6-8.4L334 331.9a17.4 17.4 0 0 0-16.5-11.9 17.4 17.4 0 0 0-17.4 17.4 11.5 11.5 0 0 0 .1 1.9Z"
            style={{
              fill: 'url(#e8c82ba2-6cdb-42b9-9d2b-c7012c6fc4b4)',
            }}
          />
          <circle
            cx={172.8}
            cy={227.2}
            r={3.2}
            style={{
              stroke: '#fff',
              strokeLinecap: 'round',
              strokeMiterlimit: 10,
              strokeWidth: '6.4px',
              fill: 'none',
            }}
          />
          <path
            className="e0bfd16a-92dc-4781-af7f-8137b4798179"
            d="M291.1 211.2h-6.4v-6.3a1.6 1.6 0 1 0-3.2 0v6.3h-6.4a1.7 1.7 0 0 0-1.6 1.6 1.6 1.6 0 0 0 1.6 1.6h6.4v6.4a1.6 1.6 0 1 0 3.2 0v-6.4h6.4a1.6 1.6 0 0 0 1.6-1.6 1.7 1.7 0 0 0-1.6-1.6ZM328.1 134.3a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm0-12.8a4.8 4.8 0 1 0 4.8 4.8 4.9 4.9 0 0 0-4.8-4.8ZM158.5 179a1.7 1.7 0 0 1-1-.3l-11.8-8.8-11.8 8.8a1.4 1.4 0 0 1-1.9 0l-12.8-9.5a1.6 1.6 0 0 1 2-2.6l11.8 8.9 11.7-8.9a1.8 1.8 0 0 1 2 0l11.8 8.9 11.7-8.9a1.7 1.7 0 0 1 2.3.3 1.7 1.7 0 0 1-.3 2.3l-12.8 9.5a1.6 1.6 0 0 1-.9.3ZM372.7 364.7a1.6 1.6 0 0 1-.9-.3l-11.8-8.9-11.8 8.9a1.7 1.7 0 0 1-1.9 0l-12.8-9.6a1.6 1.6 0 0 1-.3-2.2 1.5 1.5 0 0 1 2.2-.3l11.8 8.8 11.8-8.8a1.4 1.4 0 0 1 1.9 0l11.8 8.8 11.8-8.8a1.6 1.6 0 0 1 2.3.3 1.6 1.6 0 0 1-.4 2.2l-12.7 9.6a1.7 1.7 0 0 1-1 .3Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default ImgPiggybankGlitter
