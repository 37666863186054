import { SVGProps } from 'react'

const ImgChessPieces = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a5f65207-e155-4712-9c61-fadd4e0d2625{fill:none;}.aa998738-bf28-4063-af05-e628beb767b2{opacity:0.15;fill:url(#aa8dd06f-b2ff-45f6-816a-36224e725992);}.aadecd29-face-444a-bc1d-b35cf339e84b{fill:#fff;}.edaffaa8-d69b-4dc6-a10d-36b64a4bc309{fill:url(#bb36e25c-1a67-45da-95e5-04ace33286b0);}.b36bb0c5-653d-4c83-95d2-13d93157b4e7{fill:url(#abdc9f8a-a7a0-42a4-958b-cb8880563988);}.bed1640b-d516-4757-bc51-327bb16d432b{fill:url(#ff071e28-655a-448c-a494-d3d9e96845f6);}.b8b4d156-3530-492f-bba3-0bcadc599336{fill:#5fd0b2;}.b0186706-119e-492a-97d2-6e3568fa1ef9{fill:url(#aa39f0be-2469-4202-b9f2-94b90b21cc09);}.f331d8f5-5a06-4bda-a3c7-303b92b3bc48{fill:url(#e8ddfc7c-e1b9-4265-8745-121f8eb2b4e9);}.a7be1c9b-6c3c-4770-b0b6-73a09c75f660{fill:url(#b3661336-e9c4-4f5d-be2d-eacc7fcef84f);}.a727e206-70e7-46de-ba74-536ac829ef78{fill:url(#ab3f3906-6cda-45fb-b915-d9c9f3a70dd0);}.f409739c-b0a9-428f-9efa-d2648c99bbcb{fill:#fefafb;}'
        }
      </style>
      <linearGradient
        id="aa8dd06f-b2ff-45f6-816a-36224e725992"
        x1={110.94}
        y1={472.48}
        x2={301.1}
        y2={282.32}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bb36e25c-1a67-45da-95e5-04ace33286b0"
        x1={296.14}
        y1={10418.79}
        x2={240.75}
        y2={10363.4}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="abdc9f8a-a7a0-42a4-958b-cb8880563988"
        x1={312.15}
        y1={10673.47}
        x2={265.6}
        y2={10626.92}
        xlinkHref="#bb36e25c-1a67-45da-95e5-04ace33286b0"
      />
      <linearGradient
        id="ff071e28-655a-448c-a494-d3d9e96845f6"
        x1={349.65}
        y1={10570.45}
        x2={211.45}
        y2={10432.24}
        xlinkHref="#bb36e25c-1a67-45da-95e5-04ace33286b0"
      />
      <linearGradient
        id="aa39f0be-2469-4202-b9f2-94b90b21cc09"
        x1={297.8}
        y1={10680.2}
        x2={271.93}
        y2={10654.33}
        xlinkHref="#bb36e25c-1a67-45da-95e5-04ace33286b0"
      />
      <linearGradient
        id="e8ddfc7c-e1b9-4265-8745-121f8eb2b4e9"
        x1={251.19}
        y1={10450.64}
        x2={174.32}
        y2={10373.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b3661336-e9c4-4f5d-be2d-eacc7fcef84f"
        x1={263.06}
        y1={10542.5}
        x2={155.57}
        y2={10435.02}
        xlinkHref="#e8ddfc7c-e1b9-4265-8745-121f8eb2b4e9"
      />
      <linearGradient
        id="ab3f3906-6cda-45fb-b915-d9c9f3a70dd0"
        x1={229.3}
        y1={10611.7}
        x2={193.1}
        y2={10575.49}
        xlinkHref="#e8ddfc7c-e1b9-4265-8745-121f8eb2b4e9"
      />
    </defs>
    <g id="a8db1e85-12bd-4e17-9700-809ba7307386" data-name="Layer 1">
      <g id="b3a2a07b-da4c-466a-8bce-4d3b3e84ec78" data-name="backdrop">
        <path
          className="a5f65207-e155-4712-9c61-fadd4e0d2625"
          d="M512,256c0,129.9-96.7,237.2-222.1,253.8A270.6,270.6,0,0,1,256,512,255.4,255.4,0,0,1,84.7,446.3a263.4,263.4,0,0,1-19-19.1,253.9,253.9,0,0,1-48.1-77.7A256.5,256.5,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="aa998738-bf28-4063-af05-e628beb767b2"
        d="M408.9,390.8l-119,119A270.6,270.6,0,0,1,256,512,255.4,255.4,0,0,1,84.7,446.3l-19-19.1a253.9,253.9,0,0,1-48.1-77.7L193,174.2l44.7,115,136.7,98Z"
      />
      <path className="aadecd29-face-444a-bc1d-b35cf339e84b" d="M408,390.8H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="edaffaa8-d69b-4dc6-a10d-36b64a4bc309"
        d="M214.4,355.2H361.6A12.8,12.8,0,0,1,374.4,368v19.2H201.6V368A12.8,12.8,0,0,1,214.4,355.2Z"
      />
      <path
        className="b36bb0c5-653d-4c83-95d2-13d93157b4e7"
        d="M252.8,121.6h70.4a6.4,6.4,0,0,1,6.4,6.4v19.2H246.4V128A6.4,6.4,0,0,1,252.8,121.6Z"
      />
      <path
        className="bed1640b-d516-4757-bc51-327bb16d432b"
        d="M361.6,355.2H214.4V343a26,26,0,0,1,3.4-12.7c11.4-19.9,47.8-89.9,47.8-173.5v-9.6h44.8v9.6c0,83.6,36.4,153.6,47.8,173.5a26,26,0,0,1,3.4,12.7Z"
      />
      <path
        className="b8b4d156-3530-492f-bba3-0bcadc599336"
        d="M318,166.2H258a1.4,1.4,0,1,1,0-2.8h60a1.4,1.4,0,0,1,0,2.8Z"
      />
      <rect className="b0186706-119e-492a-97d2-6e3568fa1ef9" x={265.6} y={108.8} width={44.8} height={12.8} />
      <path
        className="f331d8f5-5a06-4bda-a3c7-303b92b3bc48"
        d="M150.4,355.2H272A12.8,12.8,0,0,1,284.8,368v19.2H137.6V368A12.8,12.8,0,0,1,150.4,355.2Z"
      />
      <path
        className="a7be1c9b-6c3c-4770-b0b6-73a09c75f660"
        d="M154,336.7c11.7-19.5,44.4-58,44.4-135.1V192a12.8,12.8,0,0,1,12.8-12.8h0A12.8,12.8,0,0,1,224,192v9.6c0,77.1,32.7,115.6,44.4,135.1a24.8,24.8,0,0,1,3.6,13.1v5.4H150.4v-5.4A24.8,24.8,0,0,1,154,336.7Z"
      />
      <circle className="a727e206-70e7-46de-ba74-536ac829ef78" cx={211.2} cy={192} r={25.6} />
      <path
        className="f409739c-b0a9-428f-9efa-d2648c99bbcb"
        d="M236.2,249.5h-50a1.5,1.5,0,0,1,0-3h50a1.5,1.5,0,0,1,0,3Z"
      />
    </g>
  </svg>
)

export default ImgChessPieces
