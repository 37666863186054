import { SVGProps } from 'react'

const ImgHouseSmallGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.aca48737-9bbe-4f02-aab8-2e1c1b83c737{opacity:0.15;fill:url(#bc705a66-4fd8-458a-9023-cc3499b6da08);}.a8e5ba04-b60d-4397-8cb7-6972c43e7340{fill:none;}.b83f4c2a-d4bb-4b0a-8940-33bb5bc24cb4{fill:url(#a3f9db69-473c-45e6-bb26-c60069ff2b81);}.fca5a9d9-65db-4b87-9bbf-4ef31d3aa40a{fill:url(#f2a816e0-c6cc-4f21-8fd0-fa5d7050f428);}.e566d02e-e28e-4868-8844-c1855605578e{fill:url(#a0ec1a49-6c29-4e18-bbcf-b053ef50cd91);}.a5f607b5-a30b-4fab-9814-b4a13f33567c{fill:#fff;}.b949a9dd-9db3-406d-9724-ca1c375b4b24{fill:url(#ada02d73-ff48-4d2d-aef9-129812431298);}.aef1616b-777b-460d-8cb8-a15bddcee686{fill:url(#a163e15c-f66f-4122-94ef-a8b40d00bf85);}.fabee178-6712-4b47-bac7-27f54db528dc{fill:url(#f71929ea-ae89-45b8-8813-a8ef58cdcc2d);}.af9fd3a1-e1b3-46ca-a2a5-bf0bb4fcb656{fill:url(#ef4cd7a5-e45f-4634-86c1-d50fa1f47344);}.a0146e5f-fa71-45a5-8966-1f407789eec6{fill:url(#eaf6a531-d317-41da-9447-d5a42cd473ee);}.b879c1ff-7daa-4795-87e7-2a3dab0bbfaf{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="bc705a66-4fd8-458a-9023-cc3499b6da08"
        x1={115.54}
        y1={476.97}
        x2={345.91}
        y2={246.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a3f9db69-473c-45e6-bb26-c60069ff2b81"
        x1={178.14}
        y1={345.75}
        x2={322.66}
        y2={201.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="f2a816e0-c6cc-4f21-8fd0-fa5d7050f428"
        x1={160.97}
        y1={417.79}
        x2={339.83}
        y2={238.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="a0ec1a49-6c29-4e18-bbcf-b053ef50cd91"
        x1={305.28}
        y1={160.11}
        x2={341.64}
        y2={196.47}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="ada02d73-ff48-4d2d-aef9-129812431298"
        x1={220.72}
        y1={6740.54}
        x2={288.77}
        y2={6808.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={0.99} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="a163e15c-f66f-4122-94ef-a8b40d00bf85"
        x1={157.25}
        y1={340.6}
        x2={204.16}
        y2={293.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="f71929ea-ae89-45b8-8813-a8ef58cdcc2d"
        x1={296.64}
        y1={340.6}
        x2={343.55}
        y2={293.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ef4cd7a5-e45f-4634-86c1-d50fa1f47344"
        x1={122.38}
        y1={6726.9}
        x2={162.26}
        y2={6766.78}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="eaf6a531-d317-41da-9447-d5a42cd473ee"
        x1={354.52}
        y1={6727.74}
        x2={388.49}
        y2={6761.71}
        xlinkHref="#ef4cd7a5-e45f-4634-86c1-d50fa1f47344"
      />
    </defs>
    <g id="b9333d4f-6627-4093-81f1-22c152976578" data-name="Layer 1">
      <path
        className="aca48737-9bbe-4f02-aab8-2e1c1b83c737"
        d="M409.1,396.5,296.9,508.7A250.8,250.8,0,0,1,256,512,255.2,255.2,0,0,1,87.8,449l-2-1.8L64.2,425.5l-1.3-1.4a255.4,255.4,0,0,1-41.7-65.9L109.4,270l27.2-4.2H365.1L366,395Z"
      />
      <g id="af87c873-3177-4427-9099-735a975922e5" data-name="backdrop">
        <path
          className="a8e5ba04-b60d-4397-8cb7-6972c43e7340"
          d="M512,256c0,127.4-93.1,233.1-214.9,252.7h-.2A250.8,250.8,0,0,1,256,512,255.2,255.2,0,0,1,87.8,449l-2-1.8a245.7,245.7,0,0,1-21.6-21.7l-1.3-1.4A254.5,254.5,0,0,1,.7,274.6C.2,268.4,0,262.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b83f4c2a-d4bb-4b0a-8940-33bb5bc24cb4"
        d="M253.6,144.8a4.9,4.9,0,0,0-6.4,0L109.4,270a4.7,4.7,0,0,0,6.4,7L250.4,154.8,385,277a4.9,4.9,0,0,0,3.2,1.2,4.7,4.7,0,0,0,3.2-8.2Z"
      />
      <path
        className="fca5a9d9-65db-4b87-9bbf-4ef31d3aa40a"
        d="M252.7,162.5a3.4,3.4,0,0,0-4.6,0L135.9,264a3.7,3.7,0,0,0-1.2,2.6V390.1a3.5,3.5,0,0,0,3.5,3.5H362.6a3.5,3.5,0,0,0,3.5-3.5V266.6a3.7,3.7,0,0,0-1.2-2.6Z"
      />
      <path
        className="e566d02e-e28e-4868-8844-c1855605578e"
        d="M307,159.7a3.1,3.1,0,0,0-3.1,3.1v20.6a3.1,3.1,0,0,0,1,2.4l22.6,20.6a3.2,3.2,0,0,0,2.1.8,3.1,3.1,0,0,0,1.3-.3,3.2,3.2,0,0,0,1.9-2.9V162.8a3.1,3.1,0,0,0-3.2-3.1Z"
      />
      <path className="a5f607b5-a30b-4fab-9814-b4a13f33567c" d="M408,397H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <rect className="b949a9dd-9db3-406d-9724-ca1c375b4b24" x={222} y={293.7} width={56.8} height={99.37} />
      <rect className="aef1616b-777b-460d-8cb8-a15bddcee686" x={157.3} y={293.7} width={46.9} height={46.91} />
      <polygon
        className="fabee178-6712-4b47-bac7-27f54db528dc"
        points="296.6 340.6 296.6 293.7 343.6 293.7 343.6 340.6 296.6 340.6"
      />
      <path
        className="a5f607b5-a30b-4fab-9814-b4a13f33567c"
        d="M155.3,291.7v50.9h50.9V291.7Zm46.9,23.4H182.7V295.7h19.5Zm-23.5-19.4v19.4H159.3V295.7Zm-19.4,23.4h19.4v19.5H159.3Zm23.4,19.5V319.1h19.5v19.5Z"
      />
      <path
        className="a5f607b5-a30b-4fab-9814-b4a13f33567c"
        d="M294.6,291.7v50.9h51V291.7Zm47,23.4H322.1V295.7h19.5Zm-23.5-19.4v19.4H298.6V295.7Zm-19.5,23.4h19.5v19.5H298.6Zm23.5,19.5V319.1h19.5v19.5Z"
      />
      <circle className="a5f607b5-a30b-4fab-9814-b4a13f33567c" cx={231.1} cy={340.6} r={3.6} />
      <path
        className="af9fd3a1-e1b3-46ca-a2a5-bf0bb4fcb656"
        d="M150.7,380.8c0,10.6-5.3,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S150.7,370.2,150.7,380.8Z"
      />
      <path
        className="a5f607b5-a30b-4fab-9814-b4a13f33567c"
        d="M134.4,396.8a1.5,1.5,0,0,1-1.3-1.6v-16a1.5,1.5,0,0,1,1.3-1.6c.7,0,1.2.7,1.2,1.6v16C135.6,396.1,135.1,396.8,134.4,396.8Z"
      />
      <path
        className="a0146e5f-fa71-45a5-8966-1f407789eec6"
        d="M382.1,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.3-32,16-32S382.1,370.2,382.1,380.8Z"
      />
      <path
        className="a5f607b5-a30b-4fab-9814-b4a13f33567c"
        d="M366.1,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,1.6-1.6,1.6,1.6,0,0,1,1.6,1.6v16A1.6,1.6,0,0,1,366.1,396.8Z"
      />
      <path
        className="b879c1ff-7daa-4795-87e7-2a3dab0bbfaf"
        d="M335.8,420.2h-6.3v-6.3a1.6,1.6,0,0,0-3.2,0v6.3h-6.4a1.6,1.6,0,0,0-1.6,1.6,1.6,1.6,0,0,0,1.6,1.6h6.4v6.4a1.6,1.6,0,0,0,3.2,0v-6.4h6.3a1.6,1.6,0,0,0,1.6-1.6A1.6,1.6,0,0,0,335.8,420.2Z"
      />
      <path
        className="b879c1ff-7daa-4795-87e7-2a3dab0bbfaf"
        d="M364.5,196.9a8,8,0,0,1-8-8,8,8,0,1,1,8,8Zm0-12.7a4.8,4.8,0,1,0,4.7,4.7A4.7,4.7,0,0,0,364.5,184.2Z"
      />
      <path
        className="b879c1ff-7daa-4795-87e7-2a3dab0bbfaf"
        d="M211.1,256a1.6,1.6,0,0,1-.9-.3l-11.8-8.9-11.8,8.9a1.7,1.7,0,0,1-1.9,0l-12.8-9.6a1.6,1.6,0,0,1-.3-2.2,1.7,1.7,0,0,1,2.3-.4l11.7,8.9,11.8-8.9a1.8,1.8,0,0,1,2,0l11.7,8.9,11.8-8.9a1.7,1.7,0,0,1,2.3.4,1.6,1.6,0,0,1-.3,2.2l-12.8,9.6A1.7,1.7,0,0,1,211.1,256Z"
      />
    </g>
  </svg>
)

export default ImgHouseSmallGlitter
