import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  id?: string
  bgColor?: string
  extraClasses?: string
  height?: string
  containerClass?: string
  contentContainerClass?: string
}

const Container = ({ children, id, bgColor, extraClasses, height, containerClass, contentContainerClass }: Props) => {
  return (
    <main className={`${bgColor ?? ''} ${height ?? ''} ${containerClass ?? ''}`} id={id}>
      <div
        className={`m-auto h-full max-w-screen-xl text-tella-blue-300 md:px-6 ${extraClasses ?? ''} ${
          contentContainerClass ? contentContainerClass : 'px-4 py-12'
        }`}
      >
        {children}
      </div>
    </main>
  )
}

export default Container
