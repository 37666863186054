import { SVGProps } from 'react'

const ImgSpeechBubblesGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b945d46d-cbe7-456c-a3cd-14b3e88c7d7c{fill:none;}.a09fe53d-97a7-42f6-8938-4546cf482133{opacity:0.15;fill:url(#f65f2edd-b7cd-4a05-9ea1-df52f1f5d023);}.a120ed83-f93e-42bc-b57a-a1d4de6a3ef9{fill:url(#a4822a25-f7c6-4bf4-b388-7d755551871e);}.ffd15c25-152b-4fb2-9f5d-4a841d8cc4e1{fill:url(#bb3b5220-2f62-4793-b2f9-1ad7d2e76631);}.a354d43f-c4c2-47f1-898e-409d45ab95cf{fill:#f7ced7;}.f0f28e15-9fa3-43ca-accf-2ddfa6065e09{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="f65f2edd-b7cd-4a05-9ea1-df52f1f5d023"
        x1={76.56}
        y1={438.58}
        x2={248.32}
        y2={266.82}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a4822a25-f7c6-4bf4-b388-7d755551871e"
        x1={392.68}
        y1={8144.67}
        x2={217.56}
        y2={7969.55}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="bb3b5220-2f62-4793-b2f9-1ad7d2e76631"
        x1={115}
        y1={8014.19}
        x2={314.41}
        y2={8213.61}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
    </defs>
    <g id="aa23add2-d0b5-4e4e-a0ae-60cdea4cd21e" data-name="Layer 1">
      <g id="ffd64c71-06ed-4802-be65-3c7a2ffe24a9" data-name="backdrop">
        <path
          className="b945d46d-cbe7-456c-a3cd-14b3e88c7d7c"
          d="M512,256c0,138.2-109.5,250.8-246.4,255.8l-9.6.2A255.5,255.5,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A254.9,254.9,0,0,1,0,256v-3.2C1.7,112.9,115.7,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a09fe53d-97a7-42f6-8938-4546cf482133"
        d="M379.5,398,265.6,511.8l-9.6.2A255.5,255.5,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A254.9,254.9,0,0,1,0,256v-3.2L102.4,150.4Z"
      />
      <polygon
        className="a120ed83-f93e-42bc-b57a-a1d4de6a3ef9"
        points="192 355.2 332.8 355.2 377.6 400 377.6 355.2 409.6 355.2 409.6 201.6 192 201.6 192 355.2"
      />
      <polygon
        className="ffd15c25-152b-4fb2-9f5d-4a841d8cc4e1"
        points="320 304 179.2 304 134.4 348.8 134.4 304 102.4 304 102.4 150.4 320 150.4 320 304"
      />
      <path
        className="a354d43f-c4c2-47f1-898e-409d45ab95cf"
        d="M211.2,233.6a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,211.2,233.6Z"
      />
      <path
        className="a354d43f-c4c2-47f1-898e-409d45ab95cf"
        d="M236.8,233.6a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,236.8,233.6Z"
      />
      <path
        className="a354d43f-c4c2-47f1-898e-409d45ab95cf"
        d="M185.6,233.6a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,185.6,233.6Z"
      />
      <path
        className="f0f28e15-9fa3-43ca-accf-2ddfa6065e09"
        d="M211.2,392.1a7.9,7.9,0,1,1,7.9-7.8A7.9,7.9,0,0,1,211.2,392.1Zm0-12.7a4.9,4.9,0,1,0,4.9,4.9A4.9,4.9,0,0,0,211.2,379.4Z"
      />
      <path
        className="f0f28e15-9fa3-43ca-accf-2ddfa6065e09"
        d="M128.6,187.7l-.9-.3-11.9-8.9L104,187.4a1.5,1.5,0,0,1-1.8,0l-12.8-9.6a1.5,1.5,0,0,1,1.8-2.4l11.9,8.9,11.8-8.9a1.5,1.5,0,0,1,1.8,0l11.9,8.9,11.8-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.7,9.6Z"
      />
      <path
        className="f0f28e15-9fa3-43ca-accf-2ddfa6065e09"
        d="M374.8,256a1.5,1.5,0,0,1-1.5-1.5V238.6a1.5,1.5,0,0,1,1.5-1.5,1.4,1.4,0,0,1,1.5,1.5v15.9A1.5,1.5,0,0,1,374.8,256Z"
      />
      <path
        className="f0f28e15-9fa3-43ca-accf-2ddfa6065e09"
        d="M382.8,248H366.9a1.4,1.4,0,0,1-1.5-1.5,1.5,1.5,0,0,1,1.5-1.5h15.9a1.5,1.5,0,0,1,1.5,1.5A1.5,1.5,0,0,1,382.8,248Z"
      />
    </g>
  </svg>
)

export default ImgSpeechBubblesGlitter
