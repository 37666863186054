import { SVGProps } from 'react'

const ImgPaperWithPercentSymbolGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a1327028-2c64-4e8f-882a-2f6b21f6b531,.b8f8fd13-3769-4329-96c7-a9ca5bd9194a{fill:none;}.ab72d52d-a62d-4fdb-8bc2-287891f27974{opacity:0.15;fill:url(#e7b0fa94-c169-4fea-b0e5-d8d8e2fb6f7d);}.f87586b1-4c31-4aec-836b-03bae5dfe812{fill:url(#e4513940-f21e-425a-a303-0772f150151a);}.a09bca8b-4612-486e-8acb-780331a7cc05{fill:url(#ac7076c5-399f-4c08-ab81-947f546497e9);}.bc5829ac-70a9-41f4-b539-6139fcf87586{fill:url(#acf5c3c8-46c6-4fdb-8745-95e7ef27a055);}.b17c7aa9-64fe-4159-b427-9fc2a0169b56{fill:#fff;}.b8f8fd13-3769-4329-96c7-a9ca5bd9194a{stroke:#37c49f;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.19px;}'
        }
      </style>
      <linearGradient
        id="e7b0fa94-c169-4fea-b0e5-d8d8e2fb6f7d"
        x1={3310.69}
        y1={7774.23}
        x2={3403.47}
        y2={7774.23}
        gradientTransform="matrix(1.63, -1.63, 3, 3, -28600.83, -17526.93)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e4513940-f21e-425a-a303-0772f150151a"
        x1={252.1}
        y1={11729.72}
        x2={415.09}
        y2={11892.71}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="ac7076c5-399f-4c08-ab81-947f546497e9"
        x1={252.35}
        y1={11746.75}
        x2={383.88}
        y2={11878.27}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="acf5c3c8-46c6-4fdb-8745-95e7ef27a055"
        x1={337.21}
        y1={11851.58}
        x2={113.21}
        y2={11627.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
    </defs>
    <g id="b39be5ca-b1a0-4675-8ee9-d296210e63c3" data-name="Layer 1">
      <g id="b5061942-ad64-47a3-9393-2bae3c5e3158" data-name="backdrop">
        <circle className="a1327028-2c64-4e8f-882a-2f6b21f6b531" cx={256} cy={256} r={256} />
      </g>
      <polygon
        className="ab72d52d-a62d-4fdb-8bc2-287891f27974"
        points="378.7 365.5 227.6 516.6 -1.2 287.8 132.7 153.8 378.7 365.5"
      />
      <path
        className="f87586b1-4c31-4aec-836b-03bae5dfe812"
        d="M204.8,115.2V352a19.2,19.2,0,0,1-19.2,19.2H364.8A19.2,19.2,0,0,0,384,352h0V115.2l-12.8-12.8-12.8,12.8-12.8-12.8-12.8,12.8L320,102.4l-12.8,12.8-12.8-12.8-12.8,12.8-12.8-12.8L256,115.2l-12.8-12.8-12.8,12.8-12.8-12.8Z"
      />
      <path
        className="a09bca8b-4612-486e-8acb-780331a7cc05"
        d="M345.6,352V166.4a19.2,19.2,0,0,0-19.2-19.2H147.2a19.2,19.2,0,0,1,19.2,19.2V352a19.2,19.2,0,0,0,19.2,19.2H364.8A19.2,19.2,0,0,1,345.6,352Z"
      />
      <path
        className="bc5829ac-70a9-41f4-b539-6139fcf87586"
        d="M307.2,166.4a19.2,19.2,0,0,1,19.2-19.2H147.2A19.2,19.2,0,0,0,128,166.4V396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8L192,409.6l12.8-12.8,12.8,12.8,12.8-12.8,12.8,12.8L256,396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8Z"
      />
      <path
        className="b17c7aa9-64fe-4159-b427-9fc2a0169b56"
        d="M192,299.6a2,2,0,0,1-1.4-.6,1.9,1.9,0,0,1,0-2.8L241.8,245a2,2,0,0,1,2.8,2.8L193.4,299A2,2,0,0,1,192,299.6Z"
      />
      <path
        className="b17c7aa9-64fe-4159-b427-9fc2a0169b56"
        d="M198.4,261.2a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,198.4,261.2Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,198.4,248.4Z"
      />
      <path
        className="b17c7aa9-64fe-4159-b427-9fc2a0169b56"
        d="M236.8,299.6a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,236.8,299.6Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,236.8,286.8Z"
      />
      <line className="b8f8fd13-3769-4329-96c7-a9ca5bd9194a" x1={251.2} y1={166} x2={251.2} y2={182} />
      <line className="b8f8fd13-3769-4329-96c7-a9ca5bd9194a" x1={259.1} y1={174} x2={243.2} y2={174} />
      <circle className="b8f8fd13-3769-4329-96c7-a9ca5bd9194a" cx={402.7} cy={297.6} r={6.4} />
      <polyline
        className="b8f8fd13-3769-4329-96c7-a9ca5bd9194a"
        points="96.6 345.3 109.3 354.9 122.1 345.3 134.8 354.9 147.6 345.3"
      />
    </g>
  </svg>
)

export default ImgPaperWithPercentSymbolGlitter
