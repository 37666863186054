import { SVGProps } from 'react'

const ImgPenRulerMouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.f56b385f-4db5-457f-8902-21a2eb6bc351{fill:none;}.b7987f28-f59b-4ea8-9273-237306e7e6d9{opacity:0.15;fill:url(#a7e77991-6589-4961-9d39-a3645f57d20c);}.b1deb860-91a1-471e-9542-ccd94a188c2e{fill:url(#ad58ac68-6a88-4e52-9f1c-1fe28f2a6303);}.bbc5f687-d2ed-48a1-bbab-a034b1efc7cc{fill:url(#af57bd14-b975-49cf-8c2e-e8c62e46a9b0);}.abf01854-4e8c-4008-ae65-642f1f526494{fill:#fff;}.becddad0-6b80-4658-9790-42d4b510e1b5{fill:url(#a4dbe3ba-12dd-4c66-9c29-e986e1455963);}.bcca39bc-bfb4-4f67-bc12-4aceabf3df7d{fill:url(#ba43738f-63c4-4d01-bae4-d41ec98c3384);}.b3449499-36a8-4596-bfe3-618988334900{fill:url(#f2750a2e-1fd3-4cb6-84dc-9bb9837f3b4a);}.af44360f-bffc-4759-ad8a-7f140017dda8{fill:url(#be5c7e94-a228-41f9-b03c-12343ab3da63);}.a5375b21-cc06-4028-b58d-ba1c2454f73b{fill:url(#a6a364e0-9912-493c-a7d1-f03fbd7b3ab6);}.bcc9f6cd-02f9-466e-b630-7e2cd2a7344b{fill:url(#ed706084-9b98-4ce0-bf1a-d8c1a3711940);}.a9599777-dcb5-4993-a0b4-9c292342e4df{fill:url(#b5e06f2b-33fd-456d-803f-fcff75e72180);}.fb8aee89-f2c1-4a13-a1e9-e4b38fdb454f{fill:url(#fd6866e4-8038-4301-b4bc-80bd28e61644);}.b73ad4e7-f056-4874-adc7-8bd8ce062976{fill:url(#baa82fe8-3f7d-4461-be39-b1feaeb7075d);}'
        }
      </style>
      <linearGradient
        id="a7e77991-6589-4961-9d39-a3645f57d20c"
        x1={86.73}
        y1={448.67}
        x2={318.9}
        y2={216.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ad58ac68-6a88-4e52-9f1c-1fe28f2a6303"
        x1={267.3}
        y1={9308.16}
        x2={412.18}
        y2={9422}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="af57bd14-b975-49cf-8c2e-e8c62e46a9b0"
        x1={141.11}
        y1={335.54}
        x2={326.09}
        y2={176.46}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="a4dbe3ba-12dd-4c66-9c29-e986e1455963"
        x1={246.45}
        y1={9166.86}
        x2={389.79}
        y2={9290.13}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="ba43738f-63c4-4d01-bae4-d41ec98c3384"
        x1={287.73}
        y1={9217.2}
        x2={404.2}
        y2={9253.36}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#f58ea9" />
      </linearGradient>
      <linearGradient
        id="f2750a2e-1fd3-4cb6-84dc-9bb9837f3b4a"
        x1={-2.01}
        y1={9171.7}
        x2={198.59}
        y2={9372.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="be5c7e94-a228-41f9-b03c-12343ab3da63"
        x1={212.61}
        y1={9224.04}
        x2={57.08}
        y2={9379.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="a6a364e0-9912-493c-a7d1-f03fbd7b3ab6"
        x1={157.02}
        y1={9313.46}
        x2={187.77}
        y2={9313.46}
        xlinkHref="#a4dbe3ba-12dd-4c66-9c29-e986e1455963"
      />
      <linearGradient
        id="ed706084-9b98-4ce0-bf1a-d8c1a3711940"
        x1={190.29}
        y1={9313.46}
        x2={171.4}
        y2={9313.46}
        xlinkHref="#a4dbe3ba-12dd-4c66-9c29-e986e1455963"
      />
      <linearGradient
        id="b5e06f2b-33fd-456d-803f-fcff75e72180"
        x1={162.62}
        y1={9429.53}
        x2={181}
        y2={9445.08}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="fd6866e4-8038-4301-b4bc-80bd28e61644"
        x1={164.74}
        y1={9161.4}
        x2={204.05}
        y2={9200.71}
        xlinkHref="#a4dbe3ba-12dd-4c66-9c29-e986e1455963"
      />
      <linearGradient
        id="baa82fe8-3f7d-4461-be39-b1feaeb7075d"
        x1={187.75}
        y1={9163.31}
        x2={171.73}
        y2={9162.84}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
    </defs>
    <g id="af1c8b4a-d8a5-4410-bfa1-b2899628068b" data-name="Layer 1">
      <g id="e641dde0-2402-4e63-9231-c8c17515febb" data-name="backdrop">
        <path
          className="f56b385f-4db5-457f-8902-21a2eb6bc351"
          d="M512,256c0,141.4-114.6,256-256,256h-4.4A255.2,255.2,0,0,1,79.4,441.3q-4.5-4.2-8.7-8.7A254.7,254.7,0,0,1,4.3,302.9,258.9,258.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b7987f28-f59b-4ea8-9273-237306e7e6d9"
        d="M369.4,394.2,251.6,512A255.2,255.2,0,0,1,79.4,441.3l-8.7-8.7A254.7,254.7,0,0,1,4.3,302.9L204.8,102.4l77,153.6Z"
      />
      <circle className="b1deb860-91a1-471e-9542-ccd94a188c2e" cx={316.8} cy={208} r={105.6} />
      <path
        className="bbc5f687-d2ed-48a1-bbab-a034b1efc7cc"
        d="M204.8,102.4V409.6h57.6V102.4Zm28.8,28.8a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,233.6,131.2Z"
      />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,382.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,350.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,318.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,286.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,254.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,222.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,190.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path className="abf01854-4e8c-4008-ae65-642f1f526494" d="M240,158.8H227.2a2,2,0,0,1,0-4H240a2,2,0,0,1,0,4Z" />
      <path
        className="becddad0-6b80-4658-9790-42d4b510e1b5"
        d="M310.4,230.4h44.8A28.8,28.8,0,0,1,384,259.2v99.2a51.2,51.2,0,0,1-51.2,51.2h0a51.2,51.2,0,0,1-51.2-51.2V259.2A28.8,28.8,0,0,1,310.4,230.4Z"
      />
      <path
        className="bcca39bc-bfb4-4f67-bc12-4aceabf3df7d"
        d="M332.8,409.6h0c-28.3,0-51.2-87.7-25.6-144,13.2-29,38.4-35.2,48-35.2h0A28.8,28.8,0,0,1,384,259.2v99.2A51.2,51.2,0,0,1,332.8,409.6Z"
      />
      <path
        className="abf01854-4e8c-4008-ae65-642f1f526494"
        d="M332.8,280.4a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,332.8,280.4Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,332.8,267.6Z"
      />
      <path
        className="b3449499-36a8-4596-bfe3-618988334900"
        d="M140.8,384,128,409.6,115.2,384V115.2A12.8,12.8,0,0,1,128,102.4h0a12.8,12.8,0,0,1,12.8,12.8Z"
      />
      <rect className="af44360f-bffc-4759-ad8a-7f140017dda8" x={121.6} y={134.4} width={19.2} height={230.4} />
      <path
        className="a5375b21-cc06-4028-b58d-ba1c2454f73b"
        d="M160,108.8V374.4l12.8,6.4v-272a6.4,6.4,0,0,0-6.4-6.4h0A6.4,6.4,0,0,0,160,108.8Z"
      />
      <path
        className="bcc9f6cd-02f9-466e-b630-7e2cd2a7344b"
        d="M179.2,102.4h0a6.4,6.4,0,0,0-6.4,6.4v272l12.8-6.4V108.8A6.4,6.4,0,0,0,179.2,102.4Z"
      />
      <path
        className="a9599777-dcb5-4993-a0b4-9c292342e4df"
        d="M166.4,102.4h12.8a6.4,6.4,0,0,1,6.4,6.4V128H160V108.8A6.4,6.4,0,0,1,166.4,102.4Z"
      />
      <polygon
        className="fb8aee89-f2c1-4a13-a1e9-e4b38fdb454f"
        points="160 374.4 160 380.8 172.8 409.6 172.8 380.8 160 374.4"
      />
      <polygon
        className="b73ad4e7-f056-4874-adc7-8bd8ce062976"
        points="185.6 374.4 172.8 380.8 172.8 409.6 185.6 380.8 185.6 374.4"
      />
    </g>
  </svg>
)

export default ImgPenRulerMouse
