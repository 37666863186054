import ExpandingNavLink from 'components/elements/buttons/expanding-nav-link'
import ImgBoxWithArrowPointingUp from 'components/elements/images/regular/img-box-with-arrow-pointing-up'
import ImgHandHoldingIdCard from 'components/elements/images/regular/img-hand-holding-id-card'
import ImgHouseWithTwoPlants from 'components/elements/images/regular/img-house-with-two-plants'
import ImgKiwi from 'components/elements/images/regular/img-kiwi'
import ImgPlantWithCoins from 'components/elements/images/regular/img-plant-with-coins'
import ImgSmileyInBetweenTwoHands from 'components/elements/images/regular/img-smiley-in-between-two-hands'
import ImgThreeShapesWithArrow from 'components/elements/images/regular/img-three-shapes-with-arrow'
import ImgVideoGlitter from 'components/elements/images/regular/img-video-glitter'
import TellaLogo from 'components/elements/images/tella-logo'
import ImgHandsWithHeartGlitter from 'components/elements/images/with-glitter/img-hands-with-heart-glitter'
import ImgHeadphonesGlitter from 'components/elements/images/with-glitter/img-headphones-glitter'
import ImgPiggybankGlitter from 'components/elements/images/with-glitter/img-piggybank-glitter'
import ImgSheetOfPaperGlitter from 'components/elements/images/with-glitter/img-sheet-of-paper-glitter'
import ImgWalletGlitter from 'components/elements/images/with-glitter/img-wallet-glitter'
import SubNavLinkWithImg from 'components/links/sub-nav-link-with-img'
import Hamburger from 'hamburger-react'
import { EV_HL_APPLICATION_PATH_CLICK } from 'lib/constants/analytics-events'
import * as href from 'lib/constants/hrefs'
import { stringToImgComponent } from 'lib/switch-fns/string-to-img-component'
import { fireGAEvent } from 'lib/utilities'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'

import { HeroColorAndGlossaryLetterContext } from '../layout'

type Props = {
  isSubMenuOpen?: boolean
  isPageScrolled: boolean
  isMobileNavOpen: (state: boolean) => void
}

const TopNavigation = ({ isSubMenuOpen, isPageScrolled, isMobileNavOpen }: Props) => {
  // const isLoggedIn = useIsMemberOnly()
  const router = useRouter()
  const { heroBgColor } = useContext(HeroColorAndGlossaryLetterContext)

  const [openSubNav, setOpenSubNav] = useState<boolean>(false)
  const [openMobileNav, setOpenMobileNav] = useState<boolean>(false)
  const [isHeroBgBright, setIsHeroBgBright] = useState<boolean>(false)

  const settingIsHeroBgColor = () => {
    const brightColorEnumsInStrapi = ['tellaBlue300', 'tellaPink300', 'tellaGreen300']
    if (brightColorEnumsInStrapi.find((clr) => heroBgColor === clr)) setIsHeroBgBright(true)
    else setIsHeroBgBright(false)
  }

  const [storedCampaignString] = useState(typeof window !== 'undefined' ? localStorage.queryString : '')

  const closeNav = useCallback(() => {
    setOpenMobileNav(false)
  }, [])

  useEffect(() => {
    if (openMobileNav) {
      isMobileNavOpen(true)
    }
    /**close mobile nav when use click on any link in navbar
     * It can be controlled by using <a></a> in place of <Link/> tag as well
     */
    router.events.on('routeChangeStart', closeNav)

    settingIsHeroBgColor()
  }, [openMobileNav, isMobileNavOpen]) //eslint-disable-line

  return (
    <>
      <div
        className={`flex h-16 w-full items-center px-4 lg:hidden lg:w-auto lg:px-6 ${
          openMobileNav ? 'bg-tella-blue-300' : ''
        }`}
      >
        <Link href="/">
          <a aria-label="tella logo">
            <TellaLogo
              animate
              color={`${
                isPageScrolled
                  ? 'text-white'
                  : openMobileNav || isHeroBgBright || router.pathname === '/'
                  ? 'text-tella-blue-300'
                  : openSubNav
                  ? 'text-tella-blue-300 lg:text-tella-blue-300'
                  : 'text-tella-blue-300'
              }`}
              className="sm:w-18 z-30 h-10 w-16 cursor-pointer"
            />
          </a>
        </Link>
        <div className="z-30 ml-auto flex flex-row items-center lg:hidden">
          <div className="lg:mr-6 lg:mt-0">
            <Link href={`${href.HL_APPLICATION}${storedCampaignString}`}>
              <a
                className={`m-3 w-max rounded-full px-6 py-2 text-center lg:my-auto lg:mr-0 ${
                  isPageScrolled || openSubNav || isSubMenuOpen || router.pathname === '/home-loans-mortgages/learn'
                    ? 'bg-white text-tella-blue-300'
                    : 'bg-tella-blue-300 text-white'
                }`}
                onClick={() => fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Get started (Navbar)' })}
              >
                Get Started
              </a>
            </Link>
          </div>
          <Hamburger
            toggled={openMobileNav}
            toggle={setOpenMobileNav}
            color={isPageScrolled || openMobileNav || isHeroBgBright ? '#FFF' : 'var(--blue-300)'}
            size={24}
          />
        </div>
      </div>

      <div
        className={`fixed inset-x-0 flex flex-col overflow-hidden transition-height lg:static lg:mx-auto lg:h-16 lg:flex-row ${
          openMobileNav ? 'h-full' : 'h-0'
        } ${openSubNav ? 'text-white' : isHeroBgBright || router.pathname === '/' ? ' text-tella-blue-300' : ''} ${
          isPageScrolled ? 'bg-tella-blue-300 text-white shadow-lg' : ''
        }`}
      >
        <nav className="w-full overflow-y-scroll pb-40 scrollbar-hide lg:fixed lg:mx-auto lg:flex lg:max-w-screen-xl lg:pb-0">
          <div className="grid grid-flow-row lg:h-16 lg:w-max lg:grid-flow-col lg:items-center">
            <div className="hidden lg:block">
              <Link href="/" passHref>
                <a>
                  <TellaLogo
                    animate
                    color={`${
                      isPageScrolled
                        ? 'text-white'
                        : openSubNav
                        ? 'text-white'
                        : openMobileNav || isHeroBgBright || router.pathname === '/'
                        ? 'text-tella-blue-300'
                        : 'text-tella-blue-300'
                    }`}
                    className="sm:w-18 z-30 mx-6 my-auto mb-2 h-10 w-16 cursor-pointer transition-transform duration-300 hover:scale-110"
                  />
                </a>
              </Link>
            </div>
            <ExpandingNavLink
              text="Home Loans"
              isSubNavExpanded={(state) => setOpenSubNav(state)}
              href={href.HL_MORTGAGES}
            >
              <div
                className={`flex flex-col border-b border-t border-white border-opacity-40 p-5 lg:flex-row lg:border-b-0 lg:pt-6 ${
                  router.pathname === '/' ? ' lg:mt-12' : ''
                }`}
              >
                <div className="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <SubNavLinkWithImg
                    title="First Home"
                    description="Everything you need as a first home buyer"
                    image={<ImgHouseWithTwoPlants />}
                    href={href.FIRST_HOME}
                  />
                  <SubNavLinkWithImg
                    title="Refinance"
                    description="All you need to prepare for a refinance"
                    image={<ImgPiggybankGlitter />}
                    href={href.REFINANCE}
                  />
                  <SubNavLinkWithImg
                    title="Invest"
                    description="Get ready to make your property investment"
                    image={<ImgWalletGlitter />}
                    href={href.INVEST}
                  />
                </div>
              </div>
            </ExpandingNavLink>

            <ExpandingNavLink text="Tools" isSubNavExpanded={(state) => setOpenSubNav(state)} href={href.CALCULATORS}>
              <div
                className={`flex flex-col border-b border-t border-white border-opacity-40 p-5 lg:flex-row lg:border-b-0 lg:pt-6 ${
                  router.pathname === '/' ? 'lg:mt-12' : ''
                }`}
              >
                <div className="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <SubNavLinkWithImg
                    title="First Home Deposit"
                    description="Find out the value of property you can buy"
                    image={stringToImgComponent('PiggyBank')}
                    href={href.CALC_FIRST_HOME_DEPOSIT}
                  />
                  <SubNavLinkWithImg
                    title="Affordability"
                    description="Calculate your borrowing potential"
                    image={stringToImgComponent('CalculatorWithPen')}
                    href={href.CALC_AFFORDABILITY}
                  />
                  <SubNavLinkWithImg
                    title="Repayment"
                    description="Find out the monthly cost of a home loan"
                    image={stringToImgComponent('MoneyWithArrows')}
                    href={href.CALC_REPAYMENT}
                  />
                  <SubNavLinkWithImg
                    title="Refinance"
                    description="Find out if refinancing is worth it"
                    image={stringToImgComponent('Wallet')}
                    href={href.CALC_REFINANCE}
                  />
                  {/*<SubNavLinkWithImg
                    title="Property Portfolio Builder"
                    description="Build and manage your property portfolio"
                    image={stringToImgComponent('HouseWithTwoPlants')}
                    href={href.PROPERTY_PORTFOLIO_LANDING}
                    beta
                  />*/}
                </div>
              </div>
            </ExpandingNavLink>

            <ExpandingNavLink text="Learn" isSubNavExpanded={(state) => setOpenSubNav(state)} href={href.LEARN}>
              <div
                className={`flex flex-col border-b border-t border-white border-opacity-40 p-5 lg:flex-row lg:border-b-0 lg:pt-6 ${
                  router.pathname === '/' ? 'lg:mt-12' : ''
                }`}
              >
                <div className="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <SubNavLinkWithImg
                    href={href.LEARN_FIRST_HOME_BUYER}
                    title="Buying your first home"
                    description="Get started on your home loan journey"
                    image={<ImgHouseWithTwoPlants />}
                  />
                  <SubNavLinkWithImg
                    href={href.LEARN_MOVING_HOUSE_SWITCHING_BANKS}
                    title="Moving house or switching banks"
                    description="Guides to navigate these changes"
                    image={<ImgBoxWithArrowPointingUp />}
                  />
                  <SubNavLinkWithImg
                    href={href.LEARN_PROPERTY_INVESTMENT}
                    title="Buying investment property"
                    description="Making your money work harder"
                    image={<ImgPlantWithCoins />}
                  />
                  <SubNavLinkWithImg
                    href={href.LEARN_HELPING_KIDS_BUY_HOME}
                    title="Helping the kids buy a home"
                    description="Providing financial assistance for your kids"
                    image={<ImgSmileyInBetweenTwoHands />}
                  />
                  <SubNavLinkWithImg
                    href={href.LEARN_BUYING_FROM_OVERSEAS}
                    title="Applying from outside NZ"
                    description="For NZers and Internationals"
                    image={<ImgKiwi />}
                  />
                  {/* hiding glossary as per #440-Remove Glossary from the Learn menu and the footer
                  <SubNavLinkWithImg
                    href={href.GLOSSARY}
                    title="Glossary"
                    description="Common terms on your home loan journey"
                    image={<ImgGlossaryBook />}
                    imageClasses="mr-2"
                  /> */}
                  <SubNavLinkWithImg
                    href="https://www.youtube.com/channel/UCzb2evh7icDaUijJZevTbwQ"
                    isExternalUrl
                    title="Videos"
                    description="Check out Tella's latest YouTube videos"
                    image={<ImgVideoGlitter />}
                  />
                </div>
              </div>
            </ExpandingNavLink>

            <ExpandingNavLink text="About Us" isSubNavExpanded={(state) => setOpenSubNav(state)} href="/about-us">
              <div
                className={`flex flex-col border-b border-t border-white border-opacity-40 p-5 lg:flex-row lg:border-b-0 lg:pt-6 ${
                  router.pathname === '/' ? 'lg:mt-12' : ''
                }`}
              >
                <div className="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  <SubNavLinkWithImg
                    href={href.ABOUT_US}
                    title="About Us"
                    image={<ImgHandHoldingIdCard />}
                    description="Learn more about Tella"
                  />
                  <SubNavLinkWithImg
                    href={href.CONTACT_US}
                    title="Contact Us"
                    image={<ImgHeadphonesGlitter />}
                    description="Get in touch with the Tella team"
                  />
                  <SubNavLinkWithImg
                    href={href.FAQS}
                    title="FAQs"
                    image={<ImgSheetOfPaperGlitter />}
                    description="Answer a question about the Tella platform"
                  />
                  <SubNavLinkWithImg
                    href={href.IMPORTANT_INFO}
                    title="Important Info"
                    image={<ImgThreeShapesWithArrow />}
                    description="Read Tella's Public Disclosure, T&Cs, and more"
                  />
                  <SubNavLinkWithImg
                    href={href.CHARITIES}
                    title="Charities"
                    image={<ImgHandsWithHeartGlitter />}
                    description="Learn more about our charities"
                  />
                </div>
              </div>
            </ExpandingNavLink>

            <Link href={href.RATES}>
              <a
                className={`z-10 p-3  hover:text-tella-green-300 ${
                  isHeroBgBright || openSubNav || openMobileNav || isPageScrolled ? 'text-white' : 'text-tella-blue-300'
                }`}
              >
                Rates
              </a>
            </Link>

            <Link href={href.BLOG}>
              <a
                className={`z-10 p-3  hover:text-tella-green-300 ${
                  isHeroBgBright || openSubNav || openMobileNav || isPageScrolled ? 'text-white' : 'text-tella-blue-300'
                }`}
              >
                Blog
              </a>
            </Link>
          </div>
          <div className="z-10 grid grid-flow-row lg:ml-auto lg:h-16 lg:w-max lg:grid-flow-col lg:items-center">
            <a
              href={`${href.MY_TELLA}/auth`}
              className={`z-10 p-3  hover:text-tella-green-300 ${
                isHeroBgBright || openSubNav || openMobileNav || isPageScrolled ? 'text-white' : 'text-tella-blue-300'
              }`}
            >
              Login
            </a>
            <div className="mt-5 w-max lg:mr-6 lg:mt-0">
              <Link href={`${href.HL_APPLICATION}${storedCampaignString}`}>
                <a
                  className={`m-3 w-max rounded-full px-6 py-2 text-center lg:my-auto lg:mr-0 ${
                    openSubNav || isSubMenuOpen || router.pathname === '/home-loans-mortgages/learn'
                      ? 'bg-white text-tella-blue-300'
                      : 'bg-white text-tella-blue-300 lg:bg-tella-blue-300 lg:text-white'
                  }`}
                  target="_blank"
                  onClick={() => fireGAEvent({ event: EV_HL_APPLICATION_PATH_CLICK, element: 'Apply online (Navbar)' })}
                >
                  Apply online
                </a>
              </Link>
            </div>
            <div
              className={`m-3 mt-5 flex w-max rounded-full lg:m-0 lg:mr-6 lg:mt-0 ${
                openSubNav || isSubMenuOpen || router.pathname === '/home-loans-mortgages/learn'
                  ? 'bg-[#3a3acc] text-white'
                  : 'bg-white text-tella-blue-300 lg:bg-tella-blue-300 lg:text-white'
              }`}
            >
              <a
                href="tel:+640800483552"
                className={`relative left-[5px] top-[5px] mr-2 h-[28px] w-[28px] rounded-full bg-white text-center`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="blue"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`ml-1 mt-1 h-5 w-5 text-tella-blue-300`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
              </a>
              <a href="tel:+640800483552" className="mt-[1px] py-[6px] pl-2 pr-6">
                0800 483 552
              </a>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}

export default TopNavigation
