import { useCanonicalUrl } from 'hooks/use-canonical-url'
import Head from 'next/head'
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'

import Footer from './footer'
import Header from './header'

type Props = {
  children: ReactNode
}

export const HeroColorAndGlossaryLetterContext = createContext<{
  heroBgColor: string
  setHeroBgColor: Dispatch<SetStateAction<string>>
  selectedLetter: string
  setSelectedLetter: Dispatch<SetStateAction<string>>
}>({
  heroBgColor: '',
  setHeroBgColor: () => {}, //eslint-disable-line
  selectedLetter: '',
  setSelectedLetter: () => {}, //eslint-disable-line
})

const Layout = ({ children }: Props) => {
  const [heroBgColor, setHeroBgColor] = useState('')
  const [selectedLetter, setSelectedLetter] = useState('')
  const value = { heroBgColor, setHeroBgColor, selectedLetter, setSelectedLetter }
  const canonicalUrl = useCanonicalUrl()

  return (
    <>
      <HeroColorAndGlossaryLetterContext.Provider value={value}>
        <Head>
          <link rel="canonical" href={canonicalUrl} />
        </Head>
        <Header />
        <div className="flex min-h-screen flex-col justify-between">
          <section>{children}</section>
          <Footer />
        </div>
      </HeroColorAndGlossaryLetterContext.Provider>
    </>
  )
}

export default Layout
