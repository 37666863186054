import { SVGProps } from 'react'

const ImgBuildingsGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bc4c6f53-b53f-493f-8a85-b81cad72152a{fill:none;}.af4e78f6-ff14-41df-b975-93ce9dbc6007{opacity:0.15;fill:url(#a3cbda2f-5ce6-47ca-a086-6903a2b954f0);}.e58ad139-68be-4a40-b5dc-3b93939be6c8{fill:url(#a6726796-35ce-4917-b0a4-6bd88f23a4b2);}.ab3a9045-f08f-4575-b74e-28bdbbf85d85{fill:url(#b3043d1e-a198-4055-9ed7-4b48cf2df752);}.be2ae240-e29c-4f77-b9cb-6b87736f6192{fill:url(#a0cd5351-67c3-4c60-8e78-b7d88bd3a65d);}.ade373dd-d4e0-4862-aacb-0dc510ee1b5f{fill:url(#b54aef1e-9b62-46bd-8438-65d8d7bc9ba9);}.aecb2f7b-ea60-4a90-9c5a-99ba0b5ac4e6{fill:url(#a6a6e0ba-16a3-44e4-8e92-9dc67fe16531);}.b99507b9-3a99-4b1f-99e5-a3e586b1a3ce,.e13878eb-b4a9-493b-9386-78966478449b{fill:#fff;}.b99507b9-3a99-4b1f-99e5-a3e586b1a3ce,.e1df2fa6-b513-4084-b8b3-32ed06d0a07a,.f7b4f500-0798-4121-aff8-22d2b08da92f{isolation:isolate;}.f7b4f500-0798-4121-aff8-22d2b08da92f{fill:#f7ced7;}.a513cf67-4007-4511-a2e4-f098b70e956a{fill:url(#f3c25667-adb2-4dba-8851-93d975d426e9);}.b2d48db4-a789-4008-9010-8b2b4edcdb16{fill:url(#e8fb337c-9723-4975-be91-bb933b2cf9c7);}.e1df2fa6-b513-4084-b8b3-32ed06d0a07a,.e876614a-b3be-44da-b834-413ddd6b351e{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a3cbda2f-5ce6-47ca-a086-6903a2b954f0"
        x1={97.8}
        y1={459.61}
        x2={285.98}
        y2={271.43}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a6726796-35ce-4917-b0a4-6bd88f23a4b2"
        x1={295.15}
        y1={4355.95}
        x2={-35.47}
        y2={4686.57}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="b3043d1e-a198-4055-9ed7-4b48cf2df752"
        x1={234.11}
        y1={4386.02}
        x2={340.44}
        y2={4492.35}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="a0cd5351-67c3-4c60-8e78-b7d88bd3a65d"
        x1={297.74}
        y1={4308.66}
        x2={226.24}
        y2={4380.15}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#8782cf" />
      </linearGradient>
      <linearGradient
        id="b54aef1e-9b62-46bd-8438-65d8d7bc9ba9"
        x1={263.2}
        y1={380}
        x2={95.2}
        y2={212}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="a6a6e0ba-16a3-44e4-8e92-9dc67fe16531"
        x1={262.4}
        y1={4422.4}
        x2={216.1}
        y2={4468.7}
        xlinkHref="#a0cd5351-67c3-4c60-8e78-b7d88bd3a65d"
      />
      <linearGradient
        id="f3c25667-adb2-4dba-8851-93d975d426e9"
        x1={377.67}
        y1={4270.33}
        x2={246.03}
        y2={4401.96}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="e8fb337c-9723-4975-be91-bb933b2cf9c7"
        x1={403.01}
        y1={4315.38}
        x2={282.17}
        y2={4436.23}
        xlinkHref="#a0cd5351-67c3-4c60-8e78-b7d88bd3a65d"
      />
    </defs>
    <g id="ba6359cb-98c0-46e5-bd47-877fd4e0791f" data-name="Layer 1">
      <g id="f494452a-7ce0-4888-8484-97654ae1b666" data-name="backdrop">
        <path
          className="bc4c6f53-b53f-493f-8a85-b81cad72152a"
          d="M512,256c0,126.6-92,231.8-212.7,252.4A269.1,269.1,0,0,1,256,512,255.4,255.4,0,0,1,81.4,443.3c-4.3-4.1-8.6-8.4-12.7-12.7A255.4,255.4,0,0,1,4.3,302.9,258.9,258.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="af4e78f6-ff14-41df-b975-93ce9dbc6007"
        d="M411.1,396.5,299.3,508.4A269.1,269.1,0,0,1,256,512,255.4,255.4,0,0,1,81.4,443.3L68.7,430.6A255.4,255.4,0,0,1,4.3,302.9L121.6,185.6h51.2l217.6,208Z"
      />
      <path
        className="e58ad139-68be-4a40-b5dc-3b93939be6c8"
        d="M249.6,89.6h0a76.8,76.8,0,0,1,76.8,76.8V393.6H172.8V166.4A76.8,76.8,0,0,1,249.6,89.6Z"
      />
      <path
        className="ab3a9045-f08f-4575-b74e-28bdbbf85d85"
        d="M179.8,134.4a76.8,76.8,0,0,1,69.8-44.8h0a76.8,76.8,0,0,1,76.8,76.8V393.6H304V166.4c0-28.8-22.4-64-67.2-64S175.3,144.1,179.8,134.4Z"
      />
      <rect className="be2ae240-e29c-4f77-b9cb-6b87736f6192" x={249.6} y={249.6} width={25.6} height={144} />
      <polygon
        className="ade373dd-d4e0-4862-aacb-0dc510ee1b5f"
        points="249.6 249.6 249.6 393.6 121.6 393.6 121.6 185.6 224 185.6 224 249.6 249.6 249.6"
      />
      <rect className="aecb2f7b-ea60-4a90-9c5a-99ba0b5ac4e6" x={224} y={185.6} width={25.6} height={64} />
      <path
        className="b99507b9-3a99-4b1f-99e5-a3e586b1a3ce"
        d="M211.2,165.2a11.6,11.6,0,1,1,11.6-11.6A11.6,11.6,0,0,1,211.2,165.2Zm0-19.2a7.6,7.6,0,1,0,7.6,7.6A7.6,7.6,0,0,0,211.2,146Z"
      />
      <path className="f7b4f500-0798-4121-aff8-22d2b08da92f" d="M147.2,278a6,6,0,1,1,6-6A6,6,0,0,1,147.2,278Z" />
      <path className="f7b4f500-0798-4121-aff8-22d2b08da92f" d="M147.2,306.8a6,6,0,1,1,6-6A6,6,0,0,1,147.2,306.8Z" />
      <path className="f7b4f500-0798-4121-aff8-22d2b08da92f" d="M147.2,335.6a6,6,0,1,1,6-6A6,6,0,0,1,147.2,335.6Z" />
      <polygon
        className="a513cf67-4007-4511-a2e4-f098b70e956a"
        points="390.4 393.6 217.6 393.6 217.6 332.8 358.4 249.6 390.4 249.6 390.4 393.6"
      />
      <rect className="b2d48db4-a789-4008-9010-8b2b4edcdb16" x={358.4} y={249.6} width={32} height={144} />
      <rect className="e1df2fa6-b513-4084-b8b3-32ed06d0a07a" x={313.6} y={364} width={25.6} height={8} />
      <path
        className="e13878eb-b4a9-493b-9386-78966478449b"
        d="M409.6,397.2H102.4a2,2,0,0,1,0-4H409.6a2,2,0,0,1,0,4Z"
      />
      <path
        className="e876614a-b3be-44da-b834-413ddd6b351e"
        d="M94.9,323.6a8.4,8.4,0,1,1,8.4-8.4A8.4,8.4,0,0,1,94.9,323.6Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,94.9,310.8Z"
      />
      <path
        className="e876614a-b3be-44da-b834-413ddd6b351e"
        d="M409.1,283.7a1.6,1.6,0,0,1-1.2-.4l-11.6-8.7-11.5,8.7a2.1,2.1,0,0,1-2.4,0l-12.8-9.6a1.9,1.9,0,0,1-.4-2.8,2,2,0,0,1,2.8-.4l11.6,8.7,11.5-8.7a2.1,2.1,0,0,1,2.4,0l11.6,8.7,11.5-8.7a2,2,0,0,1,2.8.4,1.9,1.9,0,0,1-.4,2.8l-12.7,9.6A1.9,1.9,0,0,1,409.1,283.7Z"
      />
      <path
        className="e876614a-b3be-44da-b834-413ddd6b351e"
        d="M188.7,94h-5.9V88a2,2,0,0,0-4,0v6h-6a2,2,0,0,0,0,4h6v6a2,2,0,0,0,4,0V98h5.9a2,2,0,0,0,0-4Z"
      />
    </g>
  </svg>
)

export default ImgBuildingsGlitter
