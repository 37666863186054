import { SVGProps } from 'react'

const ImgBuildings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.fa307564-f76e-4ad4-ab36-9c928710c8ae{fill:none;}.e74ddfa1-c7a8-43bc-98dd-af16171e432a{opacity:0.15;fill:url(#a23ecc71-a2ab-46a7-af4b-fc3c27f8cc4d);}.b65c168f-9e3c-414c-8ee6-24405c7b1dcc{fill:url(#a60cafbf-af44-4ed4-b76c-4135473628f1);}.f294bbb3-a4ed-4081-ae46-0f32427ff60c{fill:url(#ac903101-2381-4a95-a6f1-02d01ea166ff);}.f543ea82-16b4-4ea4-b516-b155144f68cc{fill:url(#a248710f-d072-429a-b050-dbb68f446445);}.b92c980c-decd-4d19-bb33-713451b1228a{fill:url(#a503d85f-3269-400b-87a9-a79ad13ac4fe);}.bea181bd-ec32-4f80-9bfb-282c725a2bcb{fill:url(#b425953f-259f-4e95-a0c0-9e1dfb845070);}.b1bc2957-7ed1-4e05-a93b-4e07666501cb,.e34504af-718b-42f7-9e5e-f5f9ca94ccc9{fill:#fff;}.a002b5b2-819b-46bb-8552-9e034c83fd57,.b1bc2957-7ed1-4e05-a93b-4e07666501cb,.e8064f24-4f39-4edc-be0e-0781a0350aa9{isolation:isolate;}.e8064f24-4f39-4edc-be0e-0781a0350aa9{fill:#f7ced7;}.b9b72790-e1dd-43a7-98fe-742910bc47e7{fill:url(#e4cf976b-6578-4f55-b6bd-c1811517205d);}.b7715b48-b654-4eb2-a966-3bd3c18fb241{fill:url(#b87f6e75-12f4-4295-93fd-b4d5393b5fa5);}.a002b5b2-819b-46bb-8552-9e034c83fd57{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a23ecc71-a2ab-46a7-af4b-fc3c27f8cc4d"
        x1={97.8}
        y1={459.61}
        x2={285.98}
        y2={271.43}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a60cafbf-af44-4ed4-b76c-4135473628f1"
        x1={295.15}
        y1={4355.95}
        x2={-35.47}
        y2={4686.57}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="ac903101-2381-4a95-a6f1-02d01ea166ff"
        x1={234.11}
        y1={4386.02}
        x2={340.44}
        y2={4492.35}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="a248710f-d072-429a-b050-dbb68f446445"
        x1={297.74}
        y1={4308.66}
        x2={226.24}
        y2={4380.15}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#8782cf" />
      </linearGradient>
      <linearGradient
        id="a503d85f-3269-400b-87a9-a79ad13ac4fe"
        x1={263.2}
        y1={380}
        x2={95.2}
        y2={212}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="b425953f-259f-4e95-a0c0-9e1dfb845070"
        x1={262.4}
        y1={4422.4}
        x2={216.1}
        y2={4468.7}
        xlinkHref="#a248710f-d072-429a-b050-dbb68f446445"
      />
      <linearGradient
        id="e4cf976b-6578-4f55-b6bd-c1811517205d"
        x1={377.67}
        y1={4270.33}
        x2={246.03}
        y2={4401.96}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="b87f6e75-12f4-4295-93fd-b4d5393b5fa5"
        x1={403.01}
        y1={4315.38}
        x2={282.17}
        y2={4436.23}
        xlinkHref="#a248710f-d072-429a-b050-dbb68f446445"
      />
    </defs>
    <g id="a75d05e9-4d6d-41b6-b416-945517ce6e0e" data-name="Layer 1">
      <g id="b0f7b22f-195f-4b06-b179-bd69540582fb" data-name="backdrop">
        <path
          className="fa307564-f76e-4ad4-ab36-9c928710c8ae"
          d="M512,256c0,126.6-92,231.8-212.7,252.4A269.1,269.1,0,0,1,256,512,255.4,255.4,0,0,1,81.4,443.3c-4.3-4.1-8.6-8.4-12.7-12.7A255.4,255.4,0,0,1,4.3,302.9,258.9,258.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="e74ddfa1-c7a8-43bc-98dd-af16171e432a"
        d="M411.1,396.5,299.3,508.4A269.1,269.1,0,0,1,256,512,255.4,255.4,0,0,1,81.4,443.3L68.7,430.6A255.4,255.4,0,0,1,4.3,302.9L121.6,185.6h51.2l217.6,208Z"
      />
      <path
        className="b65c168f-9e3c-414c-8ee6-24405c7b1dcc"
        d="M249.6,89.6h0a76.8,76.8,0,0,1,76.8,76.8V393.6H172.8V166.4A76.8,76.8,0,0,1,249.6,89.6Z"
      />
      <path
        className="f294bbb3-a4ed-4081-ae46-0f32427ff60c"
        d="M179.8,134.4a76.8,76.8,0,0,1,69.8-44.8h0a76.8,76.8,0,0,1,76.8,76.8V393.6H304V166.4c0-28.8-22.4-64-67.2-64S175.3,144.1,179.8,134.4Z"
      />
      <rect className="f543ea82-16b4-4ea4-b516-b155144f68cc" x={249.6} y={249.6} width={25.6} height={144} />
      <polygon
        className="b92c980c-decd-4d19-bb33-713451b1228a"
        points="249.6 249.6 249.6 393.6 121.6 393.6 121.6 185.6 224 185.6 224 249.6 249.6 249.6"
      />
      <rect className="bea181bd-ec32-4f80-9bfb-282c725a2bcb" x={224} y={185.6} width={25.6} height={64} />
      <path
        className="b1bc2957-7ed1-4e05-a93b-4e07666501cb"
        d="M211.2,165.2a11.6,11.6,0,1,1,11.6-11.6A11.6,11.6,0,0,1,211.2,165.2Zm0-19.2a7.6,7.6,0,1,0,7.6,7.6A7.6,7.6,0,0,0,211.2,146Z"
      />
      <path className="e8064f24-4f39-4edc-be0e-0781a0350aa9" d="M147.2,278a6,6,0,1,1,6-6A6,6,0,0,1,147.2,278Z" />
      <path className="e8064f24-4f39-4edc-be0e-0781a0350aa9" d="M147.2,306.8a6,6,0,1,1,6-6A6,6,0,0,1,147.2,306.8Z" />
      <path className="e8064f24-4f39-4edc-be0e-0781a0350aa9" d="M147.2,335.6a6,6,0,1,1,6-6A6,6,0,0,1,147.2,335.6Z" />
      <polygon
        className="b9b72790-e1dd-43a7-98fe-742910bc47e7"
        points="390.4 393.6 217.6 393.6 217.6 332.8 358.4 249.6 390.4 249.6 390.4 393.6"
      />
      <rect className="b7715b48-b654-4eb2-a966-3bd3c18fb241" x={358.4} y={249.6} width={32} height={144} />
      <rect className="a002b5b2-819b-46bb-8552-9e034c83fd57" x={313.6} y={364} width={25.6} height={8} />
      <path
        className="e34504af-718b-42f7-9e5e-f5f9ca94ccc9"
        d="M409.6,397.2H102.4a2,2,0,0,1,0-4H409.6a2,2,0,0,1,0,4Z"
      />
    </g>
  </svg>
)

export default ImgBuildings
