const ImgHeadphonesGlitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="eb3d6511-5d5f-4628-9d08-830c4d326894"
          x1={91.3}
          y1={442.88}
          x2={173.44}
          y2={442.88}
          gradientTransform="matrix(2.4 -2.4 .92 .92 -437.05 283.49)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="b7dd0bb3-528b-450b-99a1-fe1566628c8a"
          x1={91.3}
          y1={322.36}
          x2={173.44}
          y2={322.36}
          gradientTransform="matrix(1.36 -1.36 .92 .92 -383.58 229.82)"
          xlinkHref="#eb3d6511-5d5f-4628-9d08-830c4d326894"
        />
        <linearGradient
          id="f4a72f3d-7582-4f4e-9c2c-de938afe9266"
          x1={219.45}
          y1={-6665.84}
          x2={298.42}
          y2={-6586.86}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="e08cce21-0e6a-47fb-a17e-b5e7f847e99a"
          x1={242.85}
          y1={-6805.9}
          x2={277.05}
          y2={-6771.71}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#706ac5" />
          <stop offset={1} stopColor="#4038b2" />
        </linearGradient>
        <linearGradient
          id="b546cdc5-0018-410a-b0ec-17a613c5f02c"
          x1={365}
          y1={-6713.33}
          x2={409.21}
          y2={-6669.13}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={0.99} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="be39d1d8-2b82-4fdd-b779-c9f4fe86e51b"
          x1={334.75}
          y1={-6720.13}
          x2={411.69}
          y2={-6643.2}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5851bc" />
          <stop offset={1} stopColor="#281fa9" />
        </linearGradient>
        <linearGradient
          id="b54f5903-2121-463a-95a2-c6d3490377bc"
          x1={-6015.11}
          y1={-6713.33}
          x2={-6059.32}
          y2={-6669.13}
          gradientTransform="rotate(180 -2957.3 -3196.25)"
          xlinkHref="#b546cdc5-0018-410a-b0ec-17a613c5f02c"
        />
        <linearGradient
          id="ff3e52c1-0989-414b-ac26-80836b5d06cb"
          x1={-6031.76}
          y1={-6720.13}
          x2={-6108.7}
          y2={-6643.2}
          gradientTransform="rotate(180 -2957.3 -3196.25)"
          xlinkHref="#be39d1d8-2b82-4fdd-b779-c9f4fe86e51b"
        />
        <style>
          {
            '.ba3faccf-f34b-4fb7-89c6-82e412f8ca08{fill:none;stroke:#fff;stroke-linecap:round;stroke-miterlimit:10;stroke-width:6.4px}.b415d739-dfab-4fd4-b146-65cae76a2463{fill:#37c49f}'
          }
        </style>
      </defs>
      <g id="f7963fe7-1350-436f-92be-4cb70cfb5459" data-name="Layer 1">
        <circle
          cx={256}
          cy={256}
          r={256}
          style={{
            fill: 'none',
          }}
          id="ed54137e-92cc-4b3e-b45b-027686727f6e"
          data-name="backdrop"
        />
        <path
          style={{
            fill: 'url(#eb3d6511-5d5f-4628-9d08-830c4d326894)',
            opacity: 0.15,
          }}
          d="M399.4 342 229.6 511.7l-76-75.9 197.2-197.2L399.4 342z"
        />
        <path
          style={{
            fill: 'url(#b7dd0bb3-528b-450b-99a1-fe1566628c8a)',
            opacity: 0.15,
          }}
          d="m161.3 356.6-85.1 85.1L.3 365.8l111.5-111.5 49.5 102.3z"
        />
        <circle
          cx={256}
          cy={236.8}
          r={60}
          style={{
            fill: 'url(#f4a72f3d-7582-4f4e-9c2c-de938afe9266)',
          }}
        />
        <path
          d="M230.4 384H288a6.4 6.4 0 0 1 6.4 6.4v16H224v-16a6.4 6.4 0 0 1 6.4-6.4Z"
          style={{
            fill: 'url(#e08cce21-0e6a-47fb-a17e-b5e7f847e99a)',
          }}
        />
        <circle className="ba3faccf-f34b-4fb7-89c6-82e412f8ca08" cx={256} cy={236.8} r={3.2} />
        <circle className="ba3faccf-f34b-4fb7-89c6-82e412f8ca08" cx={281.6} cy={236.8} r={3.2} />
        <circle className="ba3faccf-f34b-4fb7-89c6-82e412f8ca08" cx={230.4} cy={236.8} r={3.2} />
        <path
          d="M222.4 410a2 2 0 1 1 0-4h108.8a55.6 55.6 0 0 0 55.6-55.6V236.8A130.8 130.8 0 0 0 258.6 106c-34.7-.7-67.7 12.6-92.9 37.4a135 135 0 0 0-40.5 96v106.2a2 2 0 0 1-4 0V239.4a139.2 139.2 0 0 1 41.7-98.8c26-25.6 60-39.2 95.7-38.6a134.9 134.9 0 0 1 132.2 134.8v113.6a59.6 59.6 0 0 1-59.6 59.6Z"
          style={{
            fill: '#f7ced7',
          }}
        />
        <path
          d="M390.4 345.6h-9.6v-96h9.6a12.8 12.8 0 0 1 12.8 12.8v70.4a12.8 12.8 0 0 1-12.8 12.8Z"
          style={{
            fill: 'url(#b546cdc5-0018-410a-b0ec-17a613c5f02c)',
          }}
        />
        <rect
          x={348.8}
          y={236.8}
          width={32}
          height={121.6}
          rx={6.4}
          style={{
            fill: 'url(#be39d1d8-2b82-4fdd-b779-c9f4fe86e51b)',
          }}
        />
        <path
          d="M121.6 249.6h9.6v96h-9.6a12.8 12.8 0 0 1-12.8-12.8v-70.4a12.8 12.8 0 0 1 12.8-12.8Z"
          style={{
            fill: 'url(#b54f5903-2121-463a-95a2-c6d3490377bc)',
          }}
        />
        <rect
          x={131.2}
          y={236.8}
          width={32}
          height={121.6}
          rx={6.4}
          style={{
            fill: 'url(#ff3e52c1-0989-414b-ac26-80836b5d06cb)',
          }}
        />
        <path
          className="b415d739-dfab-4fd4-b146-65cae76a2463"
          d="M169.6 194a7.9 7.9 0 1 1 7.8-7.9 7.9 7.9 0 0 1-7.8 7.9Zm0-12.8a4.9 4.9 0 0 0 0 9.8 4.9 4.9 0 0 0 0-9.8ZM355.8 164.8l-.9-.3-11.8-8.8-11.9 8.8a1.5 1.5 0 0 1-1.8 0l-12.7-9.5a1.5 1.5 0 1 1 1.8-2.4l11.8 8.9 11.9-8.9a1.5 1.5 0 0 1 1.8 0l11.8 8.9 11.9-8.9a1.5 1.5 0 0 1 2.1.3 1.6 1.6 0 0 1-.3 2.1l-12.8 9.5a1.4 1.4 0 0 1-.9.3ZM294.4 271.9a1.5 1.5 0 0 1-1.5-1.5v-16a1.5 1.5 0 1 1 3 0v16a1.5 1.5 0 0 1-1.5 1.5Z"
        />
        <path
          className="b415d739-dfab-4fd4-b146-65cae76a2463"
          d="M302.4 263.9h-16a1.5 1.5 0 0 1 0-3h16a1.5 1.5 0 0 1 0 3Z"
        />
      </g>
    </svg>
  )
}

export default ImgHeadphonesGlitter
