type Props = {
  color: string
  containerClass?: string
}

const SocialLinks = ({ color, containerClass }: Props) => {
  return (
    <div className={`grid w-52 grid-flow-col gap-4  ${containerClass ?? ''}`}>
      {/* Facebook */}
      <a
        href="https://www.facebook.com/tella.homeloans/"
        target="_blank"
        className="h-full w-full"
        rel="noreferrer"
        aria-label="facebook"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="-170 -20 330 300"
          className={`cursor-pointer fill-current transition-transform duration-300 hover:scale-110 ${color}`}
        >
          <path
            d="M31.318 58.1c0 7.555.26 44.535.26 44.535H0V150l31.318-.087L31.579 300h63.158V150l40.445-.087s3.063-20.81 4.95-47.281h-45.4s-1.026-32.8-1.026-37.95c0-5.163 6.781-12.1 13.468-12.1h33.876V0H95.005C29.779 0 31.318 50.55 31.318 58.1"
            fillRule="evenodd"
          />
        </svg>
      </a>
      {/* Instagram */}
      <a
        href="https://www.instagram.com/tella.homeloans"
        target="_blank"
        className="h-full w-full"
        rel="noreferrer"
        aria-label="instagram"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="-30 -30 360 300"
          className={`cursor-pointer fill-current transition-transform duration-300 hover:scale-110 ${color}`}
        >
          <g fillRule="evenodd">
            <path d="M150 94.97A55.03 55.03 0 1 0 205.03 150 55.092 55.092 0 0 0 150 94.97m0 130.2A75.171 75.171 0 1 1 225.171 150 75.256 75.256 0 0 1 150 225.171M256.652 64.087a21.26 21.26 0 1 0-21.26 21.26 21.26 21.26 0 0 0 21.26-21.26" />
            <path d="M74.775 20.141a54.7 54.7 0 0 0-54.634 54.634v150.45a54.7 54.7 0 0 0 54.634 54.634h150.45a54.7 54.7 0 0 0 54.634-54.634V74.775a54.7 54.7 0 0 0-54.634-54.634ZM225.225 300H74.775A74.86 74.86 0 0 1 0 225.225V74.775A74.86 74.86 0 0 1 74.775 0h150.45A74.86 74.86 0 0 1 300 74.775v150.45A74.86 74.86 0 0 1 225.225 300Z" />
          </g>
        </svg>
      </a>
      {/* LinkedIn */}
      <a
        href="https://www.linkedin.com/company/tella-homeloans"
        target="_blank"
        className="h-full w-full"
        rel="noreferrer"
        aria-label="linkedIn"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 300 300"
          className={`cursor-pointer fill-current transition-transform duration-300 hover:scale-110 ${color}`}
        >
          <path
            d="M34.615 0A34.615 34.615 0 1 1 .001 34.615 34.614 34.614 0 0 1 34.615 0Zm69.23 92.31h69.231v25.316c21.986-13.161 48.039-25.316 69.23-25.316 46.152 0 57.7 46.151 57.7 46.151V300H230.77V161.54c-8.949-26.839-31.758-25.936-57.694-2.667V300h-69.231ZM.001 300H69.23V92.31H.001Z"
            fillRule="evenodd"
          />
        </svg>
      </a>
      {/* Twitter */}
      <a
        href="https://twitter.com/tella_homeloans"
        target="_blank"
        className="h-full w-full"
        rel="noreferrer"
        aria-label="twitter"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 300 244"
          className={`mt-0.5 cursor-pointer fill-current transition-transform duration-300 hover:scale-110 ${color}`}
        >
          <path
            d="M300 28.87a123.287 123.287 0 0 1-35.349 9.693 61.808 61.808 0 0 0 27.063-34.059 123.234 123.234 0 0 1-39.083 14.929 61.612 61.612 0 0 0-104.885 56.144A174.68 174.68 0 0 1 20.9 11.27a61.584 61.584 0 0 0 19.038 82.175 61.283 61.283 0 0 1-27.88-7.7v.774a61.582 61.582 0 0 0 49.37 60.35 61.8 61.8 0 0 1-27.8 1.056 61.61 61.61 0 0 0 57.493 42.743 123.446 123.446 0 0 1-76.432 26.347A126.158 126.158 0 0 1 0 216.16a174.341 174.341 0 0 0 94.352 27.644c113.2 0 175.121-93.786 175.121-175.113 0-2.678-.067-5.326-.186-7.974A125.183 125.183 0 0 0 300 28.87"
            fillRule="evenodd"
          />
        </svg>
      </a>
      {/* Email */}
      <a href="mailto:hello@tella.co.nz" target="_blank" className="h-full w-full" rel="noreferrer" aria-label="email">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 300 170"
          className={`cursor-pointer fill-current transition-transform duration-300 hover:scale-110 ${color}`}
        >
          <path d="M279.067 0H20.933A20.941 20.941 0 0 0 0 20.9v188.367A20.969 20.969 0 0 0 20.933 230.2h258.134A20.969 20.969 0 0 0 300 209.267V20.9A20.941 20.941 0 0 0 279.067 0Zm-14.8 20.9L150 135.2 35.733 20.9ZM20.933 35.7l79.4 79.4-79.4 79.4Zm14.8 173.6 79.4-79.4 20.1 20.1a20.924 20.924 0 0 0 29.6 0l20.1-20.1 79.4 79.4Zm163.934-94.2 79.4-79.4v158.8Z" />
        </svg>
      </a>

      {/* Youtube */}
      <a
        href="https://www.youtube.com/channel/UCzb2evh7icDaUijJZevTbwQ"
        target="_blank"
        className="h-full w-full"
        rel="noreferrer"
        aria-label="Youtube"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 -30 364 252"
          className={`mt-0.5 cursor-pointer fill-current transition-transform duration-300 hover:scale-110 ${color}`}
        >
          <path
            id="Path_336"
            data-name="Path 336"
            d="M470.4,154H229.6A61.6,61.6,0,0,0,168,215.6V344.4A61.6,61.6,0,0,0,229.6,406H470.4A61.6,61.6,0,0,0,532,344.4V215.6A61.6,61.6,0,0,0,470.4,154Zm-105,154-54.6,28V224L420,280Z"
            transform="translate(-168.002 -154)"
          />
        </svg>
      </a>
    </div>
  )
}

export default SocialLinks
