import { SVGProps } from 'react'

const ImgDocumentSignedGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ea8fe415-23a1-46be-b00b-804aee896faf{fill:none;}.b1a90a24-fdce-4a6b-bf7c-c9fc19d43ca8{opacity:0.15;fill:url(#ac317a82-dd8b-4859-8d71-5bb71b1ad8f3);}.fddc7557-b03e-4a26-afc8-22ac2d24974e{fill:url(#ac57452d-4d71-4a6c-b6a3-0d07db0d49da);}.bb71d4f3-3f5e-4e5e-b966-4ac8ddce3b5a{fill:url(#a51cfbb8-f587-4ffd-b0b9-95a0969f3524);}.b0a2c8ea-d710-4511-829e-7050c2a24015{fill:#37c49f;}.b3741ad9-78e8-4e3e-8edd-42def143aeb4,.e0802e9f-4ef5-4c3e-8107-dd8ed7104768{fill:#fff;}.e0802e9f-4ef5-4c3e-8107-dd8ed7104768,.e6c2fa1e-5a27-44fa-bfc0-7451da83d92e{opacity:0.9;isolation:isolate;}.e6c2fa1e-5a27-44fa-bfc0-7451da83d92e{fill:#f7ced7;}.e11cd41d-34d3-44bb-8bdc-af28184a2d37{fill:url(#b30422d2-6319-4013-b1db-55c2d596d0de);}'
        }
      </style>
      <linearGradient
        id="ac317a82-dd8b-4859-8d71-5bb71b1ad8f3"
        x1={72.71}
        y1={433.57}
        x2={271.35}
        y2={234.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ac57452d-4d71-4a6c-b6a3-0d07db0d49da"
        x1={163.24}
        y1={11666.55}
        x2={358.48}
        y2={11861.79}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="a51cfbb8-f587-4ffd-b0b9-95a0969f3524"
        x1={299.81}
        y1={11710.09}
        x2={146.48}
        y2={11556.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b30422d2-6319-4013-b1db-55c2d596d0de"
        x1={225.65}
        y1={11704.06}
        x2={184.12}
        y2={11662.53}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
    </defs>
    <g id="a277194a-cc99-4495-adc7-a0cbcee927c9" data-name="Layer 1">
      <g id="f22236fe-46c7-4f8b-82e9-71c38db22054" data-name="backdrop">
        <path
          className="ea8fe415-23a1-46be-b00b-804aee896faf"
          d="M512,256c0,141.4-114.6,256-256,256a258.9,258.9,0,0,1-38.6-2.9,255.3,255.3,0,0,1-127.2-58,270.2,270.2,0,0,1-29.3-29.3A254.8,254.8,0,0,1,1.6,284.4,242.1,242.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b1a90a24-fdce-4a6b-bf7c-c9fc19d43ca8"
        d="M344.8,381.7,217.4,509.1a255.3,255.3,0,0,1-127.2-58L60.9,421.8A254.8,254.8,0,0,1,1.6,284.4L161.2,124.8Z"
      />
      <path
        className="fddc7557-b03e-4a26-afc8-22ac2d24974e"
        d="M161.2,124.8V368.2a19,19,0,0,1-19,19H331.5a19,19,0,0,0,19-19V124.8Z"
      />
      <path
        className="bb71d4f3-3f5e-4e5e-b966-4ac8ddce3b5a"
        d="M312.5,368.2V356.8H123.2v11.4a19,19,0,0,0,19,19H331.5A19,19,0,0,1,312.5,368.2Z"
      />
      <path
        className="b0a2c8ea-d710-4511-829e-7050c2a24015"
        d="M131.2,320.4a1.9,1.9,0,0,1-2-2V302.5a1.9,1.9,0,0,1,2-2,2,2,0,0,1,2,2v15.9A2,2,0,0,1,131.2,320.4Z"
      />
      <path className="b0a2c8ea-d710-4511-829e-7050c2a24015" d="M139.1,312.5H123.2a2,2,0,1,1,0-4h15.9a2,2,0,1,1,0,4Z" />
      <path
        className="b0a2c8ea-d710-4511-829e-7050c2a24015"
        d="M370.1,261.6a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,370.1,261.6Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,370.1,248.8Z"
      />
      <path
        className="b0a2c8ea-d710-4511-829e-7050c2a24015"
        d="M375.7,361.3a2.8,2.8,0,0,1-1.2-.4L363,352.2l-11.6,8.7a2.1,2.1,0,0,1-2.4,0l-12.7-9.6a2,2,0,1,1,2.4-3.2l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2,2,0,0,1,2.4,3.2l-12.8,9.6A2.1,2.1,0,0,1,375.7,361.3Z"
      />
      <path className="e0802e9f-4ef5-4c3e-8107-dd8ed7104768" d="M256.1,165.2H190.3a2,2,0,0,1,0-4h65.8a2,2,0,0,1,0,4Z" />
      <path className="e6c2fa1e-5a27-44fa-bfc0-7451da83d92e" d="M321.9,329.4H256.1a2,2,0,0,1,0-4h65.8a2,2,0,0,1,0,4Z" />
      <path
        className="e0802e9f-4ef5-4c3e-8107-dd8ed7104768"
        d="M321.9,195.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="e0802e9f-4ef5-4c3e-8107-dd8ed7104768"
        d="M321.9,225.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="e0802e9f-4ef5-4c3e-8107-dd8ed7104768"
        d="M321.9,255.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="e0802e9f-4ef5-4c3e-8107-dd8ed7104768"
        d="M321.9,285.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="e11cd41d-34d3-44bb-8bdc-af28184a2d37"
        d="M230.4,313l-1.7-2.1a10.9,10.9,0,0,1-1.4-2.3l-1-2.6a9,9,0,0,0-9.8-5.6l-2.6.4h-2.7l-2.7-.4a9,9,0,0,0-9.8,5.6l-1,2.6a7.9,7.9,0,0,1-1.4,2.3l-1.7,2.1a8.7,8.7,0,0,0,0,11.3l1.7,2.2a6.8,6.8,0,0,1,1.4,2.3l1,2.5a9,9,0,0,0,9.8,5.7l2.7-.4h2.7l2.6.4a9,9,0,0,0,9.8-5.7l1-2.5a8.8,8.8,0,0,1,1.4-2.3l1.7-2.2a8.7,8.7,0,0,0,0-11.3Z"
      />
      <circle className="b0a2c8ea-d710-4511-829e-7050c2a24015" cx={212.5} cy={318.7} r={12} />
      <path
        className="b3741ad9-78e8-4e3e-8edd-42def143aeb4"
        d="M212.5,331.6a12.9,12.9,0,1,1,12.9-12.9A12.9,12.9,0,0,1,212.5,331.6Zm0-24a11.1,11.1,0,1,0,11.1,11.1A11.1,11.1,0,0,0,212.5,307.6Z"
      />
      <g id="ade08404-1188-4bef-9229-375409a5b149" data-name="7.1-Dashboard">
        <g id="a8f738ad-e328-4c4c-95dd-861f9e161ea9" data-name="7.1.1-Dashboard-S2">
          <g id="a6a8cb6c-9fe4-493e-b841-cc6fc2e19213" data-name="app-sidebar-nav1">
            <path
              className="b3741ad9-78e8-4e3e-8edd-42def143aeb4"
              d="M211.2,322.1l-.4-.2-3.1-3.1a.6.6,0,0,1,0-.8.5.5,0,0,1,.8,0l2.7,2.7,5.3-5.3a.6.6,0,0,1,.8,0,.5.5,0,0,1,0,.8l-5.7,5.7A.5.5,0,0,1,211.2,322.1Z"
            />
          </g>
        </g>
      </g>
      <path
        className="e6c2fa1e-5a27-44fa-bfc0-7451da83d92e"
        d="M258.3,320c4.5-3,7.6-7.3,10.8-11.5a36,36,0,0,1,5.1-5.9c1.7-1.7,4.5-4.1,6.9-2.3s2.7,5,3.4,7.5a20.1,20.1,0,0,0,4.1,8.1,8.1,8.1,0,0,0,7,3.3,6.1,6.1,0,0,0,3.3-1.4,7.9,7.9,0,0,0,1.8-3.1l.3-.6c.1-.4.2-.2,0-.1s-.2.1.1.4l1,.7a12.8,12.8,0,0,0,4,2.1,32.4,32.4,0,0,0,9.3,1,1.8,1.8,0,0,0,0-3.6,29,29,0,0,1-8.3-.9,13.7,13.7,0,0,1-3.2-1.6c-1.1-.9-2.1-1.9-3.6-1.6a3.6,3.6,0,0,0-2.5,2c-.5.8-.7,2.1-1.4,2.7s-4.6-.8-5.7-2.4-2.6-5.5-3.5-8.6-2-5.7-4.6-7.2c-5.5-3.2-11,2.6-14.2,6.4s-6.8,10.1-11.9,13.5c-1.9,1.3-.1,4.4,1.8,3.1Z"
      />
    </g>
  </svg>
)

export default ImgDocumentSignedGlitter
