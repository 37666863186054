import { SVGProps } from 'react'

const ImgCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.f398a985-b595-48c7-8749-89246ff9b959{fill:none;}.a6446d2d-0f15-4bf8-b64f-e3d397643e5d{opacity:0.15;fill:url(#f8369321-aaf1-406a-ad35-8621f6f371e7);}.b97785ae-4187-4585-8a00-28ed604ff3bf{fill:url(#befb3973-f586-41da-91da-27618b330424);}.f34378bf-aa60-4b36-adc1-e5599f5ae75e{fill:url(#e00b81cd-ef69-4fdb-93fd-b3bbaaaf90ed);}.af947f12-76a9-4ab0-bd78-f6902d1c2916{fill:#37c49f;}.f32ebe46-4597-41d0-9559-6a20e8a8db62{fill:url(#f442bcd2-0a17-47b4-9a17-60b2a7b0b323);}.ab624c0b-2c43-482e-8576-b20d7e68a4b8{fill:#fff;}.aba3a596-f154-44f1-a528-53ff67b5a22b{fill:url(#b3f1dae8-8769-487c-967b-9ef6dc596f55);}'
        }
      </style>
      <linearGradient
        id="f8369321-aaf1-406a-ad35-8621f6f371e7"
        x1={78.19}
        y1={440.57}
        x2={268.73}
        y2={250.03}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="befb3973-f586-41da-91da-27618b330424"
        x1={170.09}
        y1={9260.79}
        x2={336.25}
        y2={9426.96}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="e00b81cd-ef69-4fdb-93fd-b3bbaaaf90ed"
        x1={155.85}
        y1={9137.75}
        x2={380.35}
        y2={9362.25}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f442bcd2-0a17-47b4-9a17-60b2a7b0b323"
        x1={166.78}
        y1={9305.48}
        x2={312.37}
        y2={9451.08}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="b3f1dae8-8769-487c-967b-9ef6dc596f55"
        x1={173.2}
        y1={310.35}
        x2={210.8}
        y2={272.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="f1f8cf46-646f-4aaa-9982-0f1c1e166d30" data-name="Layer 1">
      <g id="a10a6c02-a08f-4d98-a9b8-747b702fbab3" data-name="backdrop">
        <path
          className="f398a985-b595-48c7-8749-89246ff9b959"
          d="M512,256.3c0,131.3-98.8,239.5-226,254.3a249.1,249.1,0,0,1-30,1.7A255.1,255.1,0,0,1,76.1,438.4l-2.2-2.1A255.3,255.3,0,0,1,0,256.3c0-5.3.2-10.6.5-15.9C8.7,106.5,120,.3,256,.3,397.4.3,512,115,512,256.3Z"
        />
      </g>
      <path
        className="a6446d2d-0f15-4bf8-b64f-e3d397643e5d"
        d="M407.6,388.9,286,510.6a249.1,249.1,0,0,1-30,1.7A255.1,255.1,0,0,1,76.1,438.4l-2.2-2.1A255.3,255.3,0,0,1,0,256.3c0-5.3.2-10.6.5-15.9L104.1,136.8Z"
      />
      <rect
        className="b97785ae-4187-4585-8a00-28ed604ff3bf"
        x={102.4}
        y={134.8}
        width={275.2}
        height={179.2}
        rx={6.4}
      />
      <path
        className="f34378bf-aa60-4b36-adc1-e5599f5ae75e"
        d="M403.2,211.6H140.8a6.4,6.4,0,0,0-6.4,6.4V384.4a6.4,6.4,0,0,0,6.4,6.4H403.2a6.4,6.4,0,0,0,6.4-6.4V218A6.4,6.4,0,0,0,403.2,211.6Z"
      />
      <circle className="af947f12-76a9-4ab0-bd78-f6902d1c2916" cx={172.8} cy={352.4} r={6.4} />
      <path
        className="af947f12-76a9-4ab0-bd78-f6902d1c2916"
        d="M198.4,358.8a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,198.4,358.8Z"
      />
      <circle className="af947f12-76a9-4ab0-bd78-f6902d1c2916" cx={236.8} cy={352.4} r={6.4} />
      <path
        className="af947f12-76a9-4ab0-bd78-f6902d1c2916"
        d="M262.4,358.8a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,262.4,358.8Z"
      />
      <circle className="af947f12-76a9-4ab0-bd78-f6902d1c2916" cx={288} cy={352.4} r={6.4} />
      <path
        className="af947f12-76a9-4ab0-bd78-f6902d1c2916"
        d="M326.4,358.8a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,12.8,0A6.4,6.4,0,0,1,326.4,358.8Z"
      />
      <circle className="af947f12-76a9-4ab0-bd78-f6902d1c2916" cx={352} cy={352.4} r={6.4} />
      <rect className="f32ebe46-4597-41d0-9559-6a20e8a8db62" x={102.4} y={154} width={275.2} height={44.8} />
      <path
        className="ab624c0b-2c43-482e-8576-b20d7e68a4b8"
        d="M198.4,312.8H167.6V288.4a18,18,0,0,1,18-18h30.8v24.4A18.1,18.1,0,0,1,198.4,312.8Z"
      />
      <path
        className="aba3a596-f154-44f1-a528-53ff67b5a22b"
        d="M171.6,308.8h26.8a14,14,0,0,0,14-14V274.4H185.6a14,14,0,0,0-14,14Z"
      />
    </g>
  </svg>
)

export default ImgCreditCard
