import { formatUrl } from 'lib/formatUrl'
import Link from 'next/link'

type Props = {
  title: string
  description?: string
  image: JSX.Element
  imageClasses?: string
  href: string
  isExternalUrl?: boolean
  beta?: boolean
}

const SubNavLinkWithImg = ({ title, description, image, href, imageClasses, isExternalUrl, beta }: Props) => {
  const formattedUrl = formatUrl(href)
  return (
    <Link href={formattedUrl} passHref>
      <a className="w-full sm:w-auto" target={isExternalUrl ? '_blank' : ''}>
        <div className="flex h-32 w-72 cursor-pointer rounded-xl bg-gradient-to-tr from-tella-light-pink-300 to-tella-light-pink-100">
          <div className="flex w-2/3 flex-col justify-between py-3 pl-3 text-tella-blue-300">
            <p className="text-xl line-clamp-2">
              <span className="font-semibold">{title}</span>
              {beta && (
                <span className="relative bottom-1 ml-2 rounded-full border border-[#1F51FF] px-2 text-sm text-[#1F51FF]">
                  Beta
                </span>
              )}
            </p>
            <p className="text-sm line-clamp-2">{description}</p>
          </div>
          <div className={`h-full w-1/3 ${imageClasses ?? ''}`}>{image}</div>
        </div>
      </a>
    </Link>
  )
}

export default SubNavLinkWithImg
