import { SVGProps } from 'react'

const ImgLetterEnvelopeWithCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bc156290-f378-48de-929a-0a86a2b28d59{fill:none;}.b5e9208a-17e3-4793-a5be-5606d80ee192{opacity:0.15;fill:url(#ff35b7df-8cc8-4623-b117-36ae02115763);}.bb7489ba-1a7e-4043-9771-8c5c007f9ae4{fill:url(#a0fd7161-39c8-4a26-b5ba-f0e50d6cda4a);}.e1e58ca1-24f3-4738-912e-087e5ec0aa57{fill:url(#a32edb2e-d37f-4b95-b08e-ad41f184b250);}.b9f4119c-b230-4192-aa86-e266703bd6fe{fill:url(#a37fe77c-b01a-4434-a4fc-85e8574d7e44);}.b0b34675-1ccb-4658-9a06-76d22154eaab{fill:#f7ced7;}.b0f88154-4fc9-4078-924c-0915155a1cca{fill:#37c49f;}.a302b1dd-3915-47d7-8f80-31ed35aecae7{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="ff35b7df-8cc8-4623-b117-36ae02115763"
        x1={78.87}
        y1={440.82}
        x2={279.05}
        y2={240.64}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a0fd7161-39c8-4a26-b5ba-f0e50d6cda4a"
        x1={263.18}
        y1={15488.09}
        x2={233.45}
        y2={15749.37}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="a32edb2e-d37f-4b95-b08e-ad41f184b250"
        x1={129.07}
        y1={15408.26}
        x2={340.78}
        y2={15619.98}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fcebef" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="a37fe77c-b01a-4434-a4fc-85e8574d7e44"
        x1={230.47}
        y1={15360.17}
        x2={272.87}
        y2={15533.29}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
    </defs>
    <g id="e2a7b4cf-4f28-448c-8d30-fa7defed204a" data-name="Layer 1">
      <g id="e4cb4093-5f7a-4ed3-90f1-bb3cdb0185c8" data-name="backdrop">
        <path
          className="bc156290-f378-48de-929a-0a86a2b28d59"
          d="M512,256c0,131.3-98.8,239.5-226.1,254.3A268.3,268.3,0,0,1,256,512,255.1,255.1,0,0,1,79.1,441q-4.2-3.9-8.1-8.1A255.1,255.1,0,0,1,0,256c0-4.4.1-8.8.3-13.1C7.2,107.6,119,0,256,0,397.4,0,512,114.6,512,256Z"
        />
      </g>
      <path
        className="b5e9208a-17e3-4793-a5be-5606d80ee192"
        d="M387.9,408.2l-102,102.1A268.3,268.3,0,0,1,256,512,255.1,255.1,0,0,1,79.1,441L71,432.9A255.1,255.1,0,0,1,0,256c0-4.4.1-8.8.3-13.1L140.8,102.4Z"
      />
      <polygon
        className="bb7489ba-1a7e-4043-9771-8c5c007f9ae4"
        points="121.6 230.4 256 326.4 390.4 230.4 256 134.4 121.6 230.4"
      />
      <path className="e1e58ca1-24f3-4738-912e-087e5ec0aa57" d="M332.8,102.4h-192v288H371.2V140.8Z" />
      <path
        className="b9f4119c-b230-4192-aa86-e266703bd6fe"
        d="M384,409.6H128a6.4,6.4,0,0,1-6.4-6.4V230.4l134.4,96,134.4-96V403.2A6.4,6.4,0,0,1,384,409.6Z"
      />
      <polygon
        className="b0b34675-1ccb-4658-9a06-76d22154eaab"
        points="332.8 140.8 371.2 140.8 332.8 102.4 332.8 140.8"
      />
      <circle className="b0f88154-4fc9-4078-924c-0915155a1cca" cx={256} cy={219.6} r={40} />
      <g id="fb2ca7e9-b33b-4458-9cf0-3fa28e89badb" data-name="7.1-Dashboard">
        <g id="a99fbb51-4ddb-40d2-8c4a-13fb5c09368d" data-name="7.1.1-Dashboard-S2">
          <g id="e191fbf7-eece-4058-be85-5d32058eb3a9" data-name="app-sidebar-nav1">
            <path
              className="a302b1dd-3915-47d7-8f80-31ed35aecae7"
              d="M251.6,231.6a2,2,0,0,1-1.5-.6L239,219.9a1.9,1.9,0,0,1,0-2.8,2.1,2.1,0,0,1,2.9,0l9.7,9.7,18.5-18.6a2.1,2.1,0,0,1,2.9,0,1.9,1.9,0,0,1,0,2.8l-20,20A2,2,0,0,1,251.6,231.6Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ImgLetterEnvelopeWithCheck
