import { SVGProps } from 'react'

const ImgDocumentGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b2289a49-e3f4-4fb5-8c50-645f69db300e{fill:none;}.e92321d6-faa1-4f42-ae64-89c35d9095c9{opacity:0.15;fill:url(#fd00203f-b1bf-42a0-8a76-8d3e0f5d9e78);}.fd4447a5-1d8e-4e4c-9e66-f6ec726f9891{fill:url(#b6ce3a77-1776-442d-a2a2-a6a7f360a63f);}.bf3fd717-b76f-46d1-bab2-cce5de989113{fill:url(#f60f8d00-b35f-4e56-a534-55f302ed09cf);}.a69b99a3-76f6-416e-b241-f4c517379672{fill:#37c49f;}.b910dcfd-5db1-45b6-a25f-e949bf696b2e{fill:#fff;opacity:0.9;isolation:isolate;}'
        }
      </style>
      <linearGradient
        id="fd00203f-b1bf-42a0-8a76-8d3e0f5d9e78"
        x1={72.71}
        y1={433.57}
        x2={271.35}
        y2={234.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b6ce3a77-1776-442d-a2a2-a6a7f360a63f"
        x1={163.24}
        y1={11666.55}
        x2={358.48}
        y2={11861.79}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="f60f8d00-b35f-4e56-a534-55f302ed09cf"
        x1={299.81}
        y1={11710.09}
        x2={146.48}
        y2={11556.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="ad1ed268-4b36-4019-91e2-e304c06b1021" data-name="Layer 1">
      <g id="abab6b41-0453-4a45-a610-525dfdc45cde" data-name="backdrop">
        <path
          className="b2289a49-e3f4-4fb5-8c50-645f69db300e"
          d="M512,256c0,141.4-114.6,256-256,256a258.9,258.9,0,0,1-38.6-2.9,255.3,255.3,0,0,1-127.2-58,270.2,270.2,0,0,1-29.3-29.3A254.8,254.8,0,0,1,1.6,284.4,242.1,242.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="e92321d6-faa1-4f42-ae64-89c35d9095c9"
        d="M344.8,381.7,217.4,509.1a255.3,255.3,0,0,1-127.2-58L60.9,421.8A254.8,254.8,0,0,1,1.6,284.4L161.2,124.8Z"
      />
      <path
        className="fd4447a5-1d8e-4e4c-9e66-f6ec726f9891"
        d="M161.2,124.8V368.2a19,19,0,0,1-19,19H331.5a19,19,0,0,0,19-19V124.8Z"
      />
      <path
        className="bf3fd717-b76f-46d1-bab2-cce5de989113"
        d="M312.5,368.2V356.8H123.2v11.4a19,19,0,0,0,19,19H331.5A19,19,0,0,1,312.5,368.2Z"
      />
      <path
        className="a69b99a3-76f6-416e-b241-f4c517379672"
        d="M136.1,250.8h-6v-6a2,2,0,0,0-4,0v6h-6a2,2,0,1,0,0,4h6v6a2,2,0,0,0,4,0v-6h6a2,2,0,0,0,0-4Z"
      />
      <path
        className="a69b99a3-76f6-416e-b241-f4c517379672"
        d="M370.1,164.7a8.4,8.4,0,1,1,8.4-8.4A8.4,8.4,0,0,1,370.1,164.7Zm0-12.7a4.4,4.4,0,1,0,4.4,4.3A4.4,4.4,0,0,0,370.1,152Z"
      />
      <path
        className="a69b99a3-76f6-416e-b241-f4c517379672"
        d="M358.2,345.7a2.8,2.8,0,0,1-1.2-.4l-11.5-8.7-11.6,8.7a2.1,2.1,0,0,1-2.4,0l-12.7-9.6a2,2,0,1,1,2.4-3.2l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2,2,0,0,1,2.4,3.2l-12.8,9.6A2.1,2.1,0,0,1,358.2,345.7Z"
      />
      <path className="b910dcfd-5db1-45b6-a25f-e949bf696b2e" d="M256.1,165.2H190.3a2,2,0,0,1,0-4h65.8a2,2,0,0,1,0,4Z" />
      <path
        className="b910dcfd-5db1-45b6-a25f-e949bf696b2e"
        d="M321.9,195.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="b910dcfd-5db1-45b6-a25f-e949bf696b2e"
        d="M321.9,225.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="b910dcfd-5db1-45b6-a25f-e949bf696b2e"
        d="M321.9,255.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="b910dcfd-5db1-45b6-a25f-e949bf696b2e"
        d="M321.9,285.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="b910dcfd-5db1-45b6-a25f-e949bf696b2e"
        d="M321.9,315.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
    </g>
  </svg>
)

export default ImgDocumentGlitter
