import { SVGProps } from 'react'

const ImgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a374d007-6c26-4849-899a-79f2c567954f{fill:none;}.b2ec8c90-b49a-4b58-a49e-0f9ea2d1aba5{opacity:0.15;fill:url(#aeaecbc0-8d48-4476-840d-f5e7eb064db2);}.b24834fd-270f-4b23-8c17-34d6d836fb89{fill:url(#f2b56394-ad22-42fa-a90a-2d9ed9f6d34f);}.bfed105a-1134-42bb-8e90-ebc9ce5ad7cc{fill:url(#b6d2e286-9a3e-47fb-b0dd-ef087a0e4cbb);}.ad706c85-d420-4a15-b200-5f3d27ded1e4{fill:#fff;opacity:0.9;isolation:isolate;}'
        }
      </style>
      <linearGradient
        id="aeaecbc0-8d48-4476-840d-f5e7eb064db2"
        x1={72.71}
        y1={433.57}
        x2={271.35}
        y2={234.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f2b56394-ad22-42fa-a90a-2d9ed9f6d34f"
        x1={163.24}
        y1={11666.55}
        x2={358.48}
        y2={11861.79}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="b6d2e286-9a3e-47fb-b0dd-ef087a0e4cbb"
        x1={299.81}
        y1={11710.09}
        x2={146.48}
        y2={11556.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
    </defs>
    <g id="bee86034-7749-4a93-9558-4b335d4f3fa1" data-name="Layer 1">
      <g id="e807b3c3-02b0-4869-a037-7362f75e0acc" data-name="backdrop">
        <path
          className="a374d007-6c26-4849-899a-79f2c567954f"
          d="M512,256c0,141.4-114.6,256-256,256a258.9,258.9,0,0,1-38.6-2.9,255.3,255.3,0,0,1-127.2-58,270.2,270.2,0,0,1-29.3-29.3A254.8,254.8,0,0,1,1.6,284.4,242.1,242.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b2ec8c90-b49a-4b58-a49e-0f9ea2d1aba5"
        d="M344.8,381.7,217.4,509.1a255.3,255.3,0,0,1-127.2-58L60.9,421.8A254.8,254.8,0,0,1,1.6,284.4L161.2,124.8Z"
      />
      <path
        className="b24834fd-270f-4b23-8c17-34d6d836fb89"
        d="M161.2,124.8V368.2a19,19,0,0,1-19,19H331.5a19,19,0,0,0,19-19V124.8Z"
      />
      <path
        className="bfed105a-1134-42bb-8e90-ebc9ce5ad7cc"
        d="M312.5,368.2V356.8H123.2v11.4a19,19,0,0,0,19,19H331.5A19,19,0,0,1,312.5,368.2Z"
      />
      <path className="ad706c85-d420-4a15-b200-5f3d27ded1e4" d="M256.1,165.2H190.3a2,2,0,0,1,0-4h65.8a2,2,0,0,1,0,4Z" />
      <path
        className="ad706c85-d420-4a15-b200-5f3d27ded1e4"
        d="M321.9,195.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="ad706c85-d420-4a15-b200-5f3d27ded1e4"
        d="M321.9,225.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="ad706c85-d420-4a15-b200-5f3d27ded1e4"
        d="M321.9,255.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="ad706c85-d420-4a15-b200-5f3d27ded1e4"
        d="M321.9,285.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="ad706c85-d420-4a15-b200-5f3d27ded1e4"
        d="M321.9,315.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
    </g>
  </svg>
)

export default ImgDocument
