import { SVGProps } from 'react'

const ImgBoxWithArrowpointingUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.fadb6dfd-2185-4dc6-b557-825a9e441e25{isolation:isolate;}.a92cb686-3789-451d-9e34-938709c36fb4{fill:none;}.e5e9b636-1e0d-493e-8a2e-f4e86b572df9{opacity:0.15;mix-blend-mode:multiply;fill:url(#fb313a0f-9ef6-4b46-a66c-c63e0dbab4f9);}.aed913f2-96f3-4ce7-b916-2a45f3397e55{fill:#fff;}.bd7a1bea-270c-4aed-b539-d3660e1cd061{fill:url(#f77fbd05-0ce6-4f7d-80c3-a85cff8ce9c6);}.bfc5d517-882f-430e-b22e-c796deb169ad{fill:url(#f4f5eb08-7706-4fd1-b7f4-a6cdfdea7ace);}.b1ae23fe-2559-4527-a9fc-218470283e91{fill:url(#a077efd6-819b-4af2-8b2b-27e9364790d9);}.aa72e2e1-3d19-4c57-a0f8-44d366525092{fill:url(#be3ed9a6-85ac-4f32-8227-f57e80130aa8);}.b1adf015-e00f-460d-bb2e-7cacab93c298{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="fb313a0f-9ef6-4b46-a66c-c63e0dbab4f9"
        x1={-517.03}
        y1={250.05}
        x2={-441.86}
        y2={250.05}
        gradientTransform="matrix(2.58, -2.58, 4.5, 4.5, 304.25, -1998.16)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f77fbd05-0ce6-4f7d-80c3-a85cff8ce9c6"
        x1={134.2}
        y1={-4018.38}
        x2={236.26}
        y2={-4048.45}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f4f5eb08-7706-4fd1-b7f4-a6cdfdea7ace"
        x1={231.61}
        y1={-4090.48}
        x2={389.08}
        y2={-3933}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="a077efd6-819b-4af2-8b2b-27e9364790d9"
        x1={110.54}
        y1={-4009.95}
        x2={198.4}
        y2={-3922.08}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="be3ed9a6-85ac-4f32-8227-f57e80130aa8"
        x1={250.57}
        y1={-4023.51}
        x2={373.44}
        y2={-3900.64}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
    </defs>
    <g className="fadb6dfd-2185-4dc6-b557-825a9e441e25">
      <g id="fcf280de-8779-420e-afca-f77bbb4c0e92" data-name="Layer 1">
        <g id="b806f6da-3516-4aaf-8ecb-0e7e9650ae84" data-name="backdrop">
          <circle className="a92cb686-3789-451d-9e34-938709c36fb4" cx={256} cy={256} r={256} />
        </g>
        <polygon
          className="e5e9b636-1e0d-493e-8a2e-f4e86b572df9"
          points="409.6 383.2 216 576.8 -18.8 342 134.4 188.8 377.6 380.8 409.6 383.2"
        />
        <path
          className="aed913f2-96f3-4ce7-b916-2a45f3397e55"
          d="M298.9,117.1,260.5,78.7l-.6-.6h-.2l-1.5-.8a.1.1,0,0,1-.1-.1l-.9-.2h-1a4.4,4.4,0,0,0-1.6.2h-.2l-.8.3h-.1l-.7.4h-.2l-.7.5h0a.8.8,0,0,0-.4.4l-38.4,38.4a6.3,6.3,0,0,0,0,9,6.3,6.3,0,0,0,9,0l27.5-27.4V336a6.4,6.4,0,1,0,12.8,0V98.7l27.5,27.4a6.4,6.4,0,0,0,9-9Z"
        />
        <rect className="bd7a1bea-270c-4aed-b539-d3660e1cd061" x={134.4} y={188.8} width={73.6} height={192} />
        <rect className="bfc5d517-882f-430e-b22e-c796deb169ad" x={208} y={188.8} width={169.6} height={192} />
        <polygon
          className="b1ae23fe-2559-4527-a9fc-218470283e91"
          points="176 252.8 102.4 252.8 134.4 188.8 208 188.8 176 252.8"
        />
        <polygon
          className="aa72e2e1-3d19-4c57-a0f8-44d366525092"
          points="240 252.8 409.6 252.8 377.6 188.8 208 188.8 240 252.8"
        />
        <path
          className="aed913f2-96f3-4ce7-b916-2a45f3397e55"
          d="M408,384H104a1.6,1.6,0,0,1,0-3.2H408a1.6,1.6,0,1,1,0,3.2Z"
        />
        <rect className="b1adf015-e00f-460d-bb2e-7cacab93c298" x={310.4} y={348.8} width={44.8} height={12.8} />
      </g>
    </g>
  </svg>
)

export default ImgBoxWithArrowpointingUp
