const ImgSmileyInBetweenTwoHands = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="e9202721-3e31-46f2-b625-c038917c5739"
          x1={-377.49}
          y1={427.01}
          x2={-258.88}
          y2={427.01}
          gradientTransform="matrix(1.32 -1.32 1.72 1.72 -118.4 -756.31)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.12} stopColor="#10069f" stopOpacity={0.06} />
          <stop offset={0.32} stopColor="#10069f" stopOpacity={0.21} />
          <stop offset={0.56} stopColor="#10069f" stopOpacity={0.46} />
          <stop offset={0.85} stopColor="#10069f" stopOpacity={0.8} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="f31c88aa-c366-42fe-9b96-54911f289317"
          x1={-5986.03}
          y1={-750.44}
          x2={-5947.97}
          y2={-691.16}
          gradientTransform="rotate(-102.1 -2408.457 -2695.87)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="edfea293-e948-4092-9ee7-2b15ac43c91d"
          x1={-5840.87}
          y1={-8641.01}
          x2={-5878.93}
          y2={-8700.29}
          gradientTransform="rotate(77.9 -8206.225 -476.33)"
          xlinkHref="#f31c88aa-c366-42fe-9b96-54911f289317"
        />
        <linearGradient
          id="aeedbce4-d216-4397-b9d1-e0b1e5589a57"
          x1={270.58}
          y1={161.98}
          x2={354.82}
          y2={77.73}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5851bc" />
          <stop offset={1} stopColor="#281fa9" />
        </linearGradient>
        <linearGradient
          id="a09ccf98-50a3-40c7-a83f-e092bf07050c"
          x1={185.59}
          y1={405.86}
          x2={269.83}
          y2={321.61}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5851bc" />
          <stop offset={1} stopColor="#4038b2" />
        </linearGradient>
        <radialGradient
          id="aa2d19a8-eb50-4b54-bbbf-1adeceeaa75f"
          cx={170.82}
          cy={-3919.31}
          r={229.48}
          gradientTransform="matrix(1 0 0 -1 0 -3744.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fdf0f3" />
          <stop offset={1} stopColor="#fefafb" />
        </radialGradient>
        <style>{'.bbefa096-25e2-4419-86b9-ac50d1df392b{fill:#ef426f}'}</style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="b0ba81b4-7a7f-4c61-a66b-81ebb6161cc6" data-name="Layer 1">
          <circle
            cx={256}
            cy={256}
            r={256}
            style={{
              fill: 'none',
            }}
            id="a5f0a90b-fa2f-41b0-ade3-71939b61e70a"
            data-name="backdrop"
          />
          <path
            style={{
              opacity: 0.15,
              mixBlendMode: 'multiply',
              fill: 'url(#e9202721-3e31-46f2-b625-c038917c5739)',
            }}
            d="M345.2 392.2 188.3 549.1 42.5 403.3l61.7-61.7 112.3 36.5 128.7 14.1z"
          />
          <path
            d="M204.8 409.6 332.2 383a2.7 2.7 0 0 0 2-3.3c0-.2-.1-.3-.2-.5l-.7-1.8a16.9 16.9 0 0 0-19.7-9.4l-97.1 10.1Z"
            style={{
              fill: 'url(#f31c88aa-c366-42fe-9b96-54911f289317)',
            }}
          />
          <path
            d="M307.2 102.4 179.8 129a2.7 2.7 0 0 0-2 3.3c0 .2.1.3.2.5l.7 1.8a16.9 16.9 0 0 0 19.7 9.4l97.1-10.1Z"
            style={{
              fill: 'url(#edfea293-e948-4092-9ee7-2b15ac43c91d)',
            }}
          />
          <path
            d="M256 153.6A102.4 102.4 0 1 0 358.4 256 102.3 102.3 0 0 0 256 153.6Z"
            style={{
              fill: 'url(#aa2d19a8-eb50-4b54-bbbf-1adeceeaa75f)',
            }}
          />
          <path
            className="bbefa096-25e2-4419-86b9-ac50d1df392b"
            d="M288 224a12.8 12.8 0 0 1 12.8 12.8 3.2 3.2 0 1 1-6.4 0 6.4 6.4 0 1 0-12.8 0 3.2 3.2 0 0 1-6.4 0A12.8 12.8 0 0 1 288 224ZM224 224a12.8 12.8 0 0 1 12.8 12.8 3.2 3.2 0 0 1-6.4 0 6.4 6.4 0 0 0-12.8 0 3.2 3.2 0 1 1-6.4 0A12.8 12.8 0 0 1 224 224ZM306.7 283.2a60.8 60.8 0 0 1-101.4 0 3.3 3.3 0 0 1 .9-4.4 3.2 3.2 0 0 1 4.5.9 54.4 54.4 0 0 0 75.4 15.2 55 55 0 0 0 15.2-15.2 3.2 3.2 0 0 1 4.5-.9 3.3 3.3 0 0 1 .9 4.4Z"
          />
          <path
            d="M403.2 119h-.6l-28.2 2.6a66 66 0 0 1-19.2-3.2L320 105.6a70.5 70.5 0 0 0-12.8-3.2c-3.2 0-136.3 15.4-136.3 15.4-1.8.4-4.5.6-4.5 3.8s7.8 13.8 16 12.8l113.1-.5 19.9 14.8-14.1.3c-13.8-3.6-28.4-.9-29.2 7.3l-.3 2.6a3.1 3.1 0 0 0 2.7 3.5s74.3 10.4 87.1 10.4h41.6a6.4 6.4 0 0 0 6.4-6.4v-41a6.4 6.4 0 0 0-6.4-6.4Z"
            style={{
              fill: 'url(#aeedbce4-d216-4397-b9d1-e0b1e5589a57)',
            }}
          />
          <path
            d="m329.6 377.6-113.1.5-19.9-14.8 14.1-.3c13.8 3.6 28.4.9 29.2-7.3l.3-2.6a3.1 3.1 0 0 0-2.7-3.5s-74.3-10.4-87.1-10.4h-41.6a6.4 6.4 0 0 0-6.4 6.4v41a6.4 6.4 0 0 0 6.4 6.4h.6l28.2-2.6a66 66 0 0 1 19.2 3.2l35.2 12.8a70.5 70.5 0 0 0 12.8 3.2c3.2 0 136.3-15.4 136.3-15.4 1.8-.4 4.5-.6 4.5-3.8s-7.8-13.8-16-12.8Z"
            style={{
              fill: 'url(#a09ccf98-50a3-40c7-a83f-e092bf07050c)',
            }}
          />
        </g>
      </g>
    </svg>
  )
}

export default ImgSmileyInBetweenTwoHands
