import { SVGProps } from 'react'

const ImgWallet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b0aaf4d8-0253-4446-a0e3-954c23fe91b6{fill:none;}.bc48d1d1-5833-4432-919d-226ef286f3f1{opacity:0.15;fill:url(#bf6d0c68-057f-45a1-8a6f-7436591ba511);}.af00eff6-665d-4410-ae16-0b6fa27532c2{fill:url(#ba69ebc9-3c88-4454-bf4d-a954f72ee28d);}.b939ffee-7494-414e-8efc-954f3612914b{fill:url(#e0c6af46-31d7-4e7f-9984-efb7fa88a966);}.b5b8594c-d68f-4f2b-8826-7172a9a650ab{fill:url(#b0b19eca-7e3c-4a27-9ea2-2335d6dda469);}.e2a80066-2f03-4b9b-b5fa-a80e38d1ec60{fill:url(#befd544c-4096-47e2-bb27-521de0e9d421);}.a85d7c72-6e27-4865-8c66-0e313f7b1cf5{fill:url(#a6ee4bf5-b87d-4b9d-b325-fe7e939720a7);}.efa864db-f6bc-4b76-a01f-ff29cb017225{fill:#fff;}.f7790c32-6edd-4204-8d0f-dde50835137d{fill:url(#a44b44ab-1be0-4e66-b8f7-33252353eacd);}.a5217532-e395-4548-9b22-804de9c2ba6b{fill:url(#a445d2df-d62c-467f-9826-cc1f6b4b4c6c);}'
        }
      </style>
      <linearGradient
        id="bf6d0c68-057f-45a1-8a6f-7436591ba511"
        x1={79.54}
        y1={440.2}
        x2={275.67}
        y2={244.07}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ba69ebc9-3c88-4454-bf4d-a954f72ee28d"
        x1={249.6}
        y1={9227.38}
        x2={249.6}
        y2={9416.84}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e0c6af46-31d7-4e7f-9984-efb7fa88a966"
        x1={227.82}
        y1={9254.09}
        x2={216.51}
        y2={9425.13}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="b0b19eca-7e3c-4a27-9ea2-2335d6dda469"
        x1={219.04}
        y1={9235.4}
        x2={373.12}
        y2={9447.43}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="befd544c-4096-47e2-bb27-521de0e9d421"
        x1={405.92}
        y1={9281.84}
        x2={437.44}
        y2={9292.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a6ee4bf5-b87d-4b9d-b325-fe7e939720a7"
        x1={133.08}
        y1={9162.01}
        x2={398.47}
        y2={9427.41}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a44b44ab-1be0-4e66-b8f7-33252353eacd"
        x1={337.49}
        y1={9237.87}
        x2={400.84}
        y2={9301.23}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="a445d2df-d62c-467f-9826-cc1f6b4b4c6c"
        x1={393.4}
        y1={9249.81}
        x2={363.71}
        y2={9268.19}
        xlinkHref="#e0c6af46-31d7-4e7f-9984-efb7fa88a966"
      />
    </defs>
    <g id="f786167f-5a01-435e-9244-8f024fa1ba14" data-name="Layer 1">
      <g id="a9d0584a-d5ef-404d-98f4-cc534a256b0c" data-name="backdrop">
        <path
          className="b0aaf4d8-0253-4446-a0e3-954c23fe91b6"
          d="M512,256c0,140.4-113.1,254.5-253.2,256H256A254.8,254.8,0,0,1,91.5,452.1a248.3,248.3,0,0,1-31.6-31.6A254.9,254.9,0,0,1,.3,268.4C.1,264.3,0,260.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="bc48d1d1-5833-4432-919d-226ef286f3f1"
        d="M401.2,369.6,258.8,512H256A254.8,254.8,0,0,1,91.5,452.1L59.9,420.5A254.9,254.9,0,0,1,.3,268.4L121.6,147.2Z"
      />
      <rect className="af00eff6-665d-4410-ae16-0b6fa27532c2" x={121.6} y={147.2} width={256} height={224} />
      <rect className="b939ffee-7494-414e-8efc-954f3612914b" x={147.2} y={128} width={153.6} height={230.4} />
      <path
        className="b5b8594c-d68f-4f2b-8826-7172a9a650ab"
        d="M115.2,166.4a6.4,6.4,0,0,0-6.4,6.4V377.6a6.4,6.4,0,0,1,6.4-6.4H396.8a6.4,6.4,0,0,0,6.4-6.4v-192a6.4,6.4,0,0,0-6.4-6.4Z"
      />
      <path
        className="e2a80066-2f03-4b9b-b5fa-a80e38d1ec60"
        d="M409.6,236.8h-6.4v64h6.4a6.4,6.4,0,0,1,6.4,6.4v-64A6.4,6.4,0,0,0,409.6,236.8Z"
      />
      <path
        className="a85d7c72-6e27-4865-8c66-0e313f7b1cf5"
        d="M384,179.2H115.2a6.4,6.4,0,0,1-6.4-6.4V377.6a6.4,6.4,0,0,0,6.4,6.4H384a6.4,6.4,0,0,0,6.4-6.4v-192A6.4,6.4,0,0,0,384,179.2ZM355.2,294.4A12.8,12.8,0,1,1,368,281.6,12.8,12.8,0,0,1,355.2,294.4Z"
      />
      <path className="efa864db-f6bc-4b76-a01f-ff29cb017225" d="M376,371.6H123.2a2,2,0,1,1,0-4H376a2,2,0,0,1,0,4Z" />
      <path
        className="f7790c32-6edd-4204-8d0f-dde50835137d"
        d="M409.6,249.6H339.2a12.8,12.8,0,0,0-12.8,12.8v38.4a12.8,12.8,0,0,0,12.8,12.8h70.4a6.4,6.4,0,0,0,6.4-6.4v-64A6.4,6.4,0,0,1,409.6,249.6Z"
      />
      <circle className="a5217532-e395-4548-9b22-804de9c2ba6b" cx={355.2} cy={281.6} r={12.8} />
    </g>
  </svg>
)

export default ImgWallet
