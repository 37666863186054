import { SVGProps } from 'react'

const ImgHandWithTwoCoinsGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a58d1772-ed3f-440e-9ecf-1495b1adaeef{fill:url(#a1d1c41e-257f-49d0-90e9-7e6f15832836);}.f4d13a4b-a1cb-4265-8725-3efbb6ea622a{fill:none;}.bb7b8c93-c430-4cb8-be7d-3dd7a230d846{fill:url(#a067ff4a-787a-4afb-b9ee-4cac47bca0f1);}.b70d963a-3806-4efe-a2d7-c1460a8da367{fill:url(#aa532528-0980-4cd4-8c1f-66de74528b0f);}.ab79a93e-4388-4e79-9f68-65dff39a8fe0{opacity:0.15;fill:url(#a1c45ef8-f5e9-4615-9d8f-5e0dd539d7c8);}.a1066c5d-1ab1-42d0-8a43-e29090ff6bdc{fill:url(#afc0cd8d-7381-4019-8ad3-7a9ae3a69245);}.b41d721f-a336-4f86-a811-be923a1ad44a{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a1d1c41e-257f-49d0-90e9-7e6f15832836"
        x1={226.96}
        y1={14214.16}
        x2={265.84}
        y2={14253.03}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="a067ff4a-787a-4afb-b9ee-4cac47bca0f1"
        x1={347.97}
        y1={14179.96}
        x2={389.47}
        y2={14221.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="aa532528-0980-4cd4-8c1f-66de74528b0f"
        x1={188.96}
        y1={244.41}
        x2={314.11}
        y2={369.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="a1c45ef8-f5e9-4615-9d8f-5e0dd539d7c8"
        x1={90.71}
        y1={452.62}
        x2={343.66}
        y2={199.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="afc0cd8d-7381-4019-8ad3-7a9ae3a69245"
        x1={284.55}
        y1={195.45}
        x2={323.45}
        y2={156.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
    </defs>
    <g id="a1728b53-4b74-4e74-85bd-f03e9c3ad80a" data-name="Layer 1">
      <path
        className="a58d1772-ed3f-440e-9ecf-1495b1adaeef"
        d="M246.4,251.5A27.5,27.5,0,1,1,273.9,224,27.5,27.5,0,0,1,246.4,251.5Z"
      />
      <g id="bf65b289-2e6b-4d9a-8cba-3e7a44f0b38b" data-name="backdrop">
        <path
          className="f4d13a4b-a1cb-4265-8725-3efbb6ea622a"
          d="M512,256c0,141.4-114.6,256-256,256A254.5,254.5,0,0,1,79.9,441.8c-3.3-3.1-6.6-6.4-9.7-9.7A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="bb7b8c93-c430-4cb8-be7d-3dd7a230d846"
        d="M300.8,300.8s50.8-47.6,57.6-54.4a29.3,29.3,0,0,1,22.4-9.6c3.8,0,5.7,2.7,3.2,6.4l-57.6,80Z"
      />
      <path
        className="b70d963a-3806-4efe-a2d7-c1460a8da367"
        d="M400,243.2c-9.6,0-12.8,3.2-22.4,9.6l-76.8,48H231.2a32.8,32.8,0,0,1,17.7-5h29.5a22.4,22.4,0,0,0,22.4-22.4h0a3.2,3.2,0,0,0-3.2-3.2H224.1c-19.8,0-25.7,6.4-64.1,25.6L108.8,315a22,22,0,0,0-6.4,15.5v40.7A12.8,12.8,0,0,0,115.2,384L177,354.7a25.5,25.5,0,0,1,11.4-2.7h62.9a38.8,38.8,0,0,0,15.1-3.1l60-25.7,76.8-64c3.2-3.2,6.4-6.4,6.4-9.6S409.6,243.2,400,243.2Z"
      />
      <path
        className="ab79a93e-4388-4e79-9f68-65dff39a8fe0"
        d="M403.3,259.3,166.6,496a256.6,256.6,0,0,1-86.7-54.2l-9.7-9.7a256.4,256.4,0,0,1-33.8-44.4l72.5-72.6,191.9-14.3Z"
      />
      <circle className="a1066c5d-1ab1-42d0-8a43-e29090ff6bdc" cx={304} cy={176} r={27.5} />
      <path
        className="b41d721f-a336-4f86-a811-be923a1ad44a"
        d="M227.8,137.8h-6.4v-6.4a1.6,1.6,0,0,0-1.6-1.6,1.6,1.6,0,0,0-1.5,1.6v6.4h-6.5a1.6,1.6,0,0,0,0,3.2h6.5v6.4a1.6,1.6,0,0,0,1.5,1.6,1.6,1.6,0,0,0,1.6-1.6V141h6.4a1.6,1.6,0,1,0,0-3.2Z"
      />
      <path
        className="b41d721f-a336-4f86-a811-be923a1ad44a"
        d="M211.9,329.6a1.4,1.4,0,0,1-.9-.3l-11.9-8.9-11.8,8.9a1.7,1.7,0,0,1-1.9,0l-12.8-9.6a1.6,1.6,0,1,1,1.9-2.6l11.8,8.9,11.9-8.9a1.7,1.7,0,0,1,1.9,0l11.8,8.9,11.9-8.9a1.6,1.6,0,0,1,2.2.3,1.7,1.7,0,0,1-.3,2.3l-12.8,9.6A1.7,1.7,0,0,1,211.9,329.6Z"
      />
      <path
        className="b41d721f-a336-4f86-a811-be923a1ad44a"
        d="M392,171.2a8,8,0,1,1,8-8A8,8,0,0,1,392,171.2Zm0-12.8a4.8,4.8,0,1,0,4.8,4.8A4.9,4.9,0,0,0,392,158.4Z"
      />
    </g>
  </svg>
)

export default ImgHandWithTwoCoinsGlitter
