import { SVGProps } from 'react'

const ImgPaperWithPercentSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.f61164c7-bdb5-491a-8301-62845cfaf3c3{fill:none;}.b5501318-b541-4128-b3c7-d5e078984f64{opacity:0.15;fill:url(#bea091f4-3a98-4b90-84da-764c732210d6);}.f4ac3cc3-5105-4633-a5c4-7b01b372fb74{fill:url(#fcc5f0a0-aba2-430e-964a-cdcc630862b3);}.a746c198-b028-422a-8aac-2762c68b15eb{fill:url(#b71e606e-0557-4e73-8c69-de6066312c63);}.ead90e2c-6147-43b8-a4c0-e7fcadbb330e{fill:url(#b0db2454-b44f-45e4-9247-a19cab12e9da);}.ea36894a-5d84-4d9c-864c-884d4af587b1{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="bea091f4-3a98-4b90-84da-764c732210d6"
        x1={76.66}
        y1={438.64}
        x2={264.35}
        y2={250.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="fcc5f0a0-aba2-430e-964a-cdcc630862b3"
        x1={252.1}
        y1={11729.72}
        x2={415.09}
        y2={11892.71}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="b71e606e-0557-4e73-8c69-de6066312c63"
        x1={252.35}
        y1={11746.75}
        x2={383.88}
        y2={11878.27}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b0db2454-b44f-45e4-9247-a19cab12e9da"
        x1={337.21}
        y1={11851.58}
        x2={113.21}
        y2={11627.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
    </defs>
    <g id="bfa58854-2d2c-4f2b-b7b4-c338a690dd9d" data-name="Layer 1">
      <g id="fe85d320-7814-446c-87e4-0469b3d249c3" data-name="backdrop">
        <path
          className="f61164c7-bdb5-491a-8301-62845cfaf3c3"
          d="M512,256c0,141.4-114.6,256-256,256-7.8,0-15.5-.4-23.1-1A254.7,254.7,0,0,1,78.3,440.2c-2.2-2.1-4.4-4.3-6.5-6.5A255.4,255.4,0,0,1,1.6,285.1,279.1,279.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b5501318-b541-4128-b3c7-d5e078984f64"
        d="M378.6,365.2,232.9,511A254.7,254.7,0,0,1,78.3,440.2l-6.5-6.5A255.4,255.4,0,0,1,1.6,285.1l132.1-132Z"
      />
      <path
        className="f4ac3cc3-5105-4633-a5c4-7b01b372fb74"
        d="M204.8,115.2V352a19.2,19.2,0,0,1-19.2,19.2H364.8A19.2,19.2,0,0,0,384,352h0V115.2l-12.8-12.8-12.8,12.8-12.8-12.8-12.8,12.8L320,102.4l-12.8,12.8-12.8-12.8-12.8,12.8-12.8-12.8L256,115.2l-12.8-12.8-12.8,12.8-12.8-12.8Z"
      />
      <path
        className="a746c198-b028-422a-8aac-2762c68b15eb"
        d="M345.6,352V166.4a19.2,19.2,0,0,0-19.2-19.2H147.2a19.2,19.2,0,0,1,19.2,19.2V352a19.2,19.2,0,0,0,19.2,19.2H364.8A19.2,19.2,0,0,1,345.6,352Z"
      />
      <path
        className="ead90e2c-6147-43b8-a4c0-e7fcadbb330e"
        d="M307.2,166.4a19.2,19.2,0,0,1,19.2-19.2H147.2A19.2,19.2,0,0,0,128,166.4V396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8L192,409.6l12.8-12.8,12.8,12.8,12.8-12.8,12.8,12.8L256,396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8Z"
      />
      <path
        className="ea36894a-5d84-4d9c-864c-884d4af587b1"
        d="M192,299.6a2,2,0,0,1-1.4-.6,1.9,1.9,0,0,1,0-2.8L241.8,245a2,2,0,0,1,2.8,2.8L193.4,299A2,2,0,0,1,192,299.6Z"
      />
      <path
        className="ea36894a-5d84-4d9c-864c-884d4af587b1"
        d="M198.4,261.2a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,198.4,261.2Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,198.4,248.4Z"
      />
      <path
        className="ea36894a-5d84-4d9c-864c-884d4af587b1"
        d="M236.8,299.6a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,236.8,299.6Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,236.8,286.8Z"
      />
    </g>
  </svg>
)

export default ImgPaperWithPercentSymbol
