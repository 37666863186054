import { SVGProps } from 'react'

const ImgTwoPeopleGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a291e13f-08c6-42db-ad05-aed2b27dbed4{fill:none;}.a15433c4-9011-4f80-8b7e-98351e0b62fb{opacity:0.2;fill:url(#e7d2d2c1-a3a6-464f-8be3-9e4db772343f);}.f94b6c17-2fc2-4f5c-89ff-21f10ea89286{fill:url(#f7926776-6d9b-40df-bd54-62668015a070);}.f60840b9-b90c-4029-ac8b-e7c0e0c3ed56{fill:url(#f729b510-18d3-4ba1-8188-1d2849cbf7cf);}.fd077259-99ac-4d86-b4a0-c5d5c246079c{fill:url(#e12a3e82-9aa0-4335-99db-ecb07be81f02);}.a4308151-001b-4d20-8ec3-dac3a4761e9d{fill:url(#f8ec0cbb-3eae-4c87-ae3d-abb3c20814a6);}.ba3a0893-d35a-4c69-9483-f681b534d2dd{fill:#fff;}.a02485a5-09d0-4b72-896a-faf54a1c7b49{fill:url(#b977c742-0c91-4b15-b4a9-73c7d05244b8);}.a1cc26e5-e265-49ea-8153-5b083c527f9b{fill:url(#b8bb37d2-5f58-4a0d-a9d4-4ff83a980d13);}.a83a3837-77df-44a2-98ee-4ec5e3de69b7{fill:url(#b83efc1a-34db-4fb1-a775-d72bb9050b15);}.b1c0dd36-89da-4553-a95b-99fbbe133bb7{fill:url(#b823c767-2fe6-49a4-9deb-4784d2b31d8b);}.a3fb69b2-abcc-493c-989d-abfe8d04acb5{fill:url(#fce33e18-161e-4269-839b-8293896320ae);}.b3a0144c-0dd1-445c-8295-ea887efb8def{fill:url(#b91ba17a-f9c8-44ad-97d6-ffda318a9f6a);}.f4039386-ba42-4481-83cf-960828fe865d{fill:url(#a1e97ef8-ddc6-4ddb-9458-5cc24414d238);}.add98043-e15c-403d-92bc-4a3793b76f43{fill:url(#f457d322-24ad-4a49-9097-edac39d1cb93);}.e0fc6379-bf7e-4a80-9a2f-226987e0c775{fill:url(#a3c8745e-2db9-4688-8595-337308c60773);}.bd571f79-943e-4b84-935a-41aca3292b5b{fill:url(#afb571b9-6650-429a-9d78-868fe2f2eab0);}.e9323024-1b42-4357-909a-f6a6c5789078{fill:url(#f86010c2-bbad-49a2-9324-1b244ee6a0b4);}.b8c42c78-742c-4638-8add-ecab8f028e11{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="e7d2d2c1-a3a6-464f-8be3-9e4db772343f"
        x1={100.88}
        y1={461.63}
        x2={331.33}
        y2={231.18}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f7926776-6d9b-40df-bd54-62668015a070"
        x1={149.49}
        y1={12990.08}
        x2={275.97}
        y2={13116.56}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="f729b510-18d3-4ba1-8188-1d2849cbf7cf"
        x1={263.94}
        y1={13034.08}
        x2={204.65}
        y2={13051.35}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f1557d" />
        <stop offset={1} stopColor="#f47b9a" />
      </linearGradient>
      <linearGradient
        id="e12a3e82-9aa0-4335-99db-ecb07be81f02"
        x1={252.18}
        y1={12977.17}
        x2={377.91}
        y2={13102.9}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f8ec0cbb-3eae-4c87-ae3d-abb3c20814a6"
        x1={286.32}
        y1={12986.72}
        x2={381.14}
        y2={13081.53}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="b977c742-0c91-4b15-b4a9-73c7d05244b8"
        x1={282.35}
        y1={13105.01}
        x2={313.65}
        y2={13136.3}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="b8bb37d2-5f58-4a0d-a9d4-4ff83a980d13"
        x1={159.28}
        y1={12960.21}
        x2={173.13}
        y2={12974.06}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        xlinkHref="#b977c742-0c91-4b15-b4a9-73c7d05244b8"
      />
      <linearGradient
        id="b83efc1a-34db-4fb1-a775-d72bb9050b15"
        x1={188.8}
        y1={13121.6}
        x2={227.2}
        y2={13121.6}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        xlinkHref="#b977c742-0c91-4b15-b4a9-73c7d05244b8"
      />
      <linearGradient
        id="b823c767-2fe6-49a4-9deb-4784d2b31d8b"
        x1={-269.41}
        y1={13085.44}
        x2={-230.54}
        y2={13113.01}
        gradientTransform="translate(41 13233.6) rotate(180)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="fce33e18-161e-4269-839b-8293896320ae"
        x1={-237.41}
        y1={13085.44}
        x2={-198.54}
        y2={13113.01}
        gradientTransform="translate(-23 13233.6) rotate(180)"
        xlinkHref="#b823c767-2fe6-49a4-9deb-4784d2b31d8b"
      />
      <linearGradient
        id="b91ba17a-f9c8-44ad-97d6-ffda318a9f6a"
        x1={167.51}
        y1={12916.69}
        x2={222.82}
        y2={12893.75}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="a1e97ef8-ddc6-4ddb-9458-5cc24414d238"
        x1={201.26}
        y1={12937.93}
        x2={274.44}
        y2={12864.76}
        xlinkHref="#b91ba17a-f9c8-44ad-97d6-ffda318a9f6a"
      />
      <linearGradient
        id="f457d322-24ad-4a49-9097-edac39d1cb93"
        x1={256.7}
        y1={12930.89}
        x2={321.75}
        y2={12865.84}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="a3c8745e-2db9-4688-8595-337308c60773"
        x1={291.26}
        y1={12937.93}
        x2={364.44}
        y2={12864.76}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="afb571b9-6650-429a-9d78-868fe2f2eab0"
        x1={249.28}
        y1={12960.21}
        x2={263.13}
        y2={12974.06}
        xlinkHref="#b977c742-0c91-4b15-b4a9-73c7d05244b8"
      />
      <linearGradient
        id="f86010c2-bbad-49a2-9324-1b244ee6a0b4"
        x1={331.46}
        y1={12958.81}
        x2={348.13}
        y2={12975.47}
        xlinkHref="#b977c742-0c91-4b15-b4a9-73c7d05244b8"
      />
    </defs>
    <g id="b4ece036-ab99-4a71-9148-156071214646" data-name="Layer 1">
      <g id="a2d89d38-336b-4d9d-ae82-2b342ddd1d64" data-name="backdrop">
        <path
          className="a291e13f-08c6-42db-ad05-aed2b27dbed4"
          d="M512,256c0,127.3-93,233-214.8,252.7A256.3,256.3,0,0,1,6.3,312.8,259.5,259.5,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a15433c4-9011-4f80-8b7e-98351e0b62fb"
        d="M409.3,396.6,297.2,508.7a256.6,256.6,0,0,1-206.3-57L60.3,421a254.2,254.2,0,0,1-54-108.2L163,156l109,15.8,67.8,221.5Z"
      />
      <path
        className="f94b6c17-2fc2-4f5c-89ff-21f10ea89286"
        d="M208,160l-19.2-19.2L171,149.7a25.7,25.7,0,0,0-14.2,22.9v86.6H208Z"
      />
      <path
        className="f60840b9-b90c-4029-ac8b-e7c0e0c3ed56"
        d="M227.2,140.8,208,160v99.2h35.2V176a6.1,6.1,0,0,1,5.7-6.4h.7l73.6-12.8v-16Z"
      />
      <path
        className="fd077259-99ac-4d86-b4a0-c5d5c246079c"
        d="M355.2,259.2H252.8V172.6A25.7,25.7,0,0,1,267,149.7l17.8-8.9L304,160l19.2-19.2,17.8,8.9a25.7,25.7,0,0,1,14.2,22.9Z"
      />
      <path
        className="a4308151-001b-4d20-8ec3-dac3a4761e9d"
        d="M355.2,259.2H304V160l19.2-19.2,17.8,8.9a25.7,25.7,0,0,1,14.2,22.9Z"
      />
      <path
        className="ba3a0893-d35a-4c69-9483-f681b534d2dd"
        d="M270.4,260.8a1.6,1.6,0,0,1-1.6-1.6V176a1.6,1.6,0,0,1,3.2,0v83.2A1.6,1.6,0,0,1,270.4,260.8Z"
      />
      <path
        className="ba3a0893-d35a-4c69-9483-f681b534d2dd"
        d="M337.6,260.8a1.6,1.6,0,0,1-1.6-1.6V176a1.6,1.6,0,1,1,3.2,0v83.2A1.6,1.6,0,0,1,337.6,260.8Z"
      />
      <path
        className="a02485a5-09d0-4b72-896a-faf54a1c7b49"
        d="M304,89.6h0a19.2,19.2,0,0,1,19.2,19.2v9.6a16,16,0,0,1-16,16h-6.4a16,16,0,0,1-16-16v-9.6A19.2,19.2,0,0,1,304,89.6Z"
      />
      <path
        className="ba3a0893-d35a-4c69-9483-f681b534d2dd"
        d="M174.4,260.8a1.6,1.6,0,0,1-1.6-1.6V176a1.6,1.6,0,1,1,3.2,0v83.2A1.6,1.6,0,0,1,174.4,260.8Z"
      />
      <path
        className="a1cc26e5-e265-49ea-8153-5b083c527f9b"
        d="M156.8,259.2h16v16a3.2,3.2,0,0,1-3.2,3.2h0a12.8,12.8,0,0,1-12.8-12.8v-6.4Z"
      />
      <path
        className="a83a3837-77df-44a2-98ee-4ec5e3de69b7"
        d="M208,89.6h0a19.2,19.2,0,0,1,19.2,19.2v9.6a16,16,0,0,1-16,16h-6.4a16,16,0,0,1-16-16v-9.6A19.2,19.2,0,0,1,208,89.6Z"
      />
      <polygon className="b1c0dd36-89da-4553-a95b-99fbbe133bb7" points="304 160 323.2 140.8 284.8 140.8 304 160" />
      <polygon className="a3fb69b2-abcc-493c-989d-abfe8d04acb5" points="208 160 227.2 140.8 188.8 140.8 208 160" />
      <polygon
        className="b3a0144c-0dd1-445c-8295-ea887efb8def"
        points="195.2 393.6 172.8 393.6 172.8 259.2 208 259.2 208 291.2 195.2 393.6"
      />
      <polygon
        className="f4039386-ba42-4481-83cf-960828fe865d"
        points="243.2 393.6 220.8 393.6 208 291.2 208 259.2 243.2 259.2 243.2 393.6"
      />
      <polygon
        className="add98043-e15c-403d-92bc-4a3793b76f43"
        points="291.2 393.6 268.8 393.6 268.8 259.2 304 259.2 304 291.2 291.2 393.6"
      />
      <polygon
        className="e0fc6379-bf7e-4a80-9a2f-226987e0c775"
        points="339.2 393.6 316.8 393.6 304 291.2 304 259.2 339.2 259.2 339.2 393.6"
      />
      <path
        className="bd571f79-943e-4b84-935a-41aca3292b5b"
        d="M252.8,259.2h16v16a3.2,3.2,0,0,1-3.2,3.2h0a12.8,12.8,0,0,1-12.8-12.8v-6.4Z"
      />
      <path
        className="e9323024-1b42-4357-909a-f6a6c5789078"
        d="M339.2,259.2h16v6.4a12.8,12.8,0,0,1-12.8,12.8h0a3.2,3.2,0,0,1-3.2-3.2v-16Z"
      />
      <path className="ba3a0893-d35a-4c69-9483-f681b534d2dd" d="M408,397.2H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="b8c42c78-742c-4638-8add-ecab8f028e11"
        d="M137,142.3a7.9,7.9,0,1,1,7.8-7.9A7.9,7.9,0,0,1,137,142.3Zm0-12.8a4.9,4.9,0,0,0,0,9.8,4.9,4.9,0,0,0,0-9.8Z"
      />
      <path
        className="b8c42c78-742c-4638-8add-ecab8f028e11"
        d="M382.4,201.5l-.9-.3-11.8-8.9-11.9,8.9a1.5,1.5,0,0,1-1.8,0l-12.7-9.6a1.5,1.5,0,0,1-.3-2.1,1.6,1.6,0,0,1,2.1-.3l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,0l11.8,8.9,11.9-8.9a1.5,1.5,0,1,1,1.8,2.4l-12.8,9.6A1.6,1.6,0,0,1,382.4,201.5Z"
      />
      <path
        className="b8c42c78-742c-4638-8add-ecab8f028e11"
        d="M256,292.8h-6.5v-6.4a1.4,1.4,0,0,0-1.5-1.5,1.5,1.5,0,0,0-1.5,1.5v6.4h-6.4a1.5,1.5,0,0,0-1.5,1.5,1.4,1.4,0,0,0,1.5,1.5h6.4v6.5a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6.5H256a1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0,256,292.8Z"
      />
    </g>
  </svg>
)

export default ImgTwoPeopleGlitter
