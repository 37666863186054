import { SVGProps } from 'react'

const ImgBank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a360cfa7-7aeb-49bf-97d3-76abac63758f{fill:none;}.fd5e7d3c-b8e7-4d6a-aa0a-65dcd7f0dd86{opacity:0.15;fill:url(#eea3fe41-cca7-4166-8e9c-986452aeb393);}.b48abb17-9c65-4877-a98b-90c2f462dba0{fill:url(#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d);}.e7018282-7060-488d-91c0-2ecd1b0cfdb2{fill:url(#af3e3912-a042-4f7f-8459-e91c739cd0a5);}.e1293635-f55a-457d-8028-52e47fbefabe{fill:#fff;}.b32d8eb5-d09b-46ce-b8cf-c26f4ef88335{fill:url(#f93f6a19-903f-4ce8-b182-d95b60cf670d);}.b4314a2a-1008-4b53-862a-b699a202128d{fill:url(#a418f206-28f6-44ea-9f63-f42ded38fa52);}.e4f72f3e-f9cd-4e6c-848c-106c241c7e84{fill:url(#f27c51d2-443d-48c8-8ac3-3406880b00a4);}.faa19d71-fc71-47c2-bc27-3b908254521f{fill:url(#be0cbeb4-ab63-4d0b-8ac9-7ffa642e5a42);}.f0c7b448-be66-4c5b-88cb-a1d234ee1bd4{fill:url(#a9e65fa8-6e6a-4804-ac02-5f8940f4b9d0);}.e72b6eb9-00a4-4b48-b80e-776d8984e422{fill:url(#bda57903-10ec-4144-bc8b-af7ee915b4b9);}.bb2d2c9a-6b08-40b7-bdf4-c6b646aa08cb{fill:url(#a19706af-09b8-4eb2-b327-fbd8897c27c3);}.a0eafa61-31d4-4095-8826-5068298fb426{fill:url(#b07cdd52-7e92-49a8-a127-f45ad051cdfb);}.b78862a1-c2c0-4074-9987-5bf1c0b72f0a{fill:url(#bc8b675b-2b2f-4d5e-83c6-8becaa74f801);}.b9351561-cbd1-4748-bd5c-4ff384e41ddd{fill:url(#ff0d3fe9-df98-4e23-86b1-832f5ad0998e);}.a11e07df-657f-47e5-8ef1-76cab66cd532{fill:url(#ae570b02-1332-44be-988c-30b30c98c563);}.aabab814-d1c7-44a5-b342-f3ea20e084e4{fill:url(#b3bb7a83-5138-4676-b308-a9e20a3b5985);}.fe002ae0-fe9a-4a78-a427-9ccd7e2c2ab3{fill:url(#af291d32-e7a2-4781-96bd-402485cb8eb2);}.a8927669-637d-49fe-ac6f-010b0f0d8913{fill:url(#fbfc36a1-c74e-434a-ba4b-8fad6c8ff47b);}.a38c4f9f-da7f-4922-b2d2-b3c726d7715b{fill:url(#f3e321f8-0ea9-4794-980a-599887c80ee4);}.a2f9320c-c088-4730-8b54-1a7c85384530{fill:url(#b1f10a60-d2ba-4596-b855-5ab4c63a1913);}.a249d8f9-ba4e-4c9d-afcf-b6dcc951a384{fill:#f7ced7;}'
        }
      </style>
      <linearGradient
        id="eea3fe41-cca7-4166-8e9c-986452aeb393"
        x1={90.73}
        y1={452.75}
        x2={281.53}
        y2={261.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
        x1={193.52}
        y1={4427.12}
        x2={325.8}
        y2={4559.39}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="af3e3912-a042-4f7f-8459-e91c739cd0a5"
        x1={180.13}
        y1={265.34}
        x2={386.63}
        y2={58.83}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="f93f6a19-903f-4ce8-b182-d95b60cf670d"
        x1={220.03}
        y1={4467.65}
        x2={313.79}
        y2={4561.41}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="a418f206-28f6-44ea-9f63-f42ded38fa52"
        x1={326.2}
        y1={238.2}
        x2={397.89}
        y2={309.89}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="f27c51d2-443d-48c8-8ac3-3406880b00a4"
        x1={350.37}
        y1={192.64}
        x2={377.18}
        y2={219.45}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="be0cbeb4-ab63-4d0b-8ac9-7ffa642e5a42"
        x1={345.22}
        y1={359.72}
        x2={373.9}
        y2={331.04}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="a9e65fa8-6e6a-4804-ac02-5f8940f4b9d0"
        x1={259.33}
        y1={241.73}
        x2={328.26}
        y2={310.66}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="bda57903-10ec-4144-bc8b-af7ee915b4b9"
        x1={280.86}
        y1={193.52}
        x2={305.6}
        y2={218.26}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="a19706af-09b8-4eb2-b327-fbd8897c27c3"
        x1={275.07}
        y1={359.47}
        x2={309.36}
        y2={325.18}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="b07cdd52-7e92-49a8-a127-f45ad051cdfb"
        x1={189.72}
        y1={242.52}
        x2={260.07}
        y2={312.87}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="bc8b675b-2b2f-4d5e-83c6-8becaa74f801"
        x1={210.46}
        y1={193.52}
        x2={237.18}
        y2={220.25}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="ff0d3fe9-df98-4e23-86b1-832f5ad0998e"
        x1={206.75}
        y1={357.38}
        x2={237.78}
        y2={326.36}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="ae570b02-1332-44be-988c-30b30c98c563"
        x1={118.99}
        y1={242.19}
        x2={191.6}
        y2={314.8}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="b3bb7a83-5138-4676-b308-a9e20a3b5985"
        x1={140.06}
        y1={193.52}
        x2={173.96}
        y2={227.42}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="af291d32-e7a2-4781-96bd-402485cb8eb2"
        x1={137.65}
        y1={356.09}
        x2={170.56}
        y2={323.18}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="fbfc36a1-c74e-434a-ba4b-8fad6c8ff47b"
        x1={204.98}
        y1={308.98}
        x2={333.28}
        y2={437.28}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="f3e321f8-0ea9-4794-980a-599887c80ee4"
        x1={202.46}
        y1={325.66}
        x2={336.62}
        y2={459.82}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#f7af40b2-caaf-4cf7-a8af-a9539d8cbd1d"
      />
      <linearGradient
        id="b1f10a60-d2ba-4596-b855-5ab4c63a1913"
        x1={235.79}
        y1={4498.19}
        x2={257.08}
        y2={4519.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
    </defs>
    <g id="ad21a9ea-83b8-47d3-a223-5e601c39500a" data-name="Layer 1">
      <g id="fc63ead1-87fd-48a9-8c90-03ae5050640e" data-name="backdrop">
        <path
          className="a360cfa7-7aeb-49bf-97d3-76abac63758f"
          d="M512,256c0,130.2-97.2,237.6-222.9,253.9A256.8,256.8,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A255.2,255.2,0,0,1,1.8,286.2,251.2,251.2,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="fd5e7d3c-b8e7-4d6a-aa0a-65dcd7f0dd86"
        d="M409.3,389.7,289.1,509.9A256.8,256.8,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A255.2,255.2,0,0,1,1.8,286.2l107-107h22.4l41.6,190.4Z"
      />
      <polygon
        className="b48abb17-9c65-4877-a98b-90c2f462dba0"
        points="396.8 179.2 115.2 179.2 140.8 147.2 371.2 147.2 396.8 179.2"
      />
      <path
        className="e7018282-7060-488d-91c0-2ecd1b0cfdb2"
        d="M115.2,182.4H396.8v12.8a3.2,3.2,0,0,1-3.2,3.2H118.4a3.2,3.2,0,0,1-3.2-3.2V182.4Z"
      />
      <path
        className="e1293635-f55a-457d-8028-52e47fbefabe"
        d="M401.6,182.8H110.4a2,2,0,0,1,0-4H401.6a2,2,0,0,1,0,4Z"
      />
      <path className="b32d8eb5-d09b-46ce-b8cf-c26f4ef88335" d="M172.8,179.2a83.2,83.2,0,0,1,166.4,0Z" />
      <path className="b4314a2a-1008-4b53-862a-b699a202128d" d="M342.4,211.2h38.4V336H342.4Z" />
      <path
        className="e4f72f3e-f9cd-4e6c-848c-106c241c7e84"
        d="M336,198.4h51.2V208a3.2,3.2,0,0,1-3.2,3.2H339.2A3.2,3.2,0,0,1,336,208v-9.6Z"
      />
      <path
        className="faa19d71-fc71-47c2-bc27-3b908254521f"
        d="M339.2,336H384a3.2,3.2,0,0,1,3.2,3.2v9.6H336v-9.6A3.2,3.2,0,0,1,339.2,336Z"
      />
      <path className="f0c7b448-be66-4c5b-88cb-a1d234ee1bd4" d="M272,211.2h38.4V336H272Z" />
      <path
        className="e72b6eb9-00a4-4b48-b80e-776d8984e422"
        d="M265.6,198.4h51.2V208a3.2,3.2,0,0,1-3.2,3.2H268.8a3.2,3.2,0,0,1-3.2-3.2v-9.6Z"
      />
      <path
        className="bb2d2c9a-6b08-40b7-bdf4-c6b646aa08cb"
        d="M268.8,336h44.8a3.2,3.2,0,0,1,3.2,3.2v9.6H265.6v-9.6A3.2,3.2,0,0,1,268.8,336Z"
      />
      <path className="a0eafa61-31d4-4095-8826-5068298fb426" d="M201.6,211.2H240V336H201.6Z" />
      <path
        className="b78862a1-c2c0-4074-9987-5bf1c0b72f0a"
        d="M195.2,198.4h51.2V208a3.2,3.2,0,0,1-3.2,3.2H198.4a3.2,3.2,0,0,1-3.2-3.2v-9.6Z"
      />
      <path
        className="b9351561-cbd1-4748-bd5c-4ff384e41ddd"
        d="M198.4,336h44.8a3.2,3.2,0,0,1,3.2,3.2v9.6H195.2v-9.6A3.2,3.2,0,0,1,198.4,336Z"
      />
      <path className="a11e07df-657f-47e5-8ef1-76cab66cd532" d="M131.2,211.2h38.4V336H131.2Z" />
      <path
        className="aabab814-d1c7-44a5-b342-f3ea20e084e4"
        d="M124.8,198.4H176V208a3.2,3.2,0,0,1-3.2,3.2H128a3.2,3.2,0,0,1-3.2-3.2v-9.6Z"
      />
      <path
        className="fe002ae0-fe9a-4a78-a427-9ccd7e2c2ab3"
        d="M128,336h44.8a3.2,3.2,0,0,1,3.2,3.2v9.6H124.8v-9.6A3.2,3.2,0,0,1,128,336Z"
      />
      <path className="a8927669-637d-49fe-ac6f-010b0f0d8913" d="M118.4,352H393.6v16H118.4Z" />
      <path className="a38c4f9f-da7f-4922-b2d2-b3c726d7715b" d="M112,371.2H400v16H112Z" />
      <circle className="a2f9320c-c088-4730-8b54-1a7c85384530" cx={256} cy={147.2} r={19.2} />
      <path className="a249d8f9-ba4e-4c9d-afcf-b6dcc951a384" d="M408,390.8H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="a249d8f9-ba4e-4c9d-afcf-b6dcc951a384"
        d="M401.6,371.6H110.4a2,2,0,1,1,0-4H401.6a2,2,0,0,1,0,4Z"
      />
      <path
        className="a249d8f9-ba4e-4c9d-afcf-b6dcc951a384"
        d="M395.2,352.4H116.8a2,2,0,0,1,0-4H395.2a2,2,0,0,1,0,4Z"
      />
    </g>
  </svg>
)

export default ImgBank
