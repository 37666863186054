import { SVGProps } from 'react'

const ImgBoxWithArrowPointingUpGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.f53277a0-5404-4071-abbc-9418a9aa4721{isolation:isolate;}.a7da0380-484c-4b7d-8a77-f3ceed768284{fill:none;}.a6941537-ec39-4cd4-a4df-9ea1a62091c3{opacity:0.2;mix-blend-mode:multiply;fill:url(#b65b2462-5e63-4999-9359-70c021771067);}.af6577c2-c77e-4a71-a79e-b88d3656c1a7{fill:#fff;}.b8461e85-132f-46e8-a14f-7bc501df3c47{fill:url(#fcd99d80-d2fc-4f77-86c7-a208d609a522);}.ebd07ed1-1dbf-420d-93f2-14198181710c{fill:url(#aae4a4d6-cd32-4c95-88c7-9ecaa3480750);}.b0c96b3c-6d4e-4632-95f3-c3ea930d6578{fill:url(#e45fd35c-52e5-416d-b415-5b762d895c10);}.fc628436-59f8-4241-bcd7-687e54b225d8{fill:#37c49f;}.aad7492f-329c-4e54-9c74-88775605777a{fill:url(#b61d2ff0-8e3a-4797-ac79-6380766d6dd1);}'
        }
      </style>
      <linearGradient
        id="b65b2462-5e63-4999-9359-70c021771067"
        x1={-16.54}
        y1={182.09}
        x2={51.33}
        y2={182.09}
        gradientTransform="matrix(2.58, -2.58, 4.5, 4.5, -659.96, -421.95)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="fcd99d80-d2fc-4f77-86c7-a208d609a522"
        x1={134.2}
        y1={-4018.38}
        x2={236.26}
        y2={-4048.45}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="aae4a4d6-cd32-4c95-88c7-9ecaa3480750"
        x1={231.61}
        y1={-4090.48}
        x2={389.08}
        y2={-3933}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="e45fd35c-52e5-416d-b415-5b762d895c10"
        x1={110.54}
        y1={-4009.95}
        x2={198.4}
        y2={-3922.08}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b61d2ff0-8e3a-4797-ac79-6380766d6dd1"
        x1={250.57}
        y1={-4023.51}
        x2={373.44}
        y2={-3900.64}
        gradientTransform="matrix(1, 0, 0, -1, 0, -3744.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.01} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
    </defs>
    <g className="f53277a0-5404-4071-abbc-9418a9aa4721">
      <g id="bbeec2ad-266a-44d1-a5bf-f3f2a191a29d" data-name="Layer 1">
        <g id="a88711f2-f661-497b-98f4-e23cc879e4e1" data-name="backdrop">
          <circle className="a7da0380-484c-4b7d-8a77-f3ceed768284" cx={256} cy={256} r={256} />
        </g>
        <polygon
          className="a6941537-ec39-4cd4-a4df-9ea1a62091c3"
          points="409.6 383.2 234.8 558 0 323.2 134.4 188.8 377.6 380.8 409.6 383.2"
        />
        <path
          className="af6577c2-c77e-4a71-a79e-b88d3656c1a7"
          d="M298.9,117.1,260.5,78.7l-.6-.6h-.2l-1.5-.8a.1.1,0,0,1-.1-.1l-.8-.2h-1.1a4.4,4.4,0,0,0-1.6.2h-.2l-.8.3h-.1l-.7.4h-.2l-.7.5h0a.8.8,0,0,0-.4.4l-38.4,38.4a6.3,6.3,0,0,0,0,9,6.3,6.3,0,0,0,9,0l27.5-27.4V336a6.4,6.4,0,1,0,12.8,0V98.7l27.5,27.4a6.4,6.4,0,0,0,9-9Z"
        />
        <rect className="b8461e85-132f-46e8-a14f-7bc501df3c47" x={134.4} y={188.8} width={73.6} height={192} />
        <rect className="ebd07ed1-1dbf-420d-93f2-14198181710c" x={208} y={188.8} width={169.6} height={192} />
        <polygon
          className="b0c96b3c-6d4e-4632-95f3-c3ea930d6578"
          points="176 252.8 102.4 252.8 134.4 188.8 208 188.8 176 252.8"
        />
        <path
          className="fc628436-59f8-4241-bcd7-687e54b225d8"
          d="M148.8,182.2a7.9,7.9,0,1,1,7.9-7.8A7.8,7.8,0,0,1,148.8,182.2Zm0-12.7a4.8,4.8,0,0,0-4.8,4.9,4.7,4.7,0,0,0,4.8,4.8,4.8,4.8,0,0,0,4.9-4.8A4.9,4.9,0,0,0,148.8,169.5Z"
        />
        <path
          className="fc628436-59f8-4241-bcd7-687e54b225d8"
          d="M352.6,113.5a1.6,1.6,0,0,1-.9-.3l-11.8-8.9L328,113.2a1.5,1.5,0,0,1-1.8,0l-12.7-9.6a1.5,1.5,0,0,1,1.8-2.4l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,0l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,2.1.3,1.6,1.6,0,0,1-.3,2.1l-12.8,9.6A1.4,1.4,0,0,1,352.6,113.5Z"
        />
        <polygon
          className="aad7492f-329c-4e54-9c74-88775605777a"
          points="240 252.8 409.6 252.8 377.6 188.8 208 188.8 240 252.8"
        />
        <path
          className="af6577c2-c77e-4a71-a79e-b88d3656c1a7"
          d="M408,384H104a1.6,1.6,0,0,1,0-3.2H408a1.6,1.6,0,1,1,0,3.2Z"
        />
        <rect className="fc628436-59f8-4241-bcd7-687e54b225d8" x={310.4} y={348.8} width={44.8} height={12.8} />
        <path
          className="fc628436-59f8-4241-bcd7-687e54b225d8"
          d="M302.4,222.6h-6.5v-6.5a1.5,1.5,0,0,0-3,0v6.5h-6.5a1.5,1.5,0,0,0,0,3h6.5v6.5a1.5,1.5,0,1,0,3,0v-6.5h6.5a1.5,1.5,0,0,0,0-3Z"
        />
      </g>
    </g>
  </svg>
)

export default ImgBoxWithArrowPointingUpGlitter
