import { SVGProps } from 'react'

const ImgHandWithShapes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.add9a1cc-7ca1-4f65-b20e-053b24258a7a{fill:none;}.b0a323ab-2e1e-4edc-850b-c1a522657fe3{fill:url(#e7b3d3ba-a1cf-402b-b0df-8bc681f07c5a);}.add5d5dc-92bf-4f1e-9bc5-5cba76e2f8d4{fill:url(#aad919aa-6c2b-41f3-8dd0-1f83e0ca158e);}.eac4fa08-e7e2-49c6-a777-b22a403dbd21{fill:url(#b85c7288-d310-434f-b673-5c1183581c53);}.f52aef71-bcd9-4329-9f56-b5b7aaf9bc6e{fill:url(#b05f0bdf-8d79-41b7-92c1-dc0a6ca3889b);}.a98f59b5-173b-48bf-9120-d7990e5922ca{fill:url(#ee599eb8-1c81-40b6-b1fc-d83d7d99f1bf);}.ae26d1a4-11c8-48d5-a897-80a8433e68f6{opacity:0.15;fill:url(#b14cb80a-d77f-4a00-99e8-e47cdaba6a01);}.e003b47b-2333-4a72-a3b1-1bf63b21961b{fill:url(#a15d363a-4371-415c-aba7-2d36b50acd93);}'
        }
      </style>
      <linearGradient
        id="e7b3d3ba-a1cf-402b-b0df-8bc681f07c5a"
        x1={200.71}
        y1={14175.1}
        x2={370.41}
        y2={14344.8}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="aad919aa-6c2b-41f3-8dd0-1f83e0ca158e"
        x1={247.2}
        y1={181.6}
        x2={194.4}
        y2={234.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="b85c7288-d310-434f-b673-5c1183581c53"
        x1={297.6}
        y1={188.8}
        x2={233.6}
        y2={252.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b05f0bdf-8d79-41b7-92c1-dc0a6ca3889b"
        x1={347.97}
        y1={14179.96}
        x2={389.47}
        y2={14221.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ee599eb8-1c81-40b6-b1fc-d83d7d99f1bf"
        x1={188.96}
        y1={244.41}
        x2={314.11}
        y2={369.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="b14cb80a-d77f-4a00-99e8-e47cdaba6a01"
        x1={90.71}
        y1={452.62}
        x2={343.66}
        y2={199.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a15d363a-4371-415c-aba7-2d36b50acd93"
        x1={323.43}
        y1={166.17}
        x2={278.17}
        y2={211.43}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
    </defs>
    <g id="bfadb4b0-2ba1-4035-9007-ea58238494bb" data-name="Layer 1">
      <g id="acda3246-84ec-455e-ace3-0a4a69bb8e1d" data-name="backdrop">
        <path
          className="add9a1cc-7ca1-4f65-b20e-053b24258a7a"
          d="M512,256c0,141.4-114.6,256-256,256A254.5,254.5,0,0,1,79.9,441.8c-3.3-3.1-6.6-6.4-9.7-9.7A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <circle className="b0a323ab-2e1e-4edc-850b-c1a522657fe3" cx={265.6} cy={217.6} r={121.6} />
      <polygon
        className="add5d5dc-92bf-4f1e-9bc5-5cba76e2f8d4"
        points="220.8 155.2 182.4 222.4 259.2 222.4 220.8 155.2"
      />
      <rect className="eac4fa08-e7e2-49c6-a777-b22a403dbd21" x={233.6} y={188.8} width={64} height={64} />
      <path
        className="f52aef71-bcd9-4329-9f56-b5b7aaf9bc6e"
        d="M300.8,300.8s50.8-47.6,57.6-54.4a29.3,29.3,0,0,1,22.4-9.6c3.8,0,5.7,2.7,3.2,6.4l-57.6,80Z"
      />
      <path
        className="a98f59b5-173b-48bf-9120-d7990e5922ca"
        d="M400,243.2c-9.6,0-12.8,3.2-22.4,9.6l-76.8,48H231.2a32.8,32.8,0,0,1,17.7-5h29.5a22.4,22.4,0,0,0,22.4-22.4h0a3.2,3.2,0,0,0-3.2-3.2H224.1c-19.8,0-25.7,6.4-64.1,25.6L108.8,315a22,22,0,0,0-6.4,15.5v40.7A12.8,12.8,0,0,0,115.2,384L177,354.7a25.5,25.5,0,0,1,11.4-2.7h62.9a38.8,38.8,0,0,0,15.1-3.1l60-25.7,76.8-64c3.2-3.2,6.4-6.4,6.4-9.6S409.6,243.2,400,243.2Z"
      />
      <path
        className="ae26d1a4-11c8-48d5-a897-80a8433e68f6"
        d="M403.3,259.3,166.6,496a256.6,256.6,0,0,1-86.7-54.2l-9.7-9.7a256.4,256.4,0,0,1-33.8-44.4l72.5-72.6,191.9-14.3Z"
      />
      <circle className="e003b47b-2333-4a72-a3b1-1bf63b21961b" cx={300.8} cy={188.8} r={32} />
    </g>
  </svg>
)

export default ImgHandWithShapes
