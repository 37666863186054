import { SVGProps } from 'react'

const ImgNotebookWithPencilGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.bec07373-202b-488d-adfe-2719bb316bd8{fill:none;}.febb54f6-d875-4837-97ef-beb415b026ff{opacity:0.15;fill:url(#a63a0bd1-df72-4cd3-975f-0718aa605293);}.b862fc3e-540f-41c8-ba00-bd6711146d9d{fill:url(#b09c213a-db2a-4cb5-be1b-5426031685a6);}.bb048dbc-9297-4fe3-bb67-6a28760c46b4{fill:url(#f7333996-c8bf-49c7-8fd9-4533f95ee100);}.aadcb115-4f6c-4a11-bcac-141a62c0a843{fill:#fff;}.e6b4005d-706b-4efb-8338-c7978b585ed2{fill:url(#a72fadf2-c858-4772-97cc-e8619fad25e4);}.f4af1aed-f4e7-4cdb-ac0e-4cf95259d1b9{fill:url(#f044ec1e-efab-4d0e-97ee-100d9fbee4df);}.abb7007f-b65b-4eda-b460-a7af31decd1b{fill:url(#b48ac4bd-ac2f-4e20-81a2-99cda67cb948);}.a3433577-4545-4cbf-bf8e-2ba6b7a5da7a{fill:url(#bf59bc4f-d458-4fd6-bd73-ebb39fd3e6dc);}.eccda79f-8151-4f8f-955d-c1a5952faaaa{fill:url(#a8ea814f-e966-408d-80b5-57a453d566a9);}.abf8b27b-239e-4543-a6d3-f1c83f8dc3aa{fill:#f7ced7;}.a2ba3f9a-a8ea-4121-8f6f-d59a3346c67b{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a63a0bd1-df72-4cd3-975f-0718aa605293"
        x1={75.73}
        y1={437.68}
        x2={272.03}
        y2={241.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b09c213a-db2a-4cb5-be1b-5426031685a6"
        x1={236.14}
        y1={178.54}
        x2={74.81}
        y2={339.86}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="f7333996-c8bf-49c7-8fd9-4533f95ee100"
        x1={392.61}
        y1={15649.96}
        x2={127.95}
        y2={15385.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="a72fadf2-c858-4772-97cc-e8619fad25e4"
        x1={743.86}
        y1={7667.36}
        x2={1097.51}
        y2={7313.7}
        gradientTransform="matrix(0, -1, -1, 0, 7635.31, 1289.31)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="f044ec1e-efab-4d0e-97ee-100d9fbee4df"
        x1={158.47}
        y1={15624.53}
        x2={421.82}
        y2={15361.18}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        xlinkHref="#a72fadf2-c858-4772-97cc-e8619fad25e4"
      />
      <linearGradient
        id="b48ac4bd-ac2f-4e20-81a2-99cda67cb948"
        x1={121.39}
        y1={15533.58}
        x2={102.02}
        y2={15514.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="bf59bc4f-d458-4fd6-bd73-ebb39fd3e6dc"
        x1={357.49}
        y1={15671.89}
        x2={296.41}
        y2={15610.81}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="a8ea814f-e966-408d-80b5-57a453d566a9"
        x1={312.84}
        y1={15679.76}
        x2={300.36}
        y2={15667.29}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        xlinkHref="#b09c213a-db2a-4cb5-be1b-5426031685a6"
      />
    </defs>
    <g id="aaea9213-c9d9-4a9f-bff4-87aaeb1e831d" data-name="Layer 1">
      <g id="b97ba4e2-3847-40c7-bbde-0b54f874fef1" data-name="backdrop">
        <path
          className="bec07373-202b-488d-adfe-2719bb316bd8"
          d="M512,256c0,139.6-111.7,253-250.6,255.9H256A255.1,255.1,0,0,1,79.1,441q-4.2-3.9-8.1-8.1A255.1,255.1,0,0,1,0,256v-2.6C1.4,113.2,115.5,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="febb54f6-d875-4837-97ef-beb415b026ff"
        d="M367.3,406,261.4,511.9H256A255.1,255.1,0,0,1,79.1,441L71,432.9A255.1,255.1,0,0,1,0,256v-2.6l142.1-142Z"
      />
      <path
        className="b862fc3e-540f-41c8-ba00-bd6711146d9d"
        d="M147.2,108.8h19.2V409.6H147.2a6.4,6.4,0,0,1-6.4-6.4v-288A6.4,6.4,0,0,1,147.2,108.8Z"
      />
      <path
        className="bb048dbc-9297-4fe3-bb67-6a28760c46b4"
        d="M358.4,108.8h-192V409.6h192a12.8,12.8,0,0,0,12.8-12.8h0V121.6a12.8,12.8,0,0,0-12.8-12.8Z"
      />
      <path className="aadcb115-4f6c-4a11-bcac-141a62c0a843" d="M160,382.8H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path className="aadcb115-4f6c-4a11-bcac-141a62c0a843" d="M160,363.6H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path className="aadcb115-4f6c-4a11-bcac-141a62c0a843" d="M160,158.8H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path className="aadcb115-4f6c-4a11-bcac-141a62c0a843" d="M160,139.6H140.8a2,2,0,0,1,0-4H160a2,2,0,0,1,0,4Z" />
      <path
        className="e6b4005d-706b-4efb-8338-c7978b585ed2"
        d="M393.1,264.2l16.5-8.2H107.2a4.7,4.7,0,0,0-4.8,4.8h0a4.7,4.7,0,0,0,4.8,4.8H387.4A12.6,12.6,0,0,0,393.1,264.2Z"
      />
      <path
        className="f4af1aed-f4e7-4cdb-ac0e-4cf95259d1b9"
        d="M409.6,256l-16.5-8.2a12.6,12.6,0,0,0-5.7-1.4H107.2a4.7,4.7,0,0,0-4.8,4.8h0a4.7,4.7,0,0,0,4.8,4.8Z"
      />
      <path
        className="abb7007f-b65b-4eda-b460-a7af31decd1b"
        d="M107.2,246.4h14.4v19.2H107.2a4.7,4.7,0,0,1-4.8-4.8v-9.6A4.7,4.7,0,0,1,107.2,246.4Z"
      />
      <path
        className="a3433577-4545-4cbf-bf8e-2ba6b7a5da7a"
        d="M339.2,102.4h-32a6.4,6.4,0,0,1,6.4,6.4v70.4l16-16,16,16V108.8A6.4,6.4,0,0,0,339.2,102.4Z"
      />
      <path
        className="eccda79f-8151-4f8f-955d-c1a5952faaaa"
        d="M307.2,102.4a6.4,6.4,0,0,0-6.4,6.4h12.8A6.4,6.4,0,0,0,307.2,102.4Z"
      />
      <path
        className="abf8b27b-239e-4543-a6d3-f1c83f8dc3aa"
        d="M243.2,227.2a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,243.2,227.2Z"
      />
      <path
        className="abf8b27b-239e-4543-a6d3-f1c83f8dc3aa"
        d="M268.8,227.2a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,268.8,227.2Z"
      />
      <path
        className="abf8b27b-239e-4543-a6d3-f1c83f8dc3aa"
        d="M294.4,227.2a6.4,6.4,0,1,1,6.4-6.4A6.4,6.4,0,0,1,294.4,227.2Z"
      />
      <path
        className="a2ba3f9a-a8ea-4121-8f6f-d59a3346c67b"
        d="M430.4,344.9h-6v-6a2,2,0,0,0-4,0v6h-6a2,2,0,0,0,0,4h6v6a2,2,0,0,0,4,0v-6h6a2,2,0,0,0,0-4Z"
      />
      <path
        className="a2ba3f9a-a8ea-4121-8f6f-d59a3346c67b"
        d="M392,171.6a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,392,171.6Zm0-12.8a4.4,4.4,0,1,0,4.4,4.4A4.4,4.4,0,0,0,392,158.8Z"
      />
      <path
        className="a2ba3f9a-a8ea-4121-8f6f-d59a3346c67b"
        d="M191.1,330a1.6,1.6,0,0,1-1.2-.4L177.1,320a2,2,0,1,1,2.4-3.2l11.6,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.6,8.7,11.6-8.7a2,2,0,0,1,2.8.4,2.2,2.2,0,0,1-.4,2.8l-12.8,9.6a2.1,2.1,0,0,1-2.4,0l-11.6-8.7-11.6,8.7A1.9,1.9,0,0,1,191.1,330Z"
      />
    </g>
  </svg>
)

export default ImgNotebookWithPencilGlitter
