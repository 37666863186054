const ImgTravelBagWithWaterBottleGlitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="f04273c9-6989-4570-8716-8ddaf7f5c7fe"
          x1={-41.31}
          y1={238.98}
          x2={54.32}
          y2={238.98}
          gradientTransform="matrix(1.67 -1.67 2.54 2.54 -415.71 -272.63)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="afcba9fe-3772-4b64-b852-68d08e28208d"
          x1={196.83}
          y1={-6773.85}
          x2={351.79}
          y2={-6618.89}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="ab5415a9-2155-4d73-bd17-7223374d6061"
          x1={227.73}
          y1={-6782.35}
          x2={277.93}
          y2={-6732.16}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fae2e7" />
          <stop offset={1} stopColor="#f7ced7" />
        </linearGradient>
        <linearGradient
          id="e56d1a42-a739-430e-afd7-8a42fe449749"
          x1={227.73}
          y1={-6795.15}
          x2={277.93}
          y2={-6744.96}
          xlinkHref="#ab5415a9-2155-4d73-bd17-7223374d6061"
        />
        <linearGradient
          id="b47dd3fc-153b-477e-82e1-3e8ccc9eba5d"
          x1={251.52}
          y1={-6768.17}
          x2={292.65}
          y2={-6727.03}
          xlinkHref="#ab5415a9-2155-4d73-bd17-7223374d6061"
        />
        <linearGradient
          id="b872b795-5cc9-4b10-9e2c-56e56307e4c5"
          x1={249.6}
          y1={268.8}
          x2={262.4}
          y2={268.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5fd0b2" />
          <stop offset={1} stopColor="#37c49f" />
        </linearGradient>
        <linearGradient
          id="ac07cd9b-4cad-4989-a681-5e60fb21d69c"
          x1={89.74}
          y1={-6747.4}
          x2={245.13}
          y2={-6592.02}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="e62aab46-5a5a-4055-a998-1f608d28c6f7"
          x1={163.8}
          y1={-6532.02}
          x2={183.3}
          y2={-6512.52}
          xlinkHref="#afcba9fe-3772-4b64-b852-68d08e28208d"
        />
        <style>{'.bed57d21-6a1f-471d-a046-e61706417047{fill:#37c49f}'}</style>
      </defs>
      <g id="e36732a3-2271-4719-a9aa-3b6edab51d97" data-name="Layer 1">
        <circle
          cx={256}
          cy={256}
          r={256}
          style={{
            fill: 'none',
          }}
          id="ee910d1a-4cec-4b81-8b33-cd2be4cb674d"
          data-name="backdrop"
        />
        <path
          style={{
            opacity: 0.15,
            fill: 'url(#f04273c9-6989-4570-8716-8ddaf7f5c7fe)',
          }}
          d="M394.8 375.3 244.5 525.6 1.4 282.4l159.7-159.7L204.8 224l190 151.3z"
        />
        <path
          d="M204.8 224h102.4a102.3 102.3 0 0 1 102.4 102.4v12.8a51.2 51.2 0 0 1-51.2 51.2H153.6a51.2 51.2 0 0 1-51.2-51.2v-12.8A102.3 102.3 0 0 1 204.8 224Z"
          style={{
            fill: 'url(#afcba9fe-3772-4b64-b852-68d08e28208d)',
          }}
        />
        <path
          d="M345.6 389.2a2 2 0 0 1-2-1.7l-26.4-167.9a64.4 64.4 0 0 0-127.5 1.8l-21.3 166.1a2 2 0 0 1-4-.6l21.4-166a68.4 68.4 0 0 1 135.4-1.9l26.4 167.9a2.1 2.1 0 0 1-1.7 2.3Z"
          style={{
            fill: '#f7ced7',
          }}
        />
        <path
          d="M185.6 364.8h121.6a6.4 6.4 0 0 1 6.4 6.4 6.4 6.4 0 0 1-6.4 6.4H185.6a6.4 6.4 0 0 1-6.4-6.4 6.4 6.4 0 0 1 6.4-6.4Z"
          style={{
            fill: 'url(#ab5415a9-2155-4d73-bd17-7223374d6061)',
          }}
        />
        <path
          d="M185.6 377.6h121.6a6.4 6.4 0 0 1 6.4 6.4 6.4 6.4 0 0 1-6.4 6.4H185.6a6.4 6.4 0 0 1-6.4-6.4 6.4 6.4 0 0 1 6.4-6.4Z"
          style={{
            fill: 'url(#e56d1a42-a739-430e-afd7-8a42fe449749)',
          }}
        />
        <path
          d="M230.4 352h76.8a6.4 6.4 0 0 1 6.4 6.4 6.4 6.4 0 0 1-6.4 6.4h-76.8a6.4 6.4 0 0 1-6.4-6.4 6.4 6.4 0 0 1 6.4-6.4Z"
          style={{
            fill: 'url(#b47dd3fc-153b-477e-82e1-3e8ccc9eba5d)',
          }}
        />
        <circle
          cx={256}
          cy={268.8}
          r={6.4}
          style={{
            fill: 'url(#b872b795-5cc9-4b10-9e2c-56e56307e4c5)',
          }}
        />
        <path
          d="M204.8 390.4h-64a6.4 6.4 0 0 1-6.4-6.4V230.4l6.4-6.4-6.4-6.4v-26.7A83.1 83.1 0 0 1 151 141l1.6-2.1a3.3 3.3 0 0 1 2.6-1.3h35.2a3.3 3.3 0 0 1 2.6 1.3l1.6 2.1a83.1 83.1 0 0 1 16.6 49.9v26.7l-6.4 6.4 6.4 6.4V384a6.4 6.4 0 0 1-6.4 6.4Z"
          style={{
            fill: 'url(#ac07cd9b-4cad-4989-a681-5e60fb21d69c)',
          }}
        />
        <path
          d="M163.2 121.6h19.2a3.2 3.2 0 0 1 3.2 3.2v12.8H160v-12.8a3.2 3.2 0 0 1 3.2-3.2Z"
          style={{
            fill: 'url(#e62aab46-5a5a-4055-a998-1f608d28c6f7)',
          }}
        />
        <path
          className="bed57d21-6a1f-471d-a046-e61706417047"
          d="M102.4 238.3a7.9 7.9 0 1 1 7.9-7.9 7.9 7.9 0 0 1-7.9 7.9Zm0-12.8a4.9 4.9 0 1 0 4.9 4.9 4.9 4.9 0 0 0-4.9-4.9ZM287.8 143.9a1.6 1.6 0 0 1-.9-.3l-11.8-8.9-11.9 8.9a1.5 1.5 0 0 1-1.8 0l-12.7-9.6a1.5 1.5 0 0 1-.3-2.1 1.6 1.6 0 0 1 2.1-.3l11.8 8.9 11.9-8.9a1.5 1.5 0 0 1 1.8 0l11.8 8.9 11.9-8.9a1.6 1.6 0 0 1 2.1.3 1.5 1.5 0 0 1-.3 2.1l-12.8 9.6a1.2 1.2 0 0 1-.9.3ZM380 305.7h-6.5v-6.5a1.5 1.5 0 0 0-3 0v6.5H364a1.5 1.5 0 0 0 0 3h6.5v6.5a1.5 1.5 0 1 0 3 0v-6.5h6.5a1.5 1.5 0 0 0 0-3Z"
        />
      </g>
    </svg>
  )
}

export default ImgTravelBagWithWaterBottleGlitter
