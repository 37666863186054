import { SVGProps } from 'react'

const ImgHouseLeaky = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.ab58625c-3316-4365-adc8-5653c2942d89{isolation:isolate;}.be819106-108b-44ee-9754-6d682df472c5{fill:none;}.b4158d49-ccfa-4ef0-b0b1-40e5067c1e14{opacity:0.15;mix-blend-mode:multiply;fill:url(#bf01c70f-b7e6-498f-8907-6345dc398be6);}.a1fcf7ca-3186-42f0-a8d2-8c2b525801a5{fill:#fff;}.b60ca59b-18c1-425a-9500-044038eb2943{fill:url(#bea67cd5-b4cc-4ea9-9d54-3933018d6556);}.b00949e7-1d82-43c9-b477-f555a3a0fb41{fill:url(#fafce7c9-ed85-4a88-9fd8-0646f5b8f70c);}.a877b01b-5546-4cf9-bcd1-d42efa33329e{fill:url(#b8c864c0-6009-45f5-bad4-a14d85faf740);}.af919995-a161-48e7-bc6b-a08e3841d93e{fill:url(#e308e8ea-9c14-47c3-aa99-a4dfe82e1b8e);}.aac036f4-db23-451a-859d-a6ece6b37e6d{fill:url(#a9a0b2d8-70de-4fdc-8cd0-a7ec0c415301);}.a6fc3bd6-0249-4b4a-be30-bce1e069dacc{fill:url(#e6c7e4e9-611e-4481-902c-6b604394dd89);}.ab4a04e9-3806-4dd2-b534-4f4fd24b5880{fill:url(#a2fae950-f262-4fbe-ad1c-53da26ec9930);}.a3831364-415e-4268-ab42-cec84b39f0a7{fill:url(#ae3f0bc6-ac10-4bde-aff9-a3d58857586a);}.e9244206-7555-4fb5-8ce9-e4f823c606b4{fill:url(#b1788769-1a03-48b0-9422-89e6cd1cd3a8);}.a8330725-2520-4681-bcb3-6eee99f6fcde{fill:url(#ab328566-446e-41ca-97c0-b08a566b547c);}'
        }
      </style>
      <linearGradient
        id="bf01c70f-b7e6-498f-8907-6345dc398be6"
        x1={89.65}
        y1={450.84}
        x2={276.95}
        y2={263.54}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" stopOpacity={0} />
        <stop offset={0.16} stopColor="#6e67c4" stopOpacity={0.03} />
        <stop offset={0.32} stopColor="#6660c1" stopOpacity={0.1} />
        <stop offset={0.48} stopColor="#5a53bc" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#4941b5" stopOpacity={0.41} />
        <stop offset={0.8} stopColor="#322aad" stopOpacity={0.64} />
        <stop offset={0.96} stopColor="#180ea2" stopOpacity={0.92} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bea67cd5-b4cc-4ea9-9d54-3933018d6556"
        x1={107.84}
        y1={6759.03}
        x2={348.02}
        y2={6999.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="fafce7c9-ed85-4a88-9fd8-0646f5b8f70c"
        x1={292.09}
        y1={6757.68}
        x2={425.84}
        y2={6891.43}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="b8c864c0-6009-45f5-bad4-a14d85faf740"
        x1={289.22}
        y1={6721.22}
        x2={381.1}
        y2={6813.09}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e308e8ea-9c14-47c3-aa99-a4dfe82e1b8e"
        x1={158.84}
        y1={6738.03}
        x2={220.2}
        y2={6799.39}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a9a0b2d8-70de-4fdc-8cd0-a7ec0c415301"
        x1={109.23}
        y1={6726.9}
        x2={149.11}
        y2={6766.78}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="e6c7e4e9-611e-4481-902c-6b604394dd89"
        x1={238.07}
        y1={6727.74}
        x2={272.04}
        y2={6761.71}
        xlinkHref="#a9a0b2d8-70de-4fdc-8cd0-a7ec0c415301"
      />
      <linearGradient
        id="a2fae950-f262-4fbe-ad1c-53da26ec9930"
        x1={290.21}
        y1={6808.6}
        x2={356.38}
        y2={6874.78}
        xlinkHref="#fafce7c9-ed85-4a88-9fd8-0646f5b8f70c"
      />
      <linearGradient
        id="ae3f0bc6-ac10-4bde-aff9-a3d58857586a"
        x1={152.16}
        y1={94.55}
        x2={217.32}
        y2={159.72}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#e308e8ea-9c14-47c3-aa99-a4dfe82e1b8e"
      />
      <linearGradient
        id="b1788769-1a03-48b0-9422-89e6cd1cd3a8"
        x1={167.37}
        y1={372.3}
        x2={151.76}
        y2={387.91}
        gradientTransform="translate(367.8 630.5) rotate(180)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b7b4e2" />
        <stop offset={0.99} stopColor="#cfcdec" />
      </linearGradient>
      <linearGradient
        id="ab328566-446e-41ca-97c0-b08a566b547c"
        x1={169.92}
        y1={197.63}
        x2={257.98}
        y2={109.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b7b4e2" />
        <stop offset={1} stopColor="#e7e6f5" />
      </linearGradient>
    </defs>
    <g className="ab58625c-3316-4365-adc8-5653c2942d89">
      <g id="f8b57127-3b3f-410c-9ef3-5bdf59437cd2" data-name="Layer 1">
        <g id="fd427c52-73c4-4270-a5cf-4d2afd99dfc2" data-name="backdrop">
          <path
            className="be819106-108b-44ee-9754-6d682df472c5"
            d="M512,256c0,127.4-93.1,233.1-214.9,252.7A256.3,256.3,0,0,1,87.8,449a266,266,0,0,1-24.9-24.9A254.8,254.8,0,0,1,.7,274.6C.2,268.4,0,262.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
          />
        </g>
        <path
          className="b4158d49-ccfa-4ef0-b0b1-40e5067c1e14"
          d="M409.6,396.2,297.1,508.7A256.3,256.3,0,0,1,87.8,449L62.9,424.1A254.8,254.8,0,0,1,.7,274.6L115.2,160,278.4,393.6Z"
        />
        <path className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5" d="M408,397H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
        <polygon
          className="b60ca59b-18c1-425a-9500-044038eb2943"
          points="115.2 160 115.2 393.6 256 393.6 256 89.6 115.2 160"
        />
        <polygon
          className="b00949e7-1d82-43c9-b477-f555a3a0fb41"
          points="256 393.6 396.8 393.6 396.8 288 339.2 249.6 256 249.6 256 393.6"
        />
        <rect className="a877b01b-5546-4cf9-bcd1-d42efa33329e" x={275.2} y={316.8} width={102.4} height={76.8} />
        <rect className="af919995-a161-48e7-bc6b-a08e3841d93e" x={160} y={304} width={51.2} height={89.6} />
        <path
          className="aac036f4-db23-451a-859d-a6ece6b37e6d"
          d="M137.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S137.6,370.2,137.6,380.8Z"
        />
        <path
          className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5"
          d="M121.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,121.6,396.8Z"
        />
        <path
          className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5"
          d="M168,358.4a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,1,1,3.2,0v16A1.6,1.6,0,0,1,168,358.4Z"
        />
        <path
          className="a6fc3bd6-0249-4b4a-be30-bce1e069dacc"
          d="M265.6,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S265.6,370.2,265.6,380.8Z"
        />
        <path
          className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5"
          d="M249.6,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,3.2,0v16A1.6,1.6,0,0,1,249.6,396.8Z"
        />
        <polygon
          className="ab4a04e9-3806-4dd2-b534-4f4fd24b5880"
          points="396.8 288 256 288 256 249.6 339.2 249.6 396.8 288"
        />
        <polygon
          className="a3831364-415e-4268-ab42-cec84b39f0a7"
          points="256 89.6 256 96 160 144 115.2 166.4 115.2 160 160 137.6 256 89.6"
        />
        <rect className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5" x={256} y={288} width={140.8} height={3.2} />
        <path
          className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5"
          d="M350.4,310.4h-48a1.6,1.6,0,0,1,0-3.2h48a1.6,1.6,0,0,1,0,3.2Z"
        />
        <polygon
          className="a1fcf7ca-3186-42f0-a8d2-8c2b525801a5"
          points="275.2 316.8 275.2 393.6 278.4 393.6 278.4 320 374.4 320 374.4 393.6 377.6 393.6 377.6 316.8 275.2 316.8"
        />
        <path
          className="e9244206-7555-4fb5-8ce9-e4f823c606b4"
          d="M209.8,261.6a9.8,9.8,0,0,0,9.8-9.8c0-9.5-9.8-19.6-9.8-19.6s-9.8,10.1-9.8,19.6A9.8,9.8,0,0,0,209.8,261.6Z"
        />
        <path
          className="a8330725-2520-4681-bcb3-6eee99f6fcde"
          d="M244,95.6v65.7a10,10,0,0,1-20,0v-4.9a1.3,1.3,0,0,1-.1.6v-.7a2,2,0,0,0-2-2,2,2,0,0,0-1.9,1.4v57.7a10,10,0,0,1-20,0V160.8a10,10,0,0,0-20,0v22.8a10,10,0,1,1-20,0v-46Z"
        />
      </g>
    </g>
  </svg>
)

export default ImgHouseLeaky
