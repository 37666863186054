import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
}

const ContainerHeader = ({ children, className }: Props) => {
  return (
    <header className={`${className ? className : ''}`}>
      <div className="m-auto max-w-screen-xl">{children}</div>
    </header>
  )
}

export default ContainerHeader
