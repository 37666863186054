import { SVGProps } from 'react'

const ImgHouseSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.e42333b9-d68e-464c-b719-08fc8b705819{opacity:0.15;fill:url(#b94d3e8a-2c67-4588-933c-05b215e4d3e5);}.efb727ba-d70d-4944-ab99-36a158d6866a{fill:none;}.ba08941d-4da1-4bfc-b32d-01af18c45a64{fill:url(#bc2a2099-f214-480a-b61b-217f7c4b7b83);}.be4927d8-6fb5-4dd0-857c-2f649d65206f{fill:url(#a6151bab-f314-4b99-a2a6-a7dfc9bf9cc9);}.a0d3f938-9777-4ece-9c45-8f8c82dad2be{fill:url(#eb1f1223-7811-4f9c-ac81-23df5119219c);}.bb6cb336-0a3f-4b75-a281-609d2da54d0c{fill:#fff;}.eb892d44-0fa4-4fb9-8f29-20cc5aa1e63d{fill:url(#f959d8d5-d81e-438f-a5e7-05b34145aaa9);}.ba93c589-76fa-4b6d-a8f5-cfb94864dc62{fill:url(#f6ba9ce9-edfe-4c17-97e4-c6cb65e2825f);}.ee3c7e04-2faa-451f-8f26-995897d37384{fill:url(#a9c1c53c-6b78-47f5-bae1-f6d82e5e497b);}.b3d8b35e-4325-4916-91ab-ffdbdc15d928{fill:url(#ff0a9f9d-66c6-4a71-b9b9-f373a65c4da1);}.ba3ce12d-dfa6-40c1-9ed6-695740fdb4e4{fill:url(#ab17338c-d359-478c-a9e4-5dfdba716cce);}'
        }
      </style>
      <linearGradient
        id="b94d3e8a-2c67-4588-933c-05b215e4d3e5"
        x1={115.54}
        y1={476.97}
        x2={345.91}
        y2={246.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bc2a2099-f214-480a-b61b-217f7c4b7b83"
        x1={178.14}
        y1={345.75}
        x2={322.66}
        y2={201.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="a6151bab-f314-4b99-a2a6-a7dfc9bf9cc9"
        x1={160.97}
        y1={417.79}
        x2={339.83}
        y2={238.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="eb1f1223-7811-4f9c-ac81-23df5119219c"
        x1={305.28}
        y1={160.11}
        x2={341.64}
        y2={196.47}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="f959d8d5-d81e-438f-a5e7-05b34145aaa9"
        x1={220.72}
        y1={6740.54}
        x2={288.77}
        y2={6808.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={0.99} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="f6ba9ce9-edfe-4c17-97e4-c6cb65e2825f"
        x1={157.25}
        y1={340.6}
        x2={204.16}
        y2={293.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="a9c1c53c-6b78-47f5-bae1-f6d82e5e497b"
        x1={296.64}
        y1={340.6}
        x2={343.55}
        y2={293.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ff0a9f9d-66c6-4a71-b9b9-f373a65c4da1"
        x1={122.38}
        y1={6726.9}
        x2={162.26}
        y2={6766.78}
        gradientTransform="matrix(1, 0, 0, -1, 0, 7113.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="ab17338c-d359-478c-a9e4-5dfdba716cce"
        x1={354.52}
        y1={6727.74}
        x2={388.49}
        y2={6761.71}
        xlinkHref="#ff0a9f9d-66c6-4a71-b9b9-f373a65c4da1"
      />
    </defs>
    <g id="a752e458-66e6-4f7d-bace-6459fe8d4425" data-name="Layer 1">
      <path
        className="e42333b9-d68e-464c-b719-08fc8b705819"
        d="M409.1,396.5,296.9,508.7A250.8,250.8,0,0,1,256,512,255.2,255.2,0,0,1,87.8,449l-2-1.8L64.2,425.5l-1.3-1.4a255.4,255.4,0,0,1-41.7-65.9L109.4,270l27.2-4.2H365.1L366,395Z"
      />
      <g id="a2d7bc1a-f76d-4c28-9460-8f509c86b0e9" data-name="backdrop">
        <path
          className="efb727ba-d70d-4944-ab99-36a158d6866a"
          d="M512,256c0,127.4-93.1,233.1-214.9,252.7h-.2A250.8,250.8,0,0,1,256,512,255.2,255.2,0,0,1,87.8,449l-2-1.8a245.7,245.7,0,0,1-21.6-21.7l-1.3-1.4A254.5,254.5,0,0,1,.7,274.6C.2,268.4,0,262.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="ba08941d-4da1-4bfc-b32d-01af18c45a64"
        d="M253.6,144.8a4.9,4.9,0,0,0-6.4,0L109.4,270a4.7,4.7,0,0,0,6.4,7L250.4,154.8,385,277a4.9,4.9,0,0,0,3.2,1.2,4.7,4.7,0,0,0,3.2-8.2Z"
      />
      <path
        className="be4927d8-6fb5-4dd0-857c-2f649d65206f"
        d="M252.7,162.5a3.4,3.4,0,0,0-4.6,0L135.9,264a3.7,3.7,0,0,0-1.2,2.6V390.1a3.5,3.5,0,0,0,3.5,3.5H362.6a3.5,3.5,0,0,0,3.5-3.5V266.6a3.7,3.7,0,0,0-1.2-2.6Z"
      />
      <path
        className="a0d3f938-9777-4ece-9c45-8f8c82dad2be"
        d="M307,159.7a3.1,3.1,0,0,0-3.1,3.1v20.6a3.1,3.1,0,0,0,1,2.4l22.6,20.6a3.2,3.2,0,0,0,2.1.8,3.1,3.1,0,0,0,1.3-.3,3.2,3.2,0,0,0,1.9-2.9V162.8a3.1,3.1,0,0,0-3.2-3.1Z"
      />
      <path className="bb6cb336-0a3f-4b75-a281-609d2da54d0c" d="M408,397H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <rect className="eb892d44-0fa4-4fb9-8f29-20cc5aa1e63d" x={222} y={293.7} width={56.8} height={99.37} />
      <rect className="ba93c589-76fa-4b6d-a8f5-cfb94864dc62" x={157.3} y={293.7} width={46.9} height={46.91} />
      <polygon
        className="ee3c7e04-2faa-451f-8f26-995897d37384"
        points="296.6 340.6 296.6 293.7 343.6 293.7 343.6 340.6 296.6 340.6"
      />
      <path
        className="bb6cb336-0a3f-4b75-a281-609d2da54d0c"
        d="M155.3,291.7v50.9h50.9V291.7Zm46.9,23.4H182.7V295.7h19.5Zm-23.5-19.4v19.4H159.3V295.7Zm-19.4,23.4h19.4v19.5H159.3Zm23.4,19.5V319.1h19.5v19.5Z"
      />
      <path
        className="bb6cb336-0a3f-4b75-a281-609d2da54d0c"
        d="M294.6,291.7v50.9h51V291.7Zm47,23.4H322.1V295.7h19.5Zm-23.5-19.4v19.4H298.6V295.7Zm-19.5,23.4h19.5v19.5H298.6Zm23.5,19.5V319.1h19.5v19.5Z"
      />
      <circle className="bb6cb336-0a3f-4b75-a281-609d2da54d0c" cx={231.1} cy={340.6} r={3.6} />
      <path
        className="b3d8b35e-4325-4916-91ab-ffdbdc15d928"
        d="M150.7,380.8c0,10.6-5.3,12.8-16,12.8s-16-2.2-16-12.8,5.4-32,16-32S150.7,370.2,150.7,380.8Z"
      />
      <path
        className="bb6cb336-0a3f-4b75-a281-609d2da54d0c"
        d="M134.4,396.8a1.5,1.5,0,0,1-1.3-1.6v-16a1.5,1.5,0,0,1,1.3-1.6c.7,0,1.2.7,1.2,1.6v16C135.6,396.1,135.1,396.8,134.4,396.8Z"
      />
      <path
        className="ba3ce12d-dfa6-40c1-9ed6-695740fdb4e4"
        d="M382.1,380.8c0,10.6-5.4,12.8-16,12.8s-16-2.2-16-12.8,5.3-32,16-32S382.1,370.2,382.1,380.8Z"
      />
      <path
        className="bb6cb336-0a3f-4b75-a281-609d2da54d0c"
        d="M366.1,396.8a1.6,1.6,0,0,1-1.6-1.6v-16a1.6,1.6,0,0,1,1.6-1.6,1.6,1.6,0,0,1,1.6,1.6v16A1.6,1.6,0,0,1,366.1,396.8Z"
      />
    </g>
  </svg>
)

export default ImgHouseSmall
