import { SVGProps } from 'react'

const ImgDocumentSigned = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b2871e67-e71a-4536-9a85-7706ddd926bf{fill:none;}.b151b637-5702-419b-9348-9702dbaa1d1a{opacity:0.15;fill:url(#a29f23ac-7ad2-4868-b073-7dacd3d66222);}.f0da080a-9127-4c35-b0a6-4f3301104646{fill:url(#a8697dfa-845c-4742-88be-625de005baaa);}.fc31ddbd-8398-44e7-8dad-c0331deef449{fill:url(#a723b029-cdcb-40e7-9c5f-943b29b32d74);}.a8ed303e-6e59-494c-b7c4-632105fabcc0,.bbf57737-b2a9-4d6b-9c9e-0311576c32ce{fill:#fff;}.a8ed303e-6e59-494c-b7c4-632105fabcc0,.eafcbe39-cce8-476a-a321-7ecb5ff00db7{opacity:0.9;isolation:isolate;}.eafcbe39-cce8-476a-a321-7ecb5ff00db7{fill:#f7ced7;}.e15bc6a9-8833-42fd-ad5f-7e92da3f65e6{fill:url(#a01c4202-39f5-426b-8816-cfa8ad5b1bd7);}.b53c90fd-a738-44ef-8a62-5adc2e2d9d6f{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a29f23ac-7ad2-4868-b073-7dacd3d66222"
        x1={72.71}
        y1={433.57}
        x2={271.35}
        y2={234.93}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a8697dfa-845c-4742-88be-625de005baaa"
        x1={163.24}
        y1={11666.55}
        x2={358.48}
        y2={11861.79}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="a723b029-cdcb-40e7-9c5f-943b29b32d74"
        x1={299.81}
        y1={11710.09}
        x2={146.48}
        y2={11556.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="a01c4202-39f5-426b-8816-cfa8ad5b1bd7"
        x1={225.65}
        y1={11704.06}
        x2={184.12}
        y2={11662.53}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
    </defs>
    <g id="a7be253d-2298-47c5-8242-9faeeef06cf3" data-name="Layer 1">
      <g id="f2ecd33e-e139-4e6b-9257-4a2f33221274" data-name="backdrop">
        <path
          className="b2871e67-e71a-4536-9a85-7706ddd926bf"
          d="M512,256c0,141.4-114.6,256-256,256a258.9,258.9,0,0,1-38.6-2.9,255.3,255.3,0,0,1-127.2-58,270.2,270.2,0,0,1-29.3-29.3A254.8,254.8,0,0,1,1.6,284.4,242.1,242.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b151b637-5702-419b-9348-9702dbaa1d1a"
        d="M344.8,381.7,217.4,509.1a255.3,255.3,0,0,1-127.2-58L60.9,421.8A254.8,254.8,0,0,1,1.6,284.4L161.2,124.8Z"
      />
      <path
        className="f0da080a-9127-4c35-b0a6-4f3301104646"
        d="M161.2,124.8V368.2a19,19,0,0,1-19,19H331.5a19,19,0,0,0,19-19V124.8Z"
      />
      <path
        className="fc31ddbd-8398-44e7-8dad-c0331deef449"
        d="M312.5,368.2V356.8H123.2v11.4a19,19,0,0,0,19,19H331.5A19,19,0,0,1,312.5,368.2Z"
      />
      <path className="a8ed303e-6e59-494c-b7c4-632105fabcc0" d="M256.1,165.2H190.3a2,2,0,0,1,0-4h65.8a2,2,0,0,1,0,4Z" />
      <path className="eafcbe39-cce8-476a-a321-7ecb5ff00db7" d="M321.9,329.4H256.1a2,2,0,0,1,0-4h65.8a2,2,0,0,1,0,4Z" />
      <path
        className="a8ed303e-6e59-494c-b7c4-632105fabcc0"
        d="M321.9,195.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="a8ed303e-6e59-494c-b7c4-632105fabcc0"
        d="M321.9,225.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="a8ed303e-6e59-494c-b7c4-632105fabcc0"
        d="M321.9,255.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="a8ed303e-6e59-494c-b7c4-632105fabcc0"
        d="M321.9,285.2H190.3a2,2,0,0,1,0-4H321.9a2,2,0,0,1,0,4Z"
      />
      <path
        className="e15bc6a9-8833-42fd-ad5f-7e92da3f65e6"
        d="M230.4,313l-1.7-2.1a10.9,10.9,0,0,1-1.4-2.3l-1-2.6a9,9,0,0,0-9.8-5.6l-2.6.4h-2.7l-2.7-.4a9,9,0,0,0-9.8,5.6l-1,2.6a7.9,7.9,0,0,1-1.4,2.3l-1.7,2.1a8.7,8.7,0,0,0,0,11.3l1.7,2.2a6.8,6.8,0,0,1,1.4,2.3l1,2.5a9,9,0,0,0,9.8,5.7l2.7-.4h2.7l2.6.4a9,9,0,0,0,9.8-5.7l1-2.5a8.8,8.8,0,0,1,1.4-2.3l1.7-2.2a8.7,8.7,0,0,0,0-11.3Z"
      />
      <circle className="b53c90fd-a738-44ef-8a62-5adc2e2d9d6f" cx={212.5} cy={318.7} r={12} />
      <path
        className="bbf57737-b2a9-4d6b-9c9e-0311576c32ce"
        d="M212.5,331.6a12.9,12.9,0,1,1,12.9-12.9A12.9,12.9,0,0,1,212.5,331.6Zm0-24a11.1,11.1,0,1,0,11.1,11.1A11.1,11.1,0,0,0,212.5,307.6Z"
      />
      <g id="af4e4f45-ea86-4607-a276-b677ddb81bcb" data-name="7.1-Dashboard">
        <g id="ac0ca46b-b96a-41a0-a740-003d8d8f76c5" data-name="7.1.1-Dashboard-S2">
          <g id="f738aebe-a6b5-440a-938a-f5c5280aba6f" data-name="app-sidebar-nav1">
            <path
              className="bbf57737-b2a9-4d6b-9c9e-0311576c32ce"
              d="M211.2,322.1l-.4-.2-3.1-3.1a.6.6,0,0,1,0-.8.5.5,0,0,1,.8,0l2.7,2.7,5.3-5.3a.6.6,0,0,1,.8,0,.5.5,0,0,1,0,.8l-5.7,5.7A.5.5,0,0,1,211.2,322.1Z"
            />
          </g>
        </g>
      </g>
      <path
        className="eafcbe39-cce8-476a-a321-7ecb5ff00db7"
        d="M258.3,320c4.5-3,7.6-7.3,10.8-11.5a36,36,0,0,1,5.1-5.9c1.7-1.7,4.5-4.1,6.9-2.3s2.7,5,3.4,7.5a20.1,20.1,0,0,0,4.1,8.1,8.1,8.1,0,0,0,7,3.3,6.1,6.1,0,0,0,3.3-1.4,7.9,7.9,0,0,0,1.8-3.1l.3-.6c.1-.4.2-.2,0-.1s-.2.1.1.4l1,.7a12.8,12.8,0,0,0,4,2.1,32.4,32.4,0,0,0,9.3,1,1.8,1.8,0,0,0,0-3.6,29,29,0,0,1-8.3-.9,13.7,13.7,0,0,1-3.2-1.6c-1.1-.9-2.1-1.9-3.6-1.6a3.6,3.6,0,0,0-2.5,2c-.5.8-.7,2.1-1.4,2.7s-4.6-.8-5.7-2.4-2.6-5.5-3.5-8.6-2-5.7-4.6-7.2c-5.5-3.2-11,2.6-14.2,6.4s-6.8,10.1-11.9,13.5c-1.9,1.3-.1,4.4,1.8,3.1Z"
      />
    </g>
  </svg>
)

export default ImgDocumentSigned
