const ImgHandsWithHeartGlitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="e8f7631f-aaa8-4376-8d6f-aab895d44cce"
          x1={-2.43}
          y1={241.82}
          x2={68.68}
          y2={241.82}
          gradientTransform="matrix(1.31 0 0 2 159.8 -268.4)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#10069f" stopOpacity={0} />
          <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="fa4c9481-760b-4c7f-902a-d34f4c7bfffb"
          x1={-686.96}
          y1={-6641.83}
          x2={-645.99}
          y2={-6600.86}
          gradientTransform="matrix(1 0 0 -1 800 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#281fa9" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="a4c3f764-7fd5-490b-831b-a400efc1e15d"
          x1={-699.75}
          y1={-6735.9}
          x2={-607.67}
          y2={-6643.82}
          gradientTransform="matrix(1 0 0 -1 800 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5851bc" />
          <stop offset={1} stopColor="#4038b2" />
        </linearGradient>
        <linearGradient
          id="ab972dfd-daa8-4d12-b547-bdb72e6956dc"
          x1={-4106.48}
          y1={-6759.98}
          x2={-4156.39}
          y2={-6710.07}
          gradientTransform="rotate(180 -1976 -3196.25)"
          xlinkHref="#a4c3f764-7fd5-490b-831b-a400efc1e15d"
        />
        <linearGradient
          id="b593b9cc-819c-42dc-bb2c-965d829e28be"
          x1={-5262.39}
          y1={-6642.61}
          x2={-5243.46}
          y2={-6640.09}
          gradientTransform="rotate(180 -2434 -3196.25)"
          xlinkHref="#fa4c9481-760b-4c7f-902a-d34f4c7bfffb"
        />
        <linearGradient
          id="bffe22d4-47e7-491f-8adf-e513d6768f0d"
          x1={-5162.58}
          y1={-6774.42}
          x2={-5346.02}
          y2={-6590.98}
          gradientTransform="rotate(180 -2434 -3196.25)"
          xlinkHref="#a4c3f764-7fd5-490b-831b-a400efc1e15d"
        />
        <linearGradient
          id="e13b6fe2-bc97-40fa-9173-99b8995c5620"
          x1={434.27}
          y1={-6765.06}
          x2={499.49}
          y2={-6699.85}
          gradientTransform="matrix(1 0 0 -1 -116 -6392.48)"
          xlinkHref="#a4c3f764-7fd5-490b-831b-a400efc1e15d"
        />
        <linearGradient
          id="abcb4003-7d1a-4f4e-b1fc-37616261faa8"
          x1={200.03}
          y1={-6597.55}
          x2={277.26}
          y2={-6578.3}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="e65e5f1b-a6d2-43a7-913c-73b8ca182b1e"
          x1={261.92}
          y1={-6599.83}
          x2={357.75}
          y2={-6504}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="e4f96f79-2a3e-4abb-ab22-a3e7e8483f72"
          x1={138.51}
          y1={-6488.26}
          x2={301.52}
          y2={-6651.27}
          gradientTransform="matrix(1 0 0 -1 0 -6392.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f9d8df" />
          <stop offset={1} stopColor="#f7ced7" />
        </linearGradient>
        <style>{'.b478fa87-ab63-40c5-ba17-5ee0c9f108ad{fill:#37c49f}'}</style>
      </defs>
      <g id="e4b8c0ed-1af6-4f31-bc94-37480848a4bf" data-name="Layer 1">
        <circle
          cx={256}
          cy={256}
          r={256}
          style={{
            fill: 'none',
          }}
          id="b4192994-9843-493a-b005-6c9814608a24"
          data-name="backdrop"
        />
        <path
          transform="rotate(-45 203.392 216.052)"
          style={{
            opacity: 0.15,
            fill: 'url(#e8f7631f-aaa8-4376-8d6f-aab895d44cce)',
          }}
          d="M156.7 121.1h93.5v189.97h-93.5z"
        />
        <path
          d="m108.6 316.8-6.8-127.9a3.3 3.3 0 0 1 3.6-2.8h.1l2 .3a16 16 0 0 1 13.3 16.4l16.6 94.7Z"
          style={{
            fill: 'url(#fa4c9481-760b-4c7f-902a-d34f4c7bfffb)',
          }}
        />
        <path
          d="m149.4 370.4-39.2-47.5a13.3 13.3 0 0 1-1.6-6.2L89.4 182.4a3.2 3.2 0 0 1 3.2-3.2H94a15.9 15.9 0 0 1 15.9 14.6l27.5 103.8 61.5 39.8a38.6 38.6 0 0 1 12.1 28v37.8a6.4 6.4 0 0 1-6.4 6.4h-44.8a6.4 6.4 0 0 1-6.4-6.4v-17.3a32.2 32.2 0 0 0-4-15.5Z"
          style={{
            fill: 'url(#a4c3f764-7fd5-490b-831b-a400efc1e15d)',
          }}
        />
        <path
          d="M211 355.5v47.7a6.4 6.4 0 0 1-6.4 6.4h-44.8a6.4 6.4 0 0 1-6.4-6.4V384l16-51.2-12.8-32c-6.4-12.8-6.9-27.6.9-30.2l2.5-.8a3.2 3.2 0 0 1 4.1 1.9l44.6 70.8a38.7 38.7 0 0 1 2.3 13Z"
          style={{
            fill: 'url(#ab972dfd-daa8-4d12-b547-bdb72e6956dc)',
          }}
        />
        <path
          d="m402.4 316.8 6.8-127.9a3.3 3.3 0 0 0-3.6-2.8h-.1l-2 .3a16 16 0 0 0-13.3 16.4l-16.6 94.7Z"
          style={{
            fill: 'url(#b593b9cc-819c-42dc-bb2c-965d829e28be)',
          }}
        />
        <path
          d="m361.6 370.4 39.2-47.5a13.3 13.3 0 0 0 1.6-6.2l19.2-134.3a3.2 3.2 0 0 0-3.2-3.2H417a15.9 15.9 0 0 0-15.9 14.6l-27.5 103.8-61.5 39.8a38.6 38.6 0 0 0-12.1 28v37.8a6.4 6.4 0 0 0 6.4 6.4h44.8a6.4 6.4 0 0 0 6.4-6.4v-17.3a32.2 32.2 0 0 1 4-15.5Z"
          style={{
            fill: 'url(#bffe22d4-47e7-491f-8adf-e513d6768f0d)',
          }}
        />
        <path
          d="M300 355.5v47.7a6.4 6.4 0 0 0 6.4 6.4h44.8a6.4 6.4 0 0 0 6.4-6.4V384l-16-51.2 12.8-32c6.4-12.8 6.9-27.6-.9-30.2l-2.5-.8a3.2 3.2 0 0 0-4.1 1.9l-44.6 70.8a38.7 38.7 0 0 0-2.3 13Z"
          style={{
            fill: 'url(#e13b6fe2-bc97-40fa-9173-99b8995c5620)',
          }}
        />
        <path
          d="M256 294.4s-52.9-48.5-66.8-62.4c-25.2-25.3-38.8-48.6-38.8-76.7 0-32.7 23.4-52.9 56.1-52.9a59.7 59.7 0 0 1 49.5 27.2"
          style={{
            fill: 'url(#abcb4003-7d1a-4f4e-b1fc-37616261faa8)',
          }}
        />
        <path
          d="M256 129.6a59.7 59.7 0 0 1 49.5-27.2c32.7 0 56.1 20.2 56.1 52.9 0 28.1-13.6 51.4-38.8 76.7-13.9 13.9-66.8 62.4-66.8 62.4Z"
          style={{
            fill: 'url(#e65e5f1b-a6d2-43a7-913c-73b8ca182b1e)',
          }}
        />
        <path
          d="M256 294.4s-52.9-48.5-66.8-62.4c-25.2-25.3-38.8-48.6-38.8-76.7 0-32.7 23.4-52.9 56.1-52.9a59.7 59.7 0 0 1 49.5 27.2Z"
          style={{
            fill: 'url(#e4f96f79-2a3e-4abb-ab22-a3e7e8483f72)',
          }}
        />
        <path
          className="b478fa87-ab63-40c5-ba17-5ee0c9f108ad"
          d="M370.5 112.9a7.9 7.9 0 1 1 7.9-7.9 8 8 0 0 1-7.9 7.9Zm0-12.8a4.9 4.9 0 1 0 4.9 4.9 4.9 4.9 0 0 0-4.9-4.9ZM173 158.6l-.9-.3-11.8-8.9-11.9 8.9a1.5 1.5 0 0 1-1.8 0l-12.7-9.6a1.5 1.5 0 0 1 1.8-2.4l11.8 8.9 11.9-8.9a1.5 1.5 0 0 1 1.8 0l11.8 8.9 11.9-8.9a1.5 1.5 0 0 1 1.8 2.4l-12.8 9.6a1.2 1.2 0 0 1-.9.3ZM296 223.7h-6.5v-6.4a1.5 1.5 0 0 0-3 0v6.4H280a1.5 1.5 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5h6.5v6.5a1.5 1.5 0 1 0 3 0v-6.5h6.5a1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5Z"
        />
      </g>
    </svg>
  )
}

export default ImgHandsWithHeartGlitter
