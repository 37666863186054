import { SVGProps } from 'react'

const ImgPuzzleInHandGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b39cce8a-9af3-475e-996d-e646ee92eb36{fill:none;}.a62654a2-e935-4a20-9636-6679e24667ba{opacity:0.15;fill:url(#fa6dd8e9-5968-40c7-ac0b-0bb0a33f26f9);}.f4138d57-2974-46ee-bbb1-845e3aecbe0c{fill:url(#b737d281-182e-4e32-b3fd-84b902f90aa0);}.aa9edc45-02cb-4cb7-84d9-bb5f9dabbcac{fill:url(#b82fd93c-a51f-43bd-bfb9-4b1bb946132b);}.ada3da9f-1b82-4a14-a728-4cc1fdd4e083{fill:url(#ae15562c-6dbd-4ce0-bf86-240bad025ac4);}.f6b5974f-9262-4082-87c7-c97a8b3ed2b0{fill:#37c49f;}.f6f4abce-cbd7-4ab8-823e-ee9e943b3619{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="fa6dd8e9-5968-40c7-ac0b-0bb0a33f26f9"
        x1={78.35}
        y1={440.37}
        x2={271.53}
        y2={247.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b737d281-182e-4e32-b3fd-84b902f90aa0"
        x1={227.36}
        y1={14223.01}
        x2={435.61}
        y2={14014.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b82fd93c-a51f-43bd-bfb9-4b1bb946132b"
        x1={321.07}
        y1={14168.52}
        x2={186.09}
        y2={14303.5}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="ae15562c-6dbd-4ce0-bf86-240bad025ac4"
        x1={296.02}
        y1={14181.86}
        x2={347.69}
        y2={14130.19}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
    </defs>
    <g id="ba047146-84e5-463a-8adc-60102e479827" data-name="Layer 1">
      <g id="aa3c725e-ed73-4b47-98fc-f8abff4a4531" data-name="backdrop">
        <path
          className="b39cce8a-9af3-475e-996d-e646ee92eb36"
          d="M512,256c0,141.4-114.6,256-256,256a260.7,260.7,0,0,1-49.6-4.8,255.9,255.9,0,0,1-129.2-68l-.6-.6-3.2-3.2-.6-.6A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a62654a2-e935-4a20-9636-6679e24667ba"
        d="M304,409.6l-97.6,97.6a255.9,255.9,0,0,1-129.2-68l-.6-.6-3.2-3.2-.6-.6A255.9,255.9,0,0,1,7.2,316.6L174.1,149.8Z"
      />
      <path
        className="f4138d57-2974-46ee-bbb1-845e3aecbe0c"
        d="M332.8,208a20.9,20.9,0,0,0-16,7V147.2A19.2,19.2,0,0,0,297.6,128h-3.2a6.4,6.4,0,0,0-6.4,6.4v-16a16,16,0,1,0-32,0v19.2a6.4,6.4,0,0,0-6.4-6.4h-3.2a19.2,19.2,0,0,0-19.2,19.2v32a6.4,6.4,0,0,0-6.4-6.4h-3.2a19.2,19.2,0,0,0-19.2,19.2h0V300.7a36.9,36.9,0,0,0,2.2,12.7L215.4,365a36.9,36.9,0,0,1,2.2,12.7v31.9H304V366.8a38.2,38.2,0,0,1,6.4-21.2h0l22.4-38.2a38.3,38.3,0,0,0,6.4-21.3V214.4A6.4,6.4,0,0,0,332.8,208Z"
      />
      <path
        className="aa9edc45-02cb-4cb7-84d9-bb5f9dabbcac"
        d="M383.8,221a25.5,25.5,0,0,0-28.3-22.4,24.7,24.7,0,0,0-11.4,4.2,3.2,3.2,0,0,1-4.4-1,3.4,3.4,0,0,1-.5-1.7V153.6a6.4,6.4,0,0,0-6.4-6.4H279.9a3.2,3.2,0,0,1-3.2-3.2,3.4,3.4,0,0,1,.5-1.7,25.6,25.6,0,1,0-42.4,0,3.2,3.2,0,0,1-1,4.4,3.4,3.4,0,0,1-1.7.5H179.2a6.4,6.4,0,0,0-6.4,6.4v46.5a3.2,3.2,0,0,1-3.2,3.2,3.4,3.4,0,0,1-1.7-.5,25.6,25.6,0,1,0,0,42.4,3.2,3.2,0,0,1,4.4,1,3.4,3.4,0,0,1,.5,1.7v46.5a6.4,6.4,0,0,0,6.4,6.4h54.6a3.2,3.2,0,0,0,3.2-3.2,2.8,2.8,0,0,0-.4-1.5,22.5,22.5,0,1,1,38.7,0,3.2,3.2,0,0,0,1.3,4.3,2.9,2.9,0,0,0,1.6.4h54.6a6.4,6.4,0,0,0,6.4-6.4V247.9a3.2,3.2,0,0,1,3.2-3.2,3.4,3.4,0,0,1,1.7.5,25.5,25.5,0,0,0,35.5-6.9,24.6,24.6,0,0,0,4.2-17.3Z"
      />
      <path
        className="ada3da9f-1b82-4a14-a728-4cc1fdd4e083"
        d="M333,208c-14.2-.1-22.6,11.4-22.6,25.6v35.2c-34.5,5.7-48,32-48.6,52.4-.4,13.8.6,33.6,13.2,39.2l29,6.4a38.2,38.2,0,0,1,6.4-21.2h0l22.4-38.2a38.3,38.3,0,0,0,6.4-21.3V214.4A6.3,6.3,0,0,0,333,208Z"
      />
      <path
        className="f6b5974f-9262-4082-87c7-c97a8b3ed2b0"
        d="M155.6,110.8a8.4,8.4,0,1,1,8.4-8.4A8.5,8.5,0,0,1,155.6,110.8Zm0-12.8a4.4,4.4,0,0,0-4.4,4.4,4.4,4.4,0,0,0,8.8,0A4.4,4.4,0,0,0,155.6,98Z"
      />
      <path
        className="f6b5974f-9262-4082-87c7-c97a8b3ed2b0"
        d="M392,147.8h-6v-6a2,2,0,1,0-4,0v6h-6a2,2,0,0,0,0,4h6v6a2,2,0,0,0,4,0v-6h6a2,2,0,0,0,0-4Z"
      />
      <path
        className="f6b5974f-9262-4082-87c7-c97a8b3ed2b0"
        d="M185.6,350.1a1.9,1.9,0,0,1-1.2-.4l-12.8-9.6a2,2,0,1,1,2.4-3.2l11.6,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.6,8.7,11.6-8.7a2,2,0,0,1,2.4,3.2l-12.8,9.6a2.1,2.1,0,0,1-2.4,0L198.4,341l-11.6,8.7A1.9,1.9,0,0,1,185.6,350.1Z"
      />
      <path className="f6f4abce-cbd7-4ab8-823e-ee9e943b3619" d="M256,230a6,6,0,1,1,6-6A6,6,0,0,1,256,230Z" />
      <path className="f6f4abce-cbd7-4ab8-823e-ee9e943b3619" d="M230.4,230a6,6,0,1,1,6-6A6,6,0,0,1,230.4,230Z" />
      <path className="f6f4abce-cbd7-4ab8-823e-ee9e943b3619" d="M281.6,230a6,6,0,1,1,6-6A6,6,0,0,1,281.6,230Z" />
    </g>
  </svg>
)

export default ImgPuzzleInHandGlitter
