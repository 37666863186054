import { SVGProps } from 'react'

const ImgLetterEnvelopeWithCheckGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a6c31f88-d95a-4d84-9356-29e9dcc2cd86{fill:none;}.f8ad591d-9d72-4011-bfe7-b1f1adf98a4d{opacity:0.15;fill:url(#f678e2c7-c971-4096-bc26-89cac0e75f9c);}.fbfd836f-fa63-4e74-ae8e-4d85567b2230{fill:url(#b38c8ce8-65c4-4c74-8730-15918c307b38);}.fd9cd7b0-d873-496c-a4da-8ee2cf39c4a8{fill:url(#b64a46a2-5888-4a7b-adc8-4e25bd892228);}.b0d69bc9-2257-4810-9613-f6ddcb2badb9{fill:url(#b3250317-a3e5-47f4-81e4-e62494cdf346);}.bc6e69e6-2824-4f29-8e86-0f15ce539bc7{fill:#f7ced7;}.fd235ea9-c646-4a11-a063-92cfc51883b6{fill:#37c49f;}.f2ceb2fb-a0df-4f2d-b593-32e09500ccb6{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="f678e2c7-c971-4096-bc26-89cac0e75f9c"
        x1={78.87}
        y1={440.82}
        x2={279.05}
        y2={240.64}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b38c8ce8-65c4-4c74-8730-15918c307b38"
        x1={263.18}
        y1={15488.09}
        x2={233.45}
        y2={15749.37}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b64a46a2-5888-4a7b-adc8-4e25bd892228"
        x1={129.07}
        y1={15408.26}
        x2={340.78}
        y2={15619.98}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fcebef" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="b3250317-a3e5-47f4-81e4-e62494cdf346"
        x1={230.47}
        y1={15360.17}
        x2={272.87}
        y2={15533.29}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
    </defs>
    <g id="a7b28dd1-d202-4739-aeb6-30733cceb60b" data-name="Layer 1">
      <g id="fbe36459-4ca6-46bb-a1f0-6cbe8daebf4f" data-name="backdrop">
        <path
          className="a6c31f88-d95a-4d84-9356-29e9dcc2cd86"
          d="M512,256c0,131.3-98.8,239.5-226.1,254.3A268.3,268.3,0,0,1,256,512,255.1,255.1,0,0,1,79.1,441q-4.2-3.9-8.1-8.1A255.1,255.1,0,0,1,0,256c0-4.4.1-8.8.3-13.1C7.2,107.6,119,0,256,0,397.4,0,512,114.6,512,256Z"
        />
      </g>
      <path
        className="f8ad591d-9d72-4011-bfe7-b1f1adf98a4d"
        d="M387.9,408.2l-102,102.1A268.3,268.3,0,0,1,256,512,255.1,255.1,0,0,1,79.1,441L71,432.9A255.1,255.1,0,0,1,0,256c0-4.4.1-8.8.3-13.1L140.8,102.4Z"
      />
      <polygon
        className="fbfd836f-fa63-4e74-ae8e-4d85567b2230"
        points="121.6 230.4 256 326.4 390.4 230.4 256 134.4 121.6 230.4"
      />
      <path className="fd9cd7b0-d873-496c-a4da-8ee2cf39c4a8" d="M332.8,102.4h-192v288H371.2V140.8Z" />
      <path
        className="b0d69bc9-2257-4810-9613-f6ddcb2badb9"
        d="M384,409.6H128a6.4,6.4,0,0,1-6.4-6.4V230.4l134.4,96,134.4-96V403.2A6.4,6.4,0,0,1,384,409.6Z"
      />
      <polygon
        className="bc6e69e6-2824-4f29-8e86-0f15ce539bc7"
        points="332.8 140.8 371.2 140.8 332.8 102.4 332.8 140.8"
      />
      <circle className="fd235ea9-c646-4a11-a063-92cfc51883b6" cx={256} cy={219.6} r={40} />
      <g id="b0a83cac-fbf2-45c0-af87-a4279686556e" data-name="7.1-Dashboard">
        <g id="f25eec24-1fae-4bef-aa01-c52ead0fe83a" data-name="7.1.1-Dashboard-S2">
          <g id="b605b9fc-b9c4-4ba3-87e3-ced62d393521" data-name="app-sidebar-nav1">
            <path
              className="f2ceb2fb-a0df-4f2d-b593-32e09500ccb6"
              d="M251.6,231.6a2,2,0,0,1-1.5-.6L239,219.9a1.9,1.9,0,0,1,0-2.8,2.1,2.1,0,0,1,2.9,0l9.7,9.7,18.5-18.6a2.1,2.1,0,0,1,2.9,0,1.9,1.9,0,0,1,0,2.8l-20,20A2,2,0,0,1,251.6,231.6Z"
            />
          </g>
        </g>
      </g>
      <path
        className="fd235ea9-c646-4a11-a063-92cfc51883b6"
        d="M144.6,334.3a7.9,7.9,0,1,1,7.9-7.9A7.9,7.9,0,0,1,144.6,334.3Zm0-12.8a4.9,4.9,0,0,0,0,9.8,4.9,4.9,0,0,0,0-9.8Z"
      />
      <path
        className="fd235ea9-c646-4a11-a063-92cfc51883b6"
        d="M409.9,378.1a1.4,1.4,0,0,1-.9-.3l-11.9-8.9-11.8,8.9a1.5,1.5,0,0,1-1.8,0l-12.8-9.6a1.5,1.5,0,1,1,1.8-2.4l11.9,8.9,11.8-8.9a1.5,1.5,0,0,1,1.8,0l11.9,8.9,11.8-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.7,9.6A1.6,1.6,0,0,1,409.9,378.1Z"
      />
      <path
        className="fd235ea9-c646-4a11-a063-92cfc51883b6"
        d="M306.9,131.3h-6.4v-6.4a1.5,1.5,0,0,0-1.5-1.5,1.4,1.4,0,0,0-1.5,1.5v6.4H291a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5h6.5v6.5a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6.5h6.4a1.4,1.4,0,0,0,1.5-1.5A1.5,1.5,0,0,0,306.9,131.3Z"
      />
    </g>
  </svg>
)

export default ImgLetterEnvelopeWithCheckGlitter
