import { motion } from 'framer-motion'

type Props = {
  color: string
  animate?: boolean
  className?: string
}

const variants = {
  initial: {
    y: 30,
    rotate: 25,
  },
  animate: {
    y: 0,
    rotate: 0,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
}

const TellaLogo = ({ color, animate, className }: Props) => {
  return (
    <div className="flex">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="566"
        height="234"
        viewBox="0 0 566.92 234.208"
        className={`${className ? className : 'w-28'}  fill-current ${color}`}
      >
        <g id="tella-logo-black-placement" transform="translate(-126.22 -126.354)">
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="31.01"
            height="200.73"
            transform="translate(487.26 157.26)"
          />
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="31.01"
            height="200.73"
            transform="translate(428.45 157.26)"
          />
          <path
            id="Path_1"
            data-name="Path 1"
            d="M252.44,297.45a63.11,63.11,0,0,1-126.22,0V184.76h31v31.12h64.32v31H157.23v50.56a32.1,32.1,0,1,0,64.2,0Z"
          />
          <path
            id="Path_6"
            data-name="Path 6"
            d="M662.14,215.89v10.32a74.1,74.1,0,1,0,0,120.5V358h31V215.89ZM619.05,329.55a43.1,43.1,0,1,1,43.09-43.093A43.1,43.1,0,0,1,619.05,329.55Z"
          />
          <motion.path
            id="Path_7"
            data-name="Path 7"
            d="M398.71,215.88a74.09,74.09,0,1,0-3,10.34h-35a43.07,43.07,0,0,1-74.62-10.39h80.42ZM286.06,184.82a43.1,43.1,0,0,1,80.42,0Z"
            variants={animate ? variants : undefined}
            initial="initial"
            animate="animate"
          />
          <motion.g variants={animate ? variants : undefined} initial="initial" animate="animate">
            <path id="Path_8" data-name="Path 8" d="M274.66,321.2l13,34.25L280.79,358l-13-34.25Z" />
            <path id="Path_9" data-name="Path 9" d="M288.36,316l13,34.25-6.85,2.59-13-34.25Z" />
            <path id="Path_10" data-name="Path 10" d="M302.06,310.82l13,34.25-6.85,2.59-13-34.25Z" />
            <path id="Path_11" data-name="Path 11" d="M315.76,305.63l13,34.25-6.85,2.59-13-34.25Z" />
            <path id="Path_12" data-name="Path 12" d="M329.46,300.44l13,34.25-6.84,2.59-13-34.25Z" />
            <path id="Path_13" data-name="Path 13" d="M343.16,295.25l13,34.25-6.85,2.59-13-34.25Z" />
            <path id="Path_14" data-name="Path 14" d="M356.86,290.06l13,34.25L363,326.9l-13-34.25Z" />
            <path id="Path_15" data-name="Path 15" d="M370.56,284.87l13,34.25-6.85,2.59-13-34.25Z" />
            <path id="Path_16" data-name="Path 16" d="M384.25,279.68l13,34.25-6.85,2.59-13-34.25Z" />
            <path id="Path_17" data-name="Path 17" d="M398,274.49l13,34.25-6.85,2.59-13-34.25Z" />
          </motion.g>
        </g>
      </motion.svg>
    </div>
  )
}

export default TellaLogo
