import { SVGProps } from 'react'

const ImgMegaphoneGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.e623890c-5459-402a-b749-9ccd752c3737{fill:none;}.f1146fd2-037c-479d-90ec-ea6f49a9f846{opacity:0.15;fill:url(#b4ab00c1-16aa-4be9-a47e-22b55261bd16);}.e444d55f-5032-466c-82fe-cc1b32b2f2b1{fill:url(#bda6880e-97f2-4d2b-894f-01569874744e);}.a50f9e56-ed47-488e-868e-59ea918b7e7a{fill:#37c49f;}.a0b01ffa-9901-4ae2-94af-63a073b6f0a4{fill:url(#fc910dc1-7757-448a-9da6-6dbe92e82f3b);}.e2b023a8-86e5-4768-9c71-083e8622630a{fill:url(#a047ebe8-db80-409a-96b2-6253e3a19e86);}.acee69eb-fa9a-428e-a995-781b3c4fc47f{fill:url(#e6210843-15c3-491f-9e1f-4b316fd31a46);}.a47311f2-6f02-4981-b852-f35062bacc90{fill:url(#be77152c-80d9-4ad8-b0e1-22ec5ea6f31e);}.a78dadff-0682-4c2f-8b15-9ef8a1105673{fill:url(#b6f2dbe8-2374-451a-bdd8-62db12488331);}.ada443a4-7764-4311-b3e3-79f1f6b3ca7e{fill:url(#aae4cdd7-0d78-4218-b74a-8e682f61529a);}.e55215d3-a934-47bb-b7ce-37fa8941a760{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="b4ab00c1-16aa-4be9-a47e-22b55261bd16"
        x1={80.73}
        y1={442.75}
        x2={282.54}
        y2={240.94}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bda6880e-97f2-4d2b-894f-01569874744e"
        x1={119.8}
        y1={8060.04}
        x2={219.87}
        y2={8160.12}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="fc910dc1-7757-448a-9da6-6dbe92e82f3b"
        x1={248.45}
        y1={8019.64}
        x2={431.65}
        y2={8202.84}
        xlinkHref="#bda6880e-97f2-4d2b-894f-01569874744e"
      />
      <linearGradient
        id="a047ebe8-db80-409a-96b2-6253e3a19e86"
        x1={123.26}
        y1={8091.54}
        x2={100.29}
        y2={8111.33}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="e6210843-15c3-491f-9e1f-4b316fd31a46"
        x1={390.4}
        y1={230.4}
        x2={396.8}
        y2={230.4}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#a047ebe8-db80-409a-96b2-6253e3a19e86"
      />
      <linearGradient
        id="be77152c-80d9-4ad8-b0e1-22ec5ea6f31e"
        x1={381.37}
        y1={8091.72}
        x2={412.61}
        y2={8122.97}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="b6f2dbe8-2374-451a-bdd8-62db12488331"
        x1={210.49}
        y1={7960.17}
        x2={128.91}
        y2={8041.75}
        xlinkHref="#be77152c-80d9-4ad8-b0e1-22ec5ea6f31e"
      />
      <linearGradient
        id="aae4cdd7-0d78-4218-b74a-8e682f61529a"
        x1={189.55}
        y1={8021.57}
        x2={205.36}
        y2={8017.33}
        gradientTransform="matrix(1, 0, 0, -1, 0, 8337.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={0.99} stopColor="#fefafb" />
      </linearGradient>
    </defs>
    <g id="ad1a0397-b25b-4aa3-8648-b0279886e556" data-name="Layer 1">
      <g id="a6a8cf57-41e5-446b-a3bb-cc1ecf166b2b" data-name="backdrop">
        <path
          className="e623890c-5459-402a-b749-9ccd752c3737"
          d="M512,256c0,141.4-114.6,256-256,256-6.4,0-12.8-.2-19-.7A255.2,255.2,0,0,1,77.2,439.2l-4.4-4.4A254.9,254.9,0,0,1,3.1,295.6,246.1,246.1,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="f1146fd2-037c-479d-90ec-ea6f49a9f846"
        d="M394.9,353.3,237,511.3A255.2,255.2,0,0,1,77.2,439.2l-4.4-4.4A254.9,254.9,0,0,1,3.1,295.6L118.9,179.8Z"
      />
      <path
        className="e444d55f-5032-466c-82fe-cc1b32b2f2b1"
        d="M128,176h83.2V284.8H128A12.8,12.8,0,0,1,115.2,272V188.8A12.8,12.8,0,0,1,128,176Z"
      />
      <path
        className="a50f9e56-ed47-488e-868e-59ea918b7e7a"
        d="M269.7,134.6a2,2,0,0,1-2-2V116.7a2,2,0,0,1,2-2,1.9,1.9,0,0,1,2,2v15.9A1.9,1.9,0,0,1,269.7,134.6Z"
      />
      <path className="a50f9e56-ed47-488e-868e-59ea918b7e7a" d="M277.7,126.7H261.8a2,2,0,0,1,0-4h15.9a2,2,0,0,1,0,4Z" />
      <path
        className="a50f9e56-ed47-488e-868e-59ea918b7e7a"
        d="M434.9,174.7a8.4,8.4,0,1,1,8.4-8.4A8.4,8.4,0,0,1,434.9,174.7Zm0-12.7a4.4,4.4,0,1,0,4.4,4.3A4.4,4.4,0,0,0,434.9,162Z"
      />
      <polygon
        className="a0b01ffa-9901-4ae2-94af-63a073b6f0a4"
        points="390.4 355.2 211.2 284.8 211.2 176 390.4 105.6 390.4 355.2"
      />
      <path
        className="e2b023a8-86e5-4768-9c71-083e8622630a"
        d="M115.2,259.2H102.4V238.9A43.7,43.7,0,0,1,115.2,208h0Z"
      />
      <path
        className="acee69eb-fa9a-428e-a995-781b3c4fc47f"
        d="M396.8,112a6.4,6.4,0,0,0-6.4-6.4V355.2a6.4,6.4,0,0,0,6.4-6.4Z"
      />
      <path
        className="a47311f2-6f02-4981-b852-f35062bacc90"
        d="M409.6,230.4a38.6,38.6,0,0,0-12.8-28.6V259A38.6,38.6,0,0,0,409.6,230.4Z"
      />
      <path
        className="a78dadff-0682-4c2f-8b15-9ef8a1105673"
        d="M185.6,393.6H164.2a12.9,12.9,0,0,1-12.6-10.4l-15.1-80.6a9.7,9.7,0,0,1,7.7-11.2l1.8-.2h46v96A6.4,6.4,0,0,1,185.6,393.6Z"
      />
      <path
        className="ada443a4-7764-4311-b3e3-79f1f6b3ca7e"
        d="M192,329.6V304a6.4,6.4,0,0,1,6.4,6.4v12.8A6.4,6.4,0,0,1,192,329.6Z"
      />
      <path className="e55215d3-a934-47bb-b7ce-37fa8941a760" d="M192,354H172.8a2,2,0,1,1,0-4H192a2,2,0,0,1,0,4Z" />
      <path className="e55215d3-a934-47bb-b7ce-37fa8941a760" d="M192,373.2H172.8a2,2,0,0,1,0-4H192a2,2,0,1,1,0,4Z" />
      <path
        className="a50f9e56-ed47-488e-868e-59ea918b7e7a"
        d="M185.6,246.4A6.4,6.4,0,1,1,192,240,6.4,6.4,0,0,1,185.6,246.4Z"
      />
      <path
        className="a50f9e56-ed47-488e-868e-59ea918b7e7a"
        d="M358.3,306a1.9,1.9,0,0,1-1.2-.4l-11.5-8.7L334,305.6a2.1,2.1,0,0,1-2.4,0L318.9,296a1.9,1.9,0,0,1-.4-2.8,2.1,2.1,0,0,1,2.8-.4l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2.2,2.2,0,0,1,2.8.4,2,2,0,0,1-.4,2.8l-12.8,9.6A1.7,1.7,0,0,1,358.3,306Z"
      />
    </g>
  </svg>
)

export default ImgMegaphoneGlitter
