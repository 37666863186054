import { SVGProps } from 'react'

const ImgChessPiecesGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.be13200f-2e74-408a-b67f-54a3f4057cf5{fill:none;}.f3b52b77-a2ca-4feb-8ab9-ed99d4e32a65{opacity:0.15;fill:url(#a57fc1e2-61ff-4117-9098-ad83535bc2fe);}.babfd048-9f94-4ae1-bce8-b3a029434aeb{fill:#fff;}.a4ab0b1b-9c80-4c11-8e79-e84982066244{fill:url(#a209c92b-a0d6-44ea-9fd1-3a6c2a910cee);}.b953b862-b8a0-4a96-b495-73defd5941b8{fill:url(#bbeb6d3a-e863-4a92-a66a-952f79ad8ef0);}.e7647165-d003-401a-ac2d-35edf0004e2c{fill:url(#aaaee6f8-2b9f-4ba1-a1e2-66195cb6fd0d);}.fc9ae16a-bbd5-4cbd-9dc8-c758a160f8de{fill:#5fd0b2;}.ff101d28-4e1a-431b-bb11-208173020446{fill:url(#b567bfac-1d2e-4426-8a74-2e97df66f238);}.a0a45a16-e9fa-4990-91dc-dfaa01daf96a{fill:url(#f6f9a410-e458-4ec4-ad44-3d3499ee371c);}.bc95a9f5-c865-46c5-b68b-ff047b1e6125{fill:url(#aa1217cd-632a-4a76-8190-7bc3ab8cc21f);}.ba77415e-dd3b-43cb-8b90-5c3db46fca85{fill:url(#bd26dada-8d53-451e-a003-56f6c1c26c36);}.b630aa06-dd9e-41d0-8665-b4297e86bafe{fill:#37c49f;}.b4d554e0-ab1f-4396-ba35-d30ec0cd7be5{fill:#fefafb;}'
        }
      </style>
      <linearGradient
        id="a57fc1e2-61ff-4117-9098-ad83535bc2fe"
        x1={110.94}
        y1={472.48}
        x2={301.1}
        y2={282.32}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a209c92b-a0d6-44ea-9fd1-3a6c2a910cee"
        x1={296.14}
        y1={10418.79}
        x2={240.75}
        y2={10363.4}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bbeb6d3a-e863-4a92-a66a-952f79ad8ef0"
        x1={312.15}
        y1={10673.47}
        x2={265.6}
        y2={10626.92}
        xlinkHref="#a209c92b-a0d6-44ea-9fd1-3a6c2a910cee"
      />
      <linearGradient
        id="aaaee6f8-2b9f-4ba1-a1e2-66195cb6fd0d"
        x1={349.65}
        y1={10570.45}
        x2={211.45}
        y2={10432.24}
        xlinkHref="#a209c92b-a0d6-44ea-9fd1-3a6c2a910cee"
      />
      <linearGradient
        id="b567bfac-1d2e-4426-8a74-2e97df66f238"
        x1={297.8}
        y1={10680.2}
        x2={271.93}
        y2={10654.33}
        xlinkHref="#a209c92b-a0d6-44ea-9fd1-3a6c2a910cee"
      />
      <linearGradient
        id="f6f9a410-e458-4ec4-ad44-3d3499ee371c"
        x1={251.19}
        y1={10450.64}
        x2={174.32}
        y2={10373.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="aa1217cd-632a-4a76-8190-7bc3ab8cc21f"
        x1={263.06}
        y1={10542.5}
        x2={155.57}
        y2={10435.02}
        xlinkHref="#f6f9a410-e458-4ec4-ad44-3d3499ee371c"
      />
      <linearGradient
        id="bd26dada-8d53-451e-a003-56f6c1c26c36"
        x1={229.3}
        y1={10611.7}
        x2={193.1}
        y2={10575.49}
        xlinkHref="#f6f9a410-e458-4ec4-ad44-3d3499ee371c"
      />
    </defs>
    <g id="a1ae7600-90a8-4baa-968c-f5e931fa8da9" data-name="Layer 1">
      <g id="eaf3bf29-b768-4452-b2bb-ec7dd0bf67e6" data-name="backdrop">
        <path
          className="be13200f-2e74-408a-b67f-54a3f4057cf5"
          d="M512,256c0,129.9-96.7,237.2-222.1,253.8A270.6,270.6,0,0,1,256,512,255.4,255.4,0,0,1,84.7,446.3a263.4,263.4,0,0,1-19-19.1,253.9,253.9,0,0,1-48.1-77.7A256.5,256.5,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="f3b52b77-a2ca-4feb-8ab9-ed99d4e32a65"
        d="M408.9,390.8l-119,119A270.6,270.6,0,0,1,256,512,255.4,255.4,0,0,1,84.7,446.3l-19-19.1a253.9,253.9,0,0,1-48.1-77.7L193,174.2l44.7,115,136.7,98Z"
      />
      <path className="babfd048-9f94-4ae1-bce8-b3a029434aeb" d="M408,390.8H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="a4ab0b1b-9c80-4c11-8e79-e84982066244"
        d="M214.4,355.2H361.6A12.8,12.8,0,0,1,374.4,368v19.2H201.6V368A12.8,12.8,0,0,1,214.4,355.2Z"
      />
      <path
        className="b953b862-b8a0-4a96-b495-73defd5941b8"
        d="M252.8,121.6h70.4a6.4,6.4,0,0,1,6.4,6.4v19.2H246.4V128A6.4,6.4,0,0,1,252.8,121.6Z"
      />
      <path
        className="e7647165-d003-401a-ac2d-35edf0004e2c"
        d="M361.6,355.2H214.4V343a26,26,0,0,1,3.4-12.7c11.4-19.9,47.8-89.9,47.8-173.5v-9.6h44.8v9.6c0,83.6,36.4,153.6,47.8,173.5a26,26,0,0,1,3.4,12.7Z"
      />
      <path
        className="fc9ae16a-bbd5-4cbd-9dc8-c758a160f8de"
        d="M318,166.2H258a1.4,1.4,0,1,1,0-2.8h60a1.4,1.4,0,0,1,0,2.8Z"
      />
      <rect className="ff101d28-4e1a-431b-bb11-208173020446" x={265.6} y={108.8} width={44.8} height={12.8} />
      <path
        className="a0a45a16-e9fa-4990-91dc-dfaa01daf96a"
        d="M150.4,355.2H272A12.8,12.8,0,0,1,284.8,368v19.2H137.6V368A12.8,12.8,0,0,1,150.4,355.2Z"
      />
      <path
        className="bc95a9f5-c865-46c5-b68b-ff047b1e6125"
        d="M154,336.7c11.7-19.5,44.4-58,44.4-135.1V192a12.8,12.8,0,0,1,12.8-12.8h0A12.8,12.8,0,0,1,224,192v9.6c0,77.1,32.7,115.6,44.4,135.1a24.8,24.8,0,0,1,3.6,13.1v5.4H150.4v-5.4A24.8,24.8,0,0,1,154,336.7Z"
      />
      <circle className="ba77415e-dd3b-43cb-8b90-5c3db46fca85" cx={211.2} cy={192} r={25.6} />
      <path
        className="b630aa06-dd9e-41d0-8665-b4297e86bafe"
        d="M326,329.5h-6.4v-6.4a1.6,1.6,0,0,0-3.2,0v6.4H310a1.6,1.6,0,0,0-1.6,1.6,1.7,1.7,0,0,0,1.6,1.6h6.4V339a1.6,1.6,0,0,0,3.2,0v-6.3H326a1.7,1.7,0,0,0,1.6-1.6A1.6,1.6,0,0,0,326,329.5Z"
      />
      <path
        className="b630aa06-dd9e-41d0-8665-b4297e86bafe"
        d="M350.5,252.5a8,8,0,0,1-8-8,8,8,0,0,1,16,0A8,8,0,0,1,350.5,252.5Zm0-12.7a4.8,4.8,0,1,0,4.8,4.7A4.8,4.8,0,0,0,350.5,239.8Z"
      />
      <path
        className="b630aa06-dd9e-41d0-8665-b4297e86bafe"
        d="M188.6,297.3a1.6,1.6,0,0,1-.9-.3l-11.8-8.9L164.1,297a1.7,1.7,0,0,1-1.9,0l-12.8-9.6a1.6,1.6,0,0,1-.3-2.2,1.6,1.6,0,0,1,2.3-.3l11.7,8.8,11.8-8.8a1.5,1.5,0,0,1,2,0l11.7,8.8,11.8-8.8a1.6,1.6,0,0,1,2.3.3,1.6,1.6,0,0,1-.3,2.2L189.6,297A1.7,1.7,0,0,1,188.6,297.3Z"
      />
      <path
        className="b4d554e0-ab1f-4396-ba35-d30ec0cd7be5"
        d="M236.2,249.5h-50a1.5,1.5,0,0,1,0-3h50a1.5,1.5,0,0,1,0,3Z"
      />
    </g>
  </svg>
)

export default ImgChessPiecesGlitter
