import { SVGProps } from 'react'

const ImgBarGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.f6fab2cf-d36f-4048-871a-a654043befce{fill:none;}.a0c3c501-7b07-4670-a78f-322a7d8d005b{opacity:0.15;fill:url(#ab84a8d5-5c79-472a-b15e-38ed612945b9);}.f6ac4e38-bdf0-4e96-97e1-4fcdbf07ba58{fill:url(#ef363a29-aadf-48c1-bbcd-9177a6edd50b);}.b33e6c65-0bd5-4e3b-9325-e772745b6e62{fill:url(#e0fbaaed-e50c-40a7-98e7-9d150fc7bf2f);}.f3110576-6f7e-4ec4-b2ff-7bc2bb01bc40{fill:url(#acf8b7a7-b3c5-456b-b21f-a6b87e082403);}.b51eb15c-fbb6-49ac-8bbe-ae81a10c7a0a{fill:url(#a6f98f7f-2131-4f14-8093-a2f8efff5a7b);}.a180320b-b50b-4f18-965e-db71357e6a36{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="ab84a8d5-5c79-472a-b15e-38ed612945b9"
        x1={98.76}
        y1={460.78}
        x2={291.32}
        y2={268.23}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ef363a29-aadf-48c1-bbcd-9177a6edd50b"
        x1={217.48}
        y1={9282.93}
        x2={146.44}
        y2={9211.9}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e0fbaaed-e50c-40a7-98e7-9d150fc7bf2f"
        x1={173.35}
        y1={9289.21}
        x2={83.14}
        y2={9198.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="acf8b7a7-b3c5-456b-b21f-a6b87e082403"
        x1={327.62}
        y1={9353.84}
        x2={451.24}
        y2={9230.21}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="a6f98f7f-2131-4f14-8093-a2f8efff5a7b"
        x1={246.97}
        y1={9344.09}
        x2={367.74}
        y2={9223.32}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
    </defs>
    <g id="bd0dc1a5-fb7a-4e88-8c00-2a20b1adb287" data-name="Layer 1">
      <g id="b9dc36ea-35f3-49c5-a8f0-78d9e0df6b07" data-name="backdrop">
        <path
          className="f6fab2cf-d36f-4048-871a-a654043befce"
          d="M512,256c0,129.5-96.1,236.5-220.9,253.6A256.2,256.2,0,0,1,77.2,439.2l-4.4-4.4A255.3,255.3,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a0c3c501-7b07-4670-a78f-322a7d8d005b"
        d="M411.9,388.8,291.1,509.6A256.2,256.2,0,0,1,77.2,439.2l-4.4-4.4A255.2,255.2,0,0,1,6.2,312.2l109-109V388.8Z"
      />
      <rect className="f6ac4e38-bdf0-4e96-97e1-4fcdbf07ba58" x={185.6} y={163.2} width={64} height={217.6} />
      <rect className="b33e6c65-0bd5-4e3b-9325-e772745b6e62" x={115.2} y={203.2} width={64} height={177.6} />
      <rect className="f3110576-6f7e-4ec4-b2ff-7bc2bb01bc40" x={326.4} y={83.2} width={64} height={297.6} />
      <rect className="b51eb15c-fbb6-49ac-8bbe-ae81a10c7a0a" x={256} y={123.2} width={64} height={257.6} />
      <path className="a180320b-b50b-4f18-965e-db71357e6a36" d="M408,390.8H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="a180320b-b50b-4f18-965e-db71357e6a36"
        d="M410,113.2v-.6h0l-.3-.3h0l-.3-.3H385.6a2,2,0,0,0,0,4h17.6l-100,100H213.6l-111,111a1.9,1.9,0,0,0,0,2.8,1.9,1.9,0,0,0,2.8,0L215.2,219.6h89.6L406,118.4V136a2,2,0,0,0,4,0V113.6h0Z"
      />
    </g>
  </svg>
)

export default ImgBarGraph
