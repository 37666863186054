import { SVGProps } from 'react'

const ImgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a5dda295-b8a3-440c-ab85-2edac95b3cdd{fill:none;}.b02c3dd0-0efe-4b16-8172-d37362a36dd8{opacity:0.15;fill:url(#bcc253f8-44fa-4e1b-ac11-fb7fd94720ef);}.bd6ddc73-3e7e-4adc-8760-24b60d7c9e88{fill:url(#a4e06966-5fce-4732-b9d6-a5a7ebf14f06);}.f1f59195-98d7-4e9b-8b12-9a331fbccee6{fill:url(#a2d6a9c0-993c-4058-aaf0-cfad05fe1c01);}.bde9da61-6af0-4beb-a1b9-7c328804022f{fill:url(#e55e0d41-3beb-44eb-906c-4b8d64f2e927);}.f8e2c487-c77b-4c2c-bf27-67180d22dd39{fill:url(#b5bbd1a0-ebe0-45e1-ac4e-7bd94d6f1e0c);}.be08ab74-4199-43e6-9626-44c50c0346e2{fill:url(#ab4b0631-8945-4d09-b2e2-d33b7a4ddda5);}.b0541279-5b4b-4a3b-86fd-40e28d0d2c4e{fill:#fff;}.bcf949dd-1960-44e6-8c41-325eea4d326f{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="bcc253f8-44fa-4e1b-ac11-fb7fd94720ef"
        x1={78.19}
        y1={440.21}
        x2={262.4}
        y2={256}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a4e06966-5fce-4732-b9d6-a5a7ebf14f06"
        x1={371.35}
        y1={15394.24}
        x2={141.19}
        y2={15624.4}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="a2d6a9c0-993c-4058-aaf0-cfad05fe1c01"
        x1={157.96}
        y1={15419.45}
        x2={361.04}
        y2={15622.53}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="e55e0d41-3beb-44eb-906c-4b8d64f2e927"
        x1={326.61}
        y1={15514.6}
        x2={121.77}
        y2={15309.76}
        xlinkHref="#a2d6a9c0-993c-4058-aaf0-cfad05fe1c01"
      />
      <linearGradient
        id="b5bbd1a0-ebe0-45e1-ac4e-7bd94d6f1e0c"
        x1={176}
        y1={214.4}
        x2={329.6}
        y2={60.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="ab4b0631-8945-4d09-b2e2-d33b7a4ddda5"
        x1={406.87}
        y1={15627.53}
        x2={371.18}
        y2={15663.22}
        xlinkHref="#a4e06966-5fce-4732-b9d6-a5a7ebf14f06"
      />
    </defs>
    <g id="aa4976b3-2d4f-4163-b687-e0bd69518c13" data-name="Layer 1">
      <g id="a0d8d368-8d06-4618-9ca5-67cd42de7630" data-name="backdrop">
        <path
          className="a5dda295-b8a3-440c-ab85-2edac95b3cdd"
          d="M512,256c0,129.8-96.6,237-221.7,253.7A258.9,258.9,0,0,1,256,512,255.2,255.2,0,0,1,77.2,439.2l-4.4-4.4A255.2,255.2,0,0,1,0,256c0-6.7.3-13.4.8-20C11,104,121.3,0,256,0,397.4,0,512,114.6,512,256Z"
        />
      </g>
      <path
        className="b02c3dd0-0efe-4b16-8172-d37362a36dd8"
        d="M396.8,403.2,290.3,509.7A258.9,258.9,0,0,1,256,512,255.2,255.2,0,0,1,77.2,439.2l-4.4-4.4A255.2,255.2,0,0,1,0,256c0-6.7.3-13.4.8-20L121.6,115.2Z"
      />
      <rect className="bd6ddc73-3e7e-4adc-8760-24b60d7c9e88" x={134.4} y={160} width={262.4} height={243.2} />
      <path
        className="f1f59195-98d7-4e9b-8b12-9a331fbccee6"
        d="M121.6,166.4V361.6a16,16,0,0,1-16,16H368a16,16,0,0,0,16-16V166.4Z"
      />
      <path
        className="bde9da61-6af0-4beb-a1b9-7c328804022f"
        d="M352,361.6V352H89.6v9.6a16,16,0,0,0,16,16H368A16,16,0,0,1,352,361.6Z"
      />
      <path
        className="f8e2c487-c77b-4c2c-bf27-67180d22dd39"
        d="M121.6,115.2V160H384V115.2ZM246.7,144a6.4,6.4,0,1,1,6.1-6.4A6.3,6.3,0,0,1,246.7,144Z"
      />
      <rect className="be08ab74-4199-43e6-9626-44c50c0346e2" x={384} y={115.2} width={12.8} height={44.8} />
      <path
        className="b0541279-5b4b-4a3b-86fd-40e28d0d2c4e"
        d="M332.8,209.2h-160a2,2,0,0,0-2,2v96h0a2,2,0,0,0,2,2h160a2,2,0,0,0,2-2h0v-96A2,2,0,0,0,332.8,209.2Zm-126,36h28v28h-28Zm-4,28h-28v-28h28Zm36-28h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Zm0-4v-28h28v28Zm-4,0h-28v-28h28Zm-32,0h-28v-28h28Zm-32,0h-28v-28h28Zm-32,0h-28v-28h28Zm-28,36h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Z"
      />
      <path
        className="bcf949dd-1960-44e6-8c41-325eea4d326f"
        d="M284.8,320.4a29.4,29.4,0,1,1,29.3-29.3A29.3,29.3,0,0,1,284.8,320.4Zm0-55a25.7,25.7,0,1,0,25.7,25.7A25.7,25.7,0,0,0,284.8,265.4Z"
      />
    </g>
  </svg>
)

export default ImgCalendar
