import { SVGProps } from 'react'

const ImgCalendarGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b788ee3a-5b28-49bf-953d-e21c520569f1{fill:none;}.adfb1273-03d1-45b6-896a-023692a678b1{opacity:0.15;fill:url(#b394dfb0-ae91-4214-a585-2c3f90ae7b6f);}.a7edfe35-7315-48e2-9119-878fe3f917c1{fill:url(#e958cae3-e5ed-48fa-989d-395f1039229a);}.f38a87fc-eb37-4e78-bfad-21a405fcb192{fill:url(#f0332412-53a0-4624-8c52-ba95c34ec701);}.bd1702b3-1f6b-4364-9ce4-7ba07768947f{fill:url(#b50ca5aa-6493-48bb-adc0-82e1821f3acd);}.f522cbb2-faf6-4de6-b5f4-11bc01ce4132{fill:url(#a085a398-66a7-4da3-bacd-9c388462a965);}.a27bd00d-e578-4ed0-b442-13b9e587eedd{fill:url(#e4d2a1ad-6d30-4fbb-bd4f-e373e975cf6a);}.b7c9d42a-7416-4fae-9243-229e8cbde743{fill:#fff;}.fe5abee8-3f53-4aed-b772-34df89d33398{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="b394dfb0-ae91-4214-a585-2c3f90ae7b6f"
        x1={78.19}
        y1={440.21}
        x2={262.4}
        y2={256}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e958cae3-e5ed-48fa-989d-395f1039229a"
        x1={371.35}
        y1={15394.24}
        x2={141.19}
        y2={15624.4}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="f0332412-53a0-4624-8c52-ba95c34ec701"
        x1={157.96}
        y1={15419.45}
        x2={361.04}
        y2={15622.53}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="b50ca5aa-6493-48bb-adc0-82e1821f3acd"
        x1={326.61}
        y1={15514.6}
        x2={121.77}
        y2={15309.76}
        xlinkHref="#f0332412-53a0-4624-8c52-ba95c34ec701"
      />
      <linearGradient
        id="a085a398-66a7-4da3-bacd-9c388462a965"
        x1={176}
        y1={214.4}
        x2={329.6}
        y2={60.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="e4d2a1ad-6d30-4fbb-bd4f-e373e975cf6a"
        x1={406.87}
        y1={15627.53}
        x2={371.18}
        y2={15663.22}
        xlinkHref="#e958cae3-e5ed-48fa-989d-395f1039229a"
      />
    </defs>
    <g id="bc456d7c-2776-49d8-a74b-9b5711d3a95c" data-name="Layer 1">
      <g id="ab5e9b15-c86f-4db2-a46b-a16416937dff" data-name="backdrop">
        <path
          className="b788ee3a-5b28-49bf-953d-e21c520569f1"
          d="M512,256c0,129.8-96.6,237-221.7,253.7A258.9,258.9,0,0,1,256,512,255.2,255.2,0,0,1,77.2,439.2l-4.4-4.4A255.2,255.2,0,0,1,0,256c0-6.7.3-13.4.8-20C11,104,121.3,0,256,0,397.4,0,512,114.6,512,256Z"
        />
      </g>
      <path
        className="adfb1273-03d1-45b6-896a-023692a678b1"
        d="M396.8,403.2,290.3,509.7A258.9,258.9,0,0,1,256,512,255.2,255.2,0,0,1,77.2,439.2l-4.4-4.4A255.2,255.2,0,0,1,0,256c0-6.7.3-13.4.8-20L121.6,115.2Z"
      />
      <rect className="a7edfe35-7315-48e2-9119-878fe3f917c1" x={134.4} y={160} width={262.4} height={243.2} />
      <path
        className="f38a87fc-eb37-4e78-bfad-21a405fcb192"
        d="M121.6,166.4V361.6a16,16,0,0,1-16,16H368a16,16,0,0,0,16-16V166.4Z"
      />
      <path
        className="bd1702b3-1f6b-4364-9ce4-7ba07768947f"
        d="M352,361.6V352H89.6v9.6a16,16,0,0,0,16,16H368A16,16,0,0,1,352,361.6Z"
      />
      <path
        className="f522cbb2-faf6-4de6-b5f4-11bc01ce4132"
        d="M121.6,115.2V160H384V115.2ZM246.7,144a6.4,6.4,0,1,1,6.1-6.4A6.3,6.3,0,0,1,246.7,144Z"
      />
      <rect className="a27bd00d-e578-4ed0-b442-13b9e587eedd" x={384} y={115.2} width={12.8} height={44.8} />
      <path
        className="b7c9d42a-7416-4fae-9243-229e8cbde743"
        d="M332.8,209.2h-160a2,2,0,0,0-2,2v96h0a2,2,0,0,0,2,2h160a2,2,0,0,0,2-2h0v-96A2,2,0,0,0,332.8,209.2Zm-126,36h28v28h-28Zm-4,28h-28v-28h28Zm36-28h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Zm0-4v-28h28v28Zm-4,0h-28v-28h28Zm-32,0h-28v-28h28Zm-32,0h-28v-28h28Zm-32,0h-28v-28h28Zm-28,36h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Zm32,0h28v28h-28Z"
      />
      <path
        className="fe5abee8-3f53-4aed-b772-34df89d33398"
        d="M284.8,320.4a29.4,29.4,0,1,1,29.3-29.3A29.3,29.3,0,0,1,284.8,320.4Zm0-55a25.7,25.7,0,1,0,25.7,25.7A25.7,25.7,0,0,0,284.8,265.4Z"
      />
      <path
        className="fe5abee8-3f53-4aed-b772-34df89d33398"
        d="M81.6,184.4a2,2,0,0,1-2-2v-16a2,2,0,0,1,4,0v16A2,2,0,0,1,81.6,184.4Z"
      />
      <path className="fe5abee8-3f53-4aed-b772-34df89d33398" d="M89.6,176.4h-16a2,2,0,0,1,0-4h16a2,2,0,0,1,0,4Z" />
      <path
        className="fe5abee8-3f53-4aed-b772-34df89d33398"
        d="M416.8,299.5a8.4,8.4,0,1,1,0-16.8,8.4,8.4,0,0,1,0,16.8Zm0-12.8a4.4,4.4,0,1,0,0,8.8,4.4,4.4,0,0,0,0-8.8Z"
      />
      <path
        className="fe5abee8-3f53-4aed-b772-34df89d33398"
        d="M185.6,358.8a1.9,1.9,0,0,1-1.2-.4l-11.6-8.7-11.6,8.7a2.1,2.1,0,0,1-2.4,0L146,348.8a2,2,0,1,1,2.4-3.2l11.6,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.6,8.7,11.6-8.7a2,2,0,0,1,2.4,3.2l-12.8,9.6A1.9,1.9,0,0,1,185.6,358.8Z"
      />
    </g>
  </svg>
)

export default ImgCalendarGlitter
