import { SVGProps } from 'react'

const ImgHandWithShapesGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a280934c-ac49-4226-9da7-4328615cf7e7{fill:none;}.b7d19ced-b103-47db-9f25-0b5f4f2237f8{fill:url(#a11190bf-3067-4bec-82e1-6db2460f7b95);}.b2385258-abb2-4535-ab28-7098f611d3fa{fill:#37c49f;}.a10bd0ce-c5d1-4fa3-8f33-de7438f12fcf{fill:url(#fd6fafc6-8713-4d34-bba0-152888f9bc2e);}.e6232162-423a-4893-9693-71290f3d3ad5{fill:url(#fe668d28-403a-4fe8-94e5-119da3272c95);}.ebb152d4-83ed-46fa-b1c5-89c520380d9b{fill:url(#ae55b0a5-f0c1-4b07-9f56-596dbf6d4c96);}.e214bf33-0ac4-4078-a86c-69dda6052cae{fill:url(#b44e7576-b3dd-4e2b-b9d5-0ce0ea9e1027);}.b0d20a8f-809d-4027-9aa0-cce97d24b484{opacity:0.15;fill:url(#a5e6e25a-9d95-4c2b-bdab-9d9eb164b1d8);}.b7913035-2aea-4a5c-bc1e-b9493479bac7{fill:url(#e54c4598-5212-41fa-bf6b-078856d3f373);}'
        }
      </style>
      <linearGradient
        id="a11190bf-3067-4bec-82e1-6db2460f7b95"
        x1={200.71}
        y1={14175.1}
        x2={370.41}
        y2={14344.8}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="fd6fafc6-8713-4d34-bba0-152888f9bc2e"
        x1={247.2}
        y1={181.6}
        x2={194.4}
        y2={234.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="fe668d28-403a-4fe8-94e5-119da3272c95"
        x1={297.6}
        y1={188.8}
        x2={233.6}
        y2={252.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="ae55b0a5-f0c1-4b07-9f56-596dbf6d4c96"
        x1={347.97}
        y1={14179.96}
        x2={389.47}
        y2={14221.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b44e7576-b3dd-4e2b-b9d5-0ce0ea9e1027"
        x1={188.96}
        y1={244.41}
        x2={314.11}
        y2={369.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="a5e6e25a-9d95-4c2b-bdab-9d9eb164b1d8"
        x1={90.71}
        y1={452.62}
        x2={343.66}
        y2={199.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e54c4598-5212-41fa-bf6b-078856d3f373"
        x1={323.43}
        y1={166.17}
        x2={278.17}
        y2={211.43}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
    </defs>
    <g id="eb7a5733-a7cc-43b5-86d2-76db116e6230" data-name="Layer 1">
      <g id="a85d8bff-4e55-4ce1-81a7-e167a13be9fe" data-name="backdrop">
        <path
          className="a280934c-ac49-4226-9da7-4328615cf7e7"
          d="M512,256c0,141.4-114.6,256-256,256A254.5,254.5,0,0,1,79.9,441.8c-3.3-3.1-6.6-6.4-9.7-9.7A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <circle className="b7d19ced-b103-47db-9f25-0b5f4f2237f8" cx={265.6} cy={217.6} r={121.6} />
      <path
        className="b2385258-abb2-4535-ab28-7098f611d3fa"
        d="M238.6,125.1a8.4,8.4,0,1,1,8.4-8.4A8.4,8.4,0,0,1,238.6,125.1Zm0-12.8a4.4,4.4,0,0,0-4.4,4.4,4.4,4.4,0,0,0,4.4,4.4,4.3,4.3,0,0,0,4.4-4.4A4.4,4.4,0,0,0,238.6,112.3Z"
      />
      <path
        className="b2385258-abb2-4535-ab28-7098f611d3fa"
        d="M411.3,180.8h-6v-6a2,2,0,0,0-4,0v6h-6a2,2,0,0,0,0,4h6v6a2,2,0,0,0,4,0v-6h6a2,2,0,0,0,0-4Z"
      />
      <path
        className="b2385258-abb2-4535-ab28-7098f611d3fa"
        d="M121.2,269.6a1.9,1.9,0,0,1-1.2-.4l-12.8-9.6a2,2,0,0,1-.4-2.8,2,2,0,0,1,2.8-.4l11.6,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.6,8.7,11.6-8.7a2,2,0,1,1,2.4,3.2L148,269.2a2.1,2.1,0,0,1-2.4,0L134,260.5l-11.6,8.7A1.6,1.6,0,0,1,121.2,269.6Z"
      />
      <polygon
        className="a10bd0ce-c5d1-4fa3-8f33-de7438f12fcf"
        points="220.8 155.2 182.4 222.4 259.2 222.4 220.8 155.2"
      />
      <rect className="e6232162-423a-4893-9693-71290f3d3ad5" x={233.6} y={188.8} width={64} height={64} />
      <path
        className="ebb152d4-83ed-46fa-b1c5-89c520380d9b"
        d="M300.8,300.8s50.8-47.6,57.6-54.4a29.3,29.3,0,0,1,22.4-9.6c3.8,0,5.7,2.7,3.2,6.4l-57.6,80Z"
      />
      <path
        className="e214bf33-0ac4-4078-a86c-69dda6052cae"
        d="M400,243.2c-9.6,0-12.8,3.2-22.4,9.6l-76.8,48H231.2a32.8,32.8,0,0,1,17.7-5h29.5a22.4,22.4,0,0,0,22.4-22.4h0a3.2,3.2,0,0,0-3.2-3.2H224.1c-19.8,0-25.7,6.4-64.1,25.6L108.8,315a22,22,0,0,0-6.4,15.5v40.7A12.8,12.8,0,0,0,115.2,384L177,354.7a25.5,25.5,0,0,1,11.4-2.7h62.9a38.8,38.8,0,0,0,15.1-3.1l60-25.7,76.8-64c3.2-3.2,6.4-6.4,6.4-9.6S409.6,243.2,400,243.2Z"
      />
      <path
        className="b0d20a8f-809d-4027-9aa0-cce97d24b484"
        d="M403.3,259.3,166.6,496a256.6,256.6,0,0,1-86.7-54.2l-9.7-9.7a256.4,256.4,0,0,1-33.8-44.4l72.5-72.6,191.9-14.3Z"
      />
      <circle className="b7913035-2aea-4a5c-bc1e-b9493479bac7" cx={300.8} cy={188.8} r={32} />
    </g>
  </svg>
)

export default ImgHandWithShapesGlitter
