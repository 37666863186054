import { SVGProps } from 'react'

const ImgWalletGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b3cf810f-cff8-4a65-b62f-04b421661655{fill:none;}.a0c8cc98-291d-471a-aded-4fcaa8eb3644{opacity:0.15;fill:url(#a55a37fd-79a0-470c-a6fe-8f09940a9278);}.a3c1e36b-2554-4421-9b95-bf37b984a065{fill:url(#e4f441ac-5dfe-4851-a43a-62bc2e77beba);}.a8bff15c-7825-486e-b5b2-049dd64fdc4f{fill:url(#f99be1fe-5090-4469-977e-c2e9e93a5a1e);}.bb4b8f2b-68ec-447c-bf4d-4d44e6f8c741{fill:url(#b9a56de1-3475-4f77-8f08-a275c689cfc2);}.bb6afed0-5610-48de-a502-6ff194d95ed3{fill:url(#f38f52e0-2990-4d0e-8e68-0470b0f339f2);}.b61ecbbf-5d6f-4fb1-b589-9f1d0f1e4b54{fill:url(#a37c176c-a3e2-4fdf-bf38-bb06061ecf34);}.b4edf6a3-003a-48f9-8c77-d222c08c94a1{fill:#fff;}.a9843321-7746-4fd2-8700-2a36e01da76e{fill:url(#ae2ff775-0e1a-494c-9a7a-2569e2216c3a);}.b1011007-ff66-4fc0-828f-bd6ada073706{fill:url(#f1553295-96c0-4bee-b509-12480faa72fb);}.e723c6e5-08e0-4bca-b104-bf34dd7426d2{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="a55a37fd-79a0-470c-a6fe-8f09940a9278"
        x1={79.54}
        y1={440.2}
        x2={275.67}
        y2={244.07}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e4f441ac-5dfe-4851-a43a-62bc2e77beba"
        x1={249.6}
        y1={9227.38}
        x2={249.6}
        y2={9416.84}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="f99be1fe-5090-4469-977e-c2e9e93a5a1e"
        x1={227.82}
        y1={9254.09}
        x2={216.51}
        y2={9425.13}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5fd0b2" />
        <stop offset={1} stopColor="#37c49f" />
      </linearGradient>
      <linearGradient
        id="b9a56de1-3475-4f77-8f08-a275c689cfc2"
        x1={219.04}
        y1={9235.4}
        x2={373.12}
        y2={9447.43}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="f38f52e0-2990-4d0e-8e68-0470b0f339f2"
        x1={405.92}
        y1={9281.84}
        x2={437.44}
        y2={9292.3}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a37c176c-a3e2-4fdf-bf38-bb06061ecf34"
        x1={133.08}
        y1={9162.01}
        x2={398.47}
        y2={9427.41}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ae2ff775-0e1a-494c-9a7a-2569e2216c3a"
        x1={337.49}
        y1={9237.87}
        x2={400.84}
        y2={9301.23}
        gradientTransform="matrix(1, 0, 0, -1, 0, 9555.06)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="f1553295-96c0-4bee-b509-12480faa72fb"
        x1={393.4}
        y1={9249.81}
        x2={363.71}
        y2={9268.19}
        xlinkHref="#f99be1fe-5090-4469-977e-c2e9e93a5a1e"
      />
    </defs>
    <g id="a23cb6e8-a7e1-4c01-acd6-6806e60b0435" data-name="Layer 1">
      <g id="b1e41587-c1d7-4f65-a733-f62589f9fbe2" data-name="backdrop">
        <path
          className="b3cf810f-cff8-4a65-b62f-04b421661655"
          d="M512,256c0,140.4-113.1,254.5-253.2,256H256A254.8,254.8,0,0,1,91.5,452.1a248.3,248.3,0,0,1-31.6-31.6A254.9,254.9,0,0,1,.3,268.4C.1,264.3,0,260.2,0,256,0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a0c8cc98-291d-471a-aded-4fcaa8eb3644"
        d="M401.2,369.6,258.8,512H256A254.8,254.8,0,0,1,91.5,452.1L59.9,420.5A254.9,254.9,0,0,1,.3,268.4L121.6,147.2Z"
      />
      <rect className="a3c1e36b-2554-4421-9b95-bf37b984a065" x={121.6} y={147.2} width={256} height={224} />
      <rect className="a8bff15c-7825-486e-b5b2-049dd64fdc4f" x={147.2} y={128} width={153.6} height={230.4} />
      <path
        className="bb4b8f2b-68ec-447c-bf4d-4d44e6f8c741"
        d="M115.2,166.4a6.4,6.4,0,0,0-6.4,6.4V377.6a6.4,6.4,0,0,1,6.4-6.4H396.8a6.4,6.4,0,0,0,6.4-6.4v-192a6.4,6.4,0,0,0-6.4-6.4Z"
      />
      <path
        className="bb6afed0-5610-48de-a502-6ff194d95ed3"
        d="M409.6,236.8h-6.4v64h6.4a6.4,6.4,0,0,1,6.4,6.4v-64A6.4,6.4,0,0,0,409.6,236.8Z"
      />
      <path
        className="b61ecbbf-5d6f-4fb1-b589-9f1d0f1e4b54"
        d="M384,179.2H115.2a6.4,6.4,0,0,1-6.4-6.4V377.6a6.4,6.4,0,0,0,6.4,6.4H384a6.4,6.4,0,0,0,6.4-6.4v-192A6.4,6.4,0,0,0,384,179.2ZM355.2,294.4A12.8,12.8,0,1,1,368,281.6,12.8,12.8,0,0,1,355.2,294.4Z"
      />
      <path className="b4edf6a3-003a-48f9-8c77-d222c08c94a1" d="M376,371.6H123.2a2,2,0,1,1,0-4H376a2,2,0,0,1,0,4Z" />
      <path
        className="a9843321-7746-4fd2-8700-2a36e01da76e"
        d="M409.6,249.6H339.2a12.8,12.8,0,0,0-12.8,12.8v38.4a12.8,12.8,0,0,0,12.8,12.8h70.4a6.4,6.4,0,0,0,6.4-6.4v-64A6.4,6.4,0,0,1,409.6,249.6Z"
      />
      <circle className="b1011007-ff66-4fc0-828f-bd6ada073706" cx={355.2} cy={281.6} r={12.8} />
      <path
        className="e723c6e5-08e0-4bca-b104-bf34dd7426d2"
        d="M123.2,121.6a7.9,7.9,0,1,1,7.9-7.9A7.9,7.9,0,0,1,123.2,121.6Zm0-12.8a4.9,4.9,0,1,0,4.9,4.9A4.9,4.9,0,0,0,123.2,108.8Z"
      />
      <path
        className="e723c6e5-08e0-4bca-b104-bf34dd7426d2"
        d="M108.9,265.5a1.6,1.6,0,0,1-.9-.3l-11.8-8.9-11.9,8.9a1.5,1.5,0,0,1-1.8,0l-12.7-9.6a1.5,1.5,0,0,1-.3-2.1,1.6,1.6,0,0,1,2.1-.3l11.8,8.9,11.9-8.9a1.5,1.5,0,0,1,1.8,0l11.8,8.9,11.9-8.9a1.6,1.6,0,0,1,2.1.3,1.5,1.5,0,0,1-.3,2.1l-12.8,9.6A1.2,1.2,0,0,1,108.9,265.5Z"
      />
      <path
        className="e723c6e5-08e0-4bca-b104-bf34dd7426d2"
        d="M295.4,214.6H289v-6.4a1.5,1.5,0,0,0-1.5-1.5,1.4,1.4,0,0,0-1.5,1.5v6.4h-6.5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5H286v6.5a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6.5h6.4a1.4,1.4,0,0,0,1.5-1.5A1.5,1.5,0,0,0,295.4,214.6Z"
      />
    </g>
  </svg>
)

export default ImgWalletGlitter
