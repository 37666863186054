const ImgLaptopAndMobileSyncWithArrows = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="aa4d2d5e-08da-4493-8a9c-73fc53851600"
          x1={3291.66}
          y1={-2595.2}
          x2={3362.49}
          y2={-2595.2}
          gradientTransform="matrix(1.99 -1.99 1.13 1.13 -3369.94 9948.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
          <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
          <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
          <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
          <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
          <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
          <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="bfa3920f-ad13-4bfc-80cb-8521a508f732"
          x1={3530.5}
          y1={-2181.14}
          x2={3592.72}
          y2={-2181.14}
          gradientTransform="rotate(-45 12519.28 11515.64) scale(3.3 2.39)"
          xlinkHref="#aa4d2d5e-08da-4493-8a9c-73fc53851600"
        />
        <linearGradient
          id="a4dbc50f-bebc-4fae-9a21-0e62aa8d8a40"
          x1={281.93}
          y1={-9128.15}
          x2={133.5}
          y2={-9276.58}
          gradientTransform="matrix(1 0 0 -1 0 -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#4038b2" />
          <stop offset={1} stopColor="#10069f" />
        </linearGradient>
        <linearGradient
          id="fb4056c2-7030-4fec-99b1-24688ae55d8a"
          x1={282.96}
          y1={-9194.32}
          x2={140.34}
          y2={-9336.95}
          gradientTransform="matrix(1 0 0 -1 0 -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f2688c" />
          <stop offset={1} stopColor="#ef426f" />
        </linearGradient>
        <linearGradient
          id="e328c319-f44b-457a-b434-43e8a85f8612"
          x1={221.21}
          y1={-9254.47}
          x2={176.3}
          y2={-9299.39}
          gradientTransform="matrix(1 0 0 -1 0 -9040.48)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f9d8df" />
          <stop offset={1} stopColor="#f7ced7" />
        </linearGradient>
        <linearGradient
          id="b29e9db2-9912-4b9f-a8a7-ef3f6571bfdd"
          x1={1294.2}
          y1={-9402.11}
          x2={1348.94}
          y2={-9456.85}
          gradientTransform="rotate(180 845 -4520.25)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5fd0b2" />
          <stop offset={1} stopColor="#37c49f" />
        </linearGradient>
        <linearGradient
          id="b41aeb0d-314f-491e-81dd-cbacf380d550"
          x1={1273.53}
          y1={-9316.07}
          x2={1362.43}
          y2={-9404.97}
          gradientTransform="rotate(180 845 -4520.25)"
          xlinkHref="#a4dbc50f-bebc-4fae-9a21-0e62aa8d8a40"
        />
        <style>
          {'.f8914133-f1ee-4e3c-8d4f-f5fd0bd6e22c{fill:#fff}.bf740d3b-34f7-424c-8929-c1b98c6b23d1{fill:#37c49f}'}
        </style>
      </defs>
      <g id="ba00d5ab-9005-4079-8088-67bd2b38b7b1" data-name="Layer 1">
        <circle
          cx={256}
          cy={256}
          r={256}
          style={{
            fill: 'none',
          }}
          id="bcab37ed-2b52-49ed-8274-b47f5018d54a"
          data-name="backdrop"
        />
        <path
          style={{
            fill: 'url(#aa4d2d5e-08da-4493-8a9c-73fc53851600)',
            opacity: 0.15,
          }}
          d="M407 401.1 295.7 512.4 188.4 405.1l140.9-141 77.7 137z"
        />
        <path
          style={{
            fill: 'url(#bfa3920f-ad13-4bfc-80cb-8521a508f732)',
            opacity: 0.15,
          }}
          d="m307.2 233.6-145 145.1L1.6 218.1l114-114.1 191.6 129.6z"
        />
        <rect
          x={115.2}
          y={102.4}
          width={182.4}
          height={121.6}
          rx={3.2}
          style={{
            fill: 'url(#a4dbc50f-bebc-4fae-9a21-0e62aa8d8a40)',
          }}
        />
        <path
          d="M283.7 243.2H125.9a31.5 31.5 0 0 1-19.2-6.4l-4.3-3.2v-3.2h204.8v3.2l-4.3 3.2a31.5 31.5 0 0 1-19.2 6.4Z"
          style={{
            fill: 'url(#fb4056c2-7030-4fec-99b1-24688ae55d8a)',
          }}
        />
        <path
          style={{
            fill: 'url(#e328c319-f44b-457a-b434-43e8a85f8612)',
          }}
          d="m179.2 230.4 6.4 6.4H224l6.4-6.4h-51.2z"
        />
        <path
          className="f8914133-f1ee-4e3c-8d4f-f5fd0bd6e22c"
          d="M185.6 190.4h-19.2a1.6 1.6 0 0 1-1.6-1.6v-19.2a1.6 1.6 0 0 1 3.2 0v17.6h17.6a1.6 1.6 0 0 1 0 3.2Z"
        />
        <path
          className="f8914133-f1ee-4e3c-8d4f-f5fd0bd6e22c"
          d="M365.1 283.2h-.4a1.6 1.6 0 0 1-1.2-2 110.4 110.4 0 0 0-195.8-91.4 1.6 1.6 0 1 1-2.6-2A113.7 113.7 0 0 1 366.6 282a1.6 1.6 0 0 1-1.5 1.2Z"
        />
        <path
          d="M408.8 397a5.8 5.8 0 0 1-5.8 5.8h-70a5.8 5.8 0 0 1-5.8-5.8v-17.5a5.8 5.8 0 0 0 5.8 5.8h70a5.8 5.8 0 0 0 5.8-5.8Z"
          style={{
            fill: 'url(#b29e9db2-9912-4b9f-a8a7-ef3f6571bfdd)',
          }}
        />
        <rect
          x={327.2}
          y={262.8}
          width={81.7}
          height={122.5}
          rx={5.8}
          style={{
            fill: 'url(#b41aeb0d-314f-491e-81dd-cbacf380d550)',
          }}
        />
        <path
          className="f8914133-f1ee-4e3c-8d4f-f5fd0bd6e22c"
          d="M368 277.4a2.9 2.9 0 0 1-2.9-2.9 2.9 2.9 0 1 1 5.8 0 2.9 2.9 0 0 1-2.9 2.9ZM345.6 344a1.6 1.6 0 0 1-1.6-1.6v-17.6h-17.6a1.6 1.6 0 1 1 0-3.2h19.2a1.6 1.6 0 0 1 1.6 1.6v19.2a1.6 1.6 0 0 1-1.6 1.6Z"
        />
        <path
          className="f8914133-f1ee-4e3c-8d4f-f5fd0bd6e22c"
          d="M255.8 369.6a112.4 112.4 0 0 1-68-22.9A113.5 113.5 0 0 1 145.4 230a1.6 1.6 0 0 1 1.9-1.2 1.6 1.6 0 0 1 1.2 2 110.4 110.4 0 0 0 195.8 91.4 1.6 1.6 0 1 1 2.6 2 113.3 113.3 0 0 1-74.9 44.2 110.4 110.4 0 0 1-16.2 1.2Z"
        />
        <path
          className="bf740d3b-34f7-424c-8929-c1b98c6b23d1"
          d="M91.6 196.7a7.9 7.9 0 1 1 7.9-7.9 8 8 0 0 1-7.9 7.9Zm0-12.8a4.9 4.9 0 1 0 4.9 4.9 4.9 4.9 0 0 0-4.9-4.9ZM428.3 324.7a7.9 7.9 0 1 1 7.9-7.9 7.8 7.8 0 0 1-7.9 7.9Zm0-12.7a4.8 4.8 0 0 0-4.8 4.8 4.9 4.9 0 1 0 9.7 0 4.8 4.8 0 0 0-4.9-4.8ZM326.9 130.8a1.2 1.2 0 0 1-.9-.3l-11.9-8.9-11.8 8.9a1.5 1.5 0 0 1-1.8 0l-12.8-9.6a1.5 1.5 0 0 1-.3-2.1 1.7 1.7 0 0 1 2.1-.3l11.9 8.9 11.8-8.9a1.5 1.5 0 0 1 1.8 0l11.9 8.9 11.8-8.9a1.7 1.7 0 0 1 2.1.3 1.5 1.5 0 0 1-.3 2.1l-12.7 9.6ZM149 362.1a1.4 1.4 0 0 1-.9-.3l-11.9-8.9-11.8 8.9a1.5 1.5 0 0 1-1.8 0l-12.8-9.6a1.6 1.6 0 0 1-.3-2.1 1.5 1.5 0 0 1 2.1-.3l11.9 8.9 11.8-8.9a1.5 1.5 0 0 1 1.8 0l11.9 8.9 11.8-8.9a1.5 1.5 0 0 1 1.8 2.4l-12.7 9.6a1.6 1.6 0 0 1-.9.3ZM230.4 286.1h-6.5v-6.5a1.5 1.5 0 0 0-1.5-1.5 1.5 1.5 0 0 0-1.5 1.5v6.5h-6.4a1.4 1.4 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5h6.4v6.4a1.5 1.5 0 0 0 1.5 1.5 1.4 1.4 0 0 0 1.5-1.5v-6.4h6.5a1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5Z"
        />
      </g>
    </svg>
  )
}

export default ImgLaptopAndMobileSyncWithArrows
