import { SVGProps } from 'react'

const ImgPiggyBank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a51026af-25b6-4819-ab08-c4bfffa14ff9{isolation:isolate;}.e716a89c-8c10-4b3f-9c3f-016f5acc6f7a{fill:none;}.b4ba7d3b-4109-4895-bc88-b12a57654451{fill:#fff;}.adbef2bc-f63a-4ca1-95d8-c535ec5ccb41{fill:url(#bb2b03c0-2df3-41a3-8206-60310cbe4299);}.b07e334c-209a-4806-8925-6562cc728857{opacity:0.15;mix-blend-mode:multiply;fill:url(#fbd6df20-dd4b-4c13-a098-aef664182a6b);}.fe91ee0e-1f07-4022-90c8-1716a1e001c7{fill:url(#bc8447e2-8533-4483-860d-beb0e7b53893);}.eb046ce0-17da-46ae-9767-3b4b8994d615{fill:url(#a328a3e0-8740-4151-a437-b26a7ab6defc);}.b4e2d0b3-4623-459b-b31a-a4971847e79c{fill:url(#e4b29e84-9a0b-4236-a99e-0aa05f7ba1d8);}.a36cc63e-57c7-4c66-8854-a6647212f0b4{fill:url(#ab578e29-384d-4156-b735-950eaa97511b);}.be0bb178-2e7a-47f0-af96-64256405a2ab{fill:url(#b225919e-28c8-47e9-be68-a00748de312c);}'
        }
      </style>
      <linearGradient
        id="bb2b03c0-2df3-41a3-8206-60310cbe4299"
        x1={262.16}
        y1={13061.62}
        x2={301.04}
        y2={13100.49}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="fbd6df20-dd4b-4c13-a098-aef664182a6b"
        x1={94.14}
        y1={456.17}
        x2={291.43}
        y2={258.88}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.48} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="bc8447e2-8533-4483-860d-beb0e7b53893"
        x1={129.61}
        y1={12962.72}
        x2={151.47}
        y2={12984.58}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a328a3e0-8740-4151-a437-b26a7ab6defc"
        x1={164.87}
        y1={13040.14}
        x2={192.35}
        y2={13067.62}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="e4b29e84-9a0b-4236-a99e-0aa05f7ba1d8"
        x1={176.63}
        y1={12907.07}
        x2={339.54}
        y2={13069.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ab578e29-384d-4156-b735-950eaa97511b"
        x1={187.41}
        y1={12860.73}
        x2={236.44}
        y2={12909.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="b225919e-28c8-47e9-be68-a00748de312c"
        x1={309.73}
        y1={12864.68}
        x2={351.44}
        y2={12906.39}
        xlinkHref="#ab578e29-384d-4156-b735-950eaa97511b"
      />
    </defs>
    <g className="a51026af-25b6-4819-ab08-c4bfffa14ff9">
      <g id="b1f53f01-fcf4-4d59-9ade-86b3be765c65" data-name="Layer 1">
        <g id="b9aeab3b-b043-48a0-af57-08f65ca8c258" data-name="backdrop">
          <path
            className="e716a89c-8c10-4b3f-9c3f-016f5acc6f7a"
            d="M512,256c0,129.9-96.8,237.2-222.2,253.8A269,269,0,0,1,256,512,255.1,255.1,0,0,1,76.1,438.1l-2.2-2.2A254.4,254.4,0,0,1,3.4,297.7,260.7,260.7,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
          />
        </g>
        <path
          className="b4ba7d3b-4109-4895-bc88-b12a57654451"
          d="M396.8,257.6h-9.6a1.6,1.6,0,1,1,0-3.2h9.6c7.9,0,14.4-5,14.4-11.2a1.6,1.6,0,1,1,3.2,0C414.4,251.1,406.5,257.6,396.8,257.6Z"
        />
        <path
          className="adbef2bc-f63a-4ca1-95d8-c535ec5ccb41"
          d="M281.6,180a27.5,27.5,0,1,1,27.5-27.5A27.5,27.5,0,0,1,281.6,180Z"
        />
        <path
          className="b07e334c-209a-4806-8925-6562cc728857"
          d="M416.1,383.5,289.8,509.8A269,269,0,0,1,256,512,255.1,255.1,0,0,1,76.1,438.1l-2.2-2.2A254.4,254.4,0,0,1,3.4,297.7l155-155.1L343.6,380.8Z"
        />
        <path
          className="fe91ee0e-1f07-4022-90c8-1716a1e001c7"
          d="M147.2,291.2H126.3a12.8,12.8,0,0,1-12.7-11l-3.8-26.5a6.4,6.4,0,0,1,5.5-7.2h31.9a6.4,6.4,0,0,1,6.4,6.4v32A6.4,6.4,0,0,1,147.2,291.2Z"
        />
        <path className="b4ba7d3b-4109-4895-bc88-b12a57654451" d="M414.4,384.4h-304a2,2,0,1,1,0-4h304a2,2,0,0,1,0,4Z" />
        <path
          className="eb046ce0-17da-46ae-9767-3b4b8994d615"
          d="M217.6,198.1l-54,12.8a6.7,6.7,0,0,1-6.8-6.7h0V145.5a3.4,3.4,0,0,1,3.4-3.4,3.7,3.7,0,0,1,2.4,1Z"
        />
        <path
          className="b4e2d0b3-4623-459b-b31a-a4971847e79c"
          d="M262.4,163.2a173.8,173.8,0,0,0-44.8,5.9l-32.3-32.3a3.6,3.6,0,0,0-5.1,0,3.6,3.6,0,0,0-1,2.5v46.4c-29.3,18.2-48,45.8-48,76.7,0,54.8,58.7,99.2,131.2,99.2s131.2-44.4,131.2-99.2S334.9,163.2,262.4,163.2Z"
        />
        <path
          className="a36cc63e-57c7-4c66-8854-a6647212f0b4"
          d="M221.1,377.9a3.3,3.3,0,0,1-3.2,2.9h-33a6.4,6.4,0,0,1-6.4-6.4,6.4,6.4,0,0,1,.3-2l12.4-40.5A17.5,17.5,0,0,1,207.7,320h0a17.4,17.4,0,0,1,17.5,17.4c0,.7-.1,1.3-.1,1.9Z"
        />
        <path
          className="be0bb178-2e7a-47f0-af96-64256405a2ab"
          d="M307.4,377.9a3.2,3.2,0,0,0,3.1,2.9h33.1a6.2,6.2,0,0,0,6-8.4L334,331.9A17.4,17.4,0,0,0,317.5,320h0a17.4,17.4,0,0,0-17.4,17.4,11.5,11.5,0,0,0,.1,1.9Z"
        />
        <path className="b4ba7d3b-4109-4895-bc88-b12a57654451" d="M172.8,233.2a6,6,0,1,1,6-6A6,6,0,0,1,172.8,233.2Z" />
      </g>
    </g>
  </svg>
)

export default ImgPiggyBank
