import { SVGProps } from 'react'

const ImgKiwi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a53b49eb-a4e6-4ab4-a0c6-2165f144f670{opacity:0.2;fill:url(#f3d0ccb8-7d7f-4b86-9cda-1f53c5c57063);}.a2eb098b-d4a8-4a20-bd32-2c080173d505{fill:none;}.f9e1728e-edaa-4e68-9273-6b43e9b73638{fill:url(#e36ca2a7-ceb1-40ff-b343-8e94b70cbe17);}.b4ee3c82-0887-4554-afce-e635308f96b1{fill:url(#b49e4e30-5337-400d-8fe0-243b09f6fcfd);}.af4f0067-9ae0-4a4f-8d15-6360b10d2215{fill:url(#b8cf4e5b-49c1-4b6a-a895-dd95847d55a3);}.bc20cc2e-4a50-4ed1-ad1d-7867fc26ea0f{fill:#fff;}.f91972f6-a68c-4d09-9350-64cf04819099{fill:url(#ff7bddcd-30fc-4b34-bdae-4b3ff0e8985b);}.a8bef2d1-8f8e-4242-aac5-a9530e9f6031{fill:url(#ada72aa5-6316-4a7d-b830-14733027f6fd);}.a335ad79-cd1c-46fa-9060-295f2ed83715{fill:url(#a3eb4fc9-c00d-49f9-a7fc-7e415e1b15a5);}.af0956d1-f6fb-4766-bf22-3fc03f5c4e58{fill:url(#efb09b51-825a-47ec-8df2-8108f85fd852);}.a88aa350-5552-4e9c-9ed3-786b8318d73e{fill:url(#ae5a17be-7bef-4a51-9911-861b0aa775b6);}'
        }
      </style>
      <linearGradient
        id="f3d0ccb8-7d7f-4b86-9cda-1f53c5c57063"
        x1={109.37}
        y1={470.89}
        x2={305.82}
        y2={274.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e36ca2a7-ceb1-40ff-b343-8e94b70cbe17"
        x1={291.13}
        y1={13076.65}
        x2={330.01}
        y2={13115.52}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="b49e4e30-5337-400d-8fe0-243b09f6fcfd"
        x1={301.02}
        y1={360.88}
        x2={331.34}
        y2={360.88}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#706ac5" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="b8cf4e5b-49c1-4b6a-a895-dd95847d55a3"
        x1={259.3}
        y1={360.88}
        x2={289.62}
        y2={360.88}
        xlinkHref="#b49e4e30-5337-400d-8fe0-243b09f6fcfd"
      />
      <linearGradient
        id="ff7bddcd-30fc-4b34-bdae-4b3ff0e8985b"
        x1={277.94}
        y1={322.61}
        x2={299.44}
        y2={344.12}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ada72aa5-6316-4a7d-b830-14733027f6fd"
        x1={319.03}
        y1={307.71}
        x2={348.35}
        y2={337.03}
        xlinkHref="#ff7bddcd-30fc-4b34-bdae-4b3ff0e8985b"
      />
      <linearGradient
        id="a3eb4fc9-c00d-49f9-a7fc-7e415e1b15a5"
        x1={292.18}
        y1={223.83}
        x2={373.87}
        y2={305.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="efb09b51-825a-47ec-8df2-8108f85fd852"
        x1={66.41}
        y1={334.29}
        x2={151.83}
        y2={248.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="ae5a17be-7bef-4a51-9911-861b0aa775b6"
        x1={184.92}
        y1={230.23}
        x2={226.32}
        y2={271.63}
        xlinkHref="#a3eb4fc9-c00d-49f9-a7fc-7e415e1b15a5"
      />
    </defs>
    <g id="fea37e7f-a9a1-4b82-ab0f-29cd61889c67" data-name="Layer 1">
      <path
        className="a53b49eb-a4e6-4ab4-a0c6-2165f144f670"
        d="M415.8,384.4,290.5,509.7A261.9,261.9,0,0,1,256,512,254.8,254.8,0,0,1,84.8,446.3L65.6,427.1a256,256,0,0,1-49.9-82.5L156.5,203.8,257.2,319.3,317,341.4l7.6,38.9Z"
      />
      <g id="ffda1560-63dd-47b0-ac27-1f5aa09ca083" data-name="backdrop">
        <path
          className="a2eb098b-d4a8-4a20-bd32-2c080173d505"
          d="M512,256c0,129.7-96.4,236.8-221.5,253.7A261.9,261.9,0,0,1,256,512,254.8,254.8,0,0,1,84.8,446.3a248.4,248.4,0,0,1-19.2-19.2A254.3,254.3,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="f9e1728e-edaa-4e68-9273-6b43e9b73638"
        d="M310.6,165a27.5,27.5,0,1,1,27.5-27.5A27.5,27.5,0,0,1,310.6,165Z"
      />
      <path
        className="b4ee3c82-0887-4554-afce-e635308f96b1"
        d="M317,341.4V366h-8.8a7.2,7.2,0,1,0,0,14.3h16a7.1,7.1,0,0,0,7.1-7.1V341.4Z"
      />
      <path
        className="af4f0067-9ae0-4a4f-8d15-6360b10d2215"
        d="M275.3,341.4V366h-8.8a7.2,7.2,0,1,0,0,14.3h16a7.1,7.1,0,0,0,7.1-7.1V341.4Z"
      />
      <path className="bc20cc2e-4a50-4ed1-ad1d-7867fc26ea0f" d="M414.4,384.4h-304a2,2,0,1,1,0-4h304a2,2,0,0,1,0,4Z" />
      <circle className="f91972f6-a68c-4d09-9350-64cf04819099" cx={281.8} cy={326.5} r={25.6} />
      <rect
        className="a8bef2d1-8f8e-4242-aac5-a9530e9f6031"
        x={298.7}
        y={274.2}
        width={51.2}
        height={77.69}
        rx={25.6}
      />
      <ellipse className="a335ad79-cd1c-46fa-9060-295f2ed83715" cx={306.9} cy={238.6} rx={101.9} ry={92.5} />
      <path
        className="af0956d1-f6fb-4766-bf22-3fc03f5c4e58"
        d="M144,241.1c-34.2,39-74.3,97-70.5,100.3s33.8-50.1,77.9-79.3Z"
      />
      <circle className="a88aa350-5552-4e9c-9ed3-786b8318d73e" cx={192.4} cy={237.7} r={49.3} />
      <circle className="bc20cc2e-4a50-4ed1-ad1d-7867fc26ea0f" cx={171.8} cy={228.9} r={6.2} />
    </g>
  </svg>
)

export default ImgKiwi
