import { SVGProps } from 'react'

const ImgFolderWithArrowUpGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.e0f9fa24-a143-4ccd-b224-bddafa31fe31{fill:none;}.bdb09087-2503-4bbf-9073-6dabcdd995f9{opacity:0.15;fill:url(#bf280d8a-dc2f-4fa7-8eec-d7c2ec6153ba);}.b149d43b-50e6-4153-bce7-23ca39bc7474{fill:url(#a5f9dcab-ea20-4949-b431-02fd4d3cef76);}.a7c7533f-7be0-45ed-b862-d992dbab6e97{fill:#fff;}.a4c1ab9a-b9da-4b6e-97fb-398dd4f24a55{fill:url(#b6f34ce0-bf86-484d-b638-44ffd2d1b3cf);}.b43aae73-de73-4c50-95df-063f291d74f5{fill:#f7ced7;}.f5f54ee0-bac3-444c-aad2-6bc32380a3a9{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="bf280d8a-dc2f-4fa7-8eec-d7c2ec6153ba"
        x1={89.55}
        y1={451.02}
        x2={279.95}
        y2={260.62}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a5f9dcab-ea20-4949-b431-02fd4d3cef76"
        x1={73.75}
        y1={15683.84}
        x2={435.73}
        y2={15321.87}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="b6f34ce0-bf86-484d-b638-44ffd2d1b3cf"
        x1={365.28}
        y1={15366.65}
        x2={94.9}
        y2={15637.03}
        gradientTransform="matrix(1, 0, 0, -1, 0, 15781.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
    </defs>
    <g id="ee583c6a-640c-463a-ad68-dd18c2d6afde" data-name="Layer 1">
      <g id="fd7646aa-4d77-42c4-9ff6-393357834a9d" data-name="backdrop">
        <path
          className="e0f9fa24-a143-4ccd-b224-bddafa31fe31"
          d="M512,256c0,131.2-98.7,239.3-225.9,254.2A249.5,249.5,0,0,1,256,512,256.1,256.1,0,0,1,1.4,283.3,272.6,272.6,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="bdb09087-2503-4bbf-9073-6dabcdd995f9"
        d="M407.8,388.5,286.1,510.2A249.5,249.5,0,0,1,256,512,254.8,254.8,0,0,1,85.3,446.8L65.1,426.6A255.5,255.5,0,0,1,1.4,283.3L121.6,163.2Z"
      />
      <path
        className="b149d43b-50e6-4153-bce7-23ca39bc7474"
        d="M230.4,188.8l-25.6-25.6H121.6V384a6.4,6.4,0,0,0,6.4,6.4H403.2a6.4,6.4,0,0,0,6.4-6.4V195.2a6.4,6.4,0,0,0-6.4-6.4Z"
      />
      <path
        className="a7c7533f-7be0-45ed-b862-d992dbab6e97"
        d="M308.5,123.5,270.1,85.1l-.4-.4h0l-.7-.5h-.2l-.7-.4H268l-.8-.3H267l-.8-.2h-1.9l-.8.2h-.1l-1.5.8h-.2l-.6.6h0l-38.4,38.4a6.4,6.4,0,0,0,9,9l27.5-27.4V326.4a6.4,6.4,0,1,0,12.8,0V105.1l27.5,27.4a6.3,6.3,0,0,0,9,0A6.3,6.3,0,0,0,308.5,123.5Z"
      />
      <path
        className="a4c1ab9a-b9da-4b6e-97fb-398dd4f24a55"
        d="M211.2,220.8l-25.6-25.6H102.4L121,384.7a6.4,6.4,0,0,0,6.4,5.7H402.7a6.4,6.4,0,0,0,6.4-6.4v-.5L390.9,226.7a6.5,6.5,0,0,0-6.4-5.9Z"
      />
      <path className="b43aae73-de73-4c50-95df-063f291d74f5" d="M358.4,258H323.2a2,2,0,0,1,0-4h35.2a2,2,0,0,1,0,4Z" />
      <path
        className="f5f54ee0-bac3-444c-aad2-6bc32380a3a9"
        d="M409.1,161.2h-6v-6a2,2,0,1,0-4,0v6h-6a2,2,0,0,0,0,4h6v6a2,2,0,0,0,4,0v-6h6a2,2,0,0,0,0-4Z"
      />
      <path
        className="f5f54ee0-bac3-444c-aad2-6bc32380a3a9"
        d="M182.7,99.8a8.4,8.4,0,1,1,8.4-8.4A8.4,8.4,0,0,1,182.7,99.8Zm0-12.8a4.4,4.4,0,1,0,0,8.8,4.4,4.4,0,1,0,0-8.8Z"
      />
      <path
        className="f5f54ee0-bac3-444c-aad2-6bc32380a3a9"
        d="M176.3,350.5a1.6,1.6,0,0,1-1.2-.4l-11.6-8.7-11.6,8.7a1.8,1.8,0,0,1-2.4,0l-12.8-9.6a1.9,1.9,0,0,1-.4-2.8,2,2,0,0,1,2.8-.4l11.6,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.6,8.7,11.6-8.7a2.1,2.1,0,0,1,2.8.4,2.1,2.1,0,0,1-.4,2.8l-12.8,9.6A1.9,1.9,0,0,1,176.3,350.5Z"
      />
    </g>
  </svg>
)

export default ImgFolderWithArrowUpGlitter
