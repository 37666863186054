export const HOME = process.env.NEXT_PUBLIC_TELLA_URL
export const MY_TELLA = process.env.NEXT_PUBLIC_TELLA_MY_URL

export const HL_APPLICATION = MY_TELLA + '/application'

export const HL_APPLICATION_BUY_A_HOME = HL_APPLICATION + '?buy-a-home'
export const HL_APPLICATION_REFINANCE = HL_APPLICATION + '?refinance'

export const HL_MORTGAGES = HOME + '/home-loans-mortgages'

export const GLOSSARY = HOME + '/glossary'
export const ABOUT_US = HOME + '/about-us'
export const CONTACT_US = HOME + '/contact-us'
export const PARTNER_WITH_US = HOME + '/partner-with-tella'
export const REALESTATE_PARTNER = HOME + '/realestate-partner'
export const FAQS = HOME + '/faqs'
export const IMPORTANT_INFO = HOME + '/important-info'
export const CHARITIES = HOME + '/charities'
export const KIWISAVER_PROVIDERS = HOME + '/kiwisaver-providers'
export const REALESTATE_REFER = HOME + '/realestate-refer'
export const PROFESSIONAL_REFER = HOME + '/professional-refer'
export const BOOK_A_CHAT = 'https://calendly.com/tella-advisors/home-loan-expert-chat'
export const FIRST_HOME_BOOK_A_CHAT = HOME + '?box=strat_session_form'

export const FIRST_HOME = '/first-home'
export const REFINANCE = '/refinance'
export const INVEST = '/invest'

export const RATES = HL_MORTGAGES + '/rates'
export const CALCULATORS = HL_MORTGAGES + '/calculators'
export const LEARN = HL_MORTGAGES + '/learn'

export const BLOG = '/blog'

export const CALC_FIRST_HOME_DEPOSIT = CALCULATORS + '/first-home-deposit'
export const CALC_AFFORDABILITY = CALCULATORS + '/affordability'
export const CALC_REPAYMENT = CALCULATORS + '/repayment'
export const CALC_REFINANCE = CALCULATORS + '/refinance'

export const LEARN_FIRST_HOME_BUYER = HL_MORTGAGES + '/first-home-buyer'
export const LEARN_MOVING_HOUSE_SWITCHING_BANKS = HL_MORTGAGES + '/moving-home-switching-bank'
export const LEARN_PROPERTY_INVESTMENT = HL_MORTGAGES + '/property-investment'
export const LEARN_HELPING_KIDS_BUY_HOME = HL_MORTGAGES + '/helping-the-kids-buy-a-home'
export const LEARN_BUYING_FROM_OVERSEAS = HL_MORTGAGES + '/buying-from-overseas-moving-to-nz'

export const PROPERTY_PORTFOLIO_LANDING = '/property'
