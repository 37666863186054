import { SVGProps } from 'react'

const ImgAwardBadge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.f7aaf09b-0ade-4820-a7df-798c609f50ac{fill:none;}.bba382ba-f798-4666-9716-9e8cd6428ec6{opacity:0.15;fill:url(#ff2c9ed9-c046-44f2-8cfd-418b108254cc);}.eec36abf-9c3a-4862-8b1b-445288626f99{fill:url(#f8ceee59-866d-4487-8141-3ed6319cb094);}.b1f946a9-4676-4917-a6dd-279a55b1d96f{fill:url(#f5bf49cf-1530-4802-87bb-0c70cd942295);}.aa1c0fc5-1c81-44dc-939e-c8a198e712eb{fill:url(#adab0d29-b303-47c4-9bb3-921d26ec93b9);}.ac86a4b7-7d99-46ee-b854-b0661067bed4{fill:#fff;opacity:0.9;isolation:isolate;}.bc3b0a9c-93f5-43ac-9e27-7553e4ef78f4{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="ff2c9ed9-c046-44f2-8cfd-418b108254cc"
        x1={75.86}
        y1={437.8}
        x2={258.91}
        y2={254.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f8ceee59-866d-4487-8141-3ed6319cb094"
        x1={186.04}
        y1={10684.37}
        x2={313.92}
        y2={10454.11}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f9d8df" />
        <stop offset={1} stopColor="#f7ced7" />
      </linearGradient>
      <linearGradient
        id="f5bf49cf-1530-4802-87bb-0c70cd942295"
        x1={235.6}
        y1={10441.2}
        x2={302.73}
        y2={10374.06}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="adab0d29-b303-47c4-9bb3-921d26ec93b9"
        x1={333.79}
        y1={10636.19}
        x2={87.94}
        y2={10390.34}
        gradientTransform="matrix(1, 0, 0, -1, 0, 10785.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
    </defs>
    <g id="e078cc7f-9608-421e-983e-573b01193c41" data-name="Layer 1">
      <g id="b5e726a6-f375-418c-8452-2871fcef0820" data-name="backdrop">
        <circle className="f7aaf09b-0ade-4820-a7df-798c609f50ac" cx={256} cy={256} r={256} />
      </g>
      <path
        className="bba382ba-f798-4666-9716-9e8cd6428ec6"
        d="M291.2,416l-90.1,90.1A255,255,0,0,1,79.4,441.3l-8.7-8.7a255.8,255.8,0,0,1-64.2-119L162.1,158Z"
      />
      <path
        className="eec36abf-9c3a-4862-8b1b-445288626f99"
        d="M362,235.2a42.9,42.9,0,0,1-.4-8,42.9,42.9,0,0,1,.4-8l2.3-15.9a53.6,53.6,0,0,0-33.5-58l-14.9-5.9a48.8,48.8,0,0,1-13.8-8l-12.6-10A54.6,54.6,0,0,0,256,108.8a54.6,54.6,0,0,0-33.5,12.6l-12.6,10a48.8,48.8,0,0,1-13.8,8l-14.9,5.9a53.6,53.6,0,0,0-33.5,58l2.3,15.9a42.9,42.9,0,0,1,.4,8,42.9,42.9,0,0,1-.4,8l-2.3,15.9a53.6,53.6,0,0,0,33.5,58l14.9,5.9a48.8,48.8,0,0,1,13.8,8l12.6,10A54.6,54.6,0,0,0,256,345.6,54.6,54.6,0,0,0,289.5,333l12.6-10a48.8,48.8,0,0,1,13.8-8l14.9-5.9a53.6,53.6,0,0,0,33.5-58Z"
      />
      <polygon
        className="b1f946a9-4676-4917-a6dd-279a55b1d96f"
        points="291.2 416 256 380.8 220.8 416 220.8 313.6 291.2 313.6 291.2 416"
      />
      <path
        className="aa1c0fc5-1c81-44dc-939e-c8a198e712eb"
        d="M361.8,193.7l-10-12.6a48.8,48.8,0,0,1-8-13.8l-5.9-14.9a53.6,53.6,0,0,0-58-33.5L264,121.2a42.9,42.9,0,0,1-8,.4,42.9,42.9,0,0,1-8-.4l-15.9-2.3a53.6,53.6,0,0,0-58,33.5l-5.9,14.9a48.8,48.8,0,0,1-8,13.8l-10,12.6a54.6,54.6,0,0,0-12.6,33.5,54.6,54.6,0,0,0,12.6,33.5l10,12.6a48.8,48.8,0,0,1,8,13.8l5.9,14.9a53.6,53.6,0,0,0,58,33.5l15.9-2.3a42.9,42.9,0,0,1,8-.4,42.9,42.9,0,0,1,8,.4l15.9,2.3a53.6,53.6,0,0,0,58-33.5l5.9-14.9a48.8,48.8,0,0,1,8-13.8l10-12.6a54.6,54.6,0,0,0,12.6-33.5A54.6,54.6,0,0,0,361.8,193.7Z"
      />
      <path
        className="ac86a4b7-7d99-46ee-b854-b0661067bed4"
        d="M256,304.4a77.2,77.2,0,1,1,77.2-77.2A77.2,77.2,0,0,1,256,304.4ZM256,154a73.2,73.2,0,1,0,73.2,73.2A73.2,73.2,0,0,0,256,154Z"
      />
      <polygon
        className="bc3b0a9c-93f5-43ac-9e27-7553e4ef78f4"
        points="274.3 252.3 256 239.1 237.7 252.3 244.7 230.9 226.5 217.6 249 217.6 256 196.1 263 217.6 285.5 217.6 267.3 230.9 274.3 252.3"
      />
    </g>
  </svg>
)

export default ImgAwardBadge
