import { SVGProps } from 'react'

const BankGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a52a88f0-6a48-4626-a9ea-677d47c95add{fill:none;}.edd14c7f-9179-49bc-b877-613b6cf58740{opacity:0.15;fill:url(#ffe1516a-9386-4f7f-8345-b858c4430c8b);}.e0e36cd5-4941-46bd-82df-13e510221b42{fill:url(#b974d502-af68-4ce9-a6e4-e79bac6d5216);}.a6af8e7f-9a1f-44bc-90e3-f23e20c0af90{fill:url(#f2309728-7a9a-4e36-b41f-e93929b489cd);}.b6ab523c-628a-45b3-a046-89c523378919{fill:#fff;}.ec804513-c396-4e4f-9777-bb93824fae99{fill:url(#ec1b114e-3efe-4b7a-aae7-818595b897f8);}.e9ce802a-0faa-4777-a06d-2b7c8ad48a53{fill:url(#bec27c00-d6e3-445e-8826-6b9bd339954f);}.adbd1b98-d261-4a91-b668-210bb9fb4848{fill:url(#b7cb2439-8ef8-4d58-adc2-704da0a27fe3);}.f71a8348-888c-4bc0-834e-e15a24ce6ae3{fill:url(#f2182628-0a67-4a3a-bb2c-79b3742bd862);}.f9ca3821-4525-4f0a-9a09-192f7b70d51d{fill:url(#a0fb9ee0-47c9-41de-b547-f0027cf9a4b5);}.b0d6fa33-506b-41fc-a8bd-06362e9248fd{fill:url(#a4cbe655-1b3b-48a9-b065-2dd94a7f7679);}.f2747105-5ab9-4fd8-879a-35bf62671067{fill:url(#f1b4a8ce-e44a-4b59-8015-756f402a99a4);}.fdac116a-73b1-4491-afd1-281ca39187bd{fill:url(#bd00feb3-af52-4705-a7b1-838a1be07e96);}.a9fb2ed4-4e45-4749-96e7-ef2c58c3c233{fill:url(#be0d2eae-a1fd-4a3b-b53d-e63ddd879c1f);}.aea29046-5182-4233-bec7-4d4bad78e09f{fill:url(#f281c6d9-2b18-4e5d-9ca2-9401a6258cd3);}.a1316c6b-1e36-4148-a2aa-86b6ab3dc858{fill:url(#e1f44d16-ef6d-4baa-bf07-cfa22f95b29b);}.bbc38a19-789f-4054-9e68-42a3ba8393b6{fill:url(#ffd0b7f4-f345-4db3-a645-9290c58c8f90);}.b05cc431-57fd-4558-8b0d-86b73069d2b1{fill:url(#bf8357b0-f4e1-4d00-a9a5-47f20f0aded9);}.b274dfd8-1669-4c85-a159-ff9937ca4b21{fill:url(#ff39b6d0-3cfc-4b9f-97c3-4e205389b2f9);}.a3c14947-8c5e-4e97-8d38-16bf3f579adb{fill:url(#f9bf6ddd-3924-4001-aa25-aa33fa7f10df);}.b368d3ea-d8d9-479c-8d90-0a66a53dfdfd{fill:url(#f05a848c-287a-4979-b9a9-ccc8566744b0);}.fa339a8b-f773-42d1-a03a-05bcf3b31ab3{fill:#f7ced7;}.b6ac8b36-b7dd-4c1e-a0e2-f1ba2f8c11b3{fill:#37c49f;}'
        }
      </style>
      <linearGradient
        id="ffe1516a-9386-4f7f-8345-b858c4430c8b"
        x1={90.73}
        y1={452.75}
        x2={281.53}
        y2={261.95}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="b974d502-af68-4ce9-a6e4-e79bac6d5216"
        x1={193.52}
        y1={4427.12}
        x2={325.8}
        y2={4559.39}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
      <linearGradient
        id="f2309728-7a9a-4e36-b41f-e93929b489cd"
        x1={180.13}
        y1={265.34}
        x2={386.63}
        y2={58.83}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="ec1b114e-3efe-4b7a-aae7-818595b897f8"
        x1={220.03}
        y1={4467.65}
        x2={313.79}
        y2={4561.41}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="bec27c00-d6e3-445e-8826-6b9bd339954f"
        x1={326.2}
        y1={238.2}
        x2={397.89}
        y2={309.89}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="b7cb2439-8ef8-4d58-adc2-704da0a27fe3"
        x1={350.37}
        y1={192.64}
        x2={377.18}
        y2={219.45}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="f2182628-0a67-4a3a-bb2c-79b3742bd862"
        x1={345.22}
        y1={359.72}
        x2={373.9}
        y2={331.04}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="a0fb9ee0-47c9-41de-b547-f0027cf9a4b5"
        x1={259.33}
        y1={241.73}
        x2={328.26}
        y2={310.66}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="a4cbe655-1b3b-48a9-b065-2dd94a7f7679"
        x1={280.86}
        y1={193.52}
        x2={305.6}
        y2={218.26}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="f1b4a8ce-e44a-4b59-8015-756f402a99a4"
        x1={275.07}
        y1={359.47}
        x2={309.36}
        y2={325.18}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="bd00feb3-af52-4705-a7b1-838a1be07e96"
        x1={189.72}
        y1={242.52}
        x2={260.07}
        y2={312.87}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="be0d2eae-a1fd-4a3b-b53d-e63ddd879c1f"
        x1={210.46}
        y1={193.52}
        x2={237.18}
        y2={220.25}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="f281c6d9-2b18-4e5d-9ca2-9401a6258cd3"
        x1={206.75}
        y1={357.38}
        x2={237.78}
        y2={326.36}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="e1f44d16-ef6d-4baa-bf07-cfa22f95b29b"
        x1={118.99}
        y1={242.19}
        x2={191.6}
        y2={314.8}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="ffd0b7f4-f345-4db3-a645-9290c58c8f90"
        x1={140.06}
        y1={193.52}
        x2={173.96}
        y2={227.42}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="bf8357b0-f4e1-4d00-a9a5-47f20f0aded9"
        x1={137.65}
        y1={356.09}
        x2={170.56}
        y2={323.18}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="ff39b6d0-3cfc-4b9f-97c3-4e205389b2f9"
        x1={204.98}
        y1={308.98}
        x2={333.28}
        y2={437.28}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="f9bf6ddd-3924-4001-aa25-aa33fa7f10df"
        x1={202.46}
        y1={325.66}
        x2={336.62}
        y2={459.82}
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlinkHref="#b974d502-af68-4ce9-a6e4-e79bac6d5216"
      />
      <linearGradient
        id="f05a848c-287a-4979-b9a9-ccc8566744b0"
        x1={235.79}
        y1={4498.19}
        x2={257.08}
        y2={4519.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 4665.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
    </defs>
    <g id="adfc7c10-a046-427f-9e77-8d8a80f5d2d6" data-name="Layer 1">
      <g id="ea0bab02-276b-42eb-bd89-b10f293738c0" data-name="backdrop">
        <path
          className="a52a88f0-6a48-4626-a9ea-677d47c95add"
          d="M512,256c0,130.2-97.2,237.6-222.9,253.9A256.8,256.8,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A255.2,255.2,0,0,1,1.8,286.2,251.2,251.2,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="edd14c7f-9179-49bc-b877-613b6cf58740"
        d="M409.3,389.7,289.1,509.9A256.8,256.8,0,0,1,77.4,439.4l-.2-.2-4.4-4.4-.2-.2A255.2,255.2,0,0,1,1.8,286.2l107-107h22.4l41.6,190.4Z"
      />
      <polygon
        className="e0e36cd5-4941-46bd-82df-13e510221b42"
        points="396.8 179.2 115.2 179.2 140.8 147.2 371.2 147.2 396.8 179.2"
      />
      <path
        className="a6af8e7f-9a1f-44bc-90e3-f23e20c0af90"
        d="M115.2,182.4H396.8v12.8a3.2,3.2,0,0,1-3.2,3.2H118.4a3.2,3.2,0,0,1-3.2-3.2V182.4Z"
      />
      <path
        className="b6ab523c-628a-45b3-a046-89c523378919"
        d="M401.6,182.8H110.4a2,2,0,0,1,0-4H401.6a2,2,0,0,1,0,4Z"
      />
      <path className="ec804513-c396-4e4f-9777-bb93824fae99" d="M172.8,179.2a83.2,83.2,0,0,1,166.4,0Z" />
      <path className="e9ce802a-0faa-4777-a06d-2b7c8ad48a53" d="M342.4,211.2h38.4V336H342.4Z" />
      <path
        className="adbd1b98-d261-4a91-b668-210bb9fb4848"
        d="M336,198.4h51.2V208a3.2,3.2,0,0,1-3.2,3.2H339.2A3.2,3.2,0,0,1,336,208v-9.6Z"
      />
      <path
        className="f71a8348-888c-4bc0-834e-e15a24ce6ae3"
        d="M339.2,336H384a3.2,3.2,0,0,1,3.2,3.2v9.6H336v-9.6A3.2,3.2,0,0,1,339.2,336Z"
      />
      <path className="f9ca3821-4525-4f0a-9a09-192f7b70d51d" d="M272,211.2h38.4V336H272Z" />
      <path
        className="b0d6fa33-506b-41fc-a8bd-06362e9248fd"
        d="M265.6,198.4h51.2V208a3.2,3.2,0,0,1-3.2,3.2H268.8a3.2,3.2,0,0,1-3.2-3.2v-9.6Z"
      />
      <path
        className="f2747105-5ab9-4fd8-879a-35bf62671067"
        d="M268.8,336h44.8a3.2,3.2,0,0,1,3.2,3.2v9.6H265.6v-9.6A3.2,3.2,0,0,1,268.8,336Z"
      />
      <path className="fdac116a-73b1-4491-afd1-281ca39187bd" d="M201.6,211.2H240V336H201.6Z" />
      <path
        className="a9fb2ed4-4e45-4749-96e7-ef2c58c3c233"
        d="M195.2,198.4h51.2V208a3.2,3.2,0,0,1-3.2,3.2H198.4a3.2,3.2,0,0,1-3.2-3.2v-9.6Z"
      />
      <path
        className="aea29046-5182-4233-bec7-4d4bad78e09f"
        d="M198.4,336h44.8a3.2,3.2,0,0,1,3.2,3.2v9.6H195.2v-9.6A3.2,3.2,0,0,1,198.4,336Z"
      />
      <path className="a1316c6b-1e36-4148-a2aa-86b6ab3dc858" d="M131.2,211.2h38.4V336H131.2Z" />
      <path
        className="bbc38a19-789f-4054-9e68-42a3ba8393b6"
        d="M124.8,198.4H176V208a3.2,3.2,0,0,1-3.2,3.2H128a3.2,3.2,0,0,1-3.2-3.2v-9.6Z"
      />
      <path
        className="b05cc431-57fd-4558-8b0d-86b73069d2b1"
        d="M128,336h44.8a3.2,3.2,0,0,1,3.2,3.2v9.6H124.8v-9.6A3.2,3.2,0,0,1,128,336Z"
      />
      <path className="b274dfd8-1669-4c85-a159-ff9937ca4b21" d="M118.4,352H393.6v16H118.4Z" />
      <path className="a3c14947-8c5e-4e97-8d38-16bf3f579adb" d="M112,371.2H400v16H112Z" />
      <circle className="b368d3ea-d8d9-479c-8d90-0a66a53dfdfd" cx={256} cy={147.2} r={19.2} />
      <path className="fa339a8b-f773-42d1-a03a-05bcf3b31ab3" d="M408,390.8H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
      <path
        className="fa339a8b-f773-42d1-a03a-05bcf3b31ab3"
        d="M401.6,371.6H110.4a2,2,0,1,1,0-4H401.6a2,2,0,0,1,0,4Z"
      />
      <path
        className="fa339a8b-f773-42d1-a03a-05bcf3b31ab3"
        d="M395.2,352.4H116.8a2,2,0,0,1,0-4H395.2a2,2,0,0,1,0,4Z"
      />
      <path
        className="b6ac8b36-b7dd-4c1e-a0e2-f1ba2f8c11b3"
        d="M103.3,300.4a8.4,8.4,0,1,1,8.4-8.3A8.4,8.4,0,0,1,103.3,300.4Zm0-12.7a4.4,4.4,0,0,0-4.4,4.4,4.4,4.4,0,0,0,8.8,0A4.4,4.4,0,0,0,103.3,287.7Z"
      />
      <path
        className="b6ac8b36-b7dd-4c1e-a0e2-f1ba2f8c11b3"
        d="M399.9,247.4a2.8,2.8,0,0,1-1.2-.4l-11.5-8.7L375.6,247a2.1,2.1,0,0,1-2.4,0l-12.7-9.6a2,2,0,1,1,2.4-3.2l11.5,8.7,11.6-8.7a2.1,2.1,0,0,1,2.4,0l11.5,8.7,11.6-8.7a2,2,0,0,1,2.4,3.2L401.1,247A2.1,2.1,0,0,1,399.9,247.4Z"
      />
      <path
        className="b6ac8b36-b7dd-4c1e-a0e2-f1ba2f8c11b3"
        d="M188.7,94h-5.9V88a2,2,0,0,0-4,0v6h-6a2,2,0,0,0,0,4h6v6a2,2,0,0,0,4,0V98h5.9a2,2,0,0,0,0-4Z"
      />
    </g>
  </svg>
)

export default BankGlitter
