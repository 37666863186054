import { SVGProps } from 'react'

const ImgHandWithTwoCoins = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b8440bdb-9661-4629-8c8f-c9ad3056bfe9{fill:url(#b2b2ff4a-f28d-4046-8477-097649b8d1ad);}.ecd22582-0988-4937-b73e-9306185ec592{fill:none;}.ed7399ef-9899-47bd-8da7-ccb0d14104c1{fill:url(#ad051874-b66d-4532-9732-39696b80c3b2);}.f12bad50-93fb-4a37-b276-f85a86321886{fill:url(#eb5b555b-cb7c-47e7-8455-fa19483de2a8);}.bbf23ccc-1016-472b-a028-38edd47d95a1{opacity:0.15;fill:url(#fedab6d2-4523-467b-becc-b3557a23783e);}.f5d07f4f-a131-41ed-9157-18248f53a822{fill:url(#a734d9d4-e6a1-43dd-aefa-b03e26eff9de);}'
        }
      </style>
      <linearGradient
        id="b2b2ff4a-f28d-4046-8477-097649b8d1ad"
        x1={226.96}
        y1={14214.16}
        x2={265.84}
        y2={14253.03}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="ad051874-b66d-4532-9732-39696b80c3b2"
        x1={347.97}
        y1={14179.96}
        x2={389.47}
        y2={14221.47}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="eb5b555b-cb7c-47e7-8455-fa19483de2a8"
        x1={188.96}
        y1={244.41}
        x2={314.11}
        y2={369.57}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="fedab6d2-4523-467b-becc-b3557a23783e"
        x1={90.71}
        y1={452.62}
        x2={343.66}
        y2={199.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a734d9d4-e6a1-43dd-aefa-b03e26eff9de"
        x1={284.55}
        y1={195.45}
        x2={323.45}
        y2={156.55}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
    </defs>
    <g id="b9b98ebd-95b3-4b27-9373-066b54607c0e" data-name="Layer 1">
      <path
        className="b8440bdb-9661-4629-8c8f-c9ad3056bfe9"
        d="M246.4,251.5A27.5,27.5,0,1,1,273.9,224,27.5,27.5,0,0,1,246.4,251.5Z"
      />
      <g id="a5cbab20-9203-4cc3-acf5-6bdf2fe083b8" data-name="backdrop">
        <path
          className="ecd22582-0988-4937-b73e-9306185ec592"
          d="M512,256c0,141.4-114.6,256-256,256A254.5,254.5,0,0,1,79.9,441.8c-3.3-3.1-6.6-6.4-9.7-9.7A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="ed7399ef-9899-47bd-8da7-ccb0d14104c1"
        d="M300.8,300.8s50.8-47.6,57.6-54.4a29.3,29.3,0,0,1,22.4-9.6c3.8,0,5.7,2.7,3.2,6.4l-57.6,80Z"
      />
      <path
        className="f12bad50-93fb-4a37-b276-f85a86321886"
        d="M400,243.2c-9.6,0-12.8,3.2-22.4,9.6l-76.8,48H231.2a32.8,32.8,0,0,1,17.7-5h29.5a22.4,22.4,0,0,0,22.4-22.4h0a3.2,3.2,0,0,0-3.2-3.2H224.1c-19.8,0-25.7,6.4-64.1,25.6L108.8,315a22,22,0,0,0-6.4,15.5v40.7A12.8,12.8,0,0,0,115.2,384L177,354.7a25.5,25.5,0,0,1,11.4-2.7h62.9a38.8,38.8,0,0,0,15.1-3.1l60-25.7,76.8-64c3.2-3.2,6.4-6.4,6.4-9.6S409.6,243.2,400,243.2Z"
      />
      <path
        className="bbf23ccc-1016-472b-a028-38edd47d95a1"
        d="M403.3,259.3,166.6,496a256.6,256.6,0,0,1-86.7-54.2l-9.7-9.7a256.4,256.4,0,0,1-33.8-44.4l72.5-72.6,191.9-14.3Z"
      />
      <circle className="f5d07f4f-a131-41ed-9157-18248f53a822" cx={304} cy={176} r={27.5} />
    </g>
  </svg>
)

export default ImgHandWithTwoCoins
