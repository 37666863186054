import ImgThreeShapesWithArrowNoMask from 'components/elements/images/no-mask/img-three-shapes-with-arrow-no-mask'
import ImgAwardBadge from 'components/elements/images/regular/img-award-badge'
import ImgBank from 'components/elements/images/regular/img-bank'
import ImgBarGraph from 'components/elements/images/regular/img-bar-graph'
import ImgBoxWithArrowpointingUp from 'components/elements/images/regular/img-box-with-arrow-pointing-up'
import ImgBuildings from 'components/elements/images/regular/img-buildings'
import ImgCalculatorWithPen from 'components/elements/images/regular/img-calculator-with-pen'
import ImgCalendar from 'components/elements/images/regular/img-calendar'
import ImgChecklist from 'components/elements/images/regular/img-checklist'
import ImgChessPieces from 'components/elements/images/regular/img-chess-pieces'
import ImgComputerWithLamp from 'components/elements/images/regular/img-computer-with-lamp'
import ImgCreditCard from 'components/elements/images/regular/img-credit-card'
import ImgDocument from 'components/elements/images/regular/img-document'
import ImgDocumentSigned from 'components/elements/images/regular/img-document-signed'
import ImgFolderWithArrowUp from 'components/elements/images/regular/img-folder-with-arrow-up'
import ImgGirlAndBoy from 'components/elements/images/regular/img-girl-and-boy'
import ImgHandHoldingIdCard from 'components/elements/images/regular/img-hand-holding-id-card'
import ImgHandWithKey from 'components/elements/images/regular/img-hand-with-key'
import ImgHandWithShapes from 'components/elements/images/regular/img-hand-with-shapes'
import ImgHandWithTwoCoins from 'components/elements/images/regular/img-hand-with-two-coins'
import ImgHouseLeaky from 'components/elements/images/regular/img-house-leaky'
import ImgHouseSmall from 'components/elements/images/regular/img-house-small'
import ImgHouseWithTwoPlants from 'components/elements/images/regular/img-house-with-two-plants'
import ImgKiwi from 'components/elements/images/regular/img-kiwi'
import ImgLaptopAndMobileSyncWithArrows from 'components/elements/images/regular/img-laptop-and-mobile-sync-with-arrows'
import ImgLawyers from 'components/elements/images/regular/img-lawyers'
import ImgLegoBlocks from 'components/elements/images/regular/img-lego-blocks'
import ImgLetterEnvelopeWithCheck from 'components/elements/images/regular/img-letter-envelope-with-check'
import ImgMegaphone from 'components/elements/images/regular/img-megaphone'
import ImgMoneyWithArrows from 'components/elements/images/regular/img-money-with-arrows'
import ImgNoImage from 'components/elements/images/regular/img-no-image'
import ImgNotebookWithPencil from 'components/elements/images/regular/img-notebook-with-pencil'
import ImgPaperAndPen from 'components/elements/images/regular/img-paper-and-pen'
import ImgPaperWithPercentSymbol from 'components/elements/images/regular/img-paper-with-percent-symbol'
import ImgParentsWithChild from 'components/elements/images/regular/img-parents-with-child'
import ImgPenRulerMouse from 'components/elements/images/regular/img-pen-ruler-mouse'
import ImgPiggyBank from 'components/elements/images/regular/img-piggy-bank'
import ImgPlantWithCoins from 'components/elements/images/regular/img-plant-with-coins'
import ImgPuzzleInHand from 'components/elements/images/regular/img-puzzle-in-hand'
import ImgSheetOfPaper from 'components/elements/images/regular/img-sheet-of-paper'
import ImgSmileyInBetweenTwoHands from 'components/elements/images/regular/img-smiley-in-between-two-hands'
import ImgSpeechBubbles from 'components/elements/images/regular/img-speech-bubbles'
import ImgThreeShapesWithArrow from 'components/elements/images/regular/img-three-shapes-with-arrow'
import ImgTwoPeople from 'components/elements/images/regular/img-two-people'
import ImgWallet from 'components/elements/images/regular/img-wallet'
import ImgWill from 'components/elements/images/regular/img-will'
import ImgAwardBadgeGlitter from 'components/elements/images/with-glitter/img-award-badge-glitter'
import ImgBankGlitter from 'components/elements/images/with-glitter/img-bank-glitter'
import ImgBarGraphGlitter from 'components/elements/images/with-glitter/img-bar-graph-glitter'
import ImgBoxWithArrowPointingUpGlitter from 'components/elements/images/with-glitter/img-box-with-arrow-pointing-up-glitter'
import ImgBuildingsGlitter from 'components/elements/images/with-glitter/img-buildings-glitter'
import ImgCalendarGlitter from 'components/elements/images/with-glitter/img-calendar-glitter'
import ImgChecklistGlitter from 'components/elements/images/with-glitter/img-checklist-glitter'
import ImgChessPiecesGlitter from 'components/elements/images/with-glitter/img-chess-pieces-glitter'
import ImgComputerWithLampGlitter from 'components/elements/images/with-glitter/img-computer-with-lamp-glitter'
import ImgCreditCardGlitter from 'components/elements/images/with-glitter/img-credit-card-glitter'
import ImgDocumentGlitter from 'components/elements/images/with-glitter/img-document-glitter'
import ImgDocumentSignedGlitter from 'components/elements/images/with-glitter/img-document-signed-glitter'
import ImgFolderWithArrowUpGlitter from 'components/elements/images/with-glitter/img-folder-with-arrow-up-glitter'
import ImgGirlAndBoyGlitter from 'components/elements/images/with-glitter/img-girl-and-boy-glitter'
import ImgHandWithKeyGlitter from 'components/elements/images/with-glitter/img-hand-with-key-glitter'
import ImgHandWithShapesGlitter from 'components/elements/images/with-glitter/img-hand-with-shapes-glitter'
import ImgHandWithTwoCoinsGlitter from 'components/elements/images/with-glitter/img-hand-with-two-coins-glitter'
import ImgHandsWithHeartGlitter from 'components/elements/images/with-glitter/img-hands-with-heart-glitter'
import ImgHeadphonesGlitter from 'components/elements/images/with-glitter/img-headphones-glitter'
import ImgHouseLeakyGlitter from 'components/elements/images/with-glitter/img-house-leaky-glitter'
import ImgHouseSmallGlitter from 'components/elements/images/with-glitter/img-house-small-glitter'
import ImgHouseWithTwoPlantsGlitter from 'components/elements/images/with-glitter/img-house-with-two-plants-glitter'
import ImgKiwiGlitter from 'components/elements/images/with-glitter/img-kiwi-glitter'
import ImgLaptopAndMobileSyncWithArrowsGlitter from 'components/elements/images/with-glitter/img-laptop-and-mobile-sync-with-arrows-glitter'
import ImgLawyerGlitter from 'components/elements/images/with-glitter/img-lawyers-glitter'
import ImgLetterEnvelopeWithCheckGlitter from 'components/elements/images/with-glitter/img-letter-envelope-witch-check-glitter'
import ImgMegaphoneGlitter from 'components/elements/images/with-glitter/img-megaphone-glitter'
import ImgMoneyWithArrowsGlitter from 'components/elements/images/with-glitter/img-money-with-arrows-glitter'
import ImgNotebookWithPencilGlitter from 'components/elements/images/with-glitter/img-notebook-with-pencil-glitter'
import ImgPaperAndPenGlitter from 'components/elements/images/with-glitter/img-paper-and-pen-glitter'
import ImgPaperWithPercentSymbolGlitter from 'components/elements/images/with-glitter/img-paper-with-percent-symbol-glitter'
import ImgParentsWithChildGlitter from 'components/elements/images/with-glitter/img-parents-with-child-glitter'
import ImgPenRulerMouseGlitter from 'components/elements/images/with-glitter/img-pen-ruler-mouse-glitter'
import ImgPiggybankGlitter from 'components/elements/images/with-glitter/img-piggybank-glitter'
import ImgPlantWithCoinsGlitter from 'components/elements/images/with-glitter/img-plant-with-coins-glitter'
import ImgPuzzleInHandGlitter from 'components/elements/images/with-glitter/img-puzzle-in-hand-glitter'
import ImgSheetOfPaperGlitter from 'components/elements/images/with-glitter/img-sheet-of-paper-glitter'
import ImgSmileyInBetweenTwoHandsGlitter from 'components/elements/images/with-glitter/img-smiley-in-between-two-hands-glitter'
import ImgSpeechBubblesGlitter from 'components/elements/images/with-glitter/img-speech-bubbles-glitter'
import ImgThumbsUpGlitter from 'components/elements/images/with-glitter/img-thumbs-up-glitter'
import ImgTravelBagWithWaterBottleGlitter from 'components/elements/images/with-glitter/img-travelbag-with-water-bottle-glitter'
import ImgTwoPeopleGlitter from 'components/elements/images/with-glitter/img-two-people-glitter'
import ImgWalletGlitter from 'components/elements/images/with-glitter/img-wallet-glitter'
import ImgWillGlitter from 'components/elements/images/with-glitter/img-will-glitter'

export const stringToImgComponent = (str: string, isHovered?: string) => {
  switch (str) {
    case 'BoxWithArrowPointingUp':
      return <ImgBoxWithArrowpointingUp />
    case 'BoxWithArrowPointingUp_Glitter':
      return <ImgBoxWithArrowPointingUpGlitter />
    case 'CalculatorWithPen':
      return <ImgCalculatorWithPen />
    case 'ChessPieces':
      return <ImgChessPieces />
    case 'ChessPieces_Glitter':
      return <ImgChessPiecesGlitter />
    case 'HandHoldingIdCard':
      return <ImgHandHoldingIdCard />
    case 'LaptopAndMobileSyncWithArrows':
      return <ImgLaptopAndMobileSyncWithArrows />
    case 'LegoBlocks':
      return <ImgLegoBlocks isHovered={isHovered === 'LegoBlocks'} />
    case 'PlantWithCoins':
      return <ImgPlantWithCoins />
    case 'PlantWithCoins_Glitter':
      return <ImgPlantWithCoinsGlitter />
    case 'SmileyInBetweenTwoHands':
      return <ImgSmileyInBetweenTwoHands />
    case 'SmileyInBetweenTwoHands_Glitter':
      return <ImgSmileyInBetweenTwoHandsGlitter />
    case 'ThreeShapesWithArrow':
      return <ImgThreeShapesWithArrow />
    case 'HandsWithHeart_Glitter':
      return <ImgHandsWithHeartGlitter />
    case 'Headphones_Glitter':
      return <ImgHeadphonesGlitter />
    case 'Piggybank_Glitter':
      return <ImgPiggybankGlitter />
    case 'ThumbsUp_Glitter':
      return <ImgThumbsUpGlitter />
    case 'TravelbagWithWaterBottle_Glitter':
      return <ImgTravelBagWithWaterBottleGlitter />
    case 'Wallet_Glitter':
      return <ImgWalletGlitter />
    case 'LaptopAndMobileSyncWithArrows_Glitter':
      return <ImgLaptopAndMobileSyncWithArrowsGlitter />
    case 'ThreeShapesWithArrow_NoMask':
      return <ImgThreeShapesWithArrowNoMask />
    case 'AwardBadge':
      return <ImgAwardBadge />
    case 'AwardBadge_Glitter':
      return <ImgAwardBadgeGlitter />
    case 'Bank':
      return <ImgBank />
    case 'Bank_Glitter':
      return <ImgBankGlitter />
    case 'BarGraph':
      return <ImgBarGraph />
    case 'BarGraph_Glitter':
      return <ImgBarGraphGlitter />
    case 'Buildings':
      return <ImgBuildings />
    case 'Buildings_Glitter':
      return <ImgBuildingsGlitter />
    case 'Calendar':
      return <ImgCalendar />
    case 'Calendar_Glitter':
      return <ImgCalendarGlitter />
    case 'Checklist':
      return <ImgChecklist />
    case 'Checklist_Glitter':
      return <ImgChecklistGlitter />
    case 'CreditCard':
      return <ImgCreditCard />
    case 'CreditCard_Glitter':
      return <ImgCreditCardGlitter />
    case 'ComputerWithLamp':
      return <ImgComputerWithLamp />
    case 'ComputerWithLamp_Glitter':
      return <ImgComputerWithLampGlitter />
    case 'DocumentSigned':
      return <ImgDocumentSigned />
    case 'DocumentSigned_Glitter':
      return <ImgDocumentSignedGlitter />
    case 'Document':
      return <ImgDocument />
    case 'Document_Glitter':
      return <ImgDocumentGlitter />
    case 'FolderWithArrowUp':
      return <ImgFolderWithArrowUp />
    case 'FolderWithArrowUp_Glitter':
      return <ImgFolderWithArrowUpGlitter />
    case 'GirlAndBoy':
      return <ImgGirlAndBoy />
    case 'GirlAndBoy_Glitter':
      return <ImgGirlAndBoyGlitter />
    case 'HandWithKey':
      return <ImgHandWithKey />
    case 'HandWithKey_Glitter':
      return <ImgHandWithKeyGlitter />
    case 'HandWithShapes':
      return <ImgHandWithShapes />
    case 'HandWithShapes_Glitter':
      return <ImgHandWithShapesGlitter />
    case 'HandWithTwoCoins':
      return <ImgHandWithTwoCoins />
    case 'HandWithTwoCoins_Glitter':
      return <ImgHandWithTwoCoinsGlitter />
    case 'HouseWithTwoPlants':
      return <ImgHouseWithTwoPlants />
    case 'HouseWithTwoPlants_Glitter':
      return <ImgHouseWithTwoPlantsGlitter />
    case 'Kiwi':
      return <ImgKiwi />
    case 'Kiwi_Glitter':
      return <ImgKiwiGlitter />
    case 'LetterEnvelopeWithCheck':
      return <ImgLetterEnvelopeWithCheck />
    case 'LetterEnvelopeWithCheck_Glitter':
      return <ImgLetterEnvelopeWithCheckGlitter />
    case 'Lawyers':
      return <ImgLawyers />
    case 'Lawyers_Glitter':
      return <ImgLawyerGlitter />
    case 'Megaphone':
      return <ImgMegaphone />
    case 'Megaphone_Glitter':
      return <ImgMegaphoneGlitter />
    case 'MoneyWithArrows':
      return <ImgMoneyWithArrows />
    case 'MoneyWithArrows_Glitter':
      return <ImgMoneyWithArrowsGlitter />
    case 'NotebookWithPencil':
      return <ImgNotebookWithPencil />
    case 'NotebookWithPencil_Glitter':
      return <ImgNotebookWithPencilGlitter />
    case 'PaperAndPen':
      return <ImgPaperAndPen />
    case 'PaperAndPen_Glitter':
      return <ImgPaperAndPenGlitter />
    case 'PaperWithPercentSymbol':
      return <ImgPaperWithPercentSymbol />
    case 'PaperWithPercentSymbol_Glitter':
      return <ImgPaperWithPercentSymbolGlitter />
    case 'ParentsWithChild':
      return <ImgParentsWithChild />
    case 'ParentsWithChild_Glitter':
      return <ImgParentsWithChildGlitter />
    case 'PenRulerMouse':
      return <ImgPenRulerMouse />
    case 'PenRulerMouse_Glitter':
      return <ImgPenRulerMouseGlitter />
    case 'PiggyBank':
      return <ImgPiggyBank />
    case 'PuzzleInHand':
      return <ImgPuzzleInHand />
    case 'PuzzleInHand_Glitter':
      return <ImgPuzzleInHandGlitter />
    case 'SheetOfPaper':
      return <ImgSheetOfPaper />
    case 'SheetOfPaper_Glitter':
      return <ImgSheetOfPaperGlitter />
    case 'SpeechBubbles':
      return <ImgSpeechBubbles />
    case 'SpeechBubbles_Glitter':
      return <ImgSpeechBubblesGlitter />
    case 'TwoPeople':
      return <ImgTwoPeople />
    case 'TwoPeople_Glitter':
      return <ImgTwoPeopleGlitter />
    case 'Will':
      return <ImgWill />
    case 'Will_Glitter':
      return <ImgWillGlitter />
    case 'HouseLeaky':
      return <ImgHouseLeaky />
    case 'HouseLeaky_Glitter':
      return <ImgHouseLeakyGlitter />
    case 'HouseSmall':
      return <ImgHouseSmall />
    case 'HouseSmall_Glitter':
      return <ImgHouseSmallGlitter />
    case 'HouseSmall_Glitter':
      return <ImgHouseSmallGlitter />
    case 'Wallet':
      return <ImgWallet />
    default:
      return <ImgNoImage />
  }
}
