import 'styles/globals.css'

import Layout from 'components/layouts/layout'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { LiveChatWidget } from '@livechat/widget-react'

import type { AppProps } from 'next/app'
function MyApp({ Component, pageProps: { ...pageProps } }: AppProps) {
  const { query, events } = useRouter()

  useEffect(() => {
    let queryString = ''
    if (query.utm_campaign) {
      Object.keys(query).forEach((key, index) => {
        queryString += `${index === 0 ? `?${key}=${query[key]}` : `&${key}=${query[key]}`}`
      })
    }

    localStorage.setItem('queryString', queryString)
  }, [query])

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_META_PIXEL_ID as string)
        ReactPixel.pageView()
        events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }, [events])

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID as string })
  }, [])

  return (
    <>
      <LiveChatWidget license="13190706" visibility="minimized" />
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  )
}

export default MyApp
