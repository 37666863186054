import { SVGProps } from 'react'

const ImgSheetOfPaperGlitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.a1617905-4b5c-497d-bb0f-82dd8b234304{fill:none;}.bd7d2924-209a-4e41-93d4-26969acb6a29{opacity:0.15;fill:url(#a66f7b1b-4808-4028-81ea-52d1372f60a7);}.b4d1a456-d196-4dcd-aa10-c011ea9ada9a{fill:url(#f8dcdc0d-b43a-4c92-8e51-48219057c8b3);}.b2b10288-5d46-4b32-bf37-1a81fcc23580{fill:url(#f0eba5a0-1039-4dd3-a582-f099d4cae841);}.afe20bbd-62ce-425c-a540-1b899a56086e{fill:url(#bc0039f3-18dd-48d8-a607-f5e191ff0e97);}.b8875cde-faee-4dc6-8e3f-44427edfce10{fill:#37c49f;}.af165d5f-1543-4f76-b2e3-89d6f0dba53f{fill:#fff;opacity:0.9;isolation:isolate;}'
        }
      </style>
      <linearGradient
        id="a66f7b1b-4808-4028-81ea-52d1372f60a7"
        x1={77.47}
        y1={437.71}
        x2={265.21}
        y2={249.97}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="f8dcdc0d-b43a-4c92-8e51-48219057c8b3"
        x1={262.83}
        y1={11740.45}
        x2={404.06}
        y2={11881.68}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f7ced7" />
        <stop offset={1} stopColor="#f9d8df" />
      </linearGradient>
      <linearGradient
        id="f0eba5a0-1039-4dd3-a582-f099d4cae841"
        x1={279.57}
        y1={11773.97}
        x2={378.6}
        y2={11872.99}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f2688c" />
        <stop offset={1} stopColor="#ef426f" />
      </linearGradient>
      <linearGradient
        id="bc0039f3-18dd-48d8-a607-f5e191ff0e97"
        x1={346.18}
        y1={11860.55}
        x2={124.39}
        y2={11638.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 12009.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#281fa9" />
        <stop offset={1} stopColor="#5851bc" />
      </linearGradient>
    </defs>
    <g id="e6a51b4c-81b3-480a-b16d-d57f7d4f840a" data-name="Layer 1">
      <g id="ea58fb06-0ea9-4a14-ae45-434f8715ab0e" data-name="backdrop">
        <path
          className="a1617905-4b5c-497d-bb0f-82dd8b234304"
          d="M512,256c0,141.4-114.6,256-256,256-7.8,0-15.5-.4-23.1-1A256,256,0,0,1,1.6,284.9,275.3,275.3,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="bd7d2924-209a-4e41-93d4-26969acb6a29"
        d="M379.5,364.3,232.9,511a255.6,255.6,0,0,1-139-56.8L57.8,418.1A255.7,255.7,0,0,1,1.6,284.9L132.7,153.8Z"
      />
      <path
        className="b4d1a456-d196-4dcd-aa10-c011ea9ada9a"
        d="M204.8,115.2V352a19.2,19.2,0,0,1-19.2,19.2H364.8A19.2,19.2,0,0,0,384,352h0V115.2l-12.8-12.8-12.8,12.8-12.8-12.8-12.8,12.8L320,102.4l-12.8,12.8-12.8-12.8-12.8,12.8-12.8-12.8L256,115.2l-12.8-12.8-12.8,12.8-12.8-12.8Z"
      />
      <path
        className="b2b10288-5d46-4b32-bf37-1a81fcc23580"
        d="M345.6,352V166.4a19.2,19.2,0,0,0-19.2-19.2H147.2a19.2,19.2,0,0,1,19.2,19.2V352a19.2,19.2,0,0,0,19.2,19.2H364.8A19.2,19.2,0,0,1,345.6,352Z"
      />
      <path
        className="afe20bbd-62ce-425c-a540-1b899a56086e"
        d="M307.2,166.4a19.2,19.2,0,0,1,19.2-19.2H147.2A19.2,19.2,0,0,0,128,166.4V396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8L192,409.6l12.8-12.8,12.8,12.8,12.8-12.8,12.8,12.8L256,396.8l12.8,12.8,12.8-12.8,12.8,12.8,12.8-12.8Z"
      />
      <path
        className="b8875cde-faee-4dc6-8e3f-44427edfce10"
        d="M159.2,178.6h-6.4v-6.3a1.6,1.6,0,0,0-3.2,0v6.3h-6.4a1.6,1.6,0,0,0-1.6,1.6,1.6,1.6,0,0,0,1.6,1.6h6.4v6.4a1.6,1.6,0,0,0,3.2,0v-6.4h6.4a1.6,1.6,0,0,0,1.6-1.6A1.7,1.7,0,0,0,159.2,178.6Z"
      />
      <path
        className="b8875cde-faee-4dc6-8e3f-44427edfce10"
        d="M323.8,291.1a7.9,7.9,0,0,1-8-7.9,8,8,0,0,1,16,0A7.9,7.9,0,0,1,323.8,291.1Zm0-12.7a4.8,4.8,0,0,0-4.8,4.8,4.8,4.8,0,0,0,9.6,0A4.8,4.8,0,0,0,323.8,278.4Z"
      />
      <path
        className="b8875cde-faee-4dc6-8e3f-44427edfce10"
        d="M122.3,333.7a1.6,1.6,0,0,1-.9-.3l-11.8-8.9-11.8,8.9a1.7,1.7,0,0,1-1.9,0l-12.8-9.6a1.6,1.6,0,0,1-.3-2.2,1.7,1.7,0,0,1,2.3-.3l11.7,8.8,11.8-8.8a1.8,1.8,0,0,1,2,0l11.7,8.8,11.8-8.8a1.7,1.7,0,0,1,2.3.3,1.6,1.6,0,0,1-.3,2.2l-12.8,9.6A1.7,1.7,0,0,1,122.3,333.7Z"
      />
      <rect className="b8875cde-faee-4dc6-8e3f-44427edfce10" x={233.6} y={355.2} width={44.8} height={12.8} />
      <path
        className="af165d5f-1543-4f76-b2e3-89d6f0dba53f"
        d="M278.4,213.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="af165d5f-1543-4f76-b2e3-89d6f0dba53f"
        d="M278.4,245.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="af165d5f-1543-4f76-b2e3-89d6f0dba53f"
        d="M278.4,277.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="af165d5f-1543-4f76-b2e3-89d6f0dba53f"
        d="M278.4,309.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
      <path
        className="af165d5f-1543-4f76-b2e3-89d6f0dba53f"
        d="M278.4,341.2H156.8a2,2,0,0,1,0-4H278.4a2,2,0,0,1,0,4Z"
      />
    </g>
  </svg>
)

export default ImgSheetOfPaperGlitter
