import { SVGProps } from 'react'

const ImgTwoPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.b4619db4-da0a-41ea-ba26-c0d5e08e90b3{fill:none;}.b9e900cb-0f6b-43a3-82f9-f975ba34c736{opacity:0.2;fill:url(#a3f60937-2c76-44f8-83ea-5ef0ca6e62f0);}.e57eec2c-4c35-4394-a2b9-93b636752da0{fill:url(#ab78cca3-5a8a-4b61-8b5c-602c8e05a39c);}.ee1ac686-c600-4cd2-aae7-6edc6e9903e2{fill:url(#a03c53d1-9a61-4358-981b-0455570901d4);}.b750b2a1-6feb-434c-910b-6ca0394c5508{fill:url(#bb4cf330-5945-40c0-9c32-9e44b41b33f6);}.bafc591b-b3fd-45f7-93f5-a23e794329fc{fill:url(#fcecc547-e9db-4812-866a-6a595d4706cb);}.e047ee3f-ad2e-4a65-8c79-b06c439606d9{fill:#fff;}.ee8a8c2a-be90-408a-9780-2bf6789af006{fill:url(#f71bb28b-bd6c-47d7-96bb-3a74bf8be353);}.e4821712-9682-456d-a9e0-8a1c58ca1ad3{fill:url(#bb8c8e45-130b-46c4-b2e0-1e81c3d8aa45);}.aae8d580-7ee5-4bc3-8775-aaa7d288c3bf{fill:url(#ab8150c6-4f79-49cf-b795-ad5209cd244a);}.a9969a22-b5dd-4c18-8c0a-d1c1001c1b30{fill:url(#b21e6f13-438a-4ec5-b481-5b21eb6cc630);}.ee7289e4-786e-421a-a4f7-68a8078183e4{fill:url(#b0946eb2-13d2-4f2f-afd4-a5b84baba785);}.ae630ff6-058c-45d8-bbe0-2d157786c08a{fill:url(#b04df73b-713f-4d4a-8561-991cb7326480);}.ff1a5097-2257-4414-8ed6-beae44c534e9{fill:url(#b0387557-522a-4040-8f63-b8a961dbdcf5);}.fbf2ed4d-e761-45ec-ba2d-77c87f401b14{fill:url(#f1fd2aed-adf3-4392-a6aa-69418f1f5de6);}.e7df08e8-01ef-4d56-9f67-62478117b2f5{fill:url(#b6ebff38-06b2-4e39-8e56-72a5b02cca51);}.bf34b7f0-e8e6-4ba0-a852-805e3060c3b7{fill:url(#b9927cd4-6393-49fa-b3ee-ffe29d617341);}.ee2782ad-b3f6-45be-9c43-e408d52ee279{fill:url(#ab7ac387-4cf7-4165-adc4-dc214f0a12c0);}'
        }
      </style>
      <linearGradient
        id="a3f60937-2c76-44f8-83ea-5ef0ca6e62f0"
        x1={100.88}
        y1={461.63}
        x2={331.33}
        y2={231.18}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" stopOpacity={0} />
        <stop offset={0.15} stopColor="#3f37b2" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#3b33b0" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#352cae" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#2c23aa" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#2117a6" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#1309a0" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="ab78cca3-5a8a-4b61-8b5c-602c8e05a39c"
        x1={149.49}
        y1={12990.08}
        x2={275.97}
        y2={13116.56}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="a03c53d1-9a61-4358-981b-0455570901d4"
        x1={263.94}
        y1={13034.08}
        x2={204.65}
        y2={13051.35}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f1557d" />
        <stop offset={1} stopColor="#f47b9a" />
      </linearGradient>
      <linearGradient
        id="bb4cf330-5945-40c0-9c32-9e44b41b33f6"
        x1={252.18}
        y1={12977.17}
        x2={377.91}
        y2={13102.9}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="fcecc547-e9db-4812-866a-6a595d4706cb"
        x1={286.32}
        y1={12986.72}
        x2={381.14}
        y2={13081.53}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#281fa9" />
      </linearGradient>
      <linearGradient
        id="f71bb28b-bd6c-47d7-96bb-3a74bf8be353"
        x1={282.35}
        y1={13105.01}
        x2={313.65}
        y2={13136.3}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fdf0f3" />
        <stop offset={1} stopColor="#fefafb" />
      </linearGradient>
      <linearGradient
        id="bb8c8e45-130b-46c4-b2e0-1e81c3d8aa45"
        x1={159.28}
        y1={12960.21}
        x2={173.13}
        y2={12974.06}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        xlinkHref="#f71bb28b-bd6c-47d7-96bb-3a74bf8be353"
      />
      <linearGradient
        id="ab8150c6-4f79-49cf-b795-ad5209cd244a"
        x1={188.8}
        y1={13121.6}
        x2={227.2}
        y2={13121.6}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        xlinkHref="#f71bb28b-bd6c-47d7-96bb-3a74bf8be353"
      />
      <linearGradient
        id="b21e6f13-438a-4ec5-b481-5b21eb6cc630"
        x1={-269.41}
        y1={13085.44}
        x2={-230.54}
        y2={13113.01}
        gradientTransform="translate(41 13233.6) rotate(180)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="b0946eb2-13d2-4f2f-afd4-a5b84baba785"
        x1={-237.41}
        y1={13085.44}
        x2={-198.54}
        y2={13113.01}
        gradientTransform="translate(-23 13233.6) rotate(180)"
        xlinkHref="#b21e6f13-438a-4ec5-b481-5b21eb6cc630"
      />
      <linearGradient
        id="b04df73b-713f-4d4a-8561-991cb7326480"
        x1={167.51}
        y1={12916.69}
        x2={222.82}
        y2={12893.75}
        gradientTransform="matrix(1, 0, 0, -1, 0, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" />
        <stop offset={1} stopColor="#4038b2" />
      </linearGradient>
      <linearGradient
        id="b0387557-522a-4040-8f63-b8a961dbdcf5"
        x1={201.26}
        y1={12937.93}
        x2={274.44}
        y2={12864.76}
        xlinkHref="#b04df73b-713f-4d4a-8561-991cb7326480"
      />
      <linearGradient
        id="f1fd2aed-adf3-4392-a6aa-69418f1f5de6"
        x1={256.7}
        y1={12930.89}
        x2={321.75}
        y2={12865.84}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="b6ebff38-06b2-4e39-8e56-72a5b02cca51"
        x1={291.26}
        y1={12937.93}
        x2={364.44}
        y2={12864.76}
        gradientTransform="matrix(1, 0, 0, -1, 6, 13233.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#37c49f" />
        <stop offset={1} stopColor="#5fd0b2" />
      </linearGradient>
      <linearGradient
        id="b9927cd4-6393-49fa-b3ee-ffe29d617341"
        x1={249.28}
        y1={12960.21}
        x2={263.13}
        y2={12974.06}
        xlinkHref="#f71bb28b-bd6c-47d7-96bb-3a74bf8be353"
      />
      <linearGradient
        id="ab7ac387-4cf7-4165-adc4-dc214f0a12c0"
        x1={331.46}
        y1={12958.81}
        x2={348.13}
        y2={12975.47}
        xlinkHref="#f71bb28b-bd6c-47d7-96bb-3a74bf8be353"
      />
    </defs>
    <g id="babbe528-d8a0-448b-9d6d-529e5649bebe" data-name="Layer 1">
      <g id="a4acba76-f8e0-43dd-a133-cacb732a7248" data-name="backdrop">
        <path
          className="b4619db4-da0a-41ea-ba26-c0d5e08e90b3"
          d="M512,256c0,127.3-93,233-214.8,252.7A256.3,256.3,0,0,1,6.3,312.8,259.5,259.5,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="b9e900cb-0f6b-43a3-82f9-f975ba34c736"
        d="M409.3,396.6,297.2,508.7a256.6,256.6,0,0,1-206.3-57L60.3,421a254.2,254.2,0,0,1-54-108.2L163,156l109,15.8,67.8,221.5Z"
      />
      <path
        className="e57eec2c-4c35-4394-a2b9-93b636752da0"
        d="M208,160l-19.2-19.2L171,149.7a25.7,25.7,0,0,0-14.2,22.9v86.6H208Z"
      />
      <path
        className="ee1ac686-c600-4cd2-aae7-6edc6e9903e2"
        d="M227.2,140.8,208,160v99.2h35.2V176a6.1,6.1,0,0,1,5.7-6.4h.7l73.6-12.8v-16Z"
      />
      <path
        className="b750b2a1-6feb-434c-910b-6ca0394c5508"
        d="M355.2,259.2H252.8V172.6A25.7,25.7,0,0,1,267,149.7l17.8-8.9L304,160l19.2-19.2,17.8,8.9a25.7,25.7,0,0,1,14.2,22.9Z"
      />
      <path
        className="bafc591b-b3fd-45f7-93f5-a23e794329fc"
        d="M355.2,259.2H304V160l19.2-19.2,17.8,8.9a25.7,25.7,0,0,1,14.2,22.9Z"
      />
      <path
        className="e047ee3f-ad2e-4a65-8c79-b06c439606d9"
        d="M270.4,260.8a1.6,1.6,0,0,1-1.6-1.6V176a1.6,1.6,0,0,1,3.2,0v83.2A1.6,1.6,0,0,1,270.4,260.8Z"
      />
      <path
        className="e047ee3f-ad2e-4a65-8c79-b06c439606d9"
        d="M337.6,260.8a1.6,1.6,0,0,1-1.6-1.6V176a1.6,1.6,0,1,1,3.2,0v83.2A1.6,1.6,0,0,1,337.6,260.8Z"
      />
      <path
        className="ee8a8c2a-be90-408a-9780-2bf6789af006"
        d="M304,89.6h0a19.2,19.2,0,0,1,19.2,19.2v9.6a16,16,0,0,1-16,16h-6.4a16,16,0,0,1-16-16v-9.6A19.2,19.2,0,0,1,304,89.6Z"
      />
      <path
        className="e047ee3f-ad2e-4a65-8c79-b06c439606d9"
        d="M174.4,260.8a1.6,1.6,0,0,1-1.6-1.6V176a1.6,1.6,0,1,1,3.2,0v83.2A1.6,1.6,0,0,1,174.4,260.8Z"
      />
      <path
        className="e4821712-9682-456d-a9e0-8a1c58ca1ad3"
        d="M156.8,259.2h16v16a3.2,3.2,0,0,1-3.2,3.2h0a12.8,12.8,0,0,1-12.8-12.8v-6.4Z"
      />
      <path
        className="aae8d580-7ee5-4bc3-8775-aaa7d288c3bf"
        d="M208,89.6h0a19.2,19.2,0,0,1,19.2,19.2v9.6a16,16,0,0,1-16,16h-6.4a16,16,0,0,1-16-16v-9.6A19.2,19.2,0,0,1,208,89.6Z"
      />
      <polygon className="a9969a22-b5dd-4c18-8c0a-d1c1001c1b30" points="304 160 323.2 140.8 284.8 140.8 304 160" />
      <polygon className="ee7289e4-786e-421a-a4f7-68a8078183e4" points="208 160 227.2 140.8 188.8 140.8 208 160" />
      <polygon
        className="ae630ff6-058c-45d8-bbe0-2d157786c08a"
        points="195.2 393.6 172.8 393.6 172.8 259.2 208 259.2 208 291.2 195.2 393.6"
      />
      <polygon
        className="ff1a5097-2257-4414-8ed6-beae44c534e9"
        points="243.2 393.6 220.8 393.6 208 291.2 208 259.2 243.2 259.2 243.2 393.6"
      />
      <polygon
        className="fbf2ed4d-e761-45ec-ba2d-77c87f401b14"
        points="291.2 393.6 268.8 393.6 268.8 259.2 304 259.2 304 291.2 291.2 393.6"
      />
      <polygon
        className="e7df08e8-01ef-4d56-9f67-62478117b2f5"
        points="339.2 393.6 316.8 393.6 304 291.2 304 259.2 339.2 259.2 339.2 393.6"
      />
      <path
        className="bf34b7f0-e8e6-4ba0-a852-805e3060c3b7"
        d="M252.8,259.2h16v16a3.2,3.2,0,0,1-3.2,3.2h0a12.8,12.8,0,0,1-12.8-12.8v-6.4Z"
      />
      <path
        className="ee2782ad-b3f6-45be-9c43-e408d52ee279"
        d="M339.2,259.2h16v6.4a12.8,12.8,0,0,1-12.8,12.8h0a3.2,3.2,0,0,1-3.2-3.2v-16Z"
      />
      <path className="e047ee3f-ad2e-4a65-8c79-b06c439606d9" d="M408,397.2H104a2,2,0,0,1,0-4H408a2,2,0,0,1,0,4Z" />
    </g>
  </svg>
)

export default ImgTwoPeople
