import { SVGProps } from 'react'

const ImgPuzzleInHand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    {...props}
  >
    <defs>
      <style>
        {
          '.e835f5de-eefa-40a3-9a1b-6e05c1bbc83a{fill:none;}.a125129a-81e7-4f0e-86c4-0bed0a61d814{opacity:0.15;fill:url(#f6ef8ded-3b76-4735-8743-d019ada889f0);}.fb4228d7-cbd8-4908-b097-fb1b39b44b79{fill:url(#e3710555-a452-4878-b79d-99675f85c918);}.a92f2806-3ec5-4405-a60d-7707257d51a0{fill:url(#a4c549a8-64cb-4e59-ac73-98203135b786);}.bc6cf97d-f828-4b1a-ae60-ae0091de280b{fill:url(#fa1f8903-5c4e-4d39-8dc9-04e3ccb5d19b);}.e8d8cd88-b9e4-4e3e-96d9-58426ce85d67{fill:#fff;}'
        }
      </style>
      <linearGradient
        id="f6ef8ded-3b76-4735-8743-d019ada889f0"
        x1={78.35}
        y1={440.37}
        x2={271.53}
        y2={247.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#10069f" stopOpacity={0} />
        <stop offset={0.15} stopColor="#10069f" stopOpacity={0.03} />
        <stop offset={0.31} stopColor="#10069f" stopOpacity={0.1} />
        <stop offset={0.47} stopColor="#10069f" stopOpacity={0.23} />
        <stop offset={0.64} stopColor="#10069f" stopOpacity={0.42} />
        <stop offset={0.8} stopColor="#10069f" stopOpacity={0.65} />
        <stop offset={0.97} stopColor="#10069f" stopOpacity={0.94} />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="e3710555-a452-4878-b79d-99675f85c918"
        x1={227.36}
        y1={14223.01}
        x2={435.61}
        y2={14014.76}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4038b2" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
      <linearGradient
        id="a4c549a8-64cb-4e59-ac73-98203135b786"
        x1={321.07}
        y1={14168.52}
        x2={186.09}
        y2={14303.5}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef426f" />
        <stop offset={1} stopColor="#f2688c" />
      </linearGradient>
      <linearGradient
        id="fa1f8903-5c4e-4d39-8dc9-04e3ccb5d19b"
        x1={296.02}
        y1={14181.86}
        x2={347.69}
        y2={14130.19}
        gradientTransform="matrix(1, 0, 0, -1, 0, 14457.6)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#5851bc" />
        <stop offset={1} stopColor="#10069f" />
      </linearGradient>
    </defs>
    <g id="b1851706-de23-4fca-b7fd-1bbabc1005f1" data-name="Layer 1">
      <g id="af1c4eb0-eac2-4699-80a9-7da5910fea36" data-name="backdrop">
        <path
          className="e835f5de-eefa-40a3-9a1b-6e05c1bbc83a"
          d="M512,256c0,141.4-114.6,256-256,256a260.7,260.7,0,0,1-49.6-4.8,255.9,255.9,0,0,1-129.2-68l-.6-.6-3.2-3.2-.6-.6A254.9,254.9,0,0,1,0,256C0,114.6,114.6,0,256,0S512,114.6,512,256Z"
        />
      </g>
      <path
        className="a125129a-81e7-4f0e-86c4-0bed0a61d814"
        d="M304,409.6l-97.6,97.6a255.9,255.9,0,0,1-129.2-68l-.6-.6-3.2-3.2-.6-.6A255.9,255.9,0,0,1,7.2,316.6L174.1,149.8Z"
      />
      <path
        className="fb4228d7-cbd8-4908-b097-fb1b39b44b79"
        d="M332.8,208a20.9,20.9,0,0,0-16,7V147.2A19.2,19.2,0,0,0,297.6,128h-3.2a6.4,6.4,0,0,0-6.4,6.4v-16a16,16,0,1,0-32,0v19.2a6.4,6.4,0,0,0-6.4-6.4h-3.2a19.2,19.2,0,0,0-19.2,19.2v32a6.4,6.4,0,0,0-6.4-6.4h-3.2a19.2,19.2,0,0,0-19.2,19.2h0V300.7a36.9,36.9,0,0,0,2.2,12.7L215.4,365a36.9,36.9,0,0,1,2.2,12.7v31.9H304V366.8a38.2,38.2,0,0,1,6.4-21.2h0l22.4-38.2a38.3,38.3,0,0,0,6.4-21.3V214.4A6.4,6.4,0,0,0,332.8,208Z"
      />
      <path
        className="a92f2806-3ec5-4405-a60d-7707257d51a0"
        d="M383.8,221a25.5,25.5,0,0,0-28.3-22.4,24.7,24.7,0,0,0-11.4,4.2,3.2,3.2,0,0,1-4.4-1,3.4,3.4,0,0,1-.5-1.7V153.6a6.4,6.4,0,0,0-6.4-6.4H279.9a3.2,3.2,0,0,1-3.2-3.2,3.4,3.4,0,0,1,.5-1.7,25.6,25.6,0,1,0-42.4,0,3.2,3.2,0,0,1-1,4.4,3.4,3.4,0,0,1-1.7.5H179.2a6.4,6.4,0,0,0-6.4,6.4v46.5a3.2,3.2,0,0,1-3.2,3.2,3.4,3.4,0,0,1-1.7-.5,25.6,25.6,0,1,0,0,42.4,3.2,3.2,0,0,1,4.4,1,3.4,3.4,0,0,1,.5,1.7v46.5a6.4,6.4,0,0,0,6.4,6.4h54.6a3.2,3.2,0,0,0,3.2-3.2,2.8,2.8,0,0,0-.4-1.5,22.5,22.5,0,1,1,38.7,0,3.2,3.2,0,0,0,1.3,4.3,2.9,2.9,0,0,0,1.6.4h54.6a6.4,6.4,0,0,0,6.4-6.4V247.9a3.2,3.2,0,0,1,3.2-3.2,3.4,3.4,0,0,1,1.7.5,25.5,25.5,0,0,0,35.5-6.9,24.6,24.6,0,0,0,4.2-17.3Z"
      />
      <path
        className="bc6cf97d-f828-4b1a-ae60-ae0091de280b"
        d="M333,208c-14.2-.1-22.6,11.4-22.6,25.6v35.2c-34.5,5.7-48,32-48.6,52.4-.4,13.8.6,33.6,13.2,39.2l29,6.4a38.2,38.2,0,0,1,6.4-21.2h0l22.4-38.2a38.3,38.3,0,0,0,6.4-21.3V214.4A6.3,6.3,0,0,0,333,208Z"
      />
      <path className="e8d8cd88-b9e4-4e3e-96d9-58426ce85d67" d="M256,230a6,6,0,1,1,6-6A6,6,0,0,1,256,230Z" />
      <path className="e8d8cd88-b9e4-4e3e-96d9-58426ce85d67" d="M230.4,230a6,6,0,1,1,6-6A6,6,0,0,1,230.4,230Z" />
      <path className="e8d8cd88-b9e4-4e3e-96d9-58426ce85d67" d="M281.6,230a6,6,0,1,1,6-6A6,6,0,0,1,281.6,230Z" />
    </g>
  </svg>
)

export default ImgPuzzleInHand
